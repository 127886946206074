import React, { Component } from "react";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";

class SWPsection extends Component {
  constructor(props) {
    super(props);
    this.ReadMore = this.ReadMore.bind(this);
    this.state = {
      readMore: false,
      data: {},
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 2,
      },
    };
  }
  componentDidMount() {
    this.GetContent();
  };
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "homesections";
    ListRequestModel.searchString = "swp";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
        content1: data.result[1]
      });
    }
  };

  ReadMore() {
    this.setState({ readMore: !this.state.readMore });
  }
  render() {
    const { content, content1 } = this.state;
    return (
      <section className="repair-epc-area">
        {content &&
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="epc-repair-content m-4">
                  <h3>{content.title}</h3>
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.content,
                      }}
                    ></div>

                    {content1 ? this.state.readMore ?
                      <span id="more">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content1.content,
                          }}
                        ></div>

                      </span>
                      :
                      <span className={"pointer text-info"} onClick={() => this.ReadMore()}>
                        Read more
                </span> : ""}
                  </p>

                  <a href={content.link} target="_blank" rel="noopener noreferrer">
                    <button className="btn scheme">{content.linklabel}</button>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="epc-repair-img">
                  <img src={content.image} alt="source" />
                </div>
              </div>
            </div>
          </div>
        }
        <div className="circle-box">
          {/* <img src={"/img/circle.png"} alt="source" /> */}
        </div>
        <div className="epc-shape">
          <img src={"/img/cta-shape.png"} alt="source" />
        </div>
      </section>
    );
  }
}

export default SWPsection;
