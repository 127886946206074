//import React, { Fragment } from "react";
import React, { Component } from "react";

import FooterSection from "../../components/FooterSection/Footer";
import Team from "../../components/TeamSection/Team";
import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";

// Modal Video
// import images
class GoverningBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    // this.GetContent();
    this.setState({
      loading: false,
    });
  }

  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <Team />
          <FooterSection />
        </div>
      );
  }
}

export default GoverningBody;
