import React, { Component, Fragment } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { CallService } from "../helpers/servicecall";
import { MethodType } from "../constants/defaultValues";
import { cms } from "../constants/config";
class MissionSection extends Component {
  state = {
    data: {},
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1,
    },
    features: [],
  };
  componentDidMount() {
    this.setState({ heading: "What Makes FaMe TN Different?" });
    this.GetCMS("our vision");
  }
  GetCMS(search) {
    const { ListRequestModel } = this.state;
    ListRequestModel.searchString = search;
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetCMSResponse
    );
  }

  GetCMSResponse = (data) => {
    if (data.result) {
      var contResult = data.result.map((tag) => {
        return { Content: tag.Content.replace(/&lt;/g, "<"), Title: tag.Title };
      });
      var features = this.state.features;

      features = features.concat(contResult);

      if (features.length < 2) this.GetCMS("our mission");

      this.setState({
        features: features,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <section
          id="features"
          className="style-two overflow-hidden"
          style={{ marginBottom: "50px" }}
        >
          {this.state.features.map((feature, key) => (
            <Fragment>
              {feature.Title === "Our Mission" && (
                <Card style={{ height: "100%" }} className="features">
                  {/* <span className="h1 icon2 text-primary">
                      <i className={feature.icon}></i>
                    </span> */}
                  <CardBody className="p-0" style={{ backgroundColor: '#F9F4D8' }}>
                    {/* <p className="para text-muted mb-0">{feature.Content}</p> */}
                    {/* <div class="image-box media px-1 py-3 py-md-4">*/}
                    <Row className='vision-img mission-img  text-center'>
                      <Col lg="2">
                        <img src={"/img/mission.png"}
                          className='mt-5 ml-4'
                          alt="mission"
                        />
                      </Col>
                      <Col lg="10" className='p-4'  style={{ marginLeft: "-3%" }} >
                        <div>

                          {/*<img class="avatar-sm" src="" alt=""></img>*/}
                          <h3>
                            {feature.Title}
                          </h3>
                          <div
                            className="icon-text media-body align-self-center align-self-md-start mt-2"
                            dangerouslySetInnerHTML={{
                              __html: feature.Content,
                            }}
                          ></div>
                        </div>
                      </Col>

                    </Row>
                    {/*</div>*/}
                  </CardBody>
                </Card>
              )}
            </Fragment>
          ))}
        </section>
      </Fragment>
    );
  }
}


export default MissionSection;
