import React from "react";
import Header from "../../components/HeaderSection/Header";
import FooterSection from "../../components/FooterSection/Footer";
import Breadcrumb from "../../components/Blogs/Breadcrumb";
import { CallService } from "../../helpers/servicecall";
import { cms } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { Col, Container, Row } from "reactstrap";
import Loader from "../../common/loader";
import bg from "../../img/insurance/business.jpg";
class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetCMS();
  }
  GetCMS() {
    const { ListRequestModel } = this.state;
    ListRequestModel.searchString = "privacy";
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetCMSResponse
    );
  }

  GetCMSResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result && data.result[0],
        loading: false
      });
    }
  };
  render() {
    const { content } = this.state;
    return this.state.loading ? (
      <Loader />
    ) : (
        <div>
          <div id="scrollUp" title="Scroll To Top">
            <i className="fas fa-arrow-up" />
          </div>
          {content && (
            <div className="main epc">
              <Header imageData={"/img/logo-white.png"} />
              <Breadcrumb title="Privacy Policy" bg={JSON.parse(sessionStorage.getItem("menu")) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0] && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg ? JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg : bg} />
              <div className="position-relative">
                <div className="shape overflow-hidden text-white">
                  <svg
                    viewBox="0 0 2880 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </div>
              </div>
              <section className="section">
                <Container>
                  <Row className="">
                    <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <div className=" ml-lg-4">
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: content.Content,
                            }}
                          ></div>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          )}{" "}
          <FooterSection />
        </div>
      );
  }
}

export default PrivacyPolicy;
