/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
// import Exportexcel from "../../components/Export/Exportexcel";
import FooterSection from "../../components/FooterSection/Footer";
import { Container } from "reactstrap";

import { Row } from "reactstrap";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
// const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneTeamSection";

class ExportExcellence extends Component {
  state = {
    data: {},
    teamIcons: [],
    ListGroupRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    contents: [],
    isVisblecontent: "",
  };
  componentDidMount() {
    this.GetContent();
    this.GetGroup();
  }

  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "associationlistgroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
        isVisblecontent: data.result[0],
      });
  };
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "associationlist";
    ListRequestModel.sortCondition = { name: 1 };
    ListRequestModel.searchString = this.state.searchString;;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      var contents = data.result;
      // .sort((a, b) => a.name.localeCompare(b.name))
      this.setState({
        firstcontent: contents[0],
        content: contents,
        loading: false,
      });
    }
  };



  getDetails = (item) => {
    this.setState({
      person: item,
    });
  };

  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };


  render() {
    // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { isVisblecontent } = this.state;

    var orgArray = this.state.content ? this.state.content.sort() : '';

    return (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <section className="m-5">
          <Container>
            <div className="row">
              <div className="col-12 col-lg-3">
                <div>
                  <aside className="sidebar">
                    <div className="single-widget dropdown">
                      {/* Category Widget */}
                      <div
                        className="accordions widget catagory-widget"
                        id="cat-accordion"
                      >
                        <div className="single-accordion blog-accordion">
                          <h5>
                            <a
                              role="button"
                              className="collapse show text-uppercase d-block p-2"
                            // data-toggle="collapse"
                            >
                              Trade Associations / Chambers
                                  </a>
                          </h5>
                          {/* Category Widget Content */}
                          <div
                            id="accordion1"
                            className="accordion-content widget-content collapse show"
                            data-parent="#cat-accordion"
                          >
                            {/* Category Widget Items */}
                            {this.state.contents && (
                              <ul className="widget-items sidebar">
                                {this.state.groups && this.state.groups.map((item, idx) => {
                                  return (
                                    !item.parentgroup &&
                                    <li key={`wdo_${idx}`} className={(item._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                      <a
                                        role="button"
                                        className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                        // data-toggle="collapse"
                                        // href="/Export-Schemes"
                                        onClick={() =>
                                          this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) && this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).length === 0 && this.toggleSchemesTitle(item)
                                        }
                                      >
                                        <span>{item.name}</span>
                                        {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) &&

                                          <ul className="widget-items sidebar">
                                            {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).map((itemsub, idx) => {
                                              return (
                                                <li key={`wdo_${idx}`} className={(itemsub._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                                  <a
                                                    role="button"
                                                    className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                                    style={{ zIndex: 10 }}
                                                    onClick={() =>
                                                      this.toggleSchemesTitle(itemsub)
                                                    }
                                                  >
                                                    <span>{itemsub.name}</span>
                                                  </a>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        }
                                      </a>

                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                <div className="row m-0">
                  <div className="col-8" >
                    <h3>
                      {isVisblecontent && isVisblecontent.name}
                    </h3>
                  </div>
                  <div className="col-lg-4 col-10">
                    <div className="single-widget">
                      {/* Search Widget */}
                      <div className="widget-content search-widget">
                        <input type="text" placeholder="Enter your keywords to search" onChange={(e) => { this.setState({ searchString: e.target && e.target.value }, () => this.GetContent()) }} />
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  {orgArray &&
                    orgArray.map((obj, i) => {
                      return (
                        obj.group && isVisblecontent._id === obj.group._id && (
                          <div className="col-lg-6 col-12" >
                            <div className="contact-us border border-gray mb-4 contact-box  contact-box-transition p-4" style={{ minHeight: "300px" }}>

                              <h4 style={{ minHeight: "50px" }}>{obj.name}</h4>
                              <ul>

                                {obj.person && <li key={`ci_${1}`} className=" row">
                                  <div className="social-icon mr-3 col-1">
                                    <i class="fas fa-user" />
                                  </div>

                                  <span className="media-body align-self-center col-10">
                                    {obj.person}
                                  </span>
                                </li>}


                                {obj.address1 && <li key={`ci_${1}`} className=" row">
                                  <div className="social-icon mr-3 col-1">
                                    <i className={"fas fa-paper-plane"} />
                                  </div>

                                  <span className="media-body align-self-center col-10">
                                    {obj.address1} {obj.address2 && <span>,</span>}
                                  </span>
                                </li>
                                }
                                {obj.address2 && <li key={`ci_${1}`} className=" row" style={{ marginTop: "-10px" }}>
                                  <div className="social-icon mr-3 col-1" />
                                  <span className="media-body align-self-center col-10">
                                    {obj.address2}
                                  </span>
                                </li>
                                }
                                {obj.contact &&

                                  <li key={`ci_${3}`} className=" row">
                                    <div className="social-icon mr-3 col-1">
                                      <i class="fas fa-phone-square-alt"></i>
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {obj.contact}
                                    </span>
                                  </li>
                                }
                                {obj.mobile &&
                                  <li key={`ci_${2}`} className=" row">
                                    <div className="social-icon mr-3 col-1">
                                      <i class="fas fa-mobile-alt" />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {obj.mobile}
                                    </span>
                                  </li>
                                }

                                {/* <li key={`ci_${2}`} className=" row">
                                      <div className="social-icon mr-3 col-1">
                                        <i class="fas fa-map-marker-alt"/>
                                      </div>
                                      <span className="media-body align-self-center col-10">
                                        {obj.district.name}
                                      </span>
                                    </li> */}

                              </ul>
                            </div>
                          </div>
                        )
                      );
                    })}
                </Row>
              </div>
            </div>
          </Container>
        </section>
        <FooterSection />
      </div>
    );
  }
}

export default ExportExcellence;



// export default ExportExcellence;
