import React, { Component } from "react";

import FooterSection from "../../components/FooterSection/Footer";
//import FeatureSection from "../../components/Features/FeatureOne";
//import ServiceSection from "../../components/ServiceSection/ServiceOne";

import InsurancePlans from "./InsurancePlans";

import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";

class Policies extends Component {
  render() {
    return (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <section className="m-5">
          <InsurancePlans />
        </section>
        <FooterSection />
      </div>
    );
  }
}

export default Policies;
