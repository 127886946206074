import React, { Component } from "react";

class PointsDetails extends Component {
  state = {
    data: {},
    iconList: [],
    commentsData: [],
  };
  render() {
    var content = this.props.content;
    return (
      <div>
        {/* Single Blog Details */}
        <article className="single-blog-details">
          {/* Blog Thumb */}

          {/* Blog Content */}
          {content && (
            <div className="blog-content sApp-blog">
              {/* Meta Info */}
              <div className="meta-info d-flex flex-wrap align-items-center py-2">
                <h4>{content.name}</h4>
              </div>
              {/* Blog Details */}

              <div className="blog-details">
                <p className="d-none d-sm-block">
                  <div
                    className="overflow-wb"
                    dangerouslySetInnerHTML={{
                      __html: content.content,
                    }}
                  ></div>
                </p>
              </div>
            </div>
          )}
        </article>
      </div>
    );
  }
}

export default PointsDetails;
