import React from "react";
import OwlCarousel from "react-owl-carousel";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";

const slideOptions = {
  loop: true,
  nav: false,
  autoplay: true,
  autoplayHoverPause: true,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 6,
    },
    1500: {
      items: 6,
    },
  },
};
class Associates extends React.Component {
  state = {
    data: {},
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
  };
  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "associateddepartment";
    ListRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result)
      this.setState({
        contents: data.result,
      });
  };
  render() {
    return (
      <section className="works-area" style={{ backgroundColor: 'rgba(234, 236, 255, 0.5)' }}>
        <div className="container ">
          <div className="section-title" style={{ marginTop: "5%" }}>
            <h2 className="mb-3">Departments</h2>
            <p className="mb-5">
              MSMEs in the state are supported by various departments. Please find below the quick links to the departments working towards development of the MSME sector.
               </p>
          </div>
        </div>

        {this.state.contents && (
          <div className="row m-0" style={{ height: "200px" }}>
            {/* <div className="works-slides"> */}
            <OwlCarousel
              className="works-slides dept-slides owl-carousel owl-theme"
              {...slideOptions}
            >
              {this.state.contents.map((c) => (
                <div className="item" key={c._id}>
                  <div className="col-lg-12">
                    <div className=" associate-img">
                      <a href={c.link} target="_blank" rel="noopener noreferrer">
                        <img src={c.image} alt="source" />
                        <div className="works-content">
                          <h3 className="show-hover">
                            <p>{c.name}</p>
                          </h3>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>

            {/* </div> */}
          </div>
        )}
        <div className="shape8 rotateme">
          <img src={"img/shape2.svg"} alt="shape" />
        </div>
        <div className="shape2 rotateme">
          <img src={"img/shape2.svg"} alt="shape" />
        </div>
        <div className="shape7">
          <img src={"img/shape4.svg"} alt="shape" />
        </div>
        <div className="shape4">
          <img src={"img/shape4.svg"} alt="shape" />
        </div>
      </section>
    );
  }
}

export default Associates;
