import React, { Component } from "react";

class Newsletter extends Component {
  render() {
    return (
      <section className="free-trial-area">
        {/*  <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="free-trial-image">
                <img src={"/img/free-trial-img_1.png"} alt="trail" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="free-trial-content">
                <h2>Subscribe to our Newsletter</h2>

                <form className="newsletter-form ">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your business email here"
                  />
                  <button type="submit">Sign Up Free</button>
                </form>

                <p>
                  Learn about industry developments and prospects regularly.
                </p>
              </div>
            </div>
          </div>
    </div>*/}
        {/* Shape Images */}
        {/*<div className="shape2 rotateme">
          <img src={"img/shape2.svg"} alt="shape" />
        </div>
        <div className="shape4">
          <img src={"img/shape4.svg"} alt="shape" />
        </div>
        <div className="shape7">
          <img src={"img/shape4.svg"} alt="shape" />
        </div>
        <div className="shape8 rotateme">
          <img src={"img/shape2.svg"} alt="shape" />
    </div>*/}
      </section>
    );
  }
}

export default Newsletter;
