/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { Row, Container } from "reactstrap";
import KeyFeaturesBox from "../../components/Policies/PolicyFeaturesBox";
// const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneTeamSection";

class InsurancePlans extends Component {
  state = {
    data: {},
    teamIcons: [],
    keyFeatures: [
      {
        icon: "uil uil-home-alt",
        title: "Tamil Nadu Electronics Hardware Manufacturing policy 2020",
        link: "#",
      },
      {
        icon: "uil uil-user-arrows",
        title: "Tamil Nadu Industrial Policy 2014",
        link: "#",
      },
      {
        icon: "uil uil-user-circle",
        title: "Tamil Nadu Electric Vehicle Policy 2019",
        link: "#",
      },
      {
        icon: "uil uil-car",
        title: "Tamil Nadu Department Policy Note 2019-20",
        link: "#",
      },
      {
        icon: "uil uil-truck",
        title: "Tamil Nadu Aerospace & Defence Industrial Policy 2019",
        link: "#",
      },
      {
        icon: "uil uil-chart-pie-alt",
        title: "Tamil Nadu IT Policy Policy 2018",
        link: "#",
      },
      {
        icon: "uil uil-heartbeat",
        title: "Tamil Nadu Food Processing Policy 2018",
        link: "#",
      },
      {
        icon: "uil uil-chart-line",
        title: "Tamil Nadu Startup and Innovation Policy 2018-23",
        link: "#",
      },
      {
        icon: "uil uil-book-reader",
        title: "Tamil Nadu Food Processing Policy 2018",
        link: "#",
      },
      {
        icon: "uil uil-home-alt",
        title: "Tamil Nadu Solar Energy Policy 2019",
        link: "#",
      },
      {
        icon: "uil uil-user-arrows",
        title: "Tamil Nadu Ecotourism Policy 2017",
        link: "#",
      },
      {
        icon: "uil uil-user-circle",
        title: "Tamil Nadu Combined Development & Building Rules 2019",
        link: "#",
      },
      {
        icon: "uil uil-car",
        title: "Tamil Nadu Automobile and Auto Parts Policy 2014",
        link: "#",
      },
      {
        icon: "uil uil-truck",
        title: "Tamil Nadu Biotechnology Policy 2014",
        link: "#",
      },
      {
        icon: "uil uil-chart-pie-alt",
        title: "Tamil Nadu Business Facilitation Act 2018",
        link: "#",
      },
    ],
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListGroupRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    contents: [],
    isVisblecontent: "",
  };
  componentDidMount() {
    this.GetContent();
    this.GetGroup();
  }
  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "policygroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
        isVisblecontent: data.result[0]
      });
  };

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "policies";
    ListRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result.length > 0 ? data.result : [],
      });
    }
  };


  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };

  render() {

    const { isVisblecontent, contents } = this.state;

    return (

      <div>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <div className="main ">
          {/* <Header imageData={"/img/logo-white.png"} /> */}
          {/*<Breadcrumb title="Export Schemes" />*/}
          {/* <Breadcrumb title="Export Promotion Schemes" bg={JSON.parse(sessionStorage.getItem("menu")) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0] && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg ? JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg : bg} /> */}

          <section className="">
            <Container>
              <section id="blog" className="">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <div>
                        <aside className="sidebar">
                          <div className="single-widget">
                            {/* Category Widget */}
                            <div
                              className="accordions widget catagory-widget"
                              id="cat-accordion"
                            >
                              <div className="single-accordion blog-accordion">
                                <h5>
                                  <a
                                    role="button"
                                    className="collapse show text-uppercase d-block p-2"
                                  // data-toggle="collapse"
                                  >
                                    Policies
                                  </a>
                                </h5>
                                {/* Category Widget Content */}
                                <div
                                  id="accordion1"
                                  className="accordion-content widget-content collapse show"
                                  data-parent="#cat-accordion"
                                >
                                  {/* Category Widget Items */}
                                  <ul className="widget-items sidebar">
                                    {this.state.groups && this.state.groups.map((item, idx) => {
                                      return (
                                        !item.parentgroup &&
                                        <li key={`wdo_${idx}`} className={(item._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                          <a
                                            role="button"
                                            className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                            // data-toggle="collapse"
                                            // href="/Export-Schemes"
                                            onClick={() =>
                                              this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) && this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).length === 0 && this.toggleSchemesTitle(item)
                                            }
                                          >
                                            <span>{item.name}</span>
                                            {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) &&

                                              <ul className="widget-items sidebar">
                                                {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).map((itemsub, idx) => {
                                                  return (
                                                    <li key={`wdo_${idx}`} className={(itemsub._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                                      <a
                                                        role="button"
                                                        className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                                        style={{ zIndex: 10 }}
                                                        onClick={() =>
                                                          this.toggleSchemesTitle(itemsub)
                                                        }
                                                      >
                                                        <span>{itemsub.name}</span>
                                                      </a>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            }
                                          </a>

                                        </li>
                                      );
                                    })}
                                  </ul>

                                </div>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div className="col-12 col-lg-9">
                      <h3 >
                        {isVisblecontent && isVisblecontent.name}
                      </h3>
                      <Row>
                        <KeyFeaturesBox keyFeatures={contents} policyType={isVisblecontent} />
                        
                      </Row>
                    </div>
                  </div>
                </div>

              </section>
            </Container>
          </section>
        </div > {" "}
      </div >





    );
  }
}

export default InsurancePlans;