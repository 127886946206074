import React, { Component } from "react";
import { Col, Card, CardBody, CardText } from "reactstrap";

class TeamDetails extends Component {
  state = {
    data: {},
  };
  componentDidMount() {
    this.setState({
      data: {
        heading: "Governing Body",
        headingText: "",
        headingTexttwo: "",
      },
    });
  }
  render() {
    var personDetails = this.props.Person && this.props.Person;
    if (personDetails)
      return (
        <Col>
          <Card className={"text-center"}>
            <CardBody>
              <img
                width="50%"
                className={"m-auto"}
                src={
                  personDetails.photo ? personDetails.photo : "/img/Person.png"
                }
                alt=""
              />
              <CardText>
                <h3>{personDetails.name}</h3>
                <span>{personDetails.designation}</span>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: personDetails.remarks,
                    }}
                  ></div>
                </p>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      );
    else return "";
  }
}

export default TeamDetails;
