/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import TextTruncate from "../../common/TextTruncate";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
import { Modal, ModalBody } from "reactstrap";
import SingleBlogDetails from "../Blogs/SingleBlogDetails";
import {
  // EmailShareButton,
  FacebookShareButton,
  // HatenaShareButton,
  InstapaperShareButton,
  // LineShareButton,
  // LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  // WhatsappShareButton,
  // WorkplaceShareButton,
  TwitterIcon,
  FacebookIcon,
  InstapaperIcon,
} from "react-share";
import queryString from "query-string";

// const contents = [{ "title": "Resources", "content": "A report on investment opportunities for the MSME sector of Tamil Nadu with special focus on Singapore presented during the Singapore High Commissioners’ visit to Tamil Nadu " }]
// const groups = [{ "name": "Resources" }, { "name": "Blogs" }]

class KnowledgeCentre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      isOpen: false,
      isVisblecontent: "",
      detailsModal: false,
      currentContent: "",
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetGroup();
    this.GetContent();
  }
  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "knowledgecentergroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    debugger;
    if (data.result) {
      var param = queryString.parse(this.props.location.search);
      var isVisblecontent = param.SelectedPath
        ? data.result.find(
            (r) => (r.title && r.title.toLowerCase()) === param.SelectedPath
          )
        : data.result[0];
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
        isVisblecontent: isVisblecontent,
      });
    }
  };
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "knowledgecentercontent";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result,
        loading: false,
      });
    }
  };
  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };
  render() {
    const { isVisblecontent } = this.state;

    return this.state.loading ? (
      <Loader />
    ) : (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <section className="m-5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-3">
                <div>
                  <aside className="sidebar">
                    <div className="single-widget">
                      {/* Category Widget */}
                      <div
                        className="accordions widget catagory-widget"
                        id="cat-accordion"
                      >
                        <div className="single-accordion blog-accordion">
                          <h5>
                            <a
                              role="button"
                              className="collapse show text-uppercase d-block p-2"
                              // data-toggle="collapse"
                              href="/knowledge"
                            >
                              Knowledge Center
                            </a>
                          </h5>
                          {/* Category Widget Content */}
                          <div
                            id="accordion1"
                            className="accordion-content widget-content collapse show"
                            data-parent="#cat-accordion"
                          >
                            {/* Category Widget Items */}
                            {this.state.groups && (
                              <ul className="widget-items sidebar">
                                {this.state.groups.map((item, idx) => {
                                  return (
                                    <li
                                      key={`wdo_${idx}`}
                                      className={
                                        item._id === isVisblecontent._id
                                          ? "epc-li selected-accordion"
                                          : "epc-li"
                                      }
                                    >
                                      <a
                                        role="button"
                                        className="collapse show text-uppercase d-block p-2 pointer"
                                        // data-toggle="collapse"
                                        // href="/Export-Schemes"
                                        onClick={() =>
                                          this.toggleSchemesTitle(item)
                                        }
                                      >
                                        <span>{item.title}</span>
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                {this.state.contents &&
                  this.state.contents.map((item, idx) => {
                    return (
                      item &&
                      item.group &&
                      isVisblecontent._id === item.group._id && (
                        <div key={`bt_${idx}`} className="col-12">
                          {/* Single Blog */}
                          {/* <div className="key-feature border res-margin"> */}
                          {/* Blog Content */}
                          <div className="blog-content p-2">
                            <h3>{item.title}</h3>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></div>
                            </p>
                          </div>
                          {item.files && (
                            <div className="row ">
                              {item.files.map((file) =>
                                item.files.length < 3 ? (
                                  <div
                                    className="col-lg-12 col-12 res-margin ml-4 mt-4 mb-3"
                                    onClick={() => {
                                      file.friendlyurl &&
                                        this.props.history.push(
                                          file.friendlyurl
                                        );
                                    }}
                                  >
                                    <div className="row key-feature border p-2">
                                      <div className="col-3 res-margin ">
                                        <img src={file.image} alt={file.name} />
                                      </div>
                                      <div className="col-9">
                                        <h5 className="text-primary1 mb-2">
                                          {file.name}
                                        </h5>
                                        <div className="float-left">
                                          {file.date}
                                        </div>
                                        {file.file && (
                                          <TextTruncate
                                            isHtml={true}
                                            value={file.content}
                                            length={200}
                                            title={file.name}
                                          />
                                        )}{" "}
                                        <div className="mt-4  mb-2">
                                          {
                                            file.file && (
                                              <a
                                                href={file.file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <img
                                                  src={"/img/pdf.svg"}
                                                  width={30}
                                                  alt={file.name}
                                                />
                                                <span
                                                  style={{
                                                    color: "red",
                                                    paddingLeft: "10px",
                                                  }}
                                                >
                                                  View Report
                                                </span>
                                              </a>
                                            )
                                            // :
                                            // <span style={{ color: "red", paddingLeft: "10px", cursor: "pointer" }}
                                            //   onClick={() => {

                                            //     this.props.history.push(file.friendlyurl);
                                            //   }}
                                            // >View More</span>
                                          }
                                          <div className="float-right">
                                            {!file.file && (
                                              <div>
                                                {file.share &&
                                                  file.share.map((share) => {
                                                    return share.type ===
                                                      "facebook" ? (
                                                      <FacebookShareButton
                                                        url={share.url}
                                                        quote={share.content}
                                                        title={share.name}
                                                        className="share-button"
                                                      >
                                                        <FacebookIcon
                                                          size={32}
                                                        />
                                                      </FacebookShareButton>
                                                    ) : share.type ===
                                                      "twitter" ? (
                                                      <TwitterShareButton
                                                        url={share.url}
                                                        quote={share.content}
                                                        title={share.name}
                                                        className="share-button"
                                                      >
                                                        <TwitterIcon
                                                          size={32}
                                                        />
                                                      </TwitterShareButton>
                                                    ) : share.type ===
                                                      "instagram" ? (
                                                      <InstapaperShareButton
                                                        url={share.url}
                                                        quote={share.content}
                                                        title={share.name}
                                                        className="share-button"
                                                      >
                                                        <InstapaperIcon
                                                          size={32}
                                                        />
                                                      </InstapaperShareButton>
                                                    ) : (
                                                      ""
                                                    );
                                                  })}
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="col-lg-4 col-12 res-margin mt-4  mb-3"
                                    onClick={() => {
                                      file.friendlyurl &&
                                        this.props.history.push(
                                          file.friendlyurl
                                        );
                                    }}
                                    style={
                                      file.friendlyurl && { cursor: "pointer" }
                                    }
                                  >
                                    <div
                                      className="key-feature border p-2"
                                      style={{ minHeight: "305px" }}
                                    >
                                      <img src={file.image} alt={file.name} />
                                      <h5 className="text-primary1 mb-2 mt-2">
                                        {file.name}
                                      </h5>
                                      <div className="float-left">
                                        {file.date}
                                      </div>
                                      {file.file && (
                                        <TextTruncate
                                          isHtml={true}
                                          value={file.content}
                                          length={100}
                                          title={file.name}
                                        />
                                      )}
                                      <div className="mt-4 mb-2">
                                        {
                                          file.file && (
                                            <a
                                              href={file.file}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <img
                                                src={"/img/pdf.svg"}
                                                width={30}
                                                alt={file.name}
                                              />
                                              <span
                                                style={{
                                                  color: "red",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                View Report
                                              </span>
                                            </a>
                                          )
                                          // :
                                          // <span style={{ color: "red", paddingLeft: "10px", cursor: "pointer" }}
                                          //   onClick={() => {
                                          //     // this.setState({
                                          //     //   detailsModal: !this.state.detailsModal,
                                          //     //   currentContent: file
                                          //     // })
                                          //     this.props.history.push(file.friendlyurl);
                                          //   }}
                                          // >View More</span>
                                        }
                                        {!file.file && (
                                          <div>
                                            {file.share &&
                                              file.share.map((share) => {
                                                return share.type ===
                                                  "facebook" ? (
                                                  <FacebookShareButton
                                                    url={share.url}
                                                    quote={share.content}
                                                    title={share.name}
                                                    className="share-button"
                                                  >
                                                    <FacebookIcon size={32} />
                                                  </FacebookShareButton>
                                                ) : share.type === "twitter" ? (
                                                  <TwitterShareButton
                                                    url={share.url}
                                                    quote={share.content}
                                                    title={share.name}
                                                    className="share-button"
                                                  >
                                                    <TwitterIcon size={32} />
                                                  </TwitterShareButton>
                                                ) : share.type ===
                                                  "instagram" ? (
                                                  <InstapaperShareButton
                                                    url={share.url}
                                                    quote={share.content}
                                                    title={share.name}
                                                    className="share-button"
                                                  >
                                                    <InstapaperIcon size={32} />
                                                  </InstapaperShareButton>
                                                ) : (
                                                  ""
                                                );
                                              })}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                        // </div>
                      )
                    );
                  })}
              </div>
            </div>
          </div>
          <Modal
            size="lg"
            className="viewDocumentModal"
            isOpen={this.state.detailsModal}
            toggle={() =>
              this.setState({
                detailsModal: !this.state.detailsModal,
                currentContent: "",
              })
            }
          >
            <ModalBody className="m-3">
              {this.state.currentContent && (
                <SingleBlogDetails
                  currentContent={this.state.currentContent}
                  close={() =>
                    this.setState({
                      detailsModal: !this.state.detailsModal,
                      currentContent: "",
                    })
                  }
                />
              )}
            </ModalBody>
          </Modal>
        </section>
        <FooterSection />
      </div>
    );
  }
}

export default KnowledgeCentre;
