// import React, { Fragment } from "react";
import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";
import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
import { FacebookIcon, FacebookShareButton, InstapaperIcon, InstapaperShareButton, TwitterIcon, TwitterShareButton } from "react-share";
class BlogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "knowledgefile";
    ListRequestModel.searchString = window.location.pathname;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
        loading: false,
      });
    }
  };
  render() {
    const { content } = this.state;
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb breadcrumb="/knowledge" SelectedPath="blogs" />
          {content && <section className="m-5">
            <Container>
              <h3 className="ml-lg-4 pl-3">{content.name}</h3>
              <Row className="align-items-center ml-lg-4">
                <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div className="float-left">{content.date}</div>
                  <br />
                  {/* {content.image && <img src={content.image} alt={content.name} />} */}
                  <div className="">
                    <div className="float-right">
                      {content.share && content.share.map(share => {
                        return (
                          share.type === "facebook" ?
                            <FacebookShareButton
                              url={share.url}
                              quote={share.content}
                              title={share.name}
                              className="share-button">
                              <FacebookIcon
                                size={32}
                              />
                            </FacebookShareButton> :
                            share.type === "twitter" ?
                              <TwitterShareButton
                                url={share.url}
                                quote={share.content}
                                title={share.name}
                                className="share-button">
                                <TwitterIcon
                                  size={32}
                                />
                              </TwitterShareButton>
                              :
                              share.type === "instagram" ?
                                <InstapaperShareButton
                                  url={share.url}
                                  quote={share.content}
                                  title={share.name}
                                  className="share-button">
                                  <InstapaperIcon
                                    size={32}
                                  />
                                </InstapaperShareButton>
                                : ""
                        )

                      })}
                    </div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.content,
                        }}
                      ></div>
                    </p>

                  </div>
                </Col>
              </Row>
            </Container>
          </section>}
          <FooterSection />
        </div >
      );
  }
}

export default BlogDetails;
