import React, { Component } from "react";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";
import Menu from "./Menu";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DocumentMeta from "react-document-meta";
class Header extends Component {
  state = {
    data: {},
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 10,
    },
  }
  componentDidMount() {
    this.GetContent();
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // if (!isMobile)
    {
      let notScrolled = document.getElementById("notScrolled");
      let scrolled = document.getElementById("scrolled");
      if (notScrolled && scrolled)
        document.addEventListener("scroll", () => {
          if (window.scrollY > 80) {
            notScrolled.classList.add("d-none");
            scrolled.classList.remove("d-none");
          } else {
            notScrolled.classList.remove("d-none");
            scrolled.classList.add("d-none");
          }
        });
    }
    if (isMobile) {
      let mobileMenu = document.getElementById("mobileMenu");
      let mobileMenuScrolled = document.getElementById("mobileMenuScrolled");
      if (mobileMenu) {
        mobileMenu.addEventListener("click", () => {
          this.mobileMenuClicked();
        })
        if (mobileMenuScrolled)
          mobileMenuScrolled.addEventListener("click", () => {
            this.mobileMenuClicked();
          })
      }
    }
  }
  mobileMenuClicked = () => {
    if (document.getElementsByClassName('navbar'))
      for (var i = 0; i < document.getElementsByClassName('navbar').length; i++) {
        document.getElementsByClassName('navbar')[0].classList.toggle('active')
      }
    if (document.getElementsByTagName('body'))
      for (var j = 0; j < document.getElementsByTagName('body').length; j++) {
        document.getElementsByTagName('body')[0].classList.toggle('canvas-open')
      }
    if (document.getElementsByClassName('navbar-toggler-icon'))
      for (var k = 0; k < document.getElementsByClassName('navbar-toggler-icon').length; k++) {
        document.getElementsByClassName('navbar-toggler-icon')[0].classList.toggle('canvas-open')
      }
  }

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "socialmedia";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result,
      });
    }
  };

  Submit() {
    sessionStorage.clear();
    toastr.success("Logged out successfully");
    this.componentDidMount();
  };

  render() {
    var menucontent = JSON.parse(sessionStorage.getItem("menucontent"));
    var menu = JSON.parse(sessionStorage.getItem("menu"));
    var currentContent = {};
    var currentMenu = {};
    var menuName = "";
    if (menu && menucontent) {
      menuName = window.location.pathname.split("/")[1] && window.location.pathname.split("/")[1];
      currentMenu = menu.find(m => m.friendlyurl.includes(menuName));
      currentContent = menucontent.find(m => (m.menu && m.menu._id) === (currentMenu && currentMenu._id));
    }
    //navbar-dark
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    var Userdetails = JSON.parse(sessionStorage.getItem("usercontent"));
    if (Userdetails) {
      var userName = Userdetails.FirstName + ' ' + Userdetails.LastName
    }
    var meta = {
      title: currentMenu && currentMenu.metatitle,
      description: currentMenu && currentMenu.description,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: currentMenu && currentMenu.tag
        }
      }
    };

    return (
      <DocumentMeta {...meta}>
        <header
          className="navbar navbar-sticky navbar-expand-lg header-area d-flex"
          id="topnav"
        >
          <div id="notScrolled" className="position-relative" style={{ marginLeft: "-1px", marginRight: "-1px" }} >
            <button
              className="navbar-toggler d-lg-none"
              style={{ height: "136%" }}
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
              id="mobileMenu"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="row header-row bg-white" >
              <div className="col-lg-1 mt-2" />
              <div className={isMobile ? "col-3 vertical-align" : "col-lg-1 col-3 vertical-align"}>
                <a href="/">
                  {/* <a className="navbar-brand" href="/"> */}
                  <img
                    className="navbar-brand-regular brand-logo"
                    src={this.props.imageData}
                    alt="brand-logo"
                  />
                  <img
                    className="navbar-brand-sticky brand-logo"
                    src="/img/logo.png"
                    alt="sticky brand-logo"
                  />
                </a>
              </div>
              <div className="col-6 vertical-align">
                <a href="/">
                  <img
                    src="/img/Logo_Text.svg"
                    alt="sticky brand-logo"
                  />
                </a>
                {/* <span id="navbar-brand-logo-text" className=" ml-1 navbar-brand-logo-text navbar-dark navbar-nav nav-link">MSME - Trade and Investment Promotion Bureau</span> */}
              </div>
              {/* <div className="col-2 mt-3 vertical-align" style={{ flexDirection: "column" }}> */}
              {(!isMobile) &&
                <div className="col-2 vertical-align" style={{ justifyContent: "center" }}>
                  <div className="social-icons d-flex">
                    {this.state.content && this.state.content.map((item, idx) => {
                      return (
                        <a key={`fi_${idx}`} className={item.link} href={item.url} target="_blank"
                          rel="noopener noreferrer"
                          style={{ borderRadius: "50%" }}
                        >
                          <i className={item.iconclass} />
                        </a>
                      );
                    })}
                  </div>
                  {/* <div style={{ flexDirection: "row" }}>
                <h5> Toll free: 1800 244 244</h5>
              </div> */}
                </div>
              }
              <div className={isMobile ? "col-3 vertical-align" : "col-lg-1 col-3 vertical-align"}>
                <a href="/">
                  <img
                    className="navbar-brand-regular brand-logo"
                    src="/img/tnlogo.png"
                    alt="brand-logo"
                    style={{ width: "90%" }}
                  />
                  <img
                    className="navbar-brand-sticky brand-logo"
                    src="/img/tnlogo.png"
                    alt="sticky brand-logo"
                    style={{ width: "90%" }}
                  />
                </a>
              </div>
              {(isMobile) &&
                <div className="col-12 vertical-align" style={{ justifyContent: "center" }}>
                  <div className="social-icons d-flex">
                    {this.state.content && this.state.content.map((item, idx) => {
                      return (
                        <a key={`fi_${idx}`} className={item.link} href={item.url} target="_blank"
                          rel="noopener noreferrer"
                          style={{ borderRadius: "50%" }}
                        >
                          <i className={item.iconclass} />
                        </a>
                      );
                    })}
                  </div>
                </div>
              }
            </div>

            <div className="row menu-bar" style={window.location.pathname === "/" ? { justifyContent: "center", backgroundColor: "#07b2f7" } : { justifyContent: "center" }}>
              {/* <div className="col-1 vertical-align">
              <a href="/" className=" text-white ">   <h3> M-TIPB</h3></a>
            </div> */}
              <div className={Userdetails ? "navbar-inner col-11  vertical-align" : "navbar-inner col-10   vertical-align"} style={{ justifyContent: "center" }}>
                <Menu />
              </div>
              {!Userdetails &&
                <div className={(isMobile) ? "vertical-align" : "col-2 vertical-align"}>
                  <a href={"/contact-page"} title="Contact us" className={(isMobile) ? "mr-3 mb-2" : ""}>
                    <div className="header-contact">
                      <i className="fas fa-phone-square-alt"></i>
                    </div>
                  </a>
                  <div className="buy-button" >
                    <a
                      href={"/login"}
                      className="btn schemeSignup mr-3"
                      id="buy-btn-header"
                    >
                      SIGN IN
                  </a>
                  </div>
                </div>
              }
              {/* {Userdetails &&
              <React.Fragment>
                <div className="col-1" >
                  <div className="buy-button">
                    <button type="submit" className="btn schemeSignup"
                      style={{ padding: '3px 5px 0px 5px' }}
                      onClick={() => this.Submit()}>
                     {userName}
              </button>
                  </div>
                </div>
              </React.Fragment>
            } */}

            </div>
          </div>
          <div id="scrolled" className="position-relative d-none" style={{ marginLeft: "-1px", marginRight: "-1px" }} >
            <button
              className="navbar-toggler d-lg-none"
              style={{ height: "45%" }}
              type="button"
              data-toggle="navbarToggler"
              aria-label="Toggle navigation"
              id="mobileMenuScrolled"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="row header-row">
              <div className={(isMobile) ? "col-3 vertical-align" : "col-1 vertical-align"}>
                <a href="/" className=" text-white ">
                  <img
                    className=" brand-logo-floatmenu"
                    src="/img/logo.png"
                    alt="brand-logo"
                  /></a>
              </div>
              <div className={(isMobile) ? "col-2" : ""} />

              <div className={Userdetails ? "navbar-inner col-10 vertical-align" : "navbar-inner col-9 vertical-align"}>
                <Menu />
              </div>
              {!Userdetails &&
                <div className={(isMobile) ? " vertical-align" : "col-1 vertical-align"}>
                  <a href={"/contact-page"} title="Contact us" className={(isMobile) ? "mr-3" : ""}>
                    <div className="header-contact-scrolled">
                      <i className="fas fa-phone-square-alt"></i>
                    </div>
                  </a>
                  <div className="buy-button">
                    <a
                      href={"/login"}
                      className="btn schemeSignup"
                      id="buy-btn-header"
                    >
                      SIGN IN
                  </a>
                  </div>
                </div>
              }

              {/* {Userdetails &&
              <React.Fragment>
                <div className="col-1  vertical-align">
                  <a href={"/contact-page"} title="Contact us">
                    <div className="header-contact-scrolled">
                      <i className="fas fa-phone-square-alt"></i>
                    </div>
                  </a>
                  <div className="buy-button">
                    <button type="submit" className="btn schemeSignup"
                      style={{ background: 'blue', padding: '3px 5px 0px 5px' }}
                      onClick={() => this.Submit()}>
                      SIGN OUT
              </button>
                  </div>
                </div>
              </React.Fragment>
            }*/}

            </div>
          </div>
        </header>
      </DocumentMeta>
    );
  }
}

export default Header;
