import React, { Component } from "react";
import Header from "../../../src/components/HeaderSection/Header";
import FooterSection from "../../../src/components/FooterSection/Footer";
import axios from "axios";
import { Link } from "react-router-dom";
import { CallService } from "../../helpers/servicecall";
import { yellowpages } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
const BASE_URL =
  "https://my-json-server.typicode.com/themeland/json-server-1/themeOneContactSection";

class YellowPageResult extends Component {
  state = {
    data: {},
    iconList: [],
    CompanyDetails: [],
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelCategory: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 10,
    },
    CategoryList: [],
    isLoading: true,
  };

  componentWillReceiveProps() {
    var categoryName = window.location.hash.replace(
      "/yellowpages-result/",
      ""
    );
    var spaceSector = categoryName.replace("%20", " ");
    var CategoryName = this.state.CategoryList.filter(
      (name) => name.name === spaceSector
    );
    this.setState({
      categoryName: spaceSector,
    });
    this.GetContent(CategoryName ? CategoryName[0]._id : "");
  }

  componentDidMount() {
    var CategoryId = window.location.hash.replace("/yellowpages-result/", "");
    var spaceSector = CategoryId.replace("%20", " ");
    console.log(spaceSector);
    this.setState({
      categoryName: spaceSector,
    });
    axios
      .get(`${BASE_URL}`)
      .then((res) => {
        this.setState({
          data: res.data,
          iconList: res.data.iconList,
        });
      })
      .catch((err) => console.log(err));
    this.GetAllCategories();
  }

  GetContent = (CategoryId) => {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "company";
    ListRequestModel.sector = CategoryId;

    CallService(
      yellowpages.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        CompanyDetails: data.result,
      });
  };
  GetAllCategories() {
    const { ListRequestModelCategory } = this.state;
    ListRequestModelCategory.TableName = "sector";

    CallService(
      yellowpages.GetAll,
      MethodType.POST,
      false,
      ListRequestModelCategory,
      "",
      this.GetAllCategoriesResponse
    );
  }

  GetAllCategoriesResponse = (data) => {
    if (data.result)
      this.setState({
        CategoryList: data.result,
      });
    var CategoryName = data.result.filter(
      (name) => name.name === this.state.categoryName
    );
    this.GetContent(CategoryName ? CategoryName[0]._id : "");
  };
  // handlePageChange = async (page) => {
  //   console.log(page);
  //   this.setState(
  //     {
  //       pageNumber: page,
  //       ListRequestModel: {
  //         ...this.state.ListRequestModel,
  //         pageNumber: page,
  //       },
  //     },
  //     function () {
  //       this.GetAllCategories();
  //     }
  //   );
  // };
  render() {
    return (
      <div id="main-wrapper" style={{ overflowX: "hidden" }}>
        <div className="yellowpage">
          {/*====== Scroll To Top Area Start ======*/}
          <div id="scrollUp" title="Scroll To Top">
            <i className="fas fa-arrow-up" />
          </div>
          {/*====== Scroll To Top Area End ======*/}
          <div className="main yellowpage">
            <Header imageData={"/img/logo-white.png"} />
            <section className="section  breadcrumb-area bg-overlay d-flex align-items-center">
              <div className="container">
                <div class="header-search slider-home">
                  <div class="header-search-bar">
                    <form action="#">
                      <div class="search-toggle">
                        <div class="container">
                          <div class="distance-range">
                            <p>
                              <label for="amount-search">Distance:</label>
                              <input type="text" id="amount-search" />
                            </p>
                            <div id="slider-range-search"></div>
                          </div>
                          <div class="distance-range">
                            <p>
                              <label for="amount-search">Days published:</label>
                              <input type="text" id="amount-search-day" />
                            </p>
                            <div id="slider-range-search-day"></div>
                          </div>
                          <p>Location:</p>
                          <div class="select-country">
                            <select
                              class=""
                              data-placeholder="-Select Country-"
                            >
                              <option value="option">-Select Country-</option>
                              <option value="option1">option 1</option>
                              <option value="option2">option 2</option>
                              <option value="option3">option 3</option>
                            </select>
                          </div>

                          <div class="region">
                            <input type="text" placeholder="-Region-" />
                          </div>

                          <div class="address">
                            <input type="text" placeholder="-Address-" />
                          </div>

                          <div class="category-search">
                            <select
                              class=""
                              data-placeholder="-Select category-"
                            >
                              <option value="option">-Select category-</option>
                              <option value="option1">option 1</option>
                              <option value="option2">option 2</option>
                              <option value="option3">option 3</option>
                            </select>
                          </div>

                          <button class="search-btn" type="submit">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>

                      <div class="container">
                        <div class="search-value">
                          <div style={{ width: "87%" }} class="keywords">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Keywords"
                              onChange={(e) => {
                                var value = e.target.value;
                                this.setState((prevState) => ({
                                  ListRequestModel: {
                                    ...prevState.ListRequestModel,
                                    searchString: value,
                                  }
                                }))
                              }}
                            />
                          </div>

                          <button class="search-btn" type="button" onClick={() => this.GetContent(this.state.ListRequestModel && this.state.ListRequestModel.sector)}>
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="shape-bottom">
                <svg
                  viewBox="0 0 1920 310"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  className="svg replaced-svg"
                >
                  <title></title>
                  <desc></desc>
                  <defs />
                  <g
                    id="sApp-Landing-Page"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="sApp-v1.0"
                      transform="translate(0.000000, -554.000000)"
                      fill="#f8f8f8"
                    >
                      <path
                        d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                        id="sApp-v1.0"
                      />
                    </g>
                  </g>
                </svg>
              </div>{" "}
            </section>
          </div>
        </div>

        <div id="page-content" class="home-slider-content">
          <div class="container">
            <div class="row">
              <div class="col-md-9 col-md-push-3">
                <div class="page-content">
                  <div class="product-details-list view-switch">
                    <div class="tab-content">
                      <div class="tab-pane active" id="category-book">
                        <h2>
                          {this.state.categoryName}
                          <span class="comments">
                            {this.state.CompanyDetails.length}
                          </span>
                        </h2>
                        <div>
                          <div class="change-view col-11">
                            {/* <div class="filter-input">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Filter by Keywords"
                              />
                            </div> */}
                            {/* <ul class="yellowpagination">
                              <li>
                                <a href="#">
                                  <i class="fa fa-angle-left"></i>
                                </a>
                              </li>
                              <li class="active">
                                <a href="#">1</a>
                              </li>
                              <li>
                                <a href="#">2</a>
                              </li>
                              <li>
                                <a href="#">3</a>
                              </li>
                              <li>
                                <a href="#">
                                  <i class="fa fa-angle-right"></i>
                                </a>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                        <div class="clearfix">
                          <br />
                          <br />
                          {this.state.isLoading ? (
                            <div style={{ margin: "auto", marginTop: "5%" }}>
                              <h4 className="loading">Loading...</h4>
                            </div>
                          ) : this.state.CompanyDetails.length > 0 ? (
                            this.state.CompanyDetails.map((cmpy, i) => {
                              return (
                                <div className="single-product row">
                                  <div class="col-2" style={{ alignSelf: "center" }}>
                                    <img
                                      src={cmpy.logo
                                        ? cmpy.logo
                                        : "/img/logo-dummy.png"}
                                      alt=""
                                    />
                                  </div>
                                  <div class="col-8">
                                    <h4>{cmpy.name}</h4>

                                    <p>
                                      <span>
                                        <i className="fas fa-user" />
                                        &emsp;
                                        {cmpy.contactperson
                                          ? cmpy.contactperson + ", "
                                          : ""}
                                      </span>
                                      <br></br>
                                      <span>
                                        <i className="fas fa-phone-alt" />
                                        &emsp;
                                        {cmpy.mobilenumber
                                          ? cmpy.mobilenumber + ", "
                                          : ""}
                                      </span>
                                      <br></br>
                                      <span>
                                        <i className="fas fa-home" />
                                        &emsp;
                                        {cmpy.location
                                          ? cmpy.location + ", "
                                          : ""}
                                      </span>
                                      <br></br>
                                      {cmpy.email ? (
                                        <p>
                                          <span>
                                            <i className="fas fa-envelope" />
                                            &emsp;
                                            {cmpy.email
                                              ? cmpy.email + ", "
                                              : ""}
                                          </span>
                                          <br></br>
                                        </p>
                                      ) : (
                                          ""
                                        )}

                                      {cmpy.website ? (
                                        <span>
                                          <i className="fas fa-globe" />
                                          &emsp;
                                          <u>
                                            <a
                                              href={`https://${cmpy.website}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {cmpy.website}
                                            </a>
                                          </u>
                                        </span>
                                      ) : (
                                          ""
                                        )}
                                    </p>
                                  </div>
                                  <div class="col-2" >
                                    {cmpy.certificates && cmpy.certificates.map(cet =>
                                      <img
                                        src={cet.image
                                          ? cet.image
                                          : "/img/logo-white.png"}
                                        alt={cet.name}
                                      />)}
                                  </div>
                                </div>);
                            })
                          ) : (
                                ""
                              )}
                          {/* {this.state.isLoading ? (
                            <div class="pagination-center">
                              <ul class="yellowpagination">
                                <li>
                                  <a href="#">
                                    <i class="fa fa-angle-left"></i>
                                  </a>
                                </li>
                                <li class="active">
                                  <a href="#">1</a>
                                </li>
                                <li>
                                  <a href="#">2</a>
                                </li>
                                <li>
                                  <a href="#">3</a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i class="fa fa-angle-right"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          ) : (
                              ""
                            )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-md-pull-9 category-toggle">
                <button>
                  <i class="fa fa-briefcase"></i>
                </button>

                <div class="page-sidebar">
                  {/* <div class="custom-search">
                    <div class="location-details">
                      <form action="#">
                        <div class="zip-code">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Enter Pincode"
                          />
                        </div>
                      </form>
                    </div>
                  </div> */}
                  <div id="categories">
                    <div class="accordion">
                      <ul
                        class="nav nav-tabs d-block accordion-tab"
                        role="tablist"
                      >
                        <li class="active">
                          <Link to={"/yellowpages"}>
                            <i class="fa fa-shopping-cart"></i>All Product
                          </Link>
                          <div>
                            {this.state.CategoryList.map((c) => {
                              return <Link to={`${c.name}`}>{c.name}</Link>;
                            })}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default YellowPageResult;
