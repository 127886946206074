import React, { Component, Fragment } from "react";
import Header from "../HeaderSection/Header";
import FooterSection from "../FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import { authenticate, events } from "../../constants/config";
import { Button } from "reactstrap";
import toastr from "toastr";


const initData = {
    heading:
        "Welcome!",
    content: "Your first leap towards an all-encompassing platform assisting in the development of MSMEs all over Tamil Nadu.",
    formHeading: "Sign In to your FaMe TN account",
    formContent:
        "",
    formText: "Don’t have an account? Sign up here.",
    btnText: "Sign In",
    btnText_2: "Sign Up",
};

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initData: [],
            time: {},
            seconds: 0,
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    componentDidMount() {
        var Userdetails = JSON.parse(sessionStorage.getItem("usercontent"));
        if (Userdetails) {
            sessionStorage.clear();
            toastr.success("Logged out successfully");
        }
        this.setState({
            initData: initData,
            EmailID: "",
            Password: "",
        });
    }
    GetOtp() {
        const { EmailID } = this.state;
        const data = {
            EmailID,
        }
        CallService(
            authenticate.OTPGet,
            MethodType.POST,
            false,
            data,
            "",
            this.GetOtpResponse
        )
    }

    GetOtpResponse = (data) => {
        if (data.result) {
            toastr.success("OTP will be sent to registered mail ID")
            this.setState({
                ServerOtp: data.result.Otp,
                recievedOtp: true,
                loading: false, seconds: 120
            },
                () => {
                    this.startTimer();
                })
        };
    };
    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
            this.timer = 0;
        }
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds,
        };
        return obj;
    }
    startTimer() {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    Submit() {
        const { EmailID, OTP, Password, ConfirmPassword, ServerOtp } = this.state;
        const data = {
            EmailID,
            OTP,
            Password,
        }
        var error = false;
        // if (OTP !== ServerOtp) {
        //     error = true;
        //     toastr.error("Please enter valid Otp");
        // }
        if (Password !== ConfirmPassword) {
            error = true;
            toastr.error("Password and confirm password must be same");
        }
        if (!error)
            CallService(
                authenticate.ResetPassword,
                MethodType.POST,
                false,
                data,
                "",
                this.GetResponse
            )
    }

    GetResponse = (data) => {
        if (data.statusCode === StatusCodes.Success) {
            toastr.success("Password changed successfully");
            this.props.history.push('/Login');
        };
    };


    render() {
        return (
            <div className="main">
                <Header imageData={"/img/logo-white.png"} />
                <div className=" homepage-5 inner-pages signup">
                    <div className="main login " style={{ marginTop: "7%" }}>
                        <section
                            id="home"
                            className="section welcome-area h-100vh bg-overlay d-flex align-items-center"
                        >
                            <div className="container pt-5">
                                <div className="row align-items-center justify-content-center">
                                    {/* Welcome Intro Start */}
                                    <div className="col-12 col-lg-7">
                                        <div className="welcome-intro">
                                            <h2 className="text-white">
                                                {this.state.initData.heading}
                                            </h2>
                                            <p className="text-white my-4">
                                                {this.state.initData.content}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-5">
                                        {/* Contact Box */}
                                        <div className="contact-box  contact-box-transition bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                                            {/* Contact Form */}
                                            <form id="contact-form">
                                                <div className="contact-top">
                                                    <h4 className="contact-title">
                                                        Recover Account
                                                    </h4>
                                                    <p className="text-muted">Please enter your email address. You will receive a OTP to create a new password via email.</p>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        <i className="fas fa-envelope-open" />
                                                                    </span>
                                                                </div>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    name="email"
                                                                    value={this.state.EmailID}
                                                                    placeholder="Email"
                                                                    required="required"
                                                                    onChange={(e) =>
                                                                        this.setState({ EmailID: e.target.value })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.recievedOtp &&
                                                        <Fragment>
                                                            <div className="col-12 float-right">
                                                                {this.state.seconds > 0 ? (
                                                                    <span
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "#007bff",
                                                                            float: "right",
                                                                        }}
                                                                    >
                                                                        {this.state.time.m < 10 ? "0" : ""}
                                                                        {this.state.time.m}:{" "}
                                                                        {this.state.time.s < 10 ? "0" : ""}
                                                                        {this.state.time.s}
                                                                    </span>
                                                                ) : (
                                                                        <span
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                color: "#007bff",
                                                                                textDecoration: "underline",
                                                                                float: "right",
                                                                                zIndex: 3,
                                                                            }}
                                                                            onClick={() =>
                                                                                this.GetOtp()
                                                                            }
                                                                        >
                                                                            Resend OTP
                                                                        </span>)}
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                                <i className="fas fa-clock" />
                                                                            </span>
                                                                        </div>
                                                                        <input
                                                                            type="password"
                                                                            className="form-control"
                                                                            name="OTP"
                                                                            value={this.state.OTP}
                                                                            placeholder="OTP"
                                                                            required="required"
                                                                            onChange={(e) =>
                                                                                this.setState({ OTP: e.target.value })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                                <i className="fas fa-lock" />
                                                                            </span>
                                                                        </div>
                                                                        <input
                                                                            type="password"
                                                                            className="form-control"
                                                                            name="Password"
                                                                            value={this.state.Password}
                                                                            placeholder="Password"
                                                                            required="required"
                                                                            onChange={(e) =>
                                                                                this.setState({ Password: e.target.value })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">
                                                                                <i className="fas fa-lock" />
                                                                            </span>
                                                                        </div>
                                                                        <input
                                                                            type="password"
                                                                            className="form-control"
                                                                            name="ConfirmPassword"
                                                                            value={this.state.ConfirmPassword}
                                                                            placeholder="Confirm Password"
                                                                            required="required"
                                                                            onChange={(e) =>
                                                                                this.setState({ ConfirmPassword: e.target.value })
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Fragment>}

                                                    <div className="col-12">
                                                        {this.state.recievedOtp ?
                                                            <Button
                                                                className="btn btn-bordered w-100"
                                                                // type="submit"
                                                                onClick={() => this.Submit()}
                                                            >
                                                                Submit
                                                   </Button>
                                                            :
                                                            <Button
                                                                className="btn btn-bordered w-100"
                                                                // type="submit"
                                                                onClick={() => this.GetOtp()}
                                                            >
                                                                Send
                                                    </Button>
                                                        }
                                                        <div className="mx-auto">
                                                            <p className="mb-0 mt-3 text-dark mr-2">Remember your password ? <a href="/login" >Sign in</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Shape Bottom */}
                            <div className="shape-bottom">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1000 100"
                                    preserveAspectRatio="none"
                                >
                                    <path
                                        className="shape-fill"
                                        fill="#FFFFFF"
                                        d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                                    />
                                </svg>
                            </div>
                        </section>
                    </div>
                </div>
                <FooterSection />{" "}
            </div>
        );
    }
}

export default Forgot;
