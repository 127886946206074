import React, { Fragment } from "react";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Container, Row, Col, Media, Card, CardBody } from "reactstrap";
import Header from "../../components/HeaderSection/Header";
import FooterSection from "../../components/FooterSection/Footer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Breadcrumb from "../../components/Blogs/Breadcrumb";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import FeatherIcon from "feather-icons-react";
class District extends React.Component {
  state = {
    isOpen: false,
    photoIndex: 0,
    isOpenImage: false,
    contents: [],
    loading: true,
    ListRequestModelTNMap: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000000,
    },
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListDistrictProfileContent: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelEstates: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelMSME: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestTNMap: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelProducts: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelImportProducts: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelLargeIndustries: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelImages: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelProductionCrops: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelClusters: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    features1: [
      { title: "Digital Marketing Solutions for Tomorrow" },
      { title: "Our Talented & Experienced Marketing Agency" },
      { title: "Create your own skin to match your brand" },
    ],
    UAM: [],
    Estates: [],
    TotalMSME: [],
  };

  componentDidMount() {
    var schemename = this.props.location ? this.props.location.pathname : "";
    var name = schemename;
    if (name.includes("district-profile")) {
      name = name.replace("/district-profile", "");
    }
    var breadcrumbName = name.replace("/", "");
    breadcrumbName =
      breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
    var UpperProfileName = breadcrumbName.toUpperCase();
    this.setState({
      breadcrumbName: breadcrumbName,
      UpperProfileName: UpperProfileName,
    });
    this.GetTNmap();
  }
  GetTNmap() {
    const { ListRequestTNMap } = this.state;
    ListRequestTNMap.TableName = "tnmap";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestTNMap,
      "",
      this.GetTNmapResponse
    );
  }

  GetTNmapResponse = (data) => {
    if (data.result)
      var DistrictName = data.result.filter(
        (name) => name.name === this.state.breadcrumbName
      );
    this.setState(
      {
        isLoading: false,
        TNMap: data.result,
      },
      () => {
        var DistrictId = DistrictName
          ? DistrictName[0]
            ? DistrictName[0]._id
            : this.props.districid
              ? this.props.districid
              : ""
          : "";
        if (DistrictId) {
          this.GetIndustrialEstates(DistrictId);
          this.GetRegisteredMSME(DistrictId);
          this.GetRegisteredinUAM(DistrictId);
          this.GetDistrictProfileContent(DistrictId);
          this.GetExportProducts(DistrictId);
          this.GetImportProducts(DistrictId);
          this.GetLargeIndustries(DistrictId);
          this.GetDistrictGallery(DistrictId);
          this.GetDistrictProductionCrops(DistrictId);
          this.GetIndustrialCluster(DistrictId);
        }
      }
    );
  };
  GetIndustrialEstates(DistrictId) {
    const { ListRequestModelEstates } = this.state;
    ListRequestModelEstates.TableName = "industrialestates";
    ListRequestModelEstates.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelEstates,
      "",
      this.GetIndustrialEstatesResponse
    );
  }

  GetIndustrialEstatesResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        Estates: data.result,
      });
  };

  GetRegisteredMSME(DistrictId) {
    const { ListRequestModelMSME } = this.state;
    ListRequestModelMSME.TableName = "registeredmsme";
    ListRequestModelMSME.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelMSME,
      "",
      this.GetRegisteredMSMEResponse
    );
  }

  GetRegisteredMSMEResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        MSME: data.result,
      });
  };
  GetRegisteredinUAM(DistrictId) {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "registeredinuam";
    ListRequestModel.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetRegisteredinUAMResponse
    );
  }

  GetRegisteredinUAMResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        UAM: data.result,
      });
  };
  GetDistrictProfileContent(DistrictId) {
    const { ListDistrictProfileContent } = this.state;
    ListDistrictProfileContent.TableName = "districtmsmeprofile";
    ListDistrictProfileContent.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListDistrictProfileContent,
      "",
      this.GetDistrictProfileContentResponse
    );
  }

  GetDistrictProfileContentResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        ProfileContent: data.result,
      });
  };

  GetExportProducts(DistrictId) {
    const { ListRequestModelProducts } = this.state;
    ListRequestModelProducts.TableName = "exportproducts";
    ListRequestModelProducts.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelProducts,
      "",
      this.GetExportProductsResponse
    );
  }

  GetExportProductsResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        ExportProducts: data.result,
      });
  };

  GetImportProducts(DistrictId) {
    const { ListRequestModelImportProducts } = this.state;
    ListRequestModelImportProducts.TableName = "importproducts";
    ListRequestModelImportProducts.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelImportProducts,
      "",
      this.GetImportProductsResponse
    );
  }

  GetImportProductsResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        ImportProducts: data.result,
      });
  };

  GetLargeIndustries(DistrictId) {
    const { ListRequestModelLargeIndustries } = this.state;
    ListRequestModelLargeIndustries.TableName = "largeindustries";
    ListRequestModelLargeIndustries.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelLargeIndustries,
      "",
      this.GetLargeIndustriesResponse
    );
  }

  GetLargeIndustriesResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        LargeIndustries: data.result,
      });
  };

  GetDistrictGallery(DistrictId) {
    const { ListRequestModelImages } = this.state;
    ListRequestModelImages.TableName = "districtgallery";
    ListRequestModelImages.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelImages,
      "",
      this.GetDistrictGalleryResponse
    );
  }

  GetDistrictGalleryResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        DistrictGallery: data.result,
      });
  };

  GetDistrictProductionCrops(DistrictId) {
    const { ListRequestModelProductionCrops } = this.state;
    ListRequestModelProductionCrops.TableName = "productioncrops";
    ListRequestModelProductionCrops.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelProductionCrops,
      "",
      this.GetDistrictProductionCropsResponse
    );
  }

  GetDistrictProductionCropsResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        ProductionCrops: data.result,
      });
  };
  GetIndustrialCluster(DistrictId) {
    const { ListRequestModelClusters } = this.state;
    ListRequestModelClusters.TableName = "clusterdetails";
    ListRequestModelClusters.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelClusters,
      "",
      this.GetIndustrialClusterResponse
    );
  }

  GetIndustrialClusterResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        IndustrialClusters: data.result,
      });
  };
  render() {
    const { photoIndex, isOpenImage, breadcrumbName } = this.state;
    return (
      <div style={{ overflowX: "hidden" }}>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <div className="main epc">
          <Header imageData={"/img/logo-white.png"} />
          <Breadcrumb title={breadcrumbName + " District"} />
          <u>
            <h1 style={{ textAlign: "center" }}>
              {this.state.UpperProfileName} DISTRICT MSME PROFILE
            </h1>
          </u>

          <Row className="align-items-center">
            <Col lg="6" md="5">
              <div className="app-feature-shape-left position-relative">
                <div className="text-center text-md-left">
                  <img
                    src={"/img/handShake.png"}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </Col>

            <Col lg="6" md="6" className="mt-5 mt-sm-0">
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.ProfileContent
                    ? this.state.ProfileContent[0]
                      ? this.state.ProfileContent[0].section1
                      : ""
                    : "",
                }}
              ></div>
            </Col>
          </Row>

          <Container>
            <Row>
              <Col>
                {" "}
                <br></br>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.ProfileContent
                      ? this.state.ProfileContent[0]
                        ? this.state.ProfileContent[0].section2
                        : ""
                      : "",
                  }}
                ></div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.ProfileContent
                      ? this.state.ProfileContent[0]
                        ? this.state.ProfileContent[0].section3
                        : ""
                      : "",
                  }}
                ></div>
              </Col>
            </Row>
            <div className="main epc">
              <Container>
                <Row>
                  <Col>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.ProfileContent
                          ? this.state.ProfileContent[0]
                            ? this.state.ProfileContent[0].section3
                            : ""
                          : "",
                      }}
                    ></div>
                  </Col>
                </Row>

                {this.state.MSME && this.state.MSME.length > 0 && (
                  <Row>
                    <h4 style={{ textAlign: "center" }}>
                      Total No. of Industries Registered
                      {/* under Udyog Aadhaar Memorandum */}
                    </h4>
                    <div className="table-responsive bg-white shadow rounded">
                      <Table className="mb-0 table-center table">
                        <thead>
                          <tr>
                            <th scope="col"> Category</th>
                            <th scope="col">Nos.</th>
                            <th scope="col">Employment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.MSME.map((m) => (
                            <tr>
                              <td>{m.category && m.category.name}</td>
                              <td>{m.nos}</td>
                              <td>{m.employment}</td>
                            </tr>
                          ))}
                          <tr>
                            <td>Total</td>
                            <td>
                              {this.state.MSME &&
                                this.state.MSME.reduce(function (a, b) {
                                  return a + +b["nos"];
                                }, 0)}
                            </td>
                            <td>
                              {this.state.MSME &&
                                this.state.MSME.reduce(function (a, b) {
                                  return a + +b["employment"];
                                }, 0)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                )}
                <br></br>
                {this.state.ProductionCrops &&
                  this.state.ProductionCrops.length > 0 && (
                    <Row>
                      <h4 style={{ textAlign: "center" }}>
                        AREA AND PRODUCTION OF PRINCIPAL CROPS
                      </h4>
                      <div className="table-responsive bg-white shadow rounded">
                        <Table className="mb-0 table-center table">
                          <thead>
                            <tr>
                              <th scope="col">Crops </th>
                              <th scope="col">Area (in ha) </th>
                              <th scope="col">Production (in tones) </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ProductionCrops.map((m) => (
                              <tr>
                                <td>{m.crops && m.crops.name}</td>
                                <td>{m.area}</td>
                                <td>{m.production}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  )}
                <br></br>
                {console.log(this.state.UAM)}
                {this.state.UAM && this.state.UAM.length > 0 && (
                  <div>
                    {" "}
                    <u>
                      <h4 style={{ textAlign: "center" }}>
                        Major sectors registered
                      </h4>
                    </u>
                    <br></br>
                    <Row className="justify-content-center">
                      <Col xs="12" lg="9">
                        <div className="table-responsive bg-white shadow rounded">
                          <Table className="mb-0 table-center table">
                            <thead>
                              <tr>
                                <th scope="col" style={{ textAlign: "center" }}>
                                  {" "}
                                  S.No.
                                </th>
                                <th scope="col" style={{ minWidth: "100px" }}>
                                  Type of Industry
                                </th>

                                <th scope="col" style={{ width: "180px" }}>
                                  Nos
                                </th>
                                <th scope="col" style={{ width: "180px" }}>
                                  Employment
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.UAM.map((val, key) => (
                                <tr key={key}>
                                  <td style={{ textAlign: "center" }}>
                                    {key + 1}
                                  </td>
                                  <th>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0 font-weight-normal">
                                        {val.industrytype
                                          ? val.industrytype.name
                                          : val.typeofindustry}{" "}
                                      </p>
                                    </div>
                                  </th>
                                  <td>{val.nos}</td>
                                  <td>{val.employment}</td>
                                </tr>
                              ))}
                              {/* <tr>
                                                                <td style={{ textAlign: "center" }}>Total</td>
                                                                <td></td>
                                                                <td>{this.state.UAM && this.state.UAM.reduce(function (a, b) {
                                                                    return a + +b["nos"];
                                                                }, 0)}</td>
                                                                <td>{this.state.UAM && this.state.UAM.reduce(function (a, b) {
                                                                    return a + +b["employment"];
                                                                }, 0)}</td>
                                                            </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <br></br>
                {this.state.IndustrialClusters &&
                  this.state.IndustrialClusters.length > 0 && (
                    <div>
                      {" "}
                      <u>
                        <h4 style={{ textAlign: "center" }}>
                          MAJOR INDUSTRIAL CLUSTERS IN THE DISTRICT
                        </h4>
                      </u>
                      <br></br>
                      <Row className="justify-content-center">
                        <Col xs="12" lg="9">
                          <div className="table-responsive bg-white shadow rounded">
                            <Table className="mb-0 table-center table">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ textAlign: "center" }}
                                  >
                                    {" "}
                                    S.No.
                                  </th>
                                  <th scope="col" style={{ minWidth: "100px" }}>
                                    ACTIVITY
                                  </th>

                                  <th scope="col" style={{ width: "180px" }}>
                                    PROJECT COST (in lakhs)
                                  </th>
                                  <th scope="col" style={{ width: "180px" }}>
                                    STATUS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.IndustrialClusters.map(
                                  (val, key) => (
                                    <tr key={key}>
                                      <td style={{ textAlign: "center" }}>
                                        {key + 1}
                                      </td>
                                      <th>
                                        <div className="d-flex align-items-center">
                                          <p className="mb-0 font-weight-normal">
                                            {val.nameofthecluster}{" "}
                                          </p>
                                        </div>
                                      </th>
                                      <td>{val.totalprojectcost}</td>
                                      <td>{val.status}</td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                <br></br>
                <br></br>
                {this.state.ProfileContent[0].section4 && (
                  <Row style={{ marginTop: "40px" }}>
                    <Col>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.ProfileContent
                            ? this.state.ProfileContent[0]
                              ? this.state.ProfileContent[0].section4
                              : ""
                            : "",
                        }}
                      ></div>
                    </Col>
                    <Col lg="6" md="5">
                      <div className="app-feature-shape-left position-relative">
                        <div className="text-center text-md-left">
                          <img
                            style={{ height: "580px" }}
                            src={"/img/SEO.svg"}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.ProfileContent
                          ? this.state.ProfileContent[0]
                            ? this.state.ProfileContent[0].section5
                            : ""
                          : "",
                      }}
                    ></div>
                  </Col>
                </Row>
                {this.state.Estates && this.state.Estates.length > 0 && (
                  <div>
                    <u>
                      <strong>Government Industrial Estates </strong>
                    </u>
                    <Row className="justify-content-center">
                      <Col xs="12" lg="10">
                        <div className="table-responsive bg-white shadow rounded">
                          <Table className="mb-0 table-center table">
                            <thead>
                              <tr>
                                <th scope="col"> Developer</th>
                                <th scope="col">Location</th>

                                <th scope="col">Total land extent (in Acre)</th>
                                <th scope="col">Developed Plots / Sheds</th>
                                <th scope="col">Occupied</th>
                                <th scope="col">Vacant</th>
                              </tr>
                            </thead>
                            <Card className="bg-light shadow rounded border-0">
                              <div className="bg-primary p-4 text-center rounded-top">
                                <h4 className="mb-0 card-title title-dark text-light">
                                  Products identified for export
                                </h4>
                              </div>

                              <CardBody>
                                <ul className="feature list-inline">
                                  {this.state.ExportProducts.map((val) => (
                                    <Fragment>
                                      <li className="h5 font-weight-normal">
                                        <i>
                                          <FeatherIcon
                                            icon="check-circle"
                                            className="fea icon-ex-md text-primary mr-2"
                                          />
                                        </i>
                                        {val.products
                                          ? val.products.name
                                          : val.name}
                                      </li>
                                      <br />
                                    </Fragment>
                                  ))}
                                </ul>
                              </CardBody>
                            </Card>
                            <tbody>
                              {this.state.Estates.map((val, key) => (
                                <tr key={key}>
                                  <th style={{ textAlign: "center" }}>
                                    {val.developer ? val.developer.name : ""}
                                  </th>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <p className="mb-0 font-weight-normal">
                                        {val.location}{" "}
                                      </p>
                                    </div>
                                  </td>
                                  <td>{val.totalland}</td>
                                  <td>{val.developed}</td>
                                  <td>{val.occupied}</td>
                                  <td>{val.vacant}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                <br></br>
                <br></br>
                <Row>
                  <Col lg="5" md="6" className="pt-2">
                    {this.state.ExportProducts &&
                      this.state.ExportProducts.length > 0 && (
                        <Card className="bg-light shadow rounded border-0">
                          <div className="bg-primary p-4 text-center rounded-top">
                            <h4 className="mb-0 card-title title-dark text-light">
                              Products identified for export
                            </h4>
                          </div>

                          <CardBody>
                            <ul className="feature list-inline">
                              {this.state.ExportProducts.map((val) => (
                                <Fragment>
                                  <li className="h5 font-weight-normal">
                                    <i>
                                      <FeatherIcon
                                        icon="check-circle"
                                        className="fea icon-ex-md text-primary mr-2"
                                      />
                                    </i>
                                    {val.products
                                      ? val.products.name
                                      : val.name}
                                  </li>
                                  <br />
                                </Fragment>
                              ))}
                            </ul>
                          </CardBody>
                        </Card>
                      )}
                    <br />
                    {this.state.ImportProducts &&
                      this.state.ImportProducts.length > 0 && (
                        <Card className="bg-light shadow rounded border-0">
                          <div className="bg-primary p-4 text-center rounded-top">
                            <h4 className="mb-0 card-title title-dark text-light">
                              Products identified for import
                            </h4>
                          </div>

                          <CardBody>
                            <ul className="feature list-inline">
                              {this.state.ImportProducts.map((val) => (
                                <Fragment>
                                  <li className="h5 font-weight-normal">
                                    <i>
                                      <FeatherIcon
                                        icon="check-circle"
                                        className="fea icon-ex-md text-primary mr-2"
                                      />
                                    </i>
                                    {val.product ? val.product.name : val.name}
                                  </li>
                                  <br />
                                </Fragment>
                              ))}
                            </ul>
                          </CardBody>
                        </Card>
                      )}
                  </Col>
                  {/* key Features */}
                  {this.state.LargeIndustries &&
                    this.state.LargeIndustries.length > 0 && (
                      <div class="">
                        <Col lg={7} className="mt-1">
                          <strong>
                            Large Industries functioning in the District
                          </strong>
                          <Row>
                            {this.state.LargeIndustries.map((l) => (
                              <Col lg={6} className="pt-2">
                                <Media className="key-feature align-items-center p-3 rounded shadow bg-white">
                                  <Media body>
                                    <h5>{l.name}</h5>
                                  </Media>
                                </Media>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </div>
                    )}{" "}
                </Row>
                <div className="Container-fluid mt-5">
                  {this.state.DistrictGallery &&
                    this.state.DistrictGallery.map((di, index) => (
                      <div
                        className="col-lg-4 col-md-4 pointer"
                        style={{ minHeight: 370 }}
                      >
                        <div
                          className="project-details-image"
                          onClick={() =>
                            this.setState({
                              isOpenImage: true,
                              photoIndex: index,
                            })
                          }
                        >
                          <img
                            style={{
                              maxHeight: "300px",
                              minHeight: "300px",
                              maxWidth: "392px",
                              minWidth: "392px",
                            }}
                            src={di.image}
                            alt="work"
                          />
                          {di.remarks}
                          <FeatherIcon icon="camera" className="fea icon-sm" />
                        </div>
                      </div>
                    ))}
                </div>
                {isOpenImage && (
                  <Lightbox
                    mainSrc={
                      this.state.DistrictGallery[photoIndex] &&
                      this.state.DistrictGallery[photoIndex].image
                    }
                    nextSrc={
                      this.state.DistrictGallery[
                      (photoIndex + 1) % this.state.DistrictGallery.length
                      ] &&
                      this.state.DistrictGallery[
                        (photoIndex + 1) % this.state.DistrictGallery.length
                      ].image
                    }
                    prevSrc={
                      this.state.DistrictGallery[
                      (photoIndex + this.state.DistrictGallery.length - 1) %
                      this.state.DistrictGallery.length
                      ] &&
                      this.state.DistrictGallery[
                        (photoIndex + this.state.DistrictGallery.length - 1) %
                        this.state.DistrictGallery.length
                      ].image
                    }
                    onCloseRequest={() => this.setState({ isOpenImage: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + this.state.DistrictGallery.length - 1) %
                          this.state.DistrictGallery.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + 1) % this.state.DistrictGallery.length,
                      })
                    }
                  />
                )}
              </Container>
            </div>
          </Container>
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default District;
