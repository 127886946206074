import React, { Component } from "react";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";


class FooterSection extends Component {
  state = {
    data: {},
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 10,
    },
    themeOneFooterSection: {
      image: "/img/logo.png",
      text:
        "Leverage your enterprise by being equipped with the latest updates from FaMe TN.",
      address1:
        " First Floor, SIDCO Corporate Office Building, Thiru Vi Ka Industrial Estate, Guindy, Chennai, Tamil Nadu 600032 ",
      address2:
        "",
      address3:
        "",
      mail:
        " contact@fametn.com ",
      linkText_1: "Site Map",
      linkText_2: "Useful Links",
      linkText_3: "Download",
      iconList: [
        {
          id: 1,
          link: "facebook",
          iconClass: "fab fa-facebook-f",
        },
        {
          id: 2,
          link: "twitter",
          iconClass: "fab fa-twitter",
        },
        {
          id: 3,
          link: "youtube",
          iconClass: "fab fa-youtube",
        },
        {
          id: 4,
          link: "instagram",
          iconClass: "fab fa-instagram",
        }
        // {
        //   id: 3,
        //   link: "google-plus",
        //   iconClass: "fab fa-google-plus-g",
        // },
        // {
        //   "id": 4,
        //   "link": "vine",
        //   "iconClass": "fab fa-vine"
        // }
      ],
      footerList_1: [
        {
          id: 1,
          text: "Home",
          link: "/",
        },
        {
          id: 2,
          text: "About Us",
          link: "/about",
        },
        {
          id: 3,
          text: "Events",
          link: "/upcoming-events",
        },
        {
          id: 4,
          text: "Careers",
          link: "/careers",
        },
        {
          id: 5,
          text: "Contact",
          link: "/contact-page",
        }
      ],
      footerList_2: [
        {
          id: 1,
          text: "Gallery",
          link: "/gallery",
        },
        {
          id: 2,
          text: "Schemes",
          link: "/shemes",
        },
        {
          id: 3,
          text: "Policies",
          link: "/Policies",
        },
        {
          id: 4,
          text: "Tenders",
          link: "/tenders",
        },
        {
          id: 5,
          text: "Privacy Policy",
          link: "/privacy",
        },
      ],
      footerList_3: [
        {
          id: 1,
          text: "Export Promotion Schemes",
          link: "/Export-Schemes",
        },
        {
          id: 2,
          text: "Export Promotion Desks",
          link: "/export-desks",
        },
        {
          id: 3,
          text: "Export Resources",
          link: "/export-resources",
        },
        {
          id: 4,
          text: "Export Promotion Council",
          link: "/Epc",
        },
        {
          id: 5,
          text: "Export Financial Assistance",
          link: "/Financial-Assistance",
        },
      ],
      footerList_4: [
        {
          id: 1,
          text: "Udyam Registration",
          link: "/udhyam",
        },
        {
          id: 2,
          text: "District Wise Profile",
          link: "/state-profile",
        },
        {
          id: 3,
          text: "Sector Wise Profile",
          link: "/sector-profile",
        },
        {
          id: 4,
          text: "Single Window Portal",
          link: "/single-window-portal",
        },
        {
          id: 5,
          text: "Knowledge Centre",
          link: "/knowledge-centre",
        },
      ],
    },
  };
  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "socialmedia";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result,
      });
    }
  };
  render() {
    var { themeOneFooterSection } = this.state;
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      <div>
        <div id="footer-max-height" className="height-emulator d-none d-lg-block" />
        <footer id="footer" className="dark-footer-area footer-area footer-fixed ">
          {/* Footer Top */}
          <div className="footer-top pt_50">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="footer-brand row m-0" href="/#">
                      <img
                        className="logo col-4"
                        src={"/img/logo-white.png"}
                        style={{ width: "30%" }}
                        alt=""
                      />
                      {/* <img
                        className="logo col-8"
                        src={"/img/Logo_Text.svg"}
                        alt=""
                      /> */}
                      {/* <span id="navbar-brand-logo-text" className=" ml-1 navbar-brand-logo-text navbar-dark navbar-nav nav-link">MSME - Trade and Investment Promotion Bureau</span> */}
                    </a>
                    <p className="mt-0 mb-3 " style={{width: '50%'}}>
                      {themeOneFooterSection.text}
                    </p>
                  </div>
                </div>
                <div className={isMobile ? "col-5" :"col-12 col-sm-6 col-lg-1 footer-border"}>
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    {/* <h3 className="footer-title mb-3 text-white">
                      {themeOneFooterSection.linkText_1}
                    </h3> */}
                    <ul>
                      {themeOneFooterSection.footerList_1.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-0">
                            <a href={item.link} className="text-white">
                              {item.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className={isMobile ? "col-5" :"col-12 col-sm-6 col-lg-1 footer-border"}>
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    {/* <h3 className="footer-title mb-3 text-white">
                      {themeOneFooterSection.linkText_2}
                    </h3> */}
                    <ul>
                      {themeOneFooterSection.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-0">
                            <a href={item.link} className="text-white">
                              {item.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-2 footer-border">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    {/* <h3 className="footer-title mb-3 text-white">
                      {themeOneFooterSection.linkText_1}
                    </h3> */}
                    <ul>
                      {themeOneFooterSection.footerList_3.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-0">
                            <a href={item.link} className="text-white">
                              {item.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-2 footer-border">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    {/* <h3 className="footer-title mb-3 text-white">
                      {themeOneFooterSection.linkText_1}
                    </h3> */}
                    <ul>
                      {themeOneFooterSection.footerList_4.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-0">
                            <a href={item.link} className="text-white">
                              {item.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-12 col-sm-6 col-lg-3">
                  <div className="footer-items">
                    <h3 className="footer-title mb-2 text-white">
                      {themeOneFooterSection.linkText_3}
                    </h3>
                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                      {themeOneFooterSection.footerList_3.map((item, idx) => {
                        return (
                          <a
                            key={`flth_${idx}`}
                            href="/#"
                            className="text-white"
                          >
                            <img src={item.image} alt="" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
                <div className="col-12 col-sm-6 col-lg-3">
                  <div className="row text-white">
                    <div className="col-1">
                      <i className={"fas fa-paper-plane"} />
                    </div>
                    <div className=  {isMobile ? "col-11":  "col-10" }>
                      {/* {themeOneFooterSection.text} */}
                      {themeOneFooterSection.address1}
                      {themeOneFooterSection.address2}
                      {themeOneFooterSection.address3}

                    </div>
                    <div className="col-1">
                      <i className={"fas fa-envelope"} />
                    </div>
                    <div className="col-10">
                      {themeOneFooterSection.mail}
                    </div>
                    {/* <div className="col-1">
                      <i className={"fas fa-paper-plane"} />
                    </div>
                    <div className="col-10">
                      {themeOneFooterSection.contactnumber}
                    </div> */}
                    {/* Social Icons */}
                  </div>
                  <div className="social-icons d-flex mt-1">
                    {this.state.content && this.state.content.map((item, idx) => {
                      return (
                        <a key={`fi_${idx}`} className={item.link} href={item.url} target="_blank"
                          rel="noopener noreferrer"
                          style={{ borderRadius: "50%" }}
                        >
                          <i className={item.iconclass} />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Copyright Area */}
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    {/* Copyright Left */}
                    <div className="copyright-left">
                      © Copyrights {new Date().getFullYear()} FaMe TN All rights reserved.
                    </div>
                    {/* Copyright Right */}
                    <div className="copyright-right"
                      style={{ fontSize: '17px' }}>
                      Powered By{" "}
                      <a
                        className="text-white"
                        href="https://vertace.com/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Vertace
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterSection;