import React, { Component } from "react";

import Section from "./SectorSections";
//import FeatureSection from "../../components/Features/FeatureOne";
//import ServiceSection from "../../components/ServiceSection/ServiceOne";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";


class SectorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
      isOpenImage: false,
      contents: [],
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListRequestModelSector: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      }
    };
  }
  redirectTo = (path) => {
    this.props.history.push(path);
  };
  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "sector";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }
  GetContentResponse = (data) => {
    if (data.result) {
      this.setState(
        {
          sectors: data.result,
        },
      );
    }
  };
  selectSector = (sector) => {
    var selectedSector = this.state.sectors.find((s) => s._id === sector);
    const homeSelected = selectedSector.shortcode;
    this.redirectTo(`sector-profile/${homeSelected}`);
    this.setState({
      selectedSector: selectedSector,
      homeSelectedSector: homeSelected
    });
  };
  GetDistrictSectors(sector) {
    const { ListRequestModelSector } = this.state;
    ListRequestModelSector.TableName = "districtsectors";
    ListRequestModelSector.sector = sector;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelSector,
      "",
      this.GetDistrictSectorsResponse
    );
  }

  GetDistrictSectorsResponse = (data) => {
    if (data.result) {
      var districts = [];

      data.result
        .map((d) => d.district)
        .map((r) => {
          if (districts.findIndex((d) => d._id === r._id) === -1)
            districts.push(r);
          return ""
        });

      this.setState({
        isLoading: false,
        DistrictSectors: data.result,
        selectedDistricts: districts,
      });
    }
  };

  GetSectorContent(sector) {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      { TableName: "sectordetails", sector: sector },
      "",
      this.GetSectorContentResponse
    );
  }
  GetSectorContentResponse = (data) => {
    if (data.result) {
      this.setState({
        details: data.result,
      });
    }
  };
  render() {
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main state-profile">
          {/* {(!this.props.sub) && <Header imageData={"/img/logo-white.png"} />} */}

          <React.Fragment>
            <Section
              sectors={this.state.sectors}
              selectSector={this.selectSector}
              homeselected={this.state.homeSelectedSector}
            />
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default SectorProfile;
