/* eslint-disable react/jsx-no-target-blank */
import React, { Component, Fragment } from "react";

import Header from "../../components/HeaderSection/Header";
import { Row, Col, Table, Modal, ModalBody } from "reactstrap";

import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries, events, Payment_URL } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import EventAgenda from "../../components/Events/EventAgenda";
import Loader from "../../common/loader";
import PaymentForm from "../../components/Events/PaymentForm";



class SingleEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      ListEventRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      loading: true,
      choosedEvents: '',
      isOpen: false,
      currentpage: 1,
      eventTimes: '',
      selectedEvent: this.props.location.pathname.split("/")[2]
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    var userid = JSON.parse(sessionStorage.getItem("userid"));
    if (userid) {
      this.GetUserEvents();
    }
    this.GetContent();
  }

  GetUserEvents() {
    CallService(
      events.GetByUser,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100,
      },
      "",
      this.GetUserEventsResponse
    );
  }

  GetUserEventsResponse = (data) => {
    if (data.result) {
      this.setState(
        {
          userevents: data.result,
        }
      );
    }
  };

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "event";
    ListRequestModel.searchString = this.state.selectedEvent;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }


  GetContentResponse = (data) => {
    if (data.result) {
      var Event = data.result[0];
      this.setState(
        {
          event: Event,
        }, () => this.GetEvent()
      );
    }
  }

  GetEvent() {
    const { ListEventRequestModel } = this.state;
    ListEventRequestModel.TableName = "singleevent";
    ListEventRequestModel.event = this.state.event && this.state.event._id;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListEventRequestModel,
      "",
      this.GetEventResponse
    );
  }

  GetEventResponse = (data) => {
    if (data.result) {
      this.setState({
        singleevent: data.result[0],
        loading: false,
      });
    }
  };

  render() {

    const { event, singleevent, userevents } = this.state;

    return this.state.loading ? (
      <Loader />
    ) : (
        <div>
          {/*====== Scroll To Top Area Start ======*/}
          <div id="scrollUp" title="Scroll To Top">
            <i className="fas fa-arrow-up" />
          </div>
          {/*====== Scroll To Top Area End ======*/}
          <div className="main">
            <Header imageData={"/img/logo-white.png"} />
            {event &&
              <section className="">
                {/* <Row>
              <Col lg="12"> */}
                <EventAgenda selectedEvent={event} singleevent={singleevent} userevents={userevents} />
                {/* </Col>
            </Row> */}
                <div className="position-relative">
                  <div className="shape overflow-hidden text-white">
                    <svg
                      viewBox="0 0 2880 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>
                {singleevent ?
                  <Row style={{ marginLeft: 0, marginRight: 0 }}>
                    <Fragment >
                      <div className="container mt-0">
                        {/* Welcome Intro Start */}
                        {singleevent.banner &&
                          <section className="mt-2 mb-2 text-center"
                          >
                            <img src={singleevent.banner} alt="source" />
                          </section>

                        }
                        <div className="row m-0">
                          <div className={"col-12"}>
                            <div className="welcome-intro mt-4">
                              {/* <h3>Description</h3> */}

                              <div className="mt-2">
                                <p style={{ textAlign: "justify" }}>
                                  <div dangerouslySetInnerHTML={{
                                    __html: singleevent && singleevent.content,
                                  }}
                                  />
                                </p>
                                {/* <a target="_blank" jstcache="25" href="https://www.aeroindia.gov.in/"><span class="pointer text-underlined">Click Here</span></a> */}
                              </div>

                              {singleevent.image &&
                                <section className="mt-2 mb-2 text-center"
                                >
                                  <img src={singleevent.image} alt="source" />
                                </section>

                              }
                              <div className="row mt-2">
                                {singleevent.tariff &&
                                  <div className=" mt-1 mb-1 ml-3 mr-2 vertical-align" style={{ backgroundColor: "aliceblue", width: "48%" }}>
                                    <p style={{ textAlign: "justify" }} className="p-2">
                                      <div dangerouslySetInnerHTML={{
                                        __html: singleevent && singleevent.tariff,
                                      }}
                                      />
                                    </p>
                                  </div>
                                }
                                {singleevent.eligibility &&
                                  <div className=" mt-1 mb-1 ml-2" style={{ backgroundColor: "aliceblue", width: "48%" }}>
                                    <p style={{ textAlign: "justify" }} className="p-2">
                                      <div dangerouslySetInnerHTML={{
                                        __html: singleevent && singleevent.eligibility,
                                      }}
                                      />
                                    </p>
                                  </div>
                                }
                              </div>
                            </div>

                            {/* <h3>Participation from Tamil Nadu</h3> */}
                            <div className="row">
                              {singleevent.highlights &&
                                <div className="col-12 mt-1 mb-1" >
                                  <p style={{ textAlign: "justify" }}>
                                    <div dangerouslySetInnerHTML={{
                                      __html: singleevent && singleevent.highlights,
                                    }}
                                    />
                                  </p>
                                </div>
                              }
                            </div>

                            {singleevent.contact &&
                              singleevent.contact.length > 0 &&
                              <div className=" mt-2">
                                <h5 className=""><img src={singleevent.contacticon ? singleevent.contacticon : "/img/check.svg"} alt="check" className="mr-2" />Contact</h5>
                                <Row className="" >
                                  {singleevent.contact &&
                                    singleevent.contact.map((obj) => {
                                      return (
                                        <div className="col-lg-6 col-12 mt-2" >
                                          <div className="contact-us border border-gray mb-4 contact-box  contact-box-transition p-4" style={{ minHeight: "300px" }}>
                                            {obj.designation &&
                                              <h4 style={{ minHeight: "50px" }}>{obj.designation}</h4>}
                                            <ul>

                                              {obj.name && <li key={`ci_${1}`} className=" row">
                                                <div className="social-icon mr-3 col-1">
                                                  <i class="fas fa-user" />
                                                </div>

                                                <span className="media-body align-self-center col-10">
                                                  {obj.name}
                                                </span>
                                              </li>}


                                              {obj.address && <li key={`ci_${1}`} className=" row">
                                                <div className="social-icon mr-3 col-1">
                                                  <i className={"fas fa-paper-plane"} />
                                                </div>

                                                <span className="media-body align-self-center col-10">
                                                  {obj.address}
                                                </span>
                                              </li>
                                              }
                                              {obj.phonenumber &&

                                                <li key={`ci_${3}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-phone-square-alt"></i>
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.phonenumber}
                                                  </span>
                                                </li>
                                              }
                                              {obj.mobilenumber &&
                                                <li key={`ci_${2}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-mobile-alt" />
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.mobilenumber}
                                                  </span>
                                                </li>
                                              }
                                              {obj.email &&
                                                <li key={`ci_${2}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-envelope" />
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.email}
                                                  </span>
                                                </li>
                                              }
                                              {obj.website &&
                                                <li key={`ci_${2}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-globe-americas" />
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.website}
                                                  </span>
                                                </li>
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Row>
                              </div>
                            }
                          </div>
                          {singleevent.products && singleevent.products.length > 0 &&
                            <div className="col-12" style={{ marginLeft: "0px", marginTop: "0px" }}>
                              <Table bordered >
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Name Of Unit</th>
                                    <th>Product List</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {singleevent.products.map((pr, i) =>
                                    <tr cellspacing='20'>
                                      <th scope="row" cellspacing='1'>{i + 1}</th>
                                      <td >
                                        <b>{pr.title}</b>
                                        <div dangerouslySetInnerHTML={{ __html: pr.content }} />
                                      </td>
                                      <td>
                                        {/* <div className="align-items-center row">
                                          {pr.products && pr.products.map(prpr =>
                                            <Col lg={4} className={"mt-1"}
                                            >
                                              <div className="key-feature  border  p-3 rounded bg-white">
                                                <div className="icon rounded-circle h4 mr-3  mb-2">
                                                  <img
                                                    src={prpr.image}
                                                    className="avatar avatar-md-sm"
                                                    alt={prpr.title}
                                                    width='180px'
                                                    height='200px'
                                                  />
                                                </div>
                                                <div className="ml-1">
                                                  <b>{prpr.title}</b>
                                                  <p> {prpr.content}</p>
                                                </div>
                                              </div>
                                            </Col>

                                          )}

                                        </div> */}
                                        <img
                                          src={pr.image}
                                          className="avatar avatar-md-sm"
                                          alt={pr.title}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>

                          }

                        </div>
                      </div>
                    </Fragment>
                  </Row>
                  :
                  <Row className="align-items-center" style={{ marginTop: "30px", marginLeft: 0, marginRight: 0 }}>
                    <Col lg={1} />
                    <Col lg={10} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <h3 className="mt-4 text-center">Content yet to be updated</h3>
                    </Col>
                  </Row>
                }
              </section>
            }
          </div>
          <FooterSection />

        </div >
      )

  }
}


export default SingleEvent;


// export default SingleEvent;
