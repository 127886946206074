import React, { Component } from "react";
import { Link } from "react-router-dom";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";
import bg from "../../img/insurance/bg.png";
class Breadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
    }
  }
GetContent() {
  const { ListRequestModel } = this.state;
  ListRequestModel.TableName = "menu";
  ListRequestModel.sortCondition = { "sortorder": 1 };
  CallService(
    entries.GetAll,
    MethodType.POST,
    false,
    ListRequestModel,
    "",
    this.GetContentResponse
  );
}

GetContentResponse = (data) => {
  if (data.result) {
    sessionStorage.setItem("menu", JSON.stringify(data.result))
    var menu = data.result.filter(r => !r.parentmenu) && data.result.filter(r => !r.parentmenu).map(r => {
      return ({
        _id: r._id,
        id: r.sortorder,
        title: r.name,
        link: r.friendlyurl,
        child: data.result.filter(c => c.parentmenu && c.parentmenu._id === r._id) && data.result.filter(c => c.parentmenu && c.parentmenu._id === r._id).map(c => {
          return ({
            _id: c._id,
            id: c.sortorder,
            title: c.name,
            link: c.friendlyurl,
            isOpenSubMenu: false,
          })
        })
      })
    })
    this.setState({
      navLinks: menu,
      loading: false,
    });
  }
};

GetMenuContent() {
  CallService(
    entries.GetAll,
    MethodType.POST,
    false,
    {
      searchString: "",
      sortCondition: { "sortorder": 1 },
      pageNumber: 1,
      pageLimit: 1000,
      TableName: "menucontent"
    },
    "",
    this.GetMenuContentResponse
  );
}

GetMenuContentResponse = (data) => {
  if (data.result) {
    sessionStorage.setItem("menucontent", JSON.stringify(data.result))
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};

  render() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    var menucontent = JSON.parse(sessionStorage.getItem("menucontent"));
    var menu = JSON.parse(sessionStorage.getItem("menu"));
    if (menu && menucontent)
      var currentContent = menucontent.find(m => (m.menu && m.menu._id) === (menu.find(m => m.friendlyurl === window.location.pathname) && menu.find(m => m.friendlyurl === window.location.pathname)._id));
     else {
      this.GetContent();
      this.GetMenuContent();
     } 
     return (
       <section
        className="bg-half-130 bg-primary bg-overlay d-table w-100"
        style={{
          background: `url(${this.props.bg ? this.props.bg : (JSON.parse(sessionStorage.getItem("menu")) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0] && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg ? JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg : bg)}) no-repeat center center/cover fixed`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Breamcrumb Content */}
              <div className={isMobile ? "breadcrumb-content mt-5 d-flex flex-column align-items-center text-center" : "breadcrumb-content d-flex flex-column align-items-center text-center"}>
                <h1 className="text-white mb-3" style={{ textTransform: "capitalize" }}>{this.props.title}</h1>
                {this.props.breadcrumb &&
                  <h5 className="text-white font-weight-bold mt-0" style={{ width: 'auto' }}>
                    <Link className="text-white pointer" to={this.props.breadcrumb + "?SelectedPath=" + this.props.SelectedPath}>
                      {(menu &&menu.find(m => m.friendlyurl === this.props.breadcrumb)) && (menu &&menu.find(m => m.friendlyurl === this.props.breadcrumb).name)}
                    </Link>
                    {" > "}
                    {this.props.title}
                  </h5>
                }
                <p className="para-desc mx-auto text-white" style={{ minHeight: "100px" }}>
                  {this.props.subtitle ? this.props.subtitle : <div dangerouslySetInnerHTML={{
                    __html: currentContent && currentContent.content,
                  }}
                  />}
                </p>

                {/* <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link className="text-white" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item"><Link className="text-white" to="/BlogTwoColumn/">Blog Pages</Link></li>
                  <li className="breadcrumb-item active text-white">
                    {this.props.title}
                  </li>
                </ol> */}
              </div>
            </div>
          </div>
        </div>
        <div className="shape-bottom">
          {/* <svg
            viewBox="0 0 1920 310"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className="svg replaced-svg"
          >*/}
          <title></title>
          <desc></desc>
          <defs />
          <g
            id="sApp-Landing-Page"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="sApp-v1.0"
              transform="translate(0.000000, -554.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                id="sApp-v1.0"
              />
            </g>
          </g>
          {/* </svg>*/}
        </div>
      </section>
    );
  }
}

export default Breadcrumb;
