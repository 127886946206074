// import React, { Fragment } from "react";
import React, { Component } from "react";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
class AboutEpc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "exportpromotioncouncils";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result,
        loading: false,
      });
    }
  };
  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="container key-feature border">
          <div className="row">
            {this.state.content.map((obj) => {
              return (
                <div className="col-12 col-md-6 col-lg-12 res-margin">
                  {/* Single Review */}
                  <div className="card">
                    {/* Card Top */}
                    <div className="card-top">
                      <h4 className="text-primary">{obj.title}</h4>
                      {/* Review Text */}
                      <div className="review-text">
                        <p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: obj.content,
                            }}
                          ></div>
                          <div>{obj.email}</div>
                          <div>{obj.website}</div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

      );
  }
}

export default AboutEpc;
