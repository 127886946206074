import React, { Component } from "react";

import FooterSection from "../../components/FooterSection/Footer";
// import Section from "./StateSection";
//import FeatureSection from "../../components/Features/FeatureOne";
//import ServiceSection from "../../components/ServiceSection/ServiceOne";
import District from "./district";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";


class StateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  redirectTo = (path) => {
    this.props.history.push(path);
  };
  componentDidMount() {
    var toDistrict = this.props.location ? this.props.location.pathname : "";
    if (toDistrict) {
      toDistrict = toDistrict.replace("/state-profile", "");
      toDistrict = toDistrict.replace("/", "");
    }
    this.setState({
      toDistrict: toDistrict,
    });
  }
  render() {
    var menucontent = JSON.parse(sessionStorage.getItem("menucontent"));
    var menu = JSON.parse(sessionStorage.getItem("menu"));
    if (menu && menucontent)
      return (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            <District
              redirectTo={this.redirectTo}
              toDistrict={this.state.toDistrict}
            />
          </section>
          <FooterSection />
        </div>
      );
  }
}

export default StateProfile;