import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { Col, Container, Row, Media } from "reactstrap";
// import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
import KeyFeaturesBox from "../../components/Policies/PolicyFeaturesBox";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100000,
      },
      isOpen: false,
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListContentRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      groups: [],
      contents: [],
      isVisblecontent: "",
    };
  }
  componentDidMount() {
    this.GetUpdateContent();
    this.GetContent();
  }
  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "careergroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
        isVisblecontent: data.result[0],
      });
  };

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "career";
    ListRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result.length > 0 ? data.result : [],
        isVisblecontent: data.result[0],
      });
    }
    console.log(this.state.contents);
  };
  GetUpdateContent() {
    const { ListContentRequestModel } = this.state;
    ListContentRequestModel.TableName = "careerupdates";
    ListContentRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListContentRequestModel,
      "",
      this.GetUpdateContentResponse
    );
  }

  GetUpdateContentResponse = (data) => {
    if (data.result) {
      this.setState({
        careerUpdates: data.result.length > 0 ? data.result : [],
      });
    }
  };

  render() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const { isVisblecontent, contents } = this.state;

    return (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <div className="main ">
          <section className="m-5">
            <Container>
              <section id="blog" className="">
                <div className="container">
                  {this.state.careerUpdates &&
                    this.state.careerUpdates.length > 0 && (
                      <marquee
                        Scrollamount={4}
                        className={"marq"}
                        loop="infinite"
                        id={"careermarquee"}
                        data-duplicated="true"
                        onMouseOver={() =>
                          document.getElementById("careermarquee").stop()
                        }
                        onMouseLeave={() =>
                          document.getElementById("careermarquee").start()
                        }
                      >
                        {this.state.careerUpdates &&
                          this.state.careerUpdates.map((l) => (
                            <React.Fragment>
                              <img src="/img/newicon.gif" alt="new" />
                              <a
                                href={l.file ? l.file : l.link}
                                style={{ color: l.color }}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={l._id}
                                className={"m-2"}
                              >
                                {l.name}
                              </a>
                            </React.Fragment>
                          ))}
                      </marquee>
                    )}
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <div>
                        <aside className="sidebar">
                          <div className="single-widget">
                            {/* Category Widget */}
                            <div
                              className="accordions widget catagory-widget"
                              id="cat-accordion"
                            >
                              <div className="single-accordion blog-accordion">
                                <h5>
                                  <a
                                    role="button"
                                    className="collapse show text-uppercase d-block p-2"
                                    // data-toggle="collapse"
                                    href="/careers"
                                  >
                                    Careers
                                  </a>
                                </h5>
                                {/* Category Widget Content */}
                                <div
                                  id="accordion1"
                                  className="accordion-content widget-content collapse show"
                                  data-parent="#cat-accordion"
                                >
                                  {/* Category Widget Items */}
                                  {this.state.contents && (
                                    <ul className="widget-items sidebar">
                                      {this.state.contents.map((item, idx) => {
                                        return (
                                          <li
                                            key={`wdo_${idx}`}
                                            className={
                                              item._id === isVisblecontent._id
                                                ? "epc-li selected-accordion"
                                                : "epc-li"
                                            }
                                          >
                                            <a
                                              role="button"
                                              className="collapse show text-uppercase d-block p-2 pointer"
                                              // data-toggle="collapse"
                                              // href="/Export-Schemes"
                                              onClick={() =>
                                                this.toggleSchemesTitle(item)
                                              }
                                            >
                                              <span>{item.title}</span>
                                            </a>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                    {contents.length === 0 ? (
                      <div className="col-12">
                        <Row className="" style={{ minHeight: "300px" }}>
                          <Col
                            lg={12}
                            md={12}
                            className="mt-4 pt-2 mt-sm-0 pt-sm-0"
                          >
                            <div className="section-title ml-lg-4">
                              <img
                                src={"/img/tender_2.png"}
                                alt="source"
                                height="170px"
                                width="170px"
                              />
                              <p className=" my-3 mt-sm-4 mb-sm-5">
                                <h4>
                                  {" "}
                                  Details regarding opening of careers will be
                                  posted here. Kindly mark this page for future
                                  reference.
                                </h4>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div className="col-12 col-lg-9">
                        <Row>
                          {contents &&
                            contents.map((item, idx) => {
                              return (
                                isVisblecontent._id === item._id && (
                                  <div key={`bt_${idx}`} className="col-12">
                                    {/* Single Blog */}
                                    {/* <div className="key-feature border res-margin"> */}
                                    {/* Blog Content */}
                                    <div
                                      className={
                                        isMobile
                                          ? "blog-content "
                                          : "blog-content p-4"
                                      }
                                    >
                                      <strong>{item.title}</strong>
                                      <br />
                                      <a
                                        href={item.link}
                                        target="_blank"
                                        rel="nooperner noreferrer"
                                      >
                                        <button className="btn mt-1">
                                          Apply Now
                                        </button>
                                      </a>
                                      <br />
                                      <br />
                                      <p>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.content,
                                          }}
                                        ></div>
                                      </p>
                                      {item.file ? (
                                        <iframe
                                          title="procedure"
                                          src={item.file}
                                          height={500}
                                          width={isMobile ? 300 : 700}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {item.file ? <br /> : ""}
                                      <a
                                        href={item.link}
                                        target="_blank"
                                        rel="nooperner noreferrer"
                                      >
                                        <button className="btn mt-1">
                                          Apply Now
                                        </button>
                                      </a>
                                    </div>
                                  </div>
                                  // </div>
                                )
                              );
                            })}
                          {/* {contents.map((feature, key) => (
          
                            <Col key={key} lg={4} md={6} className="mt-4 pt-2">
                              <a
                                href={feature.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="media key-feature border align-items-center p-3 rounded"
                                style={{ minHeight: "100px" }}
                              >
                                {feature.icon && <div className="icon text-center rounded-circle h4 mr-3 mb-0">
                                  {
                                    <img src={feature.icon} alt="source" />
                                    }
                                </div>
                                }
                                <Media body>
                                  <h5 className="mb-0">
                                    {feature.title ? feature.title : feature.name}
                                  </h5>
                                </Media>

                                <div className="">
                                  <img src={"/img/pdf.svg"} alt="source" width='25.96px' height='31.93px' />
                                </div>

                              </a>
                            </Col>
                          
                        ))} */}
                        </Row>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </Container>
          </section>
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default Careers;
