// import React, { Fragment } from "react";
import React, { Component } from "react";

import { Container, Row, Card } from "reactstrap";
import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import UdhyamFile from "../udhyam/UdhyamFile";
import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
class Udhyam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "udhyamcontents";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result,
        loading: false,
      });
    }
  };

  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            <Container>

              <div className="col-12 text-center mb-lg-5 res-margin" >
                {/* Single Review */}
                <div className="key-feature border  p-2 card">
                  {/* Card Top */}
                  <div className="card-top p-4" >
                    <h4 className="text-primary mt-4 mb-2">Udyam Registration</h4>
                    {/* Review Text */}
                    <div className="review-text">
                      <p>A new process of registration has been introduced with effect from 01.07.2020 vide
                                                          Govt. of India Notification S.O. 2119(E),Ministry of MSME, GOI dated 26.06.2020.</p>
                    </div>
                    <div className="review-text">
                      <p>CLASSIFICATION OF ENTERPRISES</p>
                    </div>
                    <div className="justify-content-center mt-3">

                      <img src={"/img/udayam_Reg1.png"} alt="source" height='577px' width='474px' />
                      <img src={"/img/udayam_Reg2.png"} alt="source" height='577px' width='474px' />

                    </div>

                  </div>
                </div>
              </div>



            </Container>

            {/* <section className="section">*/}
            <Container>
              <Row className="align-items-center">
                {this.state.content &&
                  this.state.content.map((obj) => {
                    return (
                      <Card className="section-title mt-4 pt-2 col-md-6 col-lg-4">
                        <div
                          // className="section-title mt-4 pt-2 col-md-6 col-lg-6 key-feature align-items-center p-3 rounded shadow bg-white"
                          style={{
                            height: "250px",
                            border: "3px solid #0393ae", padding: "7px", borderRadius: "10px",
                          }}
                        >
                          <img src={obj.image} alt="udhyam" />
                          <strong>{obj.title}</strong>
                          <p className="text-muted mb-4"
                            style={{ height: "100px" }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: obj.content,
                              }}
                            ></div>

                          </p>

                          {obj.link === "/contact-page" ? <a href={obj.link}
                          >
                            <button className="btn btn-white btn-header-primary"
                            >
                              Click Here
                    </button>
                          </a> :
                            <a href={obj.link}
                              target="_blank" rel="noopener noreferrer">
                              <button className="btn btn-white btn-header-primary"
                              >
                                Click Here
                  </button>
                            </a>}
                        </div>
                      </Card>
                    );
                  })}
              </Row>
            </Container>
            {/*</section>*/}
            <UdhyamFile />
          </section>
          <FooterSection />
        </div>
      );
  }
}

export default Udhyam;
