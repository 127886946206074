// import React, { Fragment } from "react";
import React, { Component } from "react";

import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
// import images
import SWPsection from "./SWPSection";
import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
class SingleWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ListRequestModel: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 10000,
            },
            isOpen: false,
        };
        this.openModal = this.openModal.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true });
    }
    render() {
        return this.state.loading ? (
            <Loader />
        ) : (
                <div className="bg-white">
                    <HeaderBreadcrumb />
                    <section className="m-5">
                        <div className="container">
                            <SWPsection />
                        </div>
                    </section>
                    <FooterSection />
                </div>
            );
    }
}

export default SingleWindow;
