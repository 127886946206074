/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import TextTruncate from "../../common/TextTruncate";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
import { Modal, ModalBody } from "reactstrap";
import SingleBlogDetails from "../Blogs/SingleBlogDetails";
import {
  // EmailShareButton,
  FacebookShareButton,
  // HatenaShareButton,
  InstapaperShareButton,
  // LineShareButton,
  // LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  // PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  // TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  // WhatsappShareButton,
  // WorkplaceShareButton,
  TwitterIcon,
  FacebookIcon,
  InstapaperIcon,
} from "react-share";
import queryString from "query-string";

// const contents = [{ "title": "Resources", "content": "A report on investment opportunities for the MSME sector of Tamil Nadu with special focus on Singapore presented during the Singapore High Commissioners’ visit to Tamil Nadu " }]
// const groups = [{ "name": "Resources" }, { "name": "Blogs" }]

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      isOpen: false,
      isVisblecontent: "",
      detailsModal: false,
      currentContent: "",
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetContent();
  }

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "knowledgecentercontent";
    ListRequestModel.searchString = "scheme document";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result,
        loading: false,
      });
    }
  };
  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };
  render() {
    const { contents } = this.state;
    var item = contents && contents[0];
    if (!item) {
      item = {};
    }

    return this.state.loading ? (
      <Loader />
    ) : (
      <div className="bg-white">
        {/* <HeaderBreadcrumb /> */}
        <section className="m-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="col-12">
                  {/* Single Blog */}
                  {/* <div className="key-feature border res-margin"> */}
                  {/* Blog Content */}
                  <div className="blog-content p-2">
                    {/* <h3>{item.title}</h3> */}
                    <h3>Resources</h3>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></div>
                    </p>
                  </div>
                  {item.files && (
                    <div className="row ">
                      {item.files.map((file) =>
                        item.files.length < 3 ? (
                          <div
                            className="col-lg-12 col-12 res-margin ml-4 mt-4 mb-3"
                            onClick={() => {
                              file.friendlyurl &&
                                this.props.history.push(file.friendlyurl);
                            }}
                          >
                            <div className="row key-feature border p-2">
                              <div className="col-3 res-margin ">
                                <img src={file.image} alt={file.name} />
                              </div>
                              <div className="col-9">
                                <h5 className="text-primary1 mb-2">
                                  {file.name}
                                </h5>
                                <div className="float-left">{file.date}</div>
                                {file.file && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: file.content,
                                    }}
                                  ></div>
                                )}{" "}
                                <div className="mt-4  mb-2">
                                  {
                                    file.file && (
                                      <a
                                        href={file.file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={"/img/pdf.svg"}
                                          width={30}
                                          alt={file.name}
                                        />
                                        <span
                                          style={{
                                            color: "red",
                                            paddingLeft: "10px",
                                          }}
                                        >
                                          View Report
                                        </span>
                                      </a>
                                    )
                                    // :
                                    // <span style={{ color: "red", paddingLeft: "10px", cursor: "pointer" }}
                                    //   onClick={() => {

                                    //     this.props.history.push(file.friendlyurl);
                                    //   }}
                                    // >View More</span>
                                  }
                                  <div className="float-right">
                                    {!file.file && (
                                      <div>
                                        {file.share &&
                                          file.share.map((share) => {
                                            return share.type === "facebook" ? (
                                              <FacebookShareButton
                                                url={share.url}
                                                quote={share.content}
                                                title={share.name}
                                                className="share-button"
                                              >
                                                <FacebookIcon size={32} />
                                              </FacebookShareButton>
                                            ) : share.type === "twitter" ? (
                                              <TwitterShareButton
                                                url={share.url}
                                                quote={share.content}
                                                title={share.name}
                                                className="share-button"
                                              >
                                                <TwitterIcon size={32} />
                                              </TwitterShareButton>
                                            ) : share.type === "instagram" ? (
                                              <InstapaperShareButton
                                                url={share.url}
                                                quote={share.content}
                                                title={share.name}
                                                className="share-button"
                                              >
                                                <InstapaperIcon size={32} />
                                              </InstapaperShareButton>
                                            ) : (
                                              ""
                                            );
                                          })}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="col-lg-4 col-12 res-margin mt-4  mb-3"
                            onClick={() => {
                              file.friendlyurl &&
                                this.props.history.push(file.friendlyurl);
                            }}
                            style={file.friendlyurl && { cursor: "pointer" }}
                          >
                            <div
                              className="key-feature border p-2"
                              style={{ minHeight: "305px" }}
                            >
                              <img src={file.image} alt={file.name} />
                              <h5 className="text-primary1 mb-2 mt-2">
                                {file.name}
                              </h5>
                              <div className="float-left">{file.date}</div>
                              {file.file && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: file.content,
                                  }}
                                ></div>
                              )}
                              <div className="mt-4 mb-2">
                                {
                                  file.file && (
                                    <a
                                      href={file.file}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={"/img/pdf.svg"}
                                        width={30}
                                        alt={file.name}
                                      />
                                      <span
                                        style={{
                                          color: "red",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        View Report
                                      </span>
                                    </a>
                                  )
                                  // :
                                  // <span style={{ color: "red", paddingLeft: "10px", cursor: "pointer" }}
                                  //   onClick={() => {
                                  //     // this.setState({
                                  //     //   detailsModal: !this.state.detailsModal,
                                  //     //   currentContent: file
                                  //     // })
                                  //     this.props.history.push(file.friendlyurl);
                                  //   }}
                                  // >View More</span>
                                }
                                {!file.file && (
                                  <div>
                                    {file.share &&
                                      file.share.map((share) => {
                                        return share.type === "facebook" ? (
                                          <FacebookShareButton
                                            url={share.url}
                                            quote={share.content}
                                            title={share.name}
                                            className="share-button"
                                          >
                                            <FacebookIcon size={32} />
                                          </FacebookShareButton>
                                        ) : share.type === "twitter" ? (
                                          <TwitterShareButton
                                            url={share.url}
                                            quote={share.content}
                                            title={share.name}
                                            className="share-button"
                                          >
                                            <TwitterIcon size={32} />
                                          </TwitterShareButton>
                                        ) : share.type === "instagram" ? (
                                          <InstapaperShareButton
                                            url={share.url}
                                            quote={share.content}
                                            title={share.name}
                                            className="share-button"
                                          >
                                            <InstapaperIcon size={32} />
                                          </InstapaperShareButton>
                                        ) : (
                                          ""
                                        );
                                      })}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            size="lg"
            className="viewDocumentModal"
            isOpen={this.state.detailsModal}
            toggle={() =>
              this.setState({
                detailsModal: !this.state.detailsModal,
                currentContent: "",
              })
            }
          >
            <ModalBody className="m-3">
              {this.state.currentContent && (
                <SingleBlogDetails
                  currentContent={this.state.currentContent}
                  close={() =>
                    this.setState({
                      detailsModal: !this.state.detailsModal,
                      currentContent: "",
                    })
                  }
                />
              )}
            </ModalBody>
          </Modal>
        </section>
        {/* <FooterSection /> */}
      </div>
    );
  }
}

export default Report;
