/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import FooterSection from "../../../src/components/FooterSection/Footer";
import BlogDetails from "../Blogs/BlogDetails";

import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import BlogDetailsLeftSidebar from "../Blogs/BlogDetailsLeftSidebar";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import Loader from "../../common/loader";
import TextTruncate from "../../common/TextTruncate";
import DropDown from "../../common/DropDown";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
import Report from "../Report/report";
class Scheme extends React.Component {
  state = {
    applyUrlName: "",
    loading: true,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelScheme: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 12,
    },
    currentpage: 1,
    display: false,
  };
  componentDidMount() {
    var schemename = this.props.location.pathname;
    if (schemename.includes("apply")) {
      schemename = schemename.replace("/apply", "");
      this.props.history.push(schemename);
    }
    var name = schemename;
    if (name.includes("scheme")) {
      name = name.replace("/scheme", "");
    }
    var breadcrumbName = name.replace("/", "");
    // breadcrumbName = breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
    breadcrumbName = breadcrumbName.toUpperCase();
    this.GetSchemeContents();
    this.GetFilters();
    this.setState({
      currentSchemeName: schemename,
      breadcrumbName: breadcrumbName,
      urlName: breadcrumbName,
    });
  }
  GetSchemeContents() {
    const { ListRequestModelScheme } = this.state;
    ListRequestModelScheme.TableName = "scheme";
    ListRequestModelScheme.searchString = this.state.searchString ? this.state.searchString : "";
    if (this.state.selectedSchemeGroup && this.state.selectedSchemeGroup.length > 0)
      ListRequestModelScheme.schemegroup = this.state.selectedSchemeGroup;
    else
      delete ListRequestModelScheme.schemegroup;
    if (this.state.selectedSchemeCategory && this.state.selectedSchemeCategory.length > 0)
      ListRequestModelScheme.category = this.state.selectedSchemeCategory;
    else
      delete ListRequestModelScheme.category;
    if (this.state.selectedSchemeType && this.state.selectedSchemeType.length > 0)
      ListRequestModelScheme["new/existing"] = this.state.selectedSchemeType;
    else
      delete ListRequestModelScheme["new/existing"];
    if (this.state.selectedSpecialCategory && this.state.selectedSpecialCategory.length > 0)
      ListRequestModelScheme.specialcategoryfocussed = this.state.selectedSpecialCategory;
    else
      delete ListRequestModelScheme.specialcategoryfocussed;
    if (this.state.selectedWomenFocussed && this.state.selectedWomenFocussed.length > 0)
      ListRequestModelScheme.womenfocussed = this.state.selectedWomenFocussed;
    else
      delete ListRequestModelScheme.womenfocussed;
    if (this.state.selectedSchemeOffice && this.state.selectedSchemeOffice.length > 0)
      ListRequestModelScheme.schemeoffice = this.state.selectedSchemeOffice;
    else
      delete ListRequestModelScheme.schemeoffice;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelScheme,
      "",
      this.GetSchemeContentsResponse
    );
  }

  GetSchemeContentsResponse = (data) => {
    if (data.result) {
      data.result.map((r) => {
        return this.GetSchemeEligibilityContents(r._id);
      });
      this.setState({
        schemecontents: data.result.length > 0 ? data.result : [],
        totalCount: data.pagination && data.pagination.totalCount,
        loading: false,
      });
    }
  };
  GetSchemeEligibilityContents(schemeid) {
    var ListRequestModelEligibility = {};
    ListRequestModelEligibility.TableName = "schemeeligibilities";
    ListRequestModelEligibility.searchString = "";
    ListRequestModelEligibility["scheme"] = schemeid;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelEligibility,
      "",
      this.GetSchemeEligibilityContentsResponse
    );
  }

  GetSchemeEligibilityContentsResponse = (data) => {
    if (data.result) {
      var schemecontents = this.state.schemecontents;
      schemecontents.map((sc) => {
        if (data.result[0])
          if (sc._id === (data.result[0].scheme && data.result[0].scheme._id))
            sc.Eligibilities = data.result;
        return "";
      });
      this.setState({
        schemecontents: schemecontents,
      });
    }
  };
  gotoApply = (sc) => {
    this.setState({
      currentSchemeContent: sc,
      detailsModal: !this.state.detailsModal,
    });
    //   this.props.history.push(this.state.currentSchemeName + "/apply");
  };
  componentWillReceiveProps(newProps) {
    var schemename = newProps.location.pathname;
    this.setState({
      applyUrlName: schemename,
    });
  }

  handlebuttonDispay = (i) => {
    var schemecontents = this.state.schemecontents;
    schemecontents[i].display = true;
    this.setState({
      schemecontents: schemecontents,
    });
  };

  handlebuttonRemove = (i) => {
    var schemecontents = this.state.schemecontents;
    schemecontents[i].display = false;
    this.setState({
      schemecontents: schemecontents,
    });
  };
  toggleScheme = (id) => {
    var selectedSchemes = this.state.selectedSchemes;
    if (selectedSchemes && selectedSchemes.includes(id)) {
      var index = selectedSchemes.indexOf(id);
      if (index !== -1) selectedSchemes.splice(index, 1);
    } else {
      if (!selectedSchemes) {
        selectedSchemes = [];
      }
      selectedSchemes.push(id);
    }
    this.setState({
      selectedSchemes: selectedSchemes,
    });
    this.GetSchemeContents(selectedSchemes, true);
  };
  toggleSchemes = () => {
    debugger;
    var ministrycontents = this.state.ministrycontents;
    var selectedSchemes = this.state.selectedSchemes;
    ministrycontents.map((mc) => {
      if (selectedSchemes && selectedSchemes.includes(mc._id)) {
        var index = selectedSchemes.indexOf(mc._id);
        if (index !== -1) {
          selectedSchemes.splice(index, 1);
        }
      } else {
        if (!selectedSchemes) {
          selectedSchemes = [];
        }
        selectedSchemes.push(mc._id);
      }
      return this.setState({
        selectedSchemes: selectedSchemes,
        ministrySchemes: !this.state.ministrySchemes,
      });
    });
    this.GetSchemeContents(selectedSchemes, true);
  };
  getPage = (pageNumber) => {
    if ((pageNumber - 1) * 12 <= this.state.totalCount)
      this.setState(
        {
          currentpage: pageNumber,
          ListRequestModelScheme: {
            ...this.state.ListRequestModelScheme,
            pageNumber: pageNumber,
          },
        },
        function () {
          this.GetSchemeContents();
        }
      );
  };
  GetFilters = () => {
    this.GetSchemeGroup();
    this.GetSchemeCategory();
    this.GetSchemeType();
    this.GetSchemeSpecialCategory();
    this.GetSchemeWomenFocussed();
    this.GetSchemeOffice();
  }
  GetSchemeGroup() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "schemegroup"
      },
      "",
      this.GetSchemeGroupResponse
    );
  }

  GetSchemeGroupResponse = (data) => {
    if (data.result) {
      var schemeGroups = data.result.length > 0 ? data.result.map(r => { return ({ label: r.name, value: r._id }) }) : [];
      // schemeGroups.unshift({ label: "All Group", value: "" });
      this.setState({
        schemegroups: schemeGroups,
      });
    }
  };
  GetSchemeCategory() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "schemecategory"
      },
      "",
      this.GetSchemeCategoryResponse
    );
  }

  GetSchemeCategoryResponse = (data) => {
    if (data.result) {
      this.setState({
        schemecategories: data.result.length > 0 ? data.result.map(r => { return ({ label: r.name, value: r._id }) }) : [],
      });
    }
  };
  GetSchemeType() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "schemetype"
      },
      "",
      this.GetSchemeTypeResponse
    );
  }

  GetSchemeTypeResponse = (data) => {
    if (data.result) {
      this.setState({
        schemetypes: data.result.length > 0 ? data.result.map(r => { return ({ label: r.name, value: r._id }) }) : [],
      });
    }
  };
  GetSchemeSpecialCategory() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "schemespecialcategory"
      },
      "",
      this.GetSchemeSpecialCategoryResponse
    );
  }

  GetSchemeSpecialCategoryResponse = (data) => {
    if (data.result) {
      this.setState({
        schemespecialcategories: data.result.length > 0 ? data.result.map(r => { return ({ label: r.name, value: r._id }) }) : [],
      });
    }
  };
  GetSchemeWomenFocussed() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "schemewomenfocussed"
      },
      "",
      this.GetSchemeWomenFocussedResponse
    );
  }

  GetSchemeWomenFocussedResponse = (data) => {
    if (data.result) {
      this.setState({
        schemewomenfocusseds: data.result.length > 0 ? data.result.map(r => { return ({ label: r.name, value: r._id }) }) : [],
      });
    }
  };
  GetSchemeOffice() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "schemeoffice"
      },
      "",
      this.GetSchemeOfficeResponse
    );
  }

  GetSchemeOfficeResponse = (data) => {
    if (data.result) {
      this.setState({
        schemeoffices: data.result.length > 0 ? data.result.map(r => { return ({ label: r.name, value: r._id }) }) : [],
      }, () => {
        this.setState({
          selectedSchemeOffice: ["5fbb8147506ac68c7baf3bb6"]
        }, () => this.GetSchemeContents())
      });
    }
  };
  render() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    console.log("blog data data=>" + JSON.stringify(this.state.selectedSchemeOffice));



    return this.state.loading ? (
      <Loader />
    ) : (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <section className="m-5">
          {this.state.loading ? (
            <div className="bar"></div>
          ) : this.state.applyUrlName.includes("/apply") ? (
            <Row>
              <Col lg="1"></Col>
              <Col lg="10">
                <BlogDetailsLeftSidebar
                  schemeContent={this.state.currentSchemeContent}
                />
              </Col>
              <Col lg="1"></Col>
            </Row>
          ) : (
            <Fragment>
              <section className="pricing-area pt-3 pb-3">
                <div className="container">
                  {/* <div className="section-title">
                            <h2>Scheme Group</h2>
                          </div>
                          <Row className="p-2">
                            {this.state.nonministrycontents.map((c, i) => {
                              return (
                                <Col lg={"3"}>
                                  <div className=" pointer text-uppercase">
                                    <Alert
                                      color={
                                        this.state.selectedSchemes &&
                                          this.state.selectedSchemes.includes(c._id)
                                          ? "success"
                                          : "primary"
                                      }
                                      onClick={() => this.toggleScheme(c._id)}
                                    >
                                      {c.name}
                                    </Alert>
                                  </div>
                                </Col>
                              );
                            })}
                            <Col>
                              <div className="pointer text-uppercase">
                                <Alert
                                  color={
                                    this.state.ministrySchemes ? "success" : "primary"
                                  }
                                  onClick={() => this.toggleSchemes()}
                                >
                                  All Schemes
                          </Alert>
                              </div>
                            </Col>
                          </Row> */}



                  <div className="section-title">
                    <h2>
                      Schemes
                      </h2>
                    <div className="bar"></div>
                    <p >
                      {/* The Government supports the MSMEs with policy support and various schemes.
                            The main focus is to promote economic and social development of the country
                            by fostering entrepreneurship with a socially inclusive policy. Promotion
                            of women entrepreneurs isanother focus area of the Government. The schemes
                            are available across the life cycle of a MSME unit. The support is given in
                            the areas of self employment, financing, marketing, skill training, infrastructure
                            and certification. Please check schemes section to view the various schemes available
                            for MSMEs */}
                      {/* <div dangerouslySetInnerHTML={{
                              __html: currentContent && currentContent.content,
                            }}
                            /> */}
                    </p>
                  </div>
                  <div className="row m-4">
                    <div className="col-8" />
                    <div className="col-lg-4 col-12">
                      <div className="single-widget">
                        {/* Search Widget */}
                        <div className="widget-content search-widget">
                          <input type="text" placeholder="Enter your keywords to search" onChange={(e) => { this.setState({ searchString: e.target && e.target.value }, () => this.GetSchemeContents()) }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-4">
                    <DropDown
                      className="react-select filters"
                      classNamePrefix="react-select"
                      label="Government"
                      name="Government"
                      isMulti
                      colSplit={2}
                      MobcolSplit={12}
                      options={this.state.schemegroups}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      placeholderText={"All Government"}
                      Checkbox={true}
                      ClearAction={() => {
                        this.setState({
                          selectedSchemeGroup: []
                        }, () => this.GetSchemeContents())
                      }}
                      Action={(entity) => {
                        var selectedSchemeGroup = [];
                        if (entity)
                          entity.map((e) => {
                            return selectedSchemeGroup.push(e.value);
                          });
                        this.setState({
                          selectedSchemeGroup: selectedSchemeGroup
                        }, () => this.GetSchemeContents())
                      }}
                    />
                    <DropDown
                      className="react-select filters"
                      classNamePrefix="react-select"
                      label="Departments"
                      name="Departments"
                      isMulti
                      colSplit={2}
                      MobcolSplit={12}
                      options={this.state.schemeoffices}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      value={(this.state.selectedSchemeOffice && this.state.schemeoffices) && this.state.schemeoffices.filter(r => this.state.selectedSchemeOffice.includes(r.value))}
                      placeholderText={"All Department"}
                      Checkbox={true}
                      ClearAction={() => {
                        this.setState({
                          selectedSchemeOffice: []
                        })
                      }}
                      Action={(entity) => {
                        var selectedSchemeOffice = [];
                        if (entity)
                          entity.map((e) => {
                            return selectedSchemeOffice.push(e.value);
                          });
                        this.setState({
                          selectedSchemeOffice: selectedSchemeOffice
                        }, () => this.GetSchemeContents())
                      }}
                    />
                    <DropDown
                      className="react-select filters"
                      classNamePrefix="react-select"
                      label="Scheme Classification"
                      name="SchemeClassification"
                      isMulti
                      colSplit={3}
                      MobcolSplit={12}
                      options={this.state.schemecategories}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      placeholderText={"All Scheme Classifications"}
                      Checkbox={true}
                      ClearAction={() => {
                        this.setState({
                          selectedSchemeCategory: []
                        }, () => this.GetSchemeContents())
                      }}
                      Action={(entity) => {
                        var selectedSchemeCategory = [];
                        if (entity)
                          entity.map((e) => {
                            return selectedSchemeCategory.push(e.value);
                          });
                        this.setState({
                          selectedSchemeCategory: selectedSchemeCategory
                        }, () => this.GetSchemeContents())
                      }}
                    />
                    <DropDown
                      className="react-select filters"
                      classNamePrefix="react-select"
                      label="New/Existing MSME"
                      name="Types"
                      isMulti
                      colSplit={3}
                      MobcolSplit={12}
                      options={this.state.schemetypes}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      placeholderText={"New/Existing MSME"}
                      Checkbox={true}
                      ClearAction={() => {
                        this.setState({
                          selectedSchemeType: []
                        }, () => this.GetSchemeContents())
                      }}
                      Action={(entity) => {
                        var selectedSchemeType = [];
                        if (entity)
                          entity.map((e) => {
                            return selectedSchemeType.push(e.value);
                          });
                        this.setState({
                          selectedSchemeType: selectedSchemeType
                        }, () => this.GetSchemeContents())
                      }}
                    />
                    <DropDown
                      className="react-select filters"
                      classNamePrefix="react-select"
                      label="Special Category"
                      name="SpecialCategory"
                      isMulti
                      colSplit={2}
                      MobcolSplit={12}
                      options={this.state.schemespecialcategories}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      placeholderText={"Special Category"}
                      Checkbox={true}
                      ClearAction={() => {
                        this.setState({
                          selectedSpecialCategory: []
                        }, () => this.GetSchemeContents())
                      }}
                      Action={(entity) => {
                        var selectedSpecialCategory = [];
                        if (entity)
                          entity.map((e) => {
                            return selectedSpecialCategory.push(e.value);
                          });
                        this.setState({
                          selectedSpecialCategory: selectedSpecialCategory
                        }, () => this.GetSchemeContents())
                      }}
                    />
                    {/* <DropDown
                            className="react-select filters"
                            classNamePrefix="react-select"
                            label="Women Focussed"
                            name="WomenFocussed"
                            isMulti
                            colSplit={2}
                            MobcolSplit={12}
                            options={this.state.schemewomenfocusseds}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            placeholderText={"Women Focussed"}
                            Checkbox={true}
                            ClearAction={() => {
                              this.setState({
                                selectedWomenFocussed: []
                              }, () => this.GetSchemeContents())
                            }}
                            Action={(entity) => {
                              var selectedWomenFocussed = [];
                              if (entity)
                                entity.map((e) => {
                                  return selectedWomenFocussed.push(e.value);
                                });
                              this.setState({
                                selectedWomenFocussed: selectedWomenFocussed
                              }, () => this.GetSchemeContents())
                            }}
                          /> */}
                  </div>
                  {this.state.schemecontents &&
                    this.state.schemecontents.length === 0
                    && <h3 className={"mt-5 text-center"} style={{ minHeight: "200px" }}>
                      No schemes found
                          </h3>}
                  {this.state.schemecontents &&
                    this.state.schemecontents.length > 0
                    && <h3 className={"mt-5 text-center"}>
                      {this.state.totalCount} {this.state.totalCount === 1 ? "Scheme" : "Schemes"} found
                          </h3>}

                  <div className="row ">
                    {this.state.schemecontents &&
                      this.state.schemecontents.length > 0 &&
                      this.state.schemecontents.map((sc) => {
                        return (
                          <div className="col-lg-4 mt-4 col-12">
                            <div
                              className="border p-2"
                              style={{ borderRadius: "4px", height: "200px" }}
                            // onMouseOver={() => this.handlebuttonDispay(i)}
                            // onMouseLeave={() =>
                            //   this.handlebuttonRemove(i)
                            // }
                            >
                              <div className=""
                                style={{ height: isMobile ? "175px" : "140px", overflow: "hidden", textAlign: "justify" }}
                              >
                                <h5 className="pb-4">{sc.nameofscheme}</h5>
                                <TextTruncate value={sc.brief} length={200} onClick={() => this.gotoApply(sc)} />
                              </div>
                              <Button
                                className="btn-outline"
                                onClick={() => this.gotoApply(sc)}
                              >
                                View More
                                        </Button>
                              {/* 
                                  <div>
                                    <Button
                                      className="viewMore btn btn-primary text-white"
                                      onClick={() => this.gotoApply(sc)}
                                    >
                                      View More
                                    </Button>
                                  </div> */}

                              {/* )} */}
                            </div>
                          </div>
                        );
                      })}
                    <Modal
                      size="lg"
                      className="viewDocumentModal"
                      isOpen={this.state.detailsModal}
                      toggle={this.gotoApply}
                    >
                      <ModalBody className="m-3">
                        <BlogDetails
                          currentSchemeContent={
                            this.state.currentSchemeContent
                          }
                          gotoApply={() => this.gotoApply()}
                        />
                      </ModalBody>
                      {/* <ModalFooter>
                              <Button
                                className="default modal-btn   btn-sm mb-2 btn-addon"
                                color="danger"
                                onClick={() => this.gotoApply()}
                              >
                                Close
                          </Button>
                            </ModalFooter> */}
                    </Modal>
                    {/* </div>
                    <div className="row"> */}
                    {/* <div className="col-9">
                        {this.state.schemecontents &&
                          this.state.schemecontents.length > 0 &&
                          this.state.schemecontents.map((sc, i) => {
                            return (
                              <div className="col-lg-4 mt-4 ">
                                {/* <div
                                        onMouseOver={() => this.handlebuttonDispay(i)}
                                        onMouseLeave={() => this.handlebuttonRemove(i)}
                                        className="pricing-table border border-gray"
                                        style={{ height: "500px" }}
                                      >
                                        <div
                                          className="pricing-header customScrollBar onhoverScroll"
                                          style={{ maxHeight: "100px" }}
                                        >
                                          <h3>{sc.nameofscheme}</h3>
                                        </div>
                                        <div
                                          className="pricing-features customScrollBar onhoverScroll"
                                          style={
                                            sc.display
                                              ? { height: "250px" }
                                              : { height: "300px" }
                                          }
                                        >
                                     
                                          <div
                                            className={"m-3 text-left"}
                                            dangerouslySetInnerHTML={{
                                              __html: sc["eligibility/terms"],
                                            }}
                                          ></div>
                                        </div>
                                        {sc.display ? (
                                          <div className="pricing-footer">
                                            <Button
                                              className="btn btn-primary text-white"
                                              onClick={() => this.gotoApply(sc)}
                                            >
                                              Apply Now
                                    </Button>
                                          </div>
                                        ) : (
                                            ""
                                          )}
                                      </div> 
                                <div
                                  className="pricing-box customScrollBar onhoverScroll"
                                  onMouseOver={() => this.handlebuttonDispay(i)}
                                  onMouseLeave={() =>
                                    this.handlebuttonRemove(i)
                                  }
                                  style={{ height: "500px" }}
                                >
                                  <div className="pricing-header">
                                    <h3>{sc.nameofscheme}</h3>
                                  </div>

                                  <ul className="pricing-features  mt-2">
                                    {sc.Eligibilities &&
                                      sc.Eligibilities.map((el) => {
                                        return (
                                          <li>
                                            <Row>
                                              {/* <Col lg={4}>
                                                <img
                                                  className="float-left"
                                                  id={el._id}
                                                  src={
                                                    el.eligibility &&
                                                    el.eligibility.image
                                                  }
                                                  alt={
                                                    el.eligibility &&
                                                    el.eligibility.name
                                                  }
                                                />
                                              </Col> 
                                              <Col lg={8}>
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: el.content,
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </li>
                                        );
                                      })}
                                    {/* <div
                                            className={"m-1 text-left"}
                                            dangerouslySetInnerHTML={{
                                              __html: sc["eligibility/terms"],
                                            }}
                                          ></div> 
                                  </ul>

                                  {/* {sc.display &&  (
                                  <div className="buy-btn mt-2">
                                    <Button
                                      className="btn btn-primary text-white"
                                      onClick={() => this.gotoApply(sc)}
                                    >
                                      View More
                                    </Button>
                                  </div>
                                  {/* )} 
                                </div>
                              </div>
                            );
                          })}
                      </div> */}
                    {/* <div className="col-3">
                        <div className="row">
                          {this.state.nonministrycontents.map((c, i) => {
                            return (
                              <div className="col-12 pointer text-uppercase">
                                <Alert
                                  color={
                                    this.state.selectedSchemes &&
                                    this.state.selectedSchemes.includes(c._id)
                                      ? "success"
                                      : "primary"
                                  }
                                  onClick={() => this.toggleScheme(c._id)}
                                >
                                  {c.name}
                                </Alert>
                              </div>
                            );
                          })}
                          <div className="col-12 pointer text-uppercase">
                            <Alert
                              color={
                                this.state.ministrySchemes
                                  ? "success"
                                  : "primary"
                              }
                              onClick={() => this.toggleSchemes()}
                            >
                              All Schemes
                            </Alert>
                          </div>
                        </div>
                      </div> */}
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 col-md-12">
                      <ul className="pagination justify-content-center py-4">
                        <li>
                          <a
                            aria-label="Start"
                            onClick={() => {
                              this.getPage(1);
                            }}
                          >
                            <i className="fas  fa-angle-double-left" />
                          </a>
                        </li>
                        {((this.state.currentpage - 2) * 12) <= this.state.totalCount &&
                          <li
                            className={
                              this.state.currentpage < 2
                                ? "disabled px-1"
                                : "px1"
                            }
                          >
                            <a
                              aria-label="Previous"
                              onClick={() => {
                                this.state.currentpage > 1 &&
                                  this.getPage(this.state.currentpage - 1);
                              }}
                            >
                              <i className="fas fa-arrow-left" />
                            </a>
                          </li>}
                        {((this.state.currentpage - 1) * 12) <= this.state.totalCount &&
                          <li className="px-1 ">
                            <a
                              className="pagination-active"
                              onClick={() => {
                                this.getPage(this.state.currentpage);
                              }}
                            >
                              {this.state.currentpage}
                            </a>
                          </li>}
                        {((this.state.currentpage) * 12) <= this.state.totalCount &&
                          <li className="px-1">
                            <a
                              onClick={() => {
                                this.getPage(this.state.currentpage + 1);
                              }}
                            >
                              {this.state.currentpage + 1}
                            </a>
                          </li>}
                        {((this.state.currentpage + 1) * 12) <= this.state.totalCount &&
                          <li className="px-1">
                            <a
                              onClick={() => {
                                this.getPage(this.state.currentpage + 2);
                              }}
                            >
                              {this.state.currentpage + 2}
                            </a>
                          </li>}
                        {((this.state.currentpage + 2) * 12) <= this.state.totalCount && this.state.schemecontents && this.state.schemecontents.length > 0 &&
                          <li>
                            <a
                              aria-label="Next"
                              onClick={() => {
                                this.getPage(this.state.currentpage + 3);
                              }}
                            >
                              <i className="fas fa-arrow-right" />
                            </a>
                          </li>}
                        <li>
                          <a
                            aria-label="Last"
                            onClick={() => {
                              this.getPage(Math.ceil(this.state.totalCount / 12));
                            }}
                          >
                            <i className="fas fa-angle-double-right" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div>
                  <Report />
                </div>
              </section>
            </Fragment>
          )}
        </section>
        <FooterSection />{" "}
      </div >
    );
  }
}

export default Scheme;
