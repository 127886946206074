import React, { Component, Fragment } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import automobile_sector from "../../img/sectors/automobile_sector.png";
import aerospace_defence from "../../img/sectors/aerospace_defence.png";
import agro_food_processing from "../../img/sectors/agro_food_processing.png";
import chemicals_petro from "../../img/sectors/chemicals_petro.png";
import electronic_hardware from "../../img/sectors/electronic_hardware.png";
import heavy_engineering from "../../img/sectors/heavy_engineering.png";
import it_and_ites from "../../img/sectors/it_and_ites.png";
import pharma_biotech from "../../img/sectors/pharma_biotech.png";
import renewable_energy from "../../img/sectors/renewable_energy.png";
import textile_apparels from "../../img/sectors/textile_apparels.png";
import Loader from "../../common/loader";
import TextTruncate from "../../common/TextTruncate";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      categories: [
        { img: automobile_sector, title: "Automobile", link: "#" },
        { img: aerospace_defence, title: "Aerospace & Defence", link: "#" },
        {
          img: electronic_hardware,
          title: "Electronics & Hardware",
          link: "#",
        },
        { img: it_and_ites, title: "IT & ITES", link: "#" },
        { img: renewable_energy, title: "Renewable Energy", link: "#" },
        { img: heavy_engineering, title: "Heavy Engineering", link: "#" },
        {},
        {
          img: agro_food_processing,
          title: "Agro & Food Processing",
          link: "#",
        },
        { img: textile_apparels, title: "Textiles & Apparels", link: "#" },
        {
          img: chemicals_petro,
          title: "Chemicals & Petrochemicals",
          link: "#",
        },
        { img: pharma_biotech, title: "Pharma & Bio-Tech", link: "#" },
        {},
      ],
    };
  }
  componentDidMount() {
    // this.GetContent();
    this.setState({
      loading: false,
    });
  }

  render() {
    var menucontent = JSON.parse(sessionStorage.getItem("menucontent"));
    var menu = JSON.parse(sessionStorage.getItem("menu"));
    if (menu && menucontent)
      var currentContent = menucontent.find(m => (m.menu && m.menu._id) === (menu.find(m => m.friendlyurl === "/sector-profile") && menu.find(m => m.friendlyurl === "/sector-profile")._id));

    return this.state.loading ? (
      <Loader />
    ) : (
        <React.Fragment>
          <section
            className="d-table w-100 sector_bg"
          //style={{ background: `#20004d` }}

          >
            <Container className="mt-3">
              <Row className="mt-0 justify-content-center sector-align">
                <Col lg={12}>
                  <div className="title-heading text-center">
                    {/* <h1 className="heading title-dark text-white mb-3">
                      SECTOR
                  </h1> */}
                    <Row className="mt-2 mb-4 pt-2 key-success-stories">
                      <Col lg={3} xs={12} className="mt-4 pt-2 sector-item">
                        {/* <Card className="explore-feature insheight border-0 rounded text-center bg-white ">
                              <CardBody style={{ background: `#007bff` }}> */}
                        <h3 className="heading title-dark text-dark mb-3">
                          Sector
                        </h3>
                        <div className="content mt-3">
                          <h6 className="mb-0 text-dark" style={{ textAlign: "justify" }}
                          >
                            <TextTruncate isHtml={true} value={currentContent && currentContent.content} length={270} title={currentContent && currentContent.title} />
                          </h6>
                        </div>
                        {/* </CardBody></Card> */}
                      </Col>
                      {this.props.sectors &&
                        this.props.sectors.map((s, key) => (
                          <Fragment key={key}>
                            <Col key={key} lg={3} xs={12} className="mt-4 pt-2 sector-item">
                              {s.name && (
                                <Card className="explore-feature insheight border-0 rounded text-center bg-white ">
                                  <CardBody style={{ background: `#ffffff`, borderRadius: "4px" }}>
                                    <Link
                                      onClick={() =>
                                        this.props.selectSector(s._id)
                                      }
                                      className="title text-dark"
                                    >
                                      <div className="icon rounded-circle d-inline-block "
                                        style={{ background: `rgb(233 253 255)` }}>
                                        <img
                                          src={s.icon}
                                          className="avatar avatar-md-sm sector-img"
                                          alt={s.name}
                                        />
                                      </div>
                                      <div className="content mt-3">
                                        <h6 className="mb-0 text-dark"
                                        >{s.name}</h6>
                                      </div>
                                    </Link>
                                  </CardBody>
                                </Card>
                              )}{" "}
                            </Col>
                          </Fragment>
                        ))}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>

            {/* Shape Bottom */}
            {/* <div className=" homepage-6">
              <div className="shape-bottom">
                <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                  <path className="fill" d="M0,6V0h1000v100L0,6z" />
                </svg>
              </div>
            </div> */}
          </section>
        </React.Fragment>
      );
  }
}

export default Section;