/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Fragment } from "react";
import React, { Component } from "react";

import { Container } from "reactstrap";
import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";

class ExportSchemes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100,
      },
      isOpen: false,
      isVisblecontent: "",
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "exportscheme";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result,
        loading: false,
        isVisblecontent: data.result[0]
      });
    }
  };

  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
      window.scrollTo(0, 200);
    }
  };

  render() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const { isVisblecontent } = this.state;
    return (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <section className="m-5 ">
          <Container>
            <div className="row">
              <div className="col-12 col-lg-3">
                <div>
                  <aside className="sidebar">
                    <div className="single-widget">
                      {/* Category Widget */}
                      <div
                        className="accordions widget catagory-widget"
                        id="cat-accordion"
                      >
                        <div className="single-accordion blog-accordion">
                          <h5>
                            <a
                              role="button"
                              className="collapse show text-uppercase d-block p-2"
                              // data-toggle="collapse"
                              href="/Export-Schemes"
                            >
                              Export Promotion Schemes
                                  </a>
                          </h5>
                          {/* Category Widget Content */}
                          <div
                            id="accordion1"
                            className="accordion-content widget-content collapse show"
                            data-parent="#cat-accordion"
                          >
                            {/* Category Widget Items */}
                            {this.state.contents && (
                              <ul className="widget-items sidebar">
                                {this.state.contents.map((item, idx) => {
                                  return (
                                    <li key={`wdo_${idx}`} className={(item._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                      <a
                                        role="button"
                                        className="collapse show text-uppercase d-block p-2 pointer"
                                        // data-toggle="collapse"
                                        // href="/Export-Schemes"
                                        onClick={() =>
                                          this.toggleSchemesTitle(item)
                                        }
                                      >
                                        <span>{item.title}</span>
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
              <div className="col-12 col-lg-9">
                {this.state.contents &&
                  this.state.contents.map((item, idx) => {
                    return (

                      isVisblecontent._id === item._id && (
                        <div key={`bt_${idx}`} className="col-12">
                          {/* Single Blog */}
                          {/* <div className="key-feature border res-margin"> */}
                          {/* Blog Content */}
                          <div className={isMobile ? "blog-content " : "blog-content p-4"}>
                            <strong>{item.title}</strong>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              ></div>
                            </p>
                            <a href={item.link} target="_blank" rel="nooperner noreferrer">
                              <button className="btn mt-1">
                                Click Here
                              </button>
                            </a>
                          </div>
                        </div>
                        // </div>
                      )
                    );
                  })}
              </div>
            </div>
          </Container>
        </section>
        <FooterSection />
      </div>
    );
  }
}

export default ExportSchemes;
