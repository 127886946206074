import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Header from "../../components/HeaderSection/Header";
import FooterSection from "../../components/FooterSection/Footer";
import Breadcrumb from "../../components/Blogs/Breadcrumb";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import FeatherIcon from "feather-icons-react";
import bg from "../../img/events.jpg";


export default class Images extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      ListRequestImageModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10000,
      },
      ListRequestVideoModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10000,
      },
      isOpen: false,
      photoIndex: 0,
      isOpenImage: false,
    };
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  componentDidMount() {
    var albumname = this.props.location.pathname;
    var name = albumname.toLowerCase();
    if (name.includes("album")) {
      name = name.replace("/album", "");
    }
    var breadcrumbName = name.replace("/", "");
    // breadcrumbName = breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
    breadcrumbName = breadcrumbName.toUpperCase();
    this.GetContent(breadcrumbName);
    this.setState({
      currentAlbumName: albumname,
      breadcrumbName: breadcrumbName,
      urlName: breadcrumbName,
    });
  }
  GetContent(albumname) {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "album";
    ListRequestModel.searchString = this.props.location.pathname;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      // eslint-disable-next-line no-unused-vars
      var breadcrumbName = data.result[0].friendlyurl.replace("/Album/", "");
      breadcrumbName =
        breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
      if (!this.state.urlName) {
        breadcrumbName = this.state.breadcrumbName;
      }
      this.setState({
        contents: data.result.length > 0 ? data.result : [],
        loading: data.result[0] ? true : false,
        album: data.result[0],
      });
      if (data.result[0]) {
        this.GetImages(data.result[0]._id);
        this.GetVideos(data.result[0]._id);
      }
    }
  };

  // componentDidMount() {
  //   var albumdescription = this.props.location.pathname;
  //   var description = albumdescription.toLowerCase();
  //   if (description.includes("album")) {
  //     description = description.replace("/album", "");
  //   }
  //   var breadcrumbDescription = description.replace("/", "");
  //   // breadcrumbName = breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
  //   breadcrumbDescription = breadcrumbDescription.toUpperCase();
  //   this.GetContent(breadcrumbDescription);
  //   this.setState({

  //     breadcrumbName: breadcrumbDescription,
  //     urlName: breadcrumbDescription,
  //   });
  // }
  // GetContent(albumdescription) {
  //   const { ListRequestModel } = this.state;
  //   ListRequestModel.TableName = "album";
  //   ListRequestModel.searchString = albumdescription;

  //   CallService(
  //     entries.GetAll,
  //     MethodType.POST,
  //     false,
  //     ListRequestModel,
  //     "",
  //     this.GetContentResponse
  //   );
  // }

  GetImages(albumid) {
    const { ListRequestImageModel } = this.state;
    ListRequestImageModel.TableName = "images";
    ListRequestImageModel.album = albumid;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestImageModel,
      "",
      this.GetImagesResponse
    );
  }

  GetImagesResponse = (data) => {
    if (data.result) {
      this.setState({
        Images: data.result,
      });
    }
  };
  GetVideos(albumid) {
    const { ListRequestVideoModel } = this.state;
    ListRequestVideoModel.TableName = "videos";
    ListRequestVideoModel.album = albumid;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestVideoModel,
      "",
      this.GetVideosResponse
    );
  }

  GetVideosResponse = (data) => {
    if (data.result) {
      this.setState({
        Videos: data.result,
      });
    }
  };
  render() {
    const { photoIndex, isOpenImage, album } = this.state;
    return (
      <div>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <div className="main epc">
          <Header imageData={"/img/logo-white.png"} />
          <Breadcrumb title={album && album.name + " "} subtitle={album && album.description} bg={JSON.parse(sessionStorage.getItem("menu")) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0] && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg ? JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg : bg} />
          <div className="position-relative">
            <div className="shape overflow-hidden text-white">
              <svg
                viewBox="0 0 2880 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <section className="project-details-area ptb-80">
            <div className="container">
              <div className="row">
                {this.state.Images &&
                  this.state.Images.map((img, i) => {
                    return (
                      <div className="col-lg-4 col-md-4 pointer">
                        <div
                          className="project-details-image"
                          onClick={() =>
                            this.setState({ isOpenImage: true, photoIndex: i })
                          }
                        >
                          <img
                            src={img.image}
                            style={{height:'400px',width:'400px'}}
                            alt="work"
                          />
                          <div>{img.description}</div>
                          <FeatherIcon icon="camera" className="fea icon-sm" />
                        </div>
                      </div>
                    );
                  })}
                {this.state.Videos &&
                  this.state.Videos.map((vid, i) => {
                    return (
                      <div className="col-lg-4 col-md-4 pointer">
                        <div
                          className="project-details-image"
                          onClick={() =>
                            this.setState({
                              isOpen: true,
                              videoId: vid.videoid,
                            })
                          }
                        >
                          <img
                            src={vid.thumbnail}
                            alt="work"
                          />
                          <div>{vid.description}</div>
                          <FeatherIcon icon="video" className="fea icon-sm" />
                        </div>
                      </div>
                    );
                  })}
                <ModalVideo
                  channel="youtube"
                  isOpen={this.state.isOpen}
                  videoId={this.state.videoId}
                  onClose={() => this.setState({ isOpen: false })}
                />

                {isOpenImage && (
                  <Lightbox
                    mainSrc={this.state.Images[photoIndex].image}
                    nextSrc={
                      this.state.Images[
                        (photoIndex + 1) % this.state.Images.length
                      ].image
                    }
                    prevSrc={
                      this.state.Images[
                        (photoIndex + this.state.Images.length - 1) %
                        this.state.Images.length
                      ].image
                    }
                    onCloseRequest={() => this.setState({ isOpenImage: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + this.state.Images.length - 1) %
                          this.state.Images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % this.state.Images.length,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </section>
        </div>
        <FooterSection />{" "}
      </div>
    );
  }
}
