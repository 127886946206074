import React from "react";
import Header from "../../../src/components/HeaderSection/Header";
import FooterSection from "../../../src/components/FooterSection/Footer";
import Breadcrumb from "../../components/Blogs/Breadcrumb";
import { ReactTypeformEmbed } from "react-typeform-embed";
class SchemeForm extends React.Component {
  state = {
    contents: [],
    applyUrlName: "",
    loading: true,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    display: false,
  };
  componentDidMount() {
    this.setState({});
  }
  render() {
    return (
      <div>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <div className="main scheme">
          <Header imageData={"/img/logo-white.png"} />
          <Breadcrumb title={" Scheme Form"} />
          <section id="service" className="contact-area ptb_100">
            <div className="container">
              <div className="row" style={{ height: "500px" }}>
                <ReactTypeformEmbed url="https://demo.typeform.com/to/njdbt5" />
                ;
              </div>
            </div>
          </section>
        </div>
        <FooterSection />{" "}
      </div>
    );
  }
}

export default SchemeForm;
