import React, { Component } from "react";

import Header from "../components/HeaderSection/Header";
//import ContactSection from "../components/ContactSection/Contact";
import FooterSection from "../components/FooterSection/Footer";
import HelpCenterOverview from "../components/Helpdesk/Helpdesk";
import { Form, Button, FormGroup } from "reactstrap";
class Helpdesk extends Component {
  render() {
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main helpdesk ">
          <Header imageData={"/img/logo-white.png"} />
          <section className="section  breadcrumb-area bg-overlay d-flex align-items-center">
            <div className="container">
              <div className="justify-content-center row">
                <div className="text-center col-lg-12">
                  <h4 className="title text-white">
                    {" "}
                    Hello! <br /> How can we help you?{" "}
                  </h4>

                  <div className="subcribe-form mt-4 pt-2">
                    <Form>
                      <FormGroup className="mb-0">
                        <input
                          type="text"
                          id="help"
                          name="name"
                          className="border bg-white rounded-pill shadow"
                          required
                          placeholder="Search your questions or topic..."
                        />
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-pills"
                        >
                          Search
                        </Button>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="shape-bottom">
              <svg
                viewBox="0 0 1920 310"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                className="svg replaced-svg"
              >
                <title></title>
                <desc></desc>
                <defs />
                <g
                  id="sApp-Landing-Page"
                  stroke="none"
                  strokeWidth={1}
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="sApp-v1.0"
                    transform="translate(0.000000, -554.000000)"
                    fill="#FFFFFF"
                  >
                    <path
                      d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                      id="sApp-v1.0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </section>
          <section id="service" className="contact-area">
            <div className="container">
              <div className="row">
                <HelpCenterOverview />
              </div>
            </div>
          </section>
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default Helpdesk;
