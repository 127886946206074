import React, { Component } from "react";
import Header from "../components/HeaderSection/Header";
import HeroSection from "../components/HeroSection/HeroOne";
//import ContactSection from "../components/ContactSection/Contact";
import FooterSection from "../components/FooterSection/Footer";
import Feedback from "../components/Feedback/Feedback";
import Newsletter from "../components/NewsLetterSection/Newsletter";
import Cta from "../components/CtaSection/Cta";
import Associates from "../pages/Associates/Associates";
import SWPsection from "../pages/swp/SWPSection";
import Banner from "../pages/Home/Banner";
import StateSector from "../pages/Home/StateSector";
import { Col, Row } from "reactstrap";
import SectorProfile from "../pages/Home/SectorProfile";
import Queries from "../pages/Queries/question";
import HomeEvents from "../components/Events/HomeEvents";

// import Sectors from "../pages/Sectors/list";

class ThemeOne extends Component {
  state = {
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 10,
    },
  };
  render() {
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          <Header imageData={"/img/logo-white.png"} />
          <section className="">
            <Row style={{ marginLeft: 0, marginRight: 0 }}>
              <Col lg="12" className="banner-align">
                <Banner />
              </Col>
              {/* <Updates /> */}
              {/* <Events /> */}
            </Row>
          </section>
          <Row className="m-0">
            <HeroSection />
            <section className="home-herosection-area">
              <Queries />
            </section>
            <StateSector />
          </Row>
          <SectorProfile sub={true} history={this.props.history} />

          {/* <SchemeSection /> */}

          <Cta />
          <SWPsection />

          {/* <Counter />
                    <FeatureSection />
                    <ServiceSection />
                    <DiscoverSection />
                    <Work />
                    <ScreenshotSection />
                    <ReviewSection />
                    <PricingSection />
          <FaqSection />*/}
          {/* <Team /> */}

          {/* <EPCsection /> */}
          {/* <FeaturesContent /> */}
          {/* <BlogCard /> */}
          <section className="home-herosection-event">
            <HomeEvents history={this.props.history} />
          </section>
          <Feedback />


          {/* <Gallery /> */}
          {/* <Download />
                    <Subscribe />
                    <ContactSection /> */}

          <Associates />
          <Newsletter />
          <FooterSection />
        </div>
      </div >
    );
  }
}

export default ThemeOne;
