import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { Col, Container, Row } from "reactstrap";
// import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
import KeyFeaturesBox from "../../components/Policies/PolicyFeaturesBox";
class Tenders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100000,
      },
      isOpen: false,
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      groups: [],
      contents: [],
      isVisblecontent: "",
    };
  };
  componentDidMount() {
    this.GetGroup();
    this.GetContent();
  }
  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "tendergroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
        isVisblecontent: data.result[0]
      });
  };

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "tender";
    ListRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result.length > 0 ? data.result : [],
      });
    }
  };


  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };

  render() {

    const { isVisblecontent, contents } = this.state;

    return (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <div className="main ">
          <section className="m-5">
            <Container>
              <section id="blog" className="">
                <div className="container">
                  <div className="row">
                    {this.state.groups.length > 0 &&
                      <div className="col-12 col-lg-3">
                        <div>
                          <aside className="sidebar">
                            <div className="single-widget">
                              {/* Category Widget */}
                              <div
                                className="accordions widget catagory-widget"
                                id="cat-accordion"
                              >
                                <div className="single-accordion blog-accordion">
                                  <h5>
                                    <a
                                      role="button"
                                      className="collapse show text-uppercase d-block p-2"
                                    // data-toggle="collapse"
                                    >
                                      Tenders
                                  </a>
                                  </h5>
                                  {/* Category Widget Content */}
                                  <div
                                    id="accordion1"
                                    className="accordion-content widget-content collapse show"
                                    data-parent="#cat-accordion"
                                  >
                                    {/* Category Widget Items */}
                                    <ul className="widget-items sidebar">
                                      {this.state.groups && this.state.groups.map((item, idx) => {
                                        return (
                                          !item.parentgroup &&
                                          <li key={`wdo_${idx}`} className={(item._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                            <a
                                              role="button"
                                              className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                              // data-toggle="collapse"
                                              // href="/Export-Schemes"
                                              onClick={() =>
                                                this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) && this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).length === 0 && this.toggleSchemesTitle(item)
                                              }
                                            >
                                              <span>{item.name}</span>
                                              {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) &&

                                                <ul className="widget-items sidebar">
                                                  {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).map((itemsub, idx) => {
                                                    return (
                                                      <li key={`wdo_${idx}`} className={(itemsub._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                                        <a
                                                          role="button"
                                                          className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                                          style={{ zIndex: 10 }}
                                                          onClick={() =>
                                                            this.toggleSchemesTitle(itemsub)
                                                          }
                                                        >
                                                          <span>{itemsub.name}</span>
                                                        </a>
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              }
                                            </a>

                                          </li>
                                        );
                                      })}
                                    </ul>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </aside>
                        </div>
                      </div>
                    }
                    {contents.length === 0 ?
                      <div className="col-12" >
                        <Row className="" style={{ minHeight: "300px" }}>
                          <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="section-title ml-lg-4">
                              <img src={"/img/tender_2.png"} alt="source" height='170px' width='170px' />
                              <p className=" my-3 mt-sm-4 mb-sm-5">
                                <h4>    Details regarding opening of tenders will be posted here. Kindly mark this page for future reference.
                        </h4>
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      :
                      <div className="col-12 col-lg-9">
                        <h3 >
                          {isVisblecontent && isVisblecontent.name}
                        </h3>
                        <Row>
                          <KeyFeaturesBox keyFeatures={contents} policyType={isVisblecontent} iconImage={true} />
                        </Row>
                      </div>
                    }
                  </div>
                </div>

              </section>
            </Container>
          </section>
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default Tenders;
