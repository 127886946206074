import React, { Component, Fragment } from "react";
import Header from "../../components/HeaderSection/Header";
import Breadcrumb from "../../components/Blogs/Breadcrumb";
import bg from "../../img/insurance/light.jpg";
export default class HeaderBreadcrumb extends Component {
    render() {
        var menucontent = JSON.parse(sessionStorage.getItem("menucontent"));
        var menu = JSON.parse(sessionStorage.getItem("menu"));
        var currentContent = {};
        var currentMenu = {};
        var menuName = "";
        if (menu && menucontent) {
            menuName = window.location.pathname.split("/")[1] && window.location.pathname.split("/")[1];
            currentMenu = menu.find(m => m.friendlyurl.includes(menuName));
            currentContent = menucontent.find(m => (m.menu && m.menu._id) === (currentMenu && currentMenu._id));
        }
        return (
            <Fragment>
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                <div className="main epc">
                    <Header imageData={"/img/logo-white.png"} />
                    <Breadcrumb subtitle={<div dangerouslySetInnerHTML={{
                        __html: currentContent && currentContent.content,
                    }}
                    />}
                        breadcrumb={this.props.breadcrumb}
                        SelectedPath={this.props.SelectedPath}
                        title={currentContent && currentContent.title ? currentContent.title : currentMenu && currentMenu.name ? currentMenu.name : menuName} bg={currentContent && currentContent.bg ? currentContent.bg : currentMenu && currentMenu.bg ? currentMenu.bg : bg} />
                    <div className="position-relative">
                        <div className="shape overflow-hidden text-white">
                            <svg
                                viewBox="0 0 2880 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                    fill="currentColor"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </Fragment>)
    }
}