// import React, { Fragment } from "react";
import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";
import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
class ExportDesks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "exportdesk";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
        loading: false,
      });
    }
  };
  render() {
    const { content } = this.state;




    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            <Container>
            {/*<h3>Export Promotion Desks</h3>*/}
              <Row className="align-items-center">
                <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div className="section-title ml-lg-4">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.content,
                        }}
                      ></div>
                    </p>
                  </div>
                </Col>
              </Row>
              {/* {content.contact &&
                content.contact.length > 0 &&
                <div className="ml-3 mt-2">
                  <h5>District export facilitator contact details</h5>
                  <Row className="ml-3" >
                    {content.contact &&
                      content.contact.map((obj) => {
                        return (
                          <div className="col-lg-4 col-12 mt-2" >
                            <div className="contact-us border border-gray mb-4 contact-box  contact-box-transition p-4" style={{ minHeight: "100px" }}>
                              {obj.designation &&
                                <h4 style={{ minHeight: "10px" }}>{obj.designation}</h4>}
                              <ul>

                                {obj.name && <li key={`ci_${1}`} className=" row">
                                  <div className="social-icon mr-3 col-1">
                                    <i class="fas fa-user" />
                                  </div>

                                  <span className="media-body align-self-center col-10">
                                    {obj.name}
                                  </span>
                                </li>}


                                {obj.address && <li key={`ci_${1}`} className=" row">
                                  <div className="social-icon mr-3 col-1">
                                    <i className={"fas fa-paper-plane"} />
                                  </div>

                                  <span className="media-body align-self-center col-10">
                                    {obj.address}
                                  </span>
                                </li>
                                }
                                {obj.phonenumber &&

                                  <li key={`ci_${3}`} className=" row">
                                    <div className="social-icon mr-3 col-1">
                                      <i class="fas fa-phone-square-alt"></i>
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {obj.phonenumber}
                                    </span>
                                  </li>
                                }
                                {obj.mobilenumber &&
                                  <li key={`ci_${2}`} className=" row">
                                    <div className="social-icon mr-3 col-1">
                                      <i class="fas fa-mobile-alt" />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {obj.mobilenumber}
                                    </span>
                                  </li>
                                }
                                {obj.email &&
                                  <li key={`ci_${2}`} className=" row">
                                    <div className="social-icon mr-3 col-1">
                                      <i class="fas fa-envelope" />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {obj.email}
                                    </span>
                                  </li>
                                }
                                {obj.website &&
                                  <li key={`ci_${2}`} className=" row">
                                    <div className="social-icon mr-3 col-1">
                                      <i class="fas fa-globe-americas" />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {obj.website}
                                    </span>
                                  </li>
                                }
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                  </Row>
                </div>
              } */}
            </Container>
          </section>
          <FooterSection />
        </div >
      );
  }
}

export default ExportDesks;
