import React from "react";
import {
  Container,
  Col,
  Row,
} from "reactstrap";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-image-lightbox/style.css";
export default class SectorOL extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
      isOpenImage: false,
      contents: [],
      loading: true,
      ListRequestModelTNMap: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
    };
  }

  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "comingsoon";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
        loading: false,
      });
    }
  };
  // componentDidMount() {
  //   this.GetContent();
  // }
  // GetContent() {
  //   const { ListRequestModelTNMap } = this.state;
  //   ListRequestModelTNMap.TableName = "tnmap";
  //   CallService(
  //     entries.GetAll,
  //     MethodType.POST,
  //     false,
  //     ListRequestModelTNMap,
  //     "",
  //     this.GetContentResponse
  //   );
  // }
  // GetContentResponse = (data) => {
  //   if (data.result) {
  //     var viewbox = {
  //       label: "Map of Tamil Nadu",
  //       viewBox: "0 0 1591.1 1975.3",
  //       locations: data.result,
  //     };
  //     this.setState({
  //       tn: viewbox,
  //       districts: data.result,
  //     });
  //   }
  // };
  // handleOnChange = (selectedNode) => {
  //   setTimeout(() => {
  //     selectedNode &&
  //       selectedNode.map((sn) => {
  //         document.getElementById(sn.attributes.id.value) &&
  //           document
  //             .getElementById(sn.attributes.id.value)
  //             .setAttribute("aria-checked", false);
  //       });
  //   }, 1000);
  // };
  // handleLocationMouseOver = (event) => {
  //   const pointedLocation = event.target.attributes.name.value;
  //   const focusedId = event.target.attributes.id.value;
  //   this.setState({ focusedId: focusedId, pointedLocation: pointedLocation });
  // };

  // handleLocationMouseOut = () => {
  //   this.setState({ focusedId: null, pointedLocation: null });
  // };
  // handleLocationFocus = (event) => {
  //   const focusedLocation = event.target.attributes.name.value;
  //   const focusedId = event.target.attributes.id.value;
  //   this.setState({ focusedId: focusedId, focusedLocation: focusedLocation });
  // };

  // handleLocationBlur = () => {
  //   this.setState({ focusedId: null, focusedLocation: null });
  // };
  render() {
    const { content } = this.state;
    // if (this.state.districts)
    //   this.state.districts.map((d) => {
    //     document.getElementById(d.id) &&
    //       document.getElementById(d.id).setAttribute("aria-checked", false);
    //   });
    // setTimeout(() => {
    //   this.props.selectedDistricts &&
    //     this.props.selectedDistricts.map(
    //       (d) =>
    //         document.getElementById(d.id) &&
    //         document.getElementById(d.id).setAttribute("aria-checked", true)
    //     );
    // }, 1000);
    return (
      // <Container className="mt-60">
      //   {this.state.tn &&
      //     this.state.tn.locations &&
      //     this.state.tn.locations.map((l) => (
      //       <UncontrolledPopover trigger="hover" placement="top" target={l.id}>
      //         <PopoverHeader>{l.name}</PopoverHeader>
      //       </UncontrolledPopover>
      //     ))}
      //   {this.state.tn && (
      //     <Fragment>
      //       <div class="row">
      //         <div class="col-5">
      //           <div className={"sp-map"}>
      //             <article className={"map-wh"}>
      //               <div>
      //                 <CheckboxSVGMap
      //                   map={this.state.tn}
      //                   onChange={this.handleOnChange}
      //                 />
      //               </div>
      //             </article>
      //           </div>
      //         </div>
      //         <div class="col-7">
      //           {this.props.selectedSector && (
      //             <div className="main epc overflow-wb">
      //               <h1 style={{ textAlign: "center" }}>
      //                 {this.props.selectedSector.name}
      //               </h1>
      //               {this.props.selectedDistricts &&
      //                 this.props.selectedDistricts.length > 0 && (
      //                   <Col>
      //                     <strong>Districs</strong>
      //                     <Row>
      //                       {this.props.selectedDistricts.map((l) => (
      //                         <Col lg={12} className="pt-2">
      //                           <Media className="key-feature align-items-center p-3 rounded shadow bg-white">
      //                             <Media body>
      //                               <h5>{l.name}</h5>
      //                             </Media>
      //                           </Media>
      //                         </Col>
      //                       ))}
      //                     </Row>
      //                   </Col>
      //                 )}
      //               {this.props.details &&
      //                 this.props.details.length > 0 && (
      //                   <Col>
      //                     {/* <strong>Districs</strong> */}
      //                     <Row>
      //                       {this.props.details.map((l) => (
      //                         <Col lg={12} className="pt-2">
      //                           <Media className="key-feature align-items-center p-3 rounded shadow bg-white">
      //                             <Media body>
      //                               <div
      //                                 dangerouslySetInnerHTML={{
      //                                   __html: l.content,
      //                                 }}
      //                               ></div>
      //                             </Media>
      //                           </Media>
      //                         </Col>
      //                       ))}
      //                     </Row>
      //                   </Col>
      //                 )}
      //             </div>
      //           )}
      //         </div>
      //       </div>
      //     </Fragment>
      //   )}
      // </Container>
      <Container className="mb-5">
        <Row className="align-items-center" style={{ minHeight: "300px" }}>
          <Col lg={12} md={12} >
            <div className="section-title">
              <img src={"/img/sector_2.png"} alt="source" height='170px' width='170px' />
              {content &&
                <p className="mb-5">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.content,
                    }}
                  ></div>
                </p>}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
