import React, { Component, Fragment } from "react";
class PointsSidebar extends Component {
  state = {
    data: {},
    // Research: [
    //   { text: "Announcements" },
    //   { text: "Newsletter" },
    //   { text: "Events Reporting" },
    //   { text: "Trade leads" },
    //   { text: "Buyer requests" },
    //   { text: "Industry updates" },
    //   { text: "Policy changes" },
    // ],
    // AddOnServices: [
    //   { text: "Due deligence" },
    //   { text: "Validation" },
    //   { text: "Authenthication" },
    // ],
    widgetData_1: [],
    widgetData_2: [],
    widgetData_3: [],
    Title: "",
  };
  // componentDidMount() {
  //   console.log(window.location.hash);
  //   this.GetUrl();
  //   // axios.get(`${BASE_URL}`)
  //   //     .then(res => {
  //   //         this.setState({
  //   //             data: res.data,
  //   //             widgetData_1: res.data.widgetData_1,
  //   //             widgetData_2: res.data.widgetData_2,
  //   //             widgetData_3: res.data.widgetData_3
  //   //         })
  //   //         // console.log(this.state.data)
  //   //     })
  //   // .catch(err => console.log(err))
  // }

  // componentWillReceiveProps() {
  //   console.log("safd");
  //   this.GetUrl();
  // }
  // GetUrl() {
  //   if (
  //     window.location.hash === "/ServicePromotion/Announcements" ||
  //     window.location.hash === "/ServicePromotion/Newsletter" ||
  //     window.location.hash === "/ServicePromotion/EventsReporting" ||
  //     window.location.hash === "/ServicePromotion/TradeLeads" ||
  //     window.location.hash === "/ServicePromotion/BuyerRequests" ||
  //     window.location.hash === "/ServicePromotion/IndustryUpdates" ||
  //     window.location.hash === "/ServicePromotion/PolicyChanges"
  //   ) {
  //     this.setState({
  //       Title: "Research and Publication",
  //       widgetData_1: this.state.Research,
  //     });
  //   } else if (
  //     window.location.hash === "/ServicePromotion/DueDeligence" ||
  //     window.location.hash === "/ServicePromotion/Validation" ||
  //     window.location.hash === "/ServicePromotion/Authenthication"
  //   ) {
  //     this.setState({
  //       Title: "Add on Services",
  //       widgetData_1: this.state.AddOnServices,
  //     });
  //   } else if (window.location.hash === "/ServicePromotion/Trade") {
  //     this.setState({
  //       Title: "Trade Promotion",
  //       widgetData_1: [],
  //     });
  //   } else if (window.location.hash === "/ServicePromotion/Investment") {
  //     this.setState({
  //       Title: "Investment Promotion",
  //       widgetData_1: [],
  //     });
  //   } else if (
  //     window.location.hash === "/ServicePromotion/SinglePointContact"
  //   ) {
  //     this.setState({
  //       Title: "Single Point Contact",
  //       widgetData_1: [],
  //     });
  //   }
  // }
  render() {
    var content = this.props.content;
    var childcontents = this.props.childcontents;
    return (
      <div>
        <aside className="sidebar">
          <div className="single-widget">
            {/* Category Widget */}
            <div
              className="accordions widget catagory-widget"
              id="cat-accordion"
            >
              {content && (
                <div className="single-accordion blog-accordion">
                  {content.parentservice ?
                    <Fragment>
                      <h5>
                        <a
                          role="button"
                          className="collapse show text-uppercase d-block p-3"
                          // data-toggle="collapse"
                          href={`${content.parentservice.friendlyurl}`}
                        >
                          {content.parentservice.name}
                        </a>
                      </h5>
                      <ul className="widget-items">
                        <li key={`wdo_content_0`}>
                          <a
                            href={`${content.friendlyurl}`}
                            onMouseOver={{ color: "black" }}
                            className="d-flex p-3"
                          >
                            <span>{content.name}</span>
                          </a>
                        </li>
                      </ul>
                    </Fragment>
                    :
                    <h5>
                      <a
                        role="button"
                        className="collapse show text-uppercase d-block p-3"
                        data-toggle="collapse"
                        href="#accordion1"
                      >
                        {content.name}
                      </a>
                    </h5>}
                  {/* Category Widget Content */}
                  <div
                    id="accordion1"
                    className="accordion-content widget-content collapse show"
                    data-parent="#cat-accordion"
                  >
                    {/* Category Widget Items */}
                    <ul className="widget-items">
                      {childcontents &&
                        childcontents.map((item, idx) => {
                          return (
                            <li key={`wdo_${idx}`}>
                              <a
                                href={`${item.friendlyurl}`}
                                onMouseOver={{ color: "black" }}
                                className="d-flex p-3"
                              >
                                <span>{item.name}</span>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </aside>
      </div>
    );
  }
}

export default PointsSidebar;
