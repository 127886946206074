import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeOne from "../themes/theme-one";
// import ThemeTwo from "../themes/theme-two";
// import ThemeThree from "../themes/theme-three";
// import ThemeFour from "../themes/theme-four";
// import ThemeFive from "../themes/theme-five";
// import ThemeSix from "../themes/theme-six";
// import BlogTwoColumn from "../components/Blogs/BlogTwoColumn";
// import BlogThreeColumn from "../components/Blogs/BlogThreeColumn";
// import BlogLeftSidebar from "../components/Blogs/BlogLeftSidebar";
// import BlogRightSidebar from "../components/Blogs/BlogRightSidebar";
// import BlogDetailsLeftSidebar from "../components/Blogs/BlogDetailsLeftSidebar";
// import BlogDetailsRightSidebar from "../components/Blogs/BlogDetailsRightSidebar";
// import Reviews from "../components/ReviewSection/Reviews";
// import Pricing from "../components/PricingSection/Pricing";
// import DownloadPage from "../components/DownloadSection/DownloadPage";
// import SubscribePage from "../components/SubscribeSection/SubscribePage";
import ThankYou from "../components/InnerSection/ThankYou";
import ComingSoon from "../components/InnerSection/ComingSoon";
import Login from "../components/Accounts/Login";
import Signup from "../components/Accounts/Signup";
import Forgot from "../components/Accounts/Forgot";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
// import ContactPage from "../components/ContactSection/ContactPage";
import Services from "../pages/Services";
import Helpdesk from "../pages/Helpdesk";
import Events from "../pages/Events/Events";
import SinglePastEvent from "../pages/Events/SinglePastEvent";



import ContactPage from "../pages/ContactSection/ContactPage";
import YellowPages from "../pages/YellowPage/YellowPages";
import StateProfile from "../pages/profile/StateProfile";
import YellowPageResult from "../pages/YellowPage/YellowPages-Result";
import Scheme from "../pages/scheme/Scheme";
import SchemeForm from "../pages/scheme/SchemeForm";
import Epc from "../pages/epc/Epc";
import Promotion from "../pages/ServicePromotion/Points";

import FooterSection from "../components/FooterSection/Footer";
import EventApplication from "../pages/Events/EventApplication";
import Policies from "../pages/Policies/Policies";
import Album from "../pages/Gallery/Album";
import Images from "../pages/Gallery/Images";
import YellowPageForm from "../pages/YellowPage/form";
import AboutUS from "../pages/About/about";
import District from "../pages/District-Profile/district";
import howtoexport from "../pages/Export/howtoexport";
import exportexcellence from "../pages/Export/exportexcellence";
import SectorProfile from "../pages/sector-profile/SectorProfile";
import ExportResources from "../pages/Export/ExportResources";
import ExportSchemes from "../pages/Export/ExportSchemes";
import GoverningBody from "../pages/Team/GoverningBody";
import ExportDesks from "../pages/Export/ExportDesks";
import SingleWindow from "../pages/swp/SingleWindow";
import careers from "../pages/careers/Careers";
import Tenders from "../pages/tenders/Tenders";
import KnowledgeCenter from "../pages/knowledge/KnowledgeCenter";
import Udhyam from "../pages/udhyam/udhyam";
import FinancialAssistance from "../pages/Financial-Assistance/FinancialAssistance";
import SponsorshipPolicy from "../pages/others/SponsorshipPolicy";
import LandingPage from "../pages/Home/LandingPage";
import PrivacyPolicy from "../pages/others/privacypolicy";
import MSFEC from "../pages/Msfec";
import testinglab from "../pages/Testinglab/testinglab";
import PaymentRedirect from "../components/Events/PaymentRedirect";
import BlogDetails from "../pages/knowledge/BlogDetails";


class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router basename={process.env.REACT_APP_ROUTER_BASE || ""}>
          <Switch>
            <Route exact path="/" component={ThemeOne} />
            <Route path="/thank-you" component={ThankYou} />
            <Route path="/coming-soon" component={ComingSoon} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/faq" component={Faq} />
            <Route path="/error-page" component={ErrorPage} />
            <Route path="/contact-page" component={ContactPage} />
            <Route path="/services" component={Services} />
            {/* <Route path="/events" component={Events} /> */}
            <Route path="/upcoming-events" component={Events} />
            <Route path="/past-events" component={Events} />
            <Route path="/single-events" component={SinglePastEvent} />
            <Route path="/event" component={SinglePastEvent} />

            <Route path="/payment" component={PaymentRedirect} />

            {/* <Route path="/upcoming-events" component={Events} /> */}

            <Route path="/helpdesk" component={Helpdesk} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/yellowpages" component={YellowPages} />
            <Route path="/gallery" component={Album} />
            <Route path="/Album" component={Images} />
            <Route path="/form" component={YellowPageForm} />
            <Route path="/about" component={AboutUS} />
            <Route path="/district-profile" component={District} />
            <Route path="/yellowpages-result" component={YellowPageResult} />
            <Route path="/scheme" component={Scheme} />
            <Route path="/scheme-form" component={SchemeForm} />
            <Route path="/Epc" component={Epc} />
            <Route path="/ServicePromotion" component={Promotion} />
            <Route path="/footersection" component={FooterSection} />
            <Route path="/state-profile" component={StateProfile} />
            <Route path="/sector-profile" component={SectorProfile} />
            <Route path="/yellowpages-result" component={YellowPageResult} />
            <Route path="/event_application" component={EventApplication} />
            <Route path="/Policies" component={Policies} />
            <Route path="/howtoexport" component={howtoexport} />
            <Route path="/export-resources" component={ExportResources} />
            <Route path="/exportexcellence" component={exportexcellence} />
            <Route path="/export-Schemes" component={ExportSchemes} />
            <Route path="/export-desks" component={ExportDesks} />
            <Route path="/governing-body" component={GoverningBody} />
            <Route path="/single-window-portal" component={SingleWindow} />
            <Route path="/careers" component={careers} />
            <Route path="/tenders" component={Tenders} />
            <Route path="/knowledge" component={KnowledgeCenter} />
            <Route path="/udhyam" component={Udhyam} />
            <Route path="/msefc" component={MSFEC} />
            <Route path="/testinglab" component={testinglab} />


            <Route
              path="/Financial-Assistance"
              component={FinancialAssistance}
            />
            <Route path="/blog" component={BlogDetails} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/Sponsorship-Policy" component={SponsorshipPolicy} />
            <Route path="/msme" component={LandingPage} />
            <Route path="/mtipb" component={LandingPage} />
            <Route path="/exports" component={LandingPage} />
            <Route path="/events" component={LandingPage} />
            <Route path="/business" component={LandingPage} />
            <Route path="/others" component={LandingPage} />

            <Route path="/Forgot" component={Forgot} />
            {/* <Route path="/knowledge" component={LandingPage} /> */}
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;
