// import React, { Fragment } from "react";
import React, { Component } from "react";

import { Container, Row } from "reactstrap";
import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
// import TextTruncate from "../../common/TextTruncate";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentWillReceiveProps() {
    setTimeout(() => {
      this.setState({
        loading: true,
      }, () =>
        this.componentDidMount()
      )
    }, 1200);
  }
  componentDidMount() {
    var landingName = this.props.location ? this.props.location.pathname ? this.props.location.pathname.replace("/", "") : "" : "";
    this.setState({
      landingName: landingName,
    })
    this.GetMenu(landingName);
  }

  GetMenu() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        TableName: "menu",
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      "",
      this.GetMenuResponse
    );
  }

  GetMenuResponse = (data) => {
    if (data.result) {
      var parentmenu = data.result.find(m => m.friendlyurl === this.props.location.pathname);
      this.setState({
        menus: data.result,
        parentmenu: parentmenu,
      }, () => this.GetContent());
    }
  };
  GetContent(landingName) {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "menucontent";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      var parentmenuContent = data.result.find(r => (r.menu && r.menu._id) === (this.state.parentmenu && this.state.parentmenu._id));
      var menuContents = [];
      this.state.menus.map(m => {
        if ((m.parentmenu && m.parentmenu._id) === (this.state.parentmenu && this.state.parentmenu._id)) {
          data.result.filter(r => (r.menu && r.menu._id) === m._id).map(me => {
            me.sortorder = m.sortorder;
            return menuContents.push(me);
          })
        }
        return "";
      })
      menuContents = menuContents.sort((a, b) => a.sortorder - b.sortorder)
      this.setState({
        menuContents: menuContents,
        parentmenuContent: parentmenuContent,
        loading: false,
      });
    }
  };
  render() {
    const { menuContents, menus } = this.state;
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white ">
          <HeaderBreadcrumb />
          <section className="m-5">
            {(
              <Container>
                {/* <Row className="align-items-center">
                    <Col lg={1} />
                    <Col lg={10} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                      <div className="section-title ml-lg-4">
                        <p>
                          {parentmenuContent ? <div
                            dangerouslySetInnerHTML={{
                              __html: parentmenuContent.content,
                            }}
                          ></div>
                            :
                            <h3 className="mt-4">Content yet to be updated</h3>
                          }
                        </p>
                      </div>
                    </Col>
                  </Row> */}
                <Row className="align-items-center mt-4  mb-5">
                  {menuContents && menuContents.map(menu =>
                    menuContents.length < 2 ?
                      <div className="mb-3 bg-white">
                        <div className="col-lg-1" />
                        <div className="col-lg-10 col-12 res-margin">
                          {menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) &&
                            <a
                              href={menus.find(m => m._id === (menu.menu && menu.menu._id)).friendlyurl}
                              rel="noopener noreferrer"
                            >
                              <div className="row key-feature border p-2">
                                <div className="col-3 res-margin ">
                                  <img className="landing-img" src={(menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) && menus.find(m => m._id === (menu.menu && menu.menu._id)).thumbnail) ? menus.find(m => m._id === (menu.menu && menu.menu._id)).thumbnail : (menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) && menus.find(m => m._id === (menu.menu && menu.menu._id)).bg) ? menus.find(m => m._id === (menu.menu && menu.menu._id)).bg : menu.bg} alt={menu.title} />
                                </div>
                                <div className="col-9">
                                  <h5 className="text-primary1 mb-2" >
                                    {menu.title}
                                  </h5>
                                  {/* <TextTruncate isHtml={true} value={menu.content} length={100} title={menu.title} />
                              <div
                                className="mt-4  mb-2 "
                              >
                                {menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) &&
                                  <a
                                    href={menus.find(m => m._id === (menu.menu && menu.menu._id)).friendlyurl}
                                    rel="noopener noreferrer"
                                  >
                                   <span className="align-bottom-div pointer" style={{ color: "blue", paddingLeft: "10px" }}>View more <i class="fas fa-arrow-right" /></span>
                                  </a>
                                }
                              </div> */}
                                </div>
                              </div>
                            </a>} </div>
                      </div>
                      :
                      <div className="col-lg-4 mb-3 bg-white col-12 res-margin mt-4">
                        {menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) &&
                          <a
                            href={menus.find(m => m._id === (menu.menu && menu.menu._id)).friendlyurl}
                            rel="noopener noreferrer"
                          >   <div className=" key-feature border p-3">
                              <div className="text-center " style={{ minHeight: "250px" }}>

                                <img className="landing-img" src={(menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) && menus.find(m => m._id === (menu.menu && menu.menu._id)).thumbnail) ? menus.find(m => m._id === (menu.menu && menu.menu._id)).thumbnail : (menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) && menus.find(m => m._id === (menu.menu && menu.menu._id)).bg) ? menus.find(m => m._id === (menu.menu && menu.menu._id)).bg : menu.bg} alt={menu.title} />
                              </div>
                              <h5 className="text-primary1 mb-2 mt-2" >
                                {menu.title}
                              </h5>
                              {/* <TextTruncate isHtml={true} value={menu.content} length={100} title={menu.title} />
                          <div
                            className="mt-4 mb-2"
                          >
                            {menus && menus.find(m => m._id === (menu.menu && menu.menu._id)) &&
                              <a
                                href={menus.find(m => m._id === (menu.menu && menu.menu._id)).friendlyurl}
                                rel="noopener noreferrer"
                              >
                                <span className="align-bottom-div pointer" style={{ color: "blue", paddingLeft: "10px" }}>View more <i class="fas fa-arrow-right" /></span>
                              </a>
                            }
                          </div> */}
                            </div>
                          </a>}
                      </div>
                  )}
                </Row>
              </Container>)
            }
          </section>
          <FooterSection />
        </div>
      );
  }
}

export default LandingPage;
