import React, { Component } from "react";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      navLinks: [],
      profileLinks: [
        {
          id: 1,
          title: "Welcome ",
          isOpenSubMenu: false,
          child: [
            { id: 1, title: "Events", link: "/upcoming-events" },
            // { id: 2, title: "Forgot Password", link: "/forgot-password" },
            { id: 2, title: "Sign Out", link: "/login" },
          ],
        },
      ],
      navLinksOld: [
        { id: 1, title: "Home", link: "/" },
        // {
        //   id: 2,
        //   title: "State Profile",
        //   link: "/state-profile",
        //   isOpenSubMenu: false,
        //   // child: [{ id: 1, title: "State Profile", link: "/state-profile" }],
        // },

        {
          id: 2,
          title: "FAME TN",
          isOpenSubMenu: false,
          child: [
            { id: 1, title: "About", link: "/about" },
            { id: 2, title: "Team", link: "/governing-body" },
            { id: 3, title: "Contact Us", link: "/contact-page" },
            { id: 4, title: "Gallery", link: "/gallery" },
          ],
        },

        {
          id: 3,
          title: "MSME",
          link: "/msme",
          isOpenNestedSubMenu: true,
          child: [
            { id: 1, title: "District Wise Profile", link: "/state-profile" },
            { id: 2, title: "Sector Wise Profile", link: "/sector-profile" },
            {
              id: 3,
              title: "Trade/Industries/Chambers",
              link: "/exportexcellence",
            },
          ],
        },
        {
          id: 4,
          title: "Exports",
          link: "/#",
          isOpenSubMenu: false,
          child: [
            { id: 1, title: "Export Promotion Schemes", link: "/Export-Schemes" },
            { id: 2, title: "Export Promotion Desks", link: "/export-desks" },
            { id: 3, title: "Export Resources", link: "/export-resources" },
            {
              id: 4,
              title: "Export Promotion Council",
              link: "/Epc",
            },
            {
              id: 5,
              title: "Financial Assistance For Exporters",
              link: "/Financial-Assistance",
            },
            /*{
              id: 4,
              title: "Trade/Industries/Chambers",
              link: "/exportexcellence",
            },*/
          ],
        },
        {
          id: 5,
          title: "Events",
          link: "/events",
          isOpenSubMenu: false,
          child: [
            { id: 1, title: "Upcoming Event", link: "/upcoming-events" },
            { id: 2, title: "Past Events", link: "/past-events" },
            {
              id: 3,
              title: "FaMe TN's Sponsorship Policy",
              link: "/Sponsorship-Policy",
            },
            {
              id: 4,
              title: "Apply Online",
              link: "/event_application",
            },

            /*{
              id: 4,
              title: "Trade/Industries/Chambers",
              link: "/exportexcellence",
            },*/
          ],
        },

        { id: 6, title: "Knowledge Centre", link: "/knowledge-centre" },
        {
          id: 7,
          title: "Business Facilitation",
          link: "/#",
          isOpenSubMenu: false,
          child: [
            { id: 1, title: "Udyam Registration", link: "/udhyam" },
            {
              id: 2,
              title: "Single Window Portal",
              link: "/single-window-portal",
            },
            { id: 3, title: "Schemes", link: "/schemes" },
            { id: 4, title: "Policies", link: "/Policies" },
          ],
        },
        {
          id: 8,
          title: "Others",
          link: "/#",
          isOpenSubMenu: false,
          child: [
            { id: 1, title: "Careers", link: "/careers" },
            { id: 2, title: "Tenders", link: "/tenders" },
            // { id: 3, title: "Knowledge Centre", link: "/knowledge-centre" },
          ],
        },
        { id: 6, title: "Sign In", link: "/login", isSignIn: true },
      ],
    };
    this.toggleLine = this.toggleLine.bind(this);
    this.openBlock.bind(this);
    this.openNestedBlock.bind(this);
  }

  toggleLine() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  componentDidMount() {
    // for (var i = 0; i < items.length; ++i) {
    //     if (this.props.location.pathname === items[i].pathname) {
    //         matchingMenuItem = items[i];
    //         break;
    //     }
    // }
    // if (matchingMenuItem) {
    //     this.activateParentDropdown(matchingMenuItem);
    // }
    this.GetContent();
    this.GetMenuContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "menu";
    ListRequestModel.sortCondition = { "sortorder": 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      sessionStorage.setItem("menu", JSON.stringify(data.result))
      var menu = data.result.filter(r => !r.parentmenu) && data.result.filter(r => !r.parentmenu).map(r => {
        return ({
          _id: r._id,
          id: r.sortorder,
          title: r.name,
          link: r.friendlyurl,
          child: data.result.filter(c => c.parentmenu && c.parentmenu._id === r._id) && data.result.filter(c => c.parentmenu && c.parentmenu._id === r._id).map(c => {
            return ({
              _id: c._id,
              id: c.sortorder,
              title: c.name,
              link: c.friendlyurl,
              isOpenSubMenu: false,
            })
          })
        })
      })
      this.setState({
        navLinks: menu,
        loading: false,
      });
    }
  };

  GetMenuContent() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { "sortorder": 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "menucontent"
      },
      "",
      this.GetMenuContentResponse
    );
  }

  GetMenuContentResponse = (data) => {
    if (data.result) {
      sessionStorage.setItem("menucontent", JSON.stringify(data.result))
    }
  };

  activateParentDropdown = (item) => {
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent1 = parent.parentElement;
      parent1.classList.add("active"); // li
      if (parent1) {
        const parent2 = parent1.parentElement;
        parent2.classList.add("active"); // li
        if (parent2) {
          const parent3 = parent2.parentElement;
          parent3.classList.add("active"); // li
          if (parent3) {
            const parent4 = parent3.parentElement;
            parent4.classList.add("active"); // li
          }
        }
      }
    }
  };

  openBlock = (level2_id) => {
    var tmpLinks = this.state.navLinks;
    tmpLinks.map((tmpLink) =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? (tmpLink.isOpenSubMenu = !tmpLink.isOpenSubMenu)
        : false
    );
    this.setState({ navLinks: tmpLinks });
  };

  openNestedBlock = (level2_id, level3_id) => {
    var tmpLinks = this.state.navLinks;
    tmpLinks.map((tmpLink) =>
      //Match level 2 id
      tmpLink.id === level2_id
        ? tmpLink.child.map((tmpchild) =>
          //if level1 id is matched then match level 3 id
          tmpchild.id === level3_id
            ? //if id is matched then update status(level 3 sub menu will be open)
            (tmpchild.isOpenNestedSubMenu = !tmpchild.isOpenNestedSubMenu)
            : (tmpchild.isOpenNestedSubMenu = false)
        )
        : false
    );
    this.setState({ navLinks: tmpLinks });
  };

  render() {
    var Userdetails = JSON.parse(sessionStorage.getItem("usercontent"));
    if (Userdetails) {
      var userName = Userdetails.FirstName
    }
    return (
      <React.Fragment>
        <div id="navigation">
          <ul className="navbar-nav navigation-menu" id="top-menu">
            {this.state.navLinks.map((navLink, key) =>
              navLink.child && navLink.child.length > 0 ? (
                <li className="nav-item has-submenu dropdown" key={key}>
                  {/* child item(menu Item) - Level 1 */}
                  <a
                    className="nav-link dropdown-toggle "
                    href={navLink.link}
                    onClick={(event) => {
                      if (navLink.link === "#")
                        event.preventDefault();
                      this.openBlock(navLink.id);
                    }}
                  >
                    {navLink.title}
                  </a>
                  <span className="menu-arrow"></span>
                  {navLink.isMegaMenu ? (
                    // if menu is mega menu(2 columns grid)
                    <ul
                      className={
                        navLink.isOpenSubMenu
                          ? "submenu megamenu open"
                          : "submenu megamenu"
                      }
                    >
                      <li className="nav-item  ">
                        <ul>
                          {navLink.child.map((item, childKey) =>
                            item.id < 12 ? (
                              <li className="nav-item" key={childKey}>
                                <a className="nav-link  " href={item.link}>
                                  {item.title}
                                </a>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </li>
                      <li>
                        <ul>
                          {navLink.child.map((item, childKey) =>
                            item.id < 22 && item.id > 11 ? (
                              <li className="nav-item" key={childKey}>
                                <a className="nav-link  " href={item.link}>
                                  {item.title}
                                  {item.isNew ? (
                                    <span className="badge badge-danger rounded ml-2">
                                      v 2.2
                                    </span>
                                  ) : null}
                                </a>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </li>
                      <li>
                        <ul>
                          {navLink.child.map((item, childKey) =>
                            item.id > 21 ? (
                              <li className="nav-item" key={childKey}>
                                <a className="nav-link  " href={item.link}>
                                  {item.title}
                                  {item.isOnePage ? (
                                    <span className="badge badge-warning rounded ml-2">
                                      Onepage
                                    </span>
                                  ) : null}
                                </a>
                              </li>
                            ) : null
                          )}
                        </ul>
                      </li>
                    </ul>
                  ) : (
                      // if menu is not mega menu(1grid)
                      <ul
                        className={
                          navLink.isOpenSubMenu ? "submenu open" : "submenu"
                        }
                      >
                        {navLink.child.map((childArray, childKey) =>
                          childArray.nestedChild ? (
                            // sub menu item - Level 2
                            <li
                              className="nav-item dropdown has-submenu"
                              key={childKey}
                            >
                              <a
                                className="nav-link dropdown-toggle  "
                                href={childArray.link}
                                onClick={(event) => {
                                  event.preventDefault();
                                  this.openNestedBlock(navLink.id, childArray.id);
                                }}
                              >
                                {" "}
                                {childArray.title}{" "}
                                {childArray.isNew ? (
                                  <span className="badge badge-danger rounded">
                                    V 2.2
                                  </span>
                                ) : null}
                              </a>
                              <span className="submenu-arrow"></span>
                              <ul
                                className={
                                  childArray.isOpenNestedSubMenu
                                    ? "submenu open"
                                    : "submenu"
                                }
                              >
                                {childArray.nestedChild.map(
                                  (nestedChildArray, nestedKey) => (
                                    // nested sub menu item - Level 3
                                    <li className="nav-item" key={nestedKey}>
                                      <a
                                        className="nav-link  "
                                        href={nestedChildArray.link}
                                      >
                                        {nestedChildArray.title}{" "}
                                        {nestedChildArray.isNewPage ? (
                                          <span className="badge badge-primary rounded">
                                            NEW
                                          </span>
                                        ) : null}
                                      </a>
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          ) : (
                              <li className="nav-item" key={childKey}>
                                <a className="nav-link  " href={childArray.link}>
                                  {childArray.title}
                                </a>
                              </li>
                            )
                        )}
                      </ul>
                    )}
                </li>
              ) : (
                  <li className="nav-item" key={key}>
                    <a
                      className="nav-link  "
                      href={navLink.link}
                      style={
                        navLink.style === "button"
                          ? {
                            background: "white",
                            backgroundColor: "white !important",
                            color: "#4528dc",
                          }
                          : {}
                      }
                    >
                      {navLink.title}
                    </a>
                  </li>
                )
            )}
            {Userdetails &&
              this.state.profileLinks.map((navLink, key) =>
                navLink.child && navLink.child.length > 0 && (
                  <li className="nav-item has-submenu dropdown" key={key}>
                    {/* child item(menu Item) - Level 1 */}
                    <a
                      className="nav-link dropdown-toggle "
                      href={navLink.link}
                      onClick={(event) => {
                        if (navLink.link === "#")
                          event.preventDefault();
                        this.setState({
                          profileSubOpen: !this.state.profileSubOpen
                        });
                      }}
                    >
                      {navLink.title === "Welcome " ? navLink.title + userName : navLink.title}
                    </a>
                    <span className="menu-arrow"></span>
                    {navLink.isMegaMenu ? (
                      // if menu is mega menu(2 columns grid)
                      <ul
                        className={
                          this.state.profileSubOpen
                            ? "submenu megamenu open"
                            : "submenu megamenu"
                        }
                      >
                        <li className="nav-item  ">
                          <ul>
                            {navLink.child.map((item, childKey) =>
                              item.id < 12 ? (
                                <li className="nav-item" key={childKey}>
                                  <a className="nav-link  " href={item.link}>
                                    {item.title}
                                  </a>
                                </li>
                              ) : null
                            )}
                          </ul>
                        </li>
                        <li>
                          <ul>
                            {navLink.child.map((item, childKey) =>
                              item.id < 22 && item.id > 11 ? (
                                <li className="nav-item" key={childKey}>
                                  <a className="nav-link  " href={item.link}>
                                    {item.title}
                                    {item.isNew ? (
                                      <span className="badge badge-danger rounded ml-2">
                                        v 2.2
                                      </span>
                                    ) : null}
                                  </a>
                                </li>
                              ) : null
                            )}
                          </ul>
                        </li>
                        <li>
                          <ul>
                            {navLink.child.map((item, childKey) =>
                              item.id > 21 ? (
                                <li className="nav-item" key={childKey}>
                                  <a className="nav-link  " href={item.link}>
                                    {item.title}
                                    {item.isOnePage ? (
                                      <span className="badge badge-warning rounded ml-2">
                                        Onepage
                                      </span>
                                    ) : null}
                                  </a>
                                </li>
                              ) : null
                            )}
                          </ul>
                        </li>
                      </ul>
                    ) : (
                        // if menu is not mega menu(1grid)
                        <ul
                          className={
                            this.state.profileSubOpen ? "submenu open" : "submenu"
                          }
                        >
                          {navLink.child.map((childArray, childKey) =>
                            childArray.nestedChild ? (
                              // sub menu item - Level 2
                              <li
                                className="nav-item dropdown has-submenu"
                                key={childKey}
                              >
                                <a
                                  className="nav-link dropdown-toggle  "
                                  href={childArray.link}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    this.openNestedBlock(navLink.id, childArray.id);
                                  }}
                                >
                                  {" "}
                                  {childArray.title}{" "}
                                  {childArray.isNew ? (
                                    <span className="badge badge-danger rounded">
                                      V 2.2
                                    </span>
                                  ) : null}
                                </a>
                                <span className="submenu-arrow"></span>
                                <ul
                                  className={
                                    childArray.isOpenNestedSubMenu
                                      ? "submenu open"
                                      : "submenu"
                                  }
                                >
                                  {childArray.nestedChild.map(
                                    (nestedChildArray, nestedKey) => (
                                      // nested sub menu item - Level 3
                                      <li className="nav-item" key={nestedKey}>
                                        <a
                                          className="nav-link  "
                                          href={nestedChildArray.link}
                                        >
                                          {nestedChildArray.title}{" "}
                                          {nestedChildArray.isNewPage ? (
                                            <span className="badge badge-primary rounded">
                                              NEW
                                            </span>
                                          ) : null}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </li>
                            ) : (
                                <li className="nav-item" key={childKey}>
                                  <a className="nav-link  " href={childArray.link}>
                                    {childArray.title}
                                  </a>
                                </li>
                              )
                          )}
                        </ul>
                      )}
                  </li>
                ))}
          </ul>

        </div>
      </React.Fragment>
    );
  }
}

export default Menu;
