import React, { Component } from "react";
import { Col, Media } from "reactstrap";

class KeyFeaturesBox extends Component {
  render() {

    const { keyFeatures, policyType, iconImage } = this.props;

    return (
      <React.Fragment>
        {keyFeatures.map((feature, key) => (
          policyType && policyType._id === feature.group._id && (
            <Col key={key} lg={4} md={6} className="mt-4 pt-2">
              <a
                href={feature.link}
                target="_blank"
                rel="noopener noreferrer"
                className="media key-feature border align-items-center p-3 rounded"
                style={{ minHeight: "100px" }}
              >
                {feature.icon && <div className="icon text-center rounded-circle h4 mr-3 mb-0">
                  {iconImage ?
                    <img src={feature.icon} alt="source" />
                    :
                    <i className={feature.icon}></i>}
                </div>
                }
                <Media body>
                  <h5 className="mb-0">
                    {feature.title ? feature.title : feature.name}
                  </h5>
                </Media>

                <div className="">
                  <img src={"/img/pdf.svg"} alt="source" width='25.96px' height='31.93px' />
                </div>

              </a>
            </Col>
          )
        ))}
      </React.Fragment>
    );
  }
}

export default KeyFeaturesBox;
