import React, { Fragment } from "react";
import Header from "../../components/HeaderSection/Header";
import FooterSection from "../../components/FooterSection/Footer";
import Breadcrumb from "../../components/Blogs/Breadcrumb";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import PointsSidebar from "../ServicePromotion/PointsSidebar";

import PointsDetails from "../ServicePromotion/PointsDetails";
class Promotion extends React.Component {
  state = {
    contents: [],
    loading: true,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
  };
  componentDidMount() {
    var servicename = window.location.hash.replace("/", "");
    if (servicename.includes("apply")) {
      servicename = servicename.replace("/apply", "");
      this.props.history.push(servicename);
    }
    var name = servicename;
    if (name.includes("service")) {
      name = name.replace("/service", "");
    }
    // if (name === "AddOnService") {
    //   var bread = "Add On Service";
    // } else if (name === "SinglePointContact") {
    //   bread = "Single Point Contact";
    // } else if (name === "Trade") {
    //   bread = "Trade Promotion";
    // } else if (name === "Investment") {
    //   bread = "Investment Promotion";
    // } else if (name === "Research") {
    //   bread = "Research and Publication";
    // }
    var breadcrumbName = name.split("/")[name.split("/").length - 1];
    breadcrumbName =
      breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
    this.GetContent(servicename);
    this.setState({
      currentservicename: servicename,
      breadcrumbName: breadcrumbName,
    });
  }
  componentWillReceiveProps() {
    var servicename = window.location.hash.replace("/", "");
    if (servicename.includes("apply")) {
      servicename = servicename.replace("/apply", "");
      this.props.history.push(servicename);
    }
    var name = servicename;
    if (name.includes("service")) {
      name = name.replace("/service", "");
    }
    // if (name === "AddOnService") {
    //   var bread = "Add On Service";
    // } else if (name === "SinglePointContact") {
    //   bread = "Single Point Contact";
    // } else if (name === "Trade") {
    //   bread = "Trade Promotion";
    // } else if (name === "Investment") {
    //   bread = "Investment Promotion";
    // } else if (name === "Research") {
    //   bread = "Research and Publication";
    // }
    this.GetContent(servicename);
    var breadcrumbName = name.split("/")[name.split("/").length - 1];
    breadcrumbName =
      breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
    this.setState({
      currentservicename: servicename,
      breadcrumbName: breadcrumbName,
    });
  }

  GetContent(servicename) {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "services";
    ListRequestModel.searchString = servicename;
    delete ListRequestModel.parentservice;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      var breadcrumbName = data.result[0].name;
      breadcrumbName =
        breadcrumbName.charAt(0).toUpperCase() + breadcrumbName.slice(1);
      this.setState({
        contents: data.result.length > 0 ? data.result : [],
        loading: false,
        breadcrumbName: breadcrumbName,
      });
      this.GetChildContent();
    }
  };
  GetChildContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "services";
    ListRequestModel.searchString = "";
    ListRequestModel.parentservice = this.state.contents[0]._id;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetChildContentResponse
    );
  }

  GetChildContentResponse = (data) => {
    if (data.result) {
      this.setState({
        childcontents: data.result.length > 0 ? data.result : [],
        loading: false,
      });
    }
  };

  render() {
    var content = this.state.contents && this.state.contents[0];
    return (
      <div>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <div className="main services">
          <Header imageData={"/img/logo-white.png"} />
          <Breadcrumb title={this.state.breadcrumbName + " Service"} />

          {this.state.loading ? (
            <div className="bar"></div>
          ) : (
              <Fragment>
                <div className="blog">
                  <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                  </div>

                  <div className="main">
                    <section
                      id="blog"
                      className="section blog-area service-blog ptb_100"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-lg-4">
                            <PointsSidebar
                              content={content}
                              childcontents={this.state.childcontents}
                            />
                          </div>
                          <div className="col-12 col-lg-8">
                            <PointsDetails content={content} />
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </Fragment>
            )}
        </div>
        <FooterSection />{" "}
      </div>
    );
  }
}

export default Promotion;
