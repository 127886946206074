import React, { Component, Fragment } from "react";
import Header from "../../components/HeaderSection/Header";
import FooterSection from "../../components/FooterSection/Footer";
import { MethodType, StatusCodes } from "../../constants/defaultValues";
import DropDown from "../../common/DropDown";
import {
  Container,
  Row,
  Col,
  FormGroup,
  Button,
  Label,
  CardBody,
  Card,
  Table,
  CardHeader,
  CustomInput,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { CallService } from "../../helpers/servicecall";
import bg from "../../img/event-planner.jpeg";
import Breadcrumb from "../../components/Blogs/Breadcrumb";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { entries, documentMaster, events } from '../../constants/config';
import { Formik, Form, Field } from "formik";
import { Colxx } from "../../components/common/CustomBootstrap";

import * as Yup from "yup";
import { Link } from "react-router-dom";
var currentday =
  new Date() /
  1000;

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const validationSchema = Yup.object().shape({
  Event: Yup.string().required("Please select valid Event!"),
  FirstName: Yup.string().required("Please enter valid First Name!"),
  LastName: Yup.string().required("Please enter valid Last Name!"),
  Gender: Yup.string().required("Please select valid Gender!"),
  UnitName: Yup.string().required("Please enter valid Company Name!"),
  Website: Yup.string().required("Please enter valid Website!"),
  UDAYAMNo: Yup.string().required("Please enter valid UDAYAM Registeration No!"),
  IncorporationYear: Yup.string().required("Please enter valid Year of Incorporation!"),
  GSTNo: Yup.string().required("Please enter valid GST No!"),
  SocialStatus: Yup.string().required("Please select valid Social Status!"),
  CompanyClassification: Yup.string().required("Please select valid Company Classification!"),
  MajorIndustriesList: Yup.string().required("Please select valid Major Industry!"),
  // MajorProducts: Yup.string().required("Please select valid Major Products Or Activities!"),
  NoOfCurrentEmployees: Yup.number().required("Please enter valid no of current employees!"),
  Address: Yup.string().required("Please enter valid Address!"),
  District: Yup.string().required("Please enter valid District!"),
  Pincode: Yup.string().required("Please enter valid Pincode!").matches(/^[0-9]{6,6}$/, "Please enter valid Pincode!"),
  TelephoneNumber: Yup.number().required("Please enter valid Telephone Number!"),
  MembershipWithTradeBodies: Yup.number().required("Please select Membership!"),
  EmailID: Yup.string().required("Please enter EmailID!")
    .matches(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}/, "Please enter valid Email ID!"),
  MobileNumber: Yup.string()
    .required("Please enter mobile number")
    .matches(/^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/, "Invalid Mobile Number"),
  DelegateFirstName: Yup.string().required("Please enter valid Delegate First Name!"),
  DelegateLastName: Yup.string().required("Please enter valid Delegate Last Name!"),
  DelegateDesignation: Yup.string().required("Please enter valid Delegate Designation!"),
  DelegateCompanyName: Yup.string().required("Please enter valid Delegate Company name!"),
  DelegateEmailID: Yup.string().required("Please enter Delegate Email ID!")
    .matches(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}/, "Please enter valid Delegate Email ID!"),
  DelegateMobileNumber: Yup.string()
    .required("Please enter Delegate mobile number")
    .matches(/^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/, "Invalid Delegate Mobile Number"),
});
class EventApplication extends Component {
  state = {
    MSMEUser: "",
    FirstName: "",
    LastName: "",
    Gender: "",
    SocialStatus: "",
    UnitName: "",
    IncorporationYear: "",
    UDAYAMNo: "",
    UDAYAMRegistrationProof: "",
    GSTNo: "",
    Address: "",
    District: "",
    Pincode: "",
    TelephoneNumber: "",
    MobileNumber: "",
    EmailID: "",
    Website: "",
    CompanyClassification: "",
    MajorIndustriesList: "",
    MajorIndustryOther: "",
    TurnOvers: [{
      Year: "", DomesticAmountInLakh: "", OverseasAmountInLakh: ""
    }],
    NoOfCurrentEmployees: "",
    IECRegistrationNumber: "",
    IECRegistrationProof: "",
    HSCode: "",
    MembershipWithTradeBodies: [],
    ListOfProductForDisplay: [""],
    DelegateRepresentativeDetails: {
      FirstName: "",
      LastName: "",
      Designation: "",
      CompanyName: "",
      EmailID: "",
      MobileNumber: ""
    },
    IsInternationalEvent: false,
    InternationalEventProof: "",
    Passport: "",
    IssueDate: "",
    ExpiryDate: "",
    PlaceofIssue: "",
    PreviousEvents: [],
    Event: "",
    districts: [],
    registerd: false,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 100,
    },
    ListMemberRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 100,
    },
    ListSocialRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 100,
    },
    ListRequestModelTNMap: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    isShown: false,
    userDetails: {}
  };

  componentDidMount() {

    var userDetails = JSON.parse(sessionStorage.getItem("usercontent"));

    if (sessionStorage.getItem("userid")) {
      this.GetContent();
      this.GetSocialstatus();
      this.GetMembership();
      this.GetTnmapDistricts();
      this.GetMajorProducts();
      this.GetMajorindustrieslist();
      userDetails.UDAYAMNo = userDetails.UDAYAMRegisterationNo;
      userDetails.UnitName = userDetails.CompanyName;
      this.setState({
        userDetails: userDetails,
        TurnOvers: userDetails.TurnOvers
      });
    }
    else {
      toastr.warning("Please login to continue");
      this.props.history.push("/login");
    }
  }
  handleSubmit = (values, errors) => {
    var data = values;
    var error = false;
    if (values) {
      data.DelegateRepresentativeDetails = {
        FirstName: values.DelegateFirstName,
        LastName: values.DelegateLastName,
        Designation: values.DelegateDesignation,
        CompanyName: values.DelegateCompanyName,
        EmailID: values.DelegateEmailID,
        MobileNumber: values.DelegateMobileNumber
      };
      data.MSMEUser = JSON.parse(sessionStorage.getItem("userid"));
    }
    if (!values.ListOfProductForDisplay) {
      error = true;
      errors.setFieldError("ListOfProductForDisplay", "Please enter atleast one Product for display")
    }
    if (values.ListOfProductForDisplay.length === 0) {
      error = true;
      errors.setFieldError("ListOfProductForDisplay", "Please enter atleast one Product for display")
    }
    if (!values.TurnOvers) {
      error = true;
      errors.setFieldError("TurnOvers", "Please enter atleast one Turn over")
    }
    if (values.TurnOvers.length === 0) {
      error = true;
      errors.setFieldError("TurnOvers", "Please enter atleast one  Turn over")
    }
    if (!values.UDAYAMRegistrationProof || values.UDAYAMRegistrationProof === {}) {
      error = true;
      errors.setFieldError("UDAYAMRegistrationProof", "Please upload UDAYAM Registration Proof")
    }
    if (!error) {
      this.toggleViewModal(data)
      delete data._id;
      let formData = new FormData();
      var isFile = false;
      Object.keys(values).map(key => {
        if (typeof values[key] === "object")
          if (JSON.stringify(values[key]) === "{}") {
            isFile = true;
            return formData.append(key, values[key]);
          }
        return "";
      });

      if (isFile) {
        CallService(
          documentMaster.FileUpload,
          MethodType.POST,
          false,
          formData,
          "",
          this.DocumentUploaded,
          true
        );
        this.setState({
          values: data
        });
      }
    }
  }

  GetEventResponse = (data) => {
    if (data.result) {
      toastr.success(
        "",
        'Event Registered Successfully'
      );
      this.props.history.push('/');
    };
  };


  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "event";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      var currentEvent = window.location.pathname;
      currentEvent = currentEvent.split("/")[currentEvent.split("/").length - 1];
      var chooseevent = data.result.find(r => r.friendlyurl && r.friendlyurl.includes(currentEvent));
      var userDetails = this.state.userDetails;
      userDetails.Event = chooseevent && chooseevent._id;
      this.setState(
        {
          events: data.result.map(function (a) {
            return { value: a._id, label: a.name, startdate: a.startdate, enddate: a.enddate };
          }),
          chooseevent: chooseevent && chooseevent._id,
          eventStatus: '/upcoming-events',
          selectedEvent: chooseevent,
          userDetails: userDetails,
          // calendarEvents: Events.length > 0 ? Events : this.state.calendarEvents,
          loading: false,
        }
      );
    }
  };



  GetMajorindustrieslist() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "majorindustrylist"
      },
      "",
      this.GetMajorindustrieslistResponse
    );
  }

  GetMajorindustrieslistResponse = (data) => {
    if (data.result) {
      var majorindustrieslist = data.result.sort((a, b) => a.name.localeCompare(b.name)).map(r => {
        return ({ label: r.name, value: r._id })
      });
      this.setState({
        majorindustrieslist: majorindustrieslist
      })
    }
  }

  GetMajorProducts() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
        TableName: "majorproducts"
      },
      "",
      this.GetMajorProductsResponse
    );
  }

  GetMajorProductsResponse = (data) => {
    if (data.result) {
      var majorproducts = data.result.sort((a, b) => a.name.localeCompare(b.name)).map(r => {
        return ({ label: r.name, value: r._id })
      });
      this.setState({
        majorproducts: majorproducts
      })
    }
  }


  GetSocialstatus() {
    const { ListSocialRequestModel } = this.state;
    ListSocialRequestModel.TableName = "socialstatus";
    ListSocialRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListSocialRequestModel,
      "",
      this.GetSocialstatusResponse
    );
  }

  GetSocialstatusResponse = (data) => {
    if (data.result)
      this.setState({
        socialstatus: data.result.length > 0 ? data.result.map(function (a) {
          return { value: a._id, label: a.label };
        }) : [],
      });
  };


  GetMembership() {
    const { ListMemberRequestModel } = this.state;
    ListMemberRequestModel.TableName = "tradebodies";
    ListMemberRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListMemberRequestModel,
      "",
      this.GetMembershipResponse
    );
  }

  GetMembershipResponse = (data) => {
    if (data.result)
      this.setState({
        tradebodies: data.result.length > 0 ? data.result.map(function (a) {
          return { value: a._id, label: a.name };
        }) : [],
      });
  };



  GetTnmapDistricts() {
    const { ListRequestModelTNMap } = this.state;
    ListRequestModelTNMap.TableName = "tnmap";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelTNMap,
      "",
      this.GetTnmapDistrictsResponse
    );
  }


  GetTnmapDistrictsResponse = (data) => {
    this.setState(
      {
        districts: data.result.sort((a, b) => a.name.localeCompare(b.name)).map(function (a) {
          return { value: a._id, label: a.name };
        }),
      });
  };



  DocumentUploaded = (data) => {
    if (data.statusCode === StatusCodes.Success) {
      if (data.result) {
        var Values = this.state.values;
        Object.keys(Values).map(key => {
          if (typeof Values[key] === "object")
            if (JSON.stringify(Values[key]) === "{}") {
              return Values[key] = data.result[key];
            }
          return "";
        });

        // CallService(
        //   events.Register,
        //   MethodType.PUT,
        //   false,
        //   Values,
        //   "",
        //   this.GetEventResponse
        // );
      } else {
        toastr.error(
          "",
          "Document upload failed"
        );
      }
    } else {
      toastr.error(
        "",
        "Document upload failed"
      );
    }

  };


  toggleViewModal = (row) => {
    this.setState({
      RowDetails: row,
      viewModal: !this.state.viewModal,
    });
  };


  RegisterModal = (row) => {
    CallService(
      events.Register,
      MethodType.PUT,
      false,
      row,
      "",
      this.GetEventResponse
    );
    this.setState({
      viewModal: !this.state.viewModal,
    });
  };




  render() {
    var { selectedEvent, RowDetails } = this.state;
    return (
      <div className="contact-page">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main contact">
          <Header imageData={"/img/logo-white.png"} />

          <Breadcrumb title="Event Application" bg={JSON.parse(sessionStorage.getItem("menu")) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0] && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg ? JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg : bg} />

          <div className="position-relative">
            <div className="shape overflow-hidden text-white">
              <svg
                viewBox="0 0 2880 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </div>

          <section>
            <Container>
              <Row className="justify-content-center"
                style={{ marginTop: '-10%' }}
              >

                <Col lg="10">
                  <Card className="border-0 rounded shadow ">
                    {selectedEvent && selectedEvent.name && <CardHeader>
                      <Link className="" to="/upcoming-events">
                        Upcoming Events
                   </Link>
                      {" > "}
                      <Link className="" to={selectedEvent.friendlyurl}>
                        {selectedEvent.name}
                      </Link>
                      {" > "}
                      Event Application
                    </CardHeader>
                    }
                    <CardBody className="p-5">
                      <Formik
                        initialValues={this.state.userDetails}
                        onSubmit={this.handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                      >
                        {({ setFieldValue, values, errors, setFieldTouched }) => (

                          <Form className="av-tooltip tooltip-label-bottom">
                            <Row className="align-items-center ">
                              <Col lg="3" />
                              {this.state.events &&
                                <DropDown
                                  className="react-select  requiredField"
                                  classNamePrefix="react-select "
                                  label="Select Event"
                                  name="Event"
                                  colSplit={6}
                                  MobcolSplit={12}
                                  options={this.state.events.filter(
                                    (e) =>
                                      (e.startdate >= currentday || e.enddate >= currentday)
                                  )}
                                  placeholderText={"Event"}
                                  Checkbox={false}
                                  Action={(e) => {
                                    this.setState({
                                      chooseevent: e.value,
                                      selectedEvent: e
                                    });
                                    setFieldValue("Event", e.value)
                                  }}
                                  value={values.Event && this.state.events.find(e => e.value === values.Event)}
                                  errors={errors.Event}
                                />
                              }
                              <Col lg="3" />
                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField ">First Name</Label>

                                  <Field
                                    name="FirstName"
                                    id="first"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.FirstName && (
                                    <div className="invalid-feedback d-block">
                                      {errors.FirstName}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField">Last Name</Label>
                                  <Field
                                    name="LastName"
                                    id="first"
                                    type="text"
                                    className="form-control border"

                                  />
                                  {errors.LastName && (
                                    <div className="invalid-feedback d-block">
                                      {errors.LastName}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg={12}>
                                <FormGroup className="position-relative ml-3">
                                  <Row>
                                    <Label className="requiredField mr-3">Gender</Label>
                                    <input type="radio" id="G1" checked={values.Gender === "Male"} name="Gender" value="Male" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("Gender", "Male")
                                    } />
                                    <label for="G1" className="ml-2">Male</label>
                                    <input type="radio" id="G2" checked={values.Gender === "Female"} name="Gender" value="Female" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("Gender", "Female")
                                    } />

                                    <label for="G2" className="ml-2">Female</label>
                                    <input type="radio" id="G3" checked={values.Gender === "Transgender"} name="Gender" value="Transgender" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("Gender", "Transgender")
                                    } />
                                    <label for="G3" className="ml-2">Transgender</label>
                                  </Row>
                                  {errors.Gender && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Gender}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg={12}>
                                <FormGroup className="position-relative ml-3">
                                  <Row>
                                    <Label className="mr-3 requiredField">Social status </Label>
                                    <input type="radio" id="s1" checked={values.SocialStatus === "SC"} name="SocialStatus" value="SC" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("SocialStatus", "SC")
                                    } />
                                    <label for="s1" className="ml-2">SC</label>
                                    <input type="radio" id="s2" checked={values.SocialStatus === "ST"} name="SocialStatus" value="ST" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("SocialStatus", "ST")
                                    } />

                                    <label for="s2" className="ml-2" >ST</label>
                                    <input type="radio" checked={values.SocialStatus === "Women"} id="s3" name="SocialStatus" value="Women" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("SocialStatus", "Women")
                                    } />
                                    <label for="s3" className="ml-2" >Women</label>
                                    <input type="radio" checked={values.SocialStatus === "DifferentlyAbled"} id="s4" name="SocialStatus" value="DifferentlyAbled" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("SocialStatus", "DifferentlyAbled")
                                    } />
                                    <label for="s4" className="ml-2" >Differently Abled</label>
                                    <input type="radio" checked={values.SocialStatus === "Others"} id="s4" name="SocialStatus" value="Others" className="mt-1 ml-2" onChange={() =>
                                      setFieldValue("SocialStatus", "Others")
                                    } />
                                    <label for="s5" className="ml-2" >Others</label>
                                    {errors.SocialStatus && (
                                      <div className="invalid-feedback d-block">
                                        {errors.SocialStatus}
                                      </div>
                                    )}
                                  </Row>
                                </FormGroup>
                              </Col>

                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField">Name of the unit </Label>
                                  <Field
                                    name="UnitName"
                                    id="first"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.UnitName && (
                                    <div className="invalid-feedback d-block">
                                      {errors.UnitName}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>


                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField">Year of Incorporation </Label>
                                  <Field
                                    name="IncorporationYear"
                                    id="first"
                                    type="number"
                                    className="form-control border"
                                  />
                                  {errors.IncorporationYear && (
                                    <div className="invalid-feedback d-block">
                                      {errors.IncorporationYear}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> UDYAM/UAM No </Label>
                                  <Field
                                    name="UDAYAMNo"
                                    id="first"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.UDAYAMNo && (
                                    <div className="invalid-feedback d-block">
                                      {errors.UDAYAMNo}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField">Proof of Company - Upload UDYAM Registration</Label>
                                  <CustomInput type="file" name={"UDAYAMRegistrationProof"}
                                    className="form-control"
                                    onChange={(event) => {
                                      var value = event.target.files[0];
                                      if (value) {
                                        if (!(value.size / 1024 <= 500)) {
                                          toastr.warning(
                                            "",
                                            "File too large"
                                          );
                                          value = "";
                                        }
                                        else
                                          if (!["doc", "docx", "pdf", "png", "jpg", "jpeg"].includes(
                                            value.name.toLowerCase().split(".")[
                                            value.name.toLowerCase().split(".").length - 1
                                            ]
                                          )) {
                                            toastr.warning(
                                              "",
                                              "File format not allowed"
                                            );
                                            value = "";
                                          }
                                      }
                                      this.setState({ UDAYAMRegistrationProof: value })
                                      setFieldValue("UDAYAMRegistrationProof", value)
                                    }} />
                                  {errors.UDAYAMRegistrationProof && (
                                    <div className="invalid-feedback d-block">
                                      {errors.UDAYAMRegistrationProof}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>


                              <Col md="12">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> GST No </Label>
                                  <Field
                                    name="GSTNo"
                                    id="first"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.GSTNo && (
                                    <div className="invalid-feedback d-block">
                                      {errors.GSTNo}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> Address</Label>
                                  <Field
                                    name="Address"
                                    component="textarea"
                                    id="first"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.Address && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Address}
                                    </div>
                                  )}

                                </FormGroup>
                              </Col>
                              <DropDown
                                classNamePrefix="react-select"
                                className="react-select requiredField"
                                label="District"
                                name="District"
                                colSplit={6}
                                isSearchable
                                MobcolSplit={12}
                                options={this.state.districts}
                                placeholderText={""}
                                Checkbox={false}
                                Action={(e) => {

                                  setFieldValue("District", e.value)

                                }}
                                errors={errors.District}
                              />
                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> Pin code </Label>
                                  <Field
                                    name="Pincode"
                                    id="first"
                                    type="number"
                                    className="form-control border"
                                  />
                                  {errors.Pincode && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Pincode}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>


                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> Telephone No </Label>
                                  <Field
                                    name="TelephoneNumber"
                                    id="first"
                                    type="number"
                                    className="form-control border"
                                  />
                                  {errors.TelephoneNumber && (
                                    <div className="invalid-feedback d-block">
                                      {errors.TelephoneNumber}
                                    </div>
                                  )}

                                </FormGroup>
                              </Col>


                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> Mobile No </Label>
                                  <Field
                                    name="MobileNumber"
                                    id="first"
                                    type="number"
                                    className="form-control border"
                                  />
                                  {errors.MobileNumber && (
                                    <div className="invalid-feedback d-block">
                                      {errors.MobileNumber}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>





                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> E-mail</Label>
                                  <Field
                                    name="EmailID"
                                    id="EmailID"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.EmailID && (
                                    <div className="invalid-feedback d-block">
                                      {errors.EmailID}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>

                              <Col md="6">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> Website </Label>
                                  <Field
                                    name="Website"
                                    id="Website"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.Website && (
                                    <div className="invalid-feedback d-block">
                                      {errors.Website}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>


                              <Col lg={12}>
                                <FormGroup className="position-relative ml-3">
                                  <Row>
                                    <Label className="requiredField">Classification of Company
                                    <HtmlTooltip
                                        title={
                                          <React.Fragment>
                                            <div>
                                              <Table>
                                                <tr>
                                                  <th>Manufacturing </th>
                                                  <th>& </th>
                                                  <th>Services</th>
                                                </tr>
                                                <tr>
                                                  <td>Segment</td>
                                                  <td>Micro</td>
                                                  <td>Small</td>
                                                  <td>Medium</td>
                                                </tr>
                                                <tr>
                                                  <td>Investment</td>
                                                  <td>&#60;1 Cr.</td>
                                                  <td>&#60;10 Cr.</td>
                                                  <td>&#60;50 Cr.</td>

                                                </tr>
                                                <tr>
                                                  <td>Turnover</td>
                                                  <td>&#60;5 Cr.</td>
                                                  <td>&#60;50 Cr.</td>
                                                  <td>&#60;250 Cr.</td>

                                                </tr>
                                              </Table>
                                            </div>
                                          </React.Fragment>
                                        }
                                      >
                                        <button className="ml-2" style={{ background: "transparent", border: "0px" }}><i className="fa fa-info-circle" /></button>
                                      </HtmlTooltip>
                                    </Label>

                                    <input type="radio" id="c1" checked={values.CompanyClassification === "Micro"} name="Micro" value="Micro" className="m-1 " onChange={() =>
                                      setFieldValue("CompanyClassification", "Micro")
                                    } />
                                    <label for="c1"  >Micro</label>

                                    <input type="radio" id="c2" checked={values.CompanyClassification === "Small"} name="Small" value="Small" className="m-1" onChange={() =>
                                      setFieldValue("CompanyClassification", "Small")
                                    } />
                                    <label for="c2"  >Small</label>


                                    <input type="radio" id="c3" checked={values.CompanyClassification === "Medium"} name="Medium" value="Medium" className="m-1 " onChange={() =>
                                      setFieldValue("CompanyClassification", "Medium")
                                    } />
                                    <label for="c3"  >Medium</label>
                                    {errors.CompanyClassification && (
                                      <div className="invalid-feedback d-block">
                                        {errors.CompanyClassification}
                                      </div>
                                    )}

                                  </Row>
                                </FormGroup>
                              </Col>
                              <DropDown
                                classNamePrefix="react-select"
                                className="react-select requiredField"
                                label="Major Industry group /  Product line"
                                name="MajorIndustriesList"
                                colSplit={12}
                                isSearchable
                                MobcolSplit={12}
                                options={this.state.majorindustrieslist}
                                placeholderText={""}
                                Checkbox={false}
                                value={this.state.majorindustrieslist && this.state.majorindustrieslist.find(e => e.value === values.MajorIndustriesList)}
                                Action={(e) => {
                                  var MajorIndustryOther = false;
                                  if (e.label) {
                                    if (e.label.toLowerCase() === ("others")) {
                                      MajorIndustryOther = true;
                                    } else
                                      setFieldValue("MajorIndustryOther", "")
                                  }
                                  this.setState({
                                    MajorIndustrygroup: e.value,
                                    MajorIndustryOther: MajorIndustryOther
                                  });
                                  setFieldValue("MajorIndustriesList", e.value)

                                }}
                                errors={errors.MajorIndustriesList}
                              />
                              {this.state.MajorIndustryOther &&
                                <Col md="12">
                                  <FormGroup className="position-relative">
                                    <Label className="requiredField ">Other Major Industry</Label>

                                    <Field
                                      name="MajorIndustryOther"
                                      id="MajorIndustryOther"
                                      type="text"
                                      className="form-control border"
                                    />
                                    {errors.MajorIndustryOther && (
                                      <div className="invalid-feedback d-block">
                                        {errors.MajorIndustryOther}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                              }
                              {/* <DropDown
                                classNamePrefix="react-select"
                                className="react-select requiredField"
                                label="Major products / activities"
                                name="MajorProductsOrActivities"
                                colSplit={12}
                                isSearchable
                                isMulti
                                MobcolSplit={12}
                                options={this.state.majorproducts}
                                placeholderText={""}
                                Checkbox={false}
                                Action={(entity) => {
                                  var selectedMajorProducts = [];
                                  if (entity)
                                    entity.map((e) => {
                                      return selectedMajorProducts.push(e.value);
                                    });
                                  this.setState({
                                    selectedMajorProducts: selectedMajorProducts
                                  })
                                  setFieldValue("MajorProducts", selectedMajorProducts)
                                }}
                                errors={errors.MajorProducts}
                              /> */}
                              {/* <Col md="12" className=" pt-2">
                                <Label className="requiredField">Major products / activities </Label>
                                {this.state.MajorProducts && this.state.MajorProducts.map((lpd, i) =>
                                  <div className="row">

                                    <div className="col-1 vertical-align">
                                      <label className="event-form  ml-3">{i + 1}</label>
                                    </div>
                                    <div className="col-10">
                                      <div className="form-group">
                                        <Field type="text" className="form-control border"
                                          value={lpd}
                                          onChange={(e) => {
                                            if (e.target.value) {
                                              var MajorProducts = this.state.MajorProducts;
                                              if (!MajorProducts) {
                                                MajorProducts = [];
                                              }
                                              MajorProducts[i] = e.target.value;
                                              this.setState({
                                                MajorProducts: MajorProducts
                                              })
                                              setFieldValue("MajorProducts", MajorProducts)
                                            }
                                          }
                                          } />
                                      </div>
                                    </div>
                                    <div className="col-1">
                                      {i === (this.state.MajorProducts.length - 1) ?
                                        <Button color="primary"
                                          onClick={() => {
                                            var MajorProducts = this.state.MajorProducts;
                                            MajorProducts.push("");
                                            this.setState({
                                              MajorProducts: MajorProducts
                                            })
                                          }}>
                                          Add
                                        </Button>
                                        :
                                        <Button color="danger" style={{ background: "red" }}
                                          onClick={() => {
                                            var MajorProducts = this.state.MajorProducts;
                                            MajorProducts.splice(i, 1);
                                            this.setState({
                                              MajorProducts: MajorProducts
                                            })
                                          }}>
                                          Remove
                                        </Button>
                                      }
                                    </div>
                                  </div>
                                )}

                              </Col> */}

                              <Col md="6" className=" pt-2">
                                <h5>Turnover – Last 3 Years </h5>
                                <div className="row">
                                  <div className="col-4"></div>
                                  <div className="col-4">Domestic (Rs in lakh)</div>
                                  <div className="col-4 ">Overseas (Rs in lakh)</div>
                                </div>
                                {[3, 2, 1].map((i, ind) =>
                                  <div className="row">
                                    <div className="col-4">
                                      <label className="event-form  ml-3">FY {new Date().getFullYear() - (i + 1)}-{new Date().getFullYear() - i}</label>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                        <input type="number" className="form-control border"
                                          value={this.state.TurnOvers[ind] && this.state.TurnOvers[ind].DomesticAmountInLakh}
                                          onChange={(e) => {
                                            var TurnOvers = this.state.TurnOvers;
                                            if (!TurnOvers) {
                                              TurnOvers = [];
                                            }
                                            if (TurnOvers[ind]) {
                                              TurnOvers[ind].DomesticAmountInLakh = e.target.value;
                                            }
                                            else {
                                              TurnOvers.push({ Year: (new Date().getFullYear() - i), DomesticAmountInLakh: e.target.value })
                                            }
                                            this.setState({
                                              TurnOvers: TurnOvers
                                            })
                                            setFieldValue("TurnOvers", TurnOvers)
                                          }
                                          } />
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                        <input type="number" className="form-control border"
                                          value={this.state.TurnOvers[ind] && this.state.TurnOvers[ind].OverseasAmountInLakh}
                                          onChange={(e) => {
                                            var TurnOvers = this.state.TurnOvers;
                                            if (!TurnOvers) {
                                              TurnOvers = [];
                                            }
                                            if (TurnOvers[ind]) {
                                              TurnOvers[ind].OverseasAmountInLakh = e.target.value;
                                            }
                                            else {
                                              TurnOvers.push({ Year: (new Date().getFullYear() - i), OverseasAmountInLakh: e.target.value })
                                            }
                                            this.setState({
                                              TurnOvers: TurnOvers
                                            })
                                            setFieldValue("TurnOvers", TurnOvers)
                                          }
                                          } />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {errors.TurnOvers && (
                                  <div className="invalid-feedback d-block">
                                    {errors.TurnOvers}
                                  </div>
                                )}
                              </Col>

                              <Col md="6 ">
                                <FormGroup className="position-relative">
                                  <Label className="requiredField"> No. of Current Employees</Label>

                                  <Field
                                    name="NoOfCurrentEmployees"
                                    id="NoOfCurrentEmployees"
                                    type="number"
                                    className="form-control border"
                                  />
                                  {errors.NoOfCurrentEmployees && (
                                    <div className="invalid-feedback d-block">
                                      {errors.NoOfCurrentEmployees}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="position-relative ">
                                  <Label>IEC Registration Number </Label>
                                  <Field
                                    name="IECRegistrationNumber"
                                    id="IECRegistrationNumber"
                                    type="text"
                                    className="form-control border"
                                  />
                                  {errors.IECRegistrationNumber && (
                                    <div className="invalid-feedback d-block">
                                      {errors.IECRegistrationNumber}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup className="position-relative">
                                  <Label>IEC Registration Proof </Label>
                                  <CustomInput type="file" name={"IECRegistrationProof"}
                                    onChange={(event) => {
                                      var value = event.target.files[0];
                                      if (value) {
                                        if (!(value.size / 1024 <= 500)) {
                                          toastr.warning(
                                            "",
                                            "File too large"
                                          );
                                          value = "";
                                        }
                                        else
                                          if (!["doc", "docx", "pdf", "png", "jpg", "jpeg"].includes(
                                            value.name.toLowerCase().split(".")[
                                            value.name.toLowerCase().split(".").length - 1
                                            ]
                                          )) {
                                            toastr.warning(
                                              "",
                                              "File format not allowed"
                                            );
                                            value = "";
                                          }
                                      }
                                      this.setState({ IECRegistrationProof: value })
                                      setFieldValue("IECRegistrationProof", value)
                                    }} />
                                </FormGroup>
                              </Col>
                              <Col lg="6">

                                <FormGroup className="position-relative">
                                  <Label>HS Code (Exports) </Label>
                                  <Field
                                    name="HSCode"
                                    id="HSCode"
                                    type="number"
                                    className="form-control border"
                                  />
                                  {errors.HSCode && (
                                    <div className="invalid-feedback d-block">
                                      {errors.HSCode}
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                              <DropDown
                                classNamePrefix="react-select"
                                className="react-select requiredField"
                                label="Membership with trade bodies"
                                name="MembershipWithTradeBodies"
                                colSplit={12}
                                isMulti
                                isSearchable
                                MobcolSplit={12}
                                options={this.state.tradebodies}
                                placeholderText={""}
                                Checkbox={false}
                                Action={(e) => {
                                  var MembershipWithTradeBodies = [];
                                  if (e)
                                    e.map((e) => {
                                      return MembershipWithTradeBodies.push(e.value);
                                    });
                                  this.setState({
                                    MembershipWithTradeBodies: MembershipWithTradeBodies
                                  })
                                  setFieldValue("MembershipWithTradeBodies", MembershipWithTradeBodies)
                                }}
                                errors={errors.MembershipWithTradeBodies}
                              />
                              <Col md="12" className=" pt-2">
                                <Label className="requiredField">List of product for display at event</Label>
                                {this.state.ListOfProductForDisplay && this.state.ListOfProductForDisplay.map((lpd, i) =>
                                  <div className="row">

                                    <div className="col-1 vertical-align">
                                      <label className="event-form  ml-3">{i + 1}</label>
                                    </div>
                                    <div className="col-10">
                                      <div className="form-group">
                                        <Field type="text" className="form-control border"
                                          value={lpd}
                                          onChange={(e) => {
                                            if (e.target.value) {
                                              var ListOfProductForDisplay = this.state.ListOfProductForDisplay;
                                              if (!ListOfProductForDisplay) {
                                                ListOfProductForDisplay = [];
                                              }
                                              ListOfProductForDisplay[i] = e.target.value;
                                              this.setState({
                                                ListOfProductForDisplay: ListOfProductForDisplay
                                              })
                                              setFieldValue("ListOfProductForDisplay", ListOfProductForDisplay)
                                            }
                                          }
                                          } />
                                      </div>
                                    </div>
                                    <div className="col-1">
                                      {i === (this.state.ListOfProductForDisplay.length - 1) ?
                                        <Button color="primary"
                                          onClick={() => {
                                            var ListOfProductForDisplay = this.state.ListOfProductForDisplay;
                                            ListOfProductForDisplay.push("");
                                            this.setState({
                                              ListOfProductForDisplay: ListOfProductForDisplay
                                            })
                                          }}>
                                          Add
                                        </Button>
                                        :
                                        <Button color="danger" style={{ background: "red" }}
                                          onClick={() => {
                                            var ListOfProductForDisplay = this.state.ListOfProductForDisplay;
                                            ListOfProductForDisplay.splice(i, 1);
                                            this.setState({
                                              ListOfProductForDisplay: ListOfProductForDisplay
                                            })
                                          }}>
                                          Remove
                                        </Button>
                                      }
                                    </div>
                                  </div>
                                )}
                                {errors.ListOfProductForDisplay && (
                                  <div className="invalid-feedback d-block">
                                    {errors.ListOfProductForDisplay}
                                  </div>
                                )}
                              </Col>
                              <Col lg="12">

                                <h5>Delegate Representative Details</h5>
                                <Row>
                                  <Col lg="6">
                                    <FormGroup className="position-relative">
                                      <Label className="requiredField">First Name </Label>
                                      <Field
                                        name="DelegateFirstName"
                                        id="DelegateFirstName"
                                        type="text"
                                        className="form-control border"
                                      />
                                      {errors.DelegateFirstName && (
                                        <div className="invalid-feedback d-block">
                                          {errors.DelegateFirstName}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">
                                    <FormGroup className="position-relative">
                                      <Label className="requiredField">Last Name </Label>
                                      <Field
                                        name="DelegateLastName"
                                        id="DelegateLastName"
                                        type="text"
                                        className="form-control border"
                                      />
                                      {errors.DelegateLastName && (
                                        <div className="invalid-feedback d-block">
                                          {errors.DelegateLastName}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col lg="6">



                                    <FormGroup className="position-relative">
                                      <Label className="requiredField">Designation </Label>
                                      <Field
                                        name="DelegateDesignation"
                                        id="DelegateDesignation"
                                        type="text"
                                        className="form-control border"
                                      />
                                      {errors.DelegateDesignation && (
                                        <div className="invalid-feedback d-block">
                                          {errors.DelegateDesignation}
                                        </div>
                                      )}

                                    </FormGroup>
                                  </Col>

                                  <Col lg="6">


                                    <FormGroup className="position-relative">
                                      <Label className="requiredField">Company Name </Label>
                                      <Field
                                        name="DelegateCompanyName"
                                        id="DelegateCompanyName"
                                        type="text"
                                        className="form-control border"
                                      />
                                      {errors.DelegateCompanyName && (
                                        <div className="invalid-feedback d-block">
                                          {errors.DelegateCompanyName}
                                        </div>
                                      )}
                                    </FormGroup>

                                  </Col>
                                  <Col lg="6">

                                    <FormGroup className="position-relative">
                                      <Label className="requiredField">Email ID </Label>

                                      <Field
                                        name="DelegateEmailID"
                                        id="DelegateEmailID"
                                        type="text"
                                        className="form-control border"
                                      />
                                      {errors.DelegateEmailID && (
                                        <div className="invalid-feedback d-block">
                                          {errors.DelegateEmailID}
                                        </div>
                                      )}
                                    </FormGroup>
                                  </Col>

                                  <Col lg="6">
                                    <FormGroup className="position-relative">
                                      <Label className="requiredField">Mobile Number </Label>
                                      <Field
                                        name="DelegateMobileNumber"
                                        id="DelegateMobileNumber"
                                        type="number"
                                        className="form-control border"
                                      />
                                      {errors.DelegateMobileNumber && (
                                        <div className="invalid-feedback d-block">
                                          {errors.DelegateMobileNumber}
                                        </div>
                                      )}

                                    </FormGroup>
                                  </Col>
                                  <Col lg={12}>
                                    <FormGroup className="position-relative">
                                      <Row>

                                        <label for="s1" className='ml-3 mr-3' >Is this an International Event?</label>
                                        <input type="radio" id="1" name="yesno" value={this.state.IsInternationalEvent} className=" m-1" onChange={(e) => {
                                          this.setState({
                                            IsInternationalEvent: true
                                          })
                                          setFieldValue("IsInternationalEvent", true)
                                        }
                                        } />
                                        <label for="s1" className='mr-3' >Yes</label>
                                        <input type="radio" id="1" name="yesno" value={this.state.IsInternationalEvent} className=" m-1" onChange={(e) => {
                                          this.setState({
                                            IsInternationalEvent: false
                                          })
                                          setFieldValue("IsInternationalEvent", false)
                                        }
                                        } />
                                        <label for="s1" >No</label>
                                      </Row>

                                    </FormGroup>
                                  </Col>

                                  {this.state.IsInternationalEvent &&
                                    <Row>
                                      <Col lg="7">
                                        <Col md="12 ">
                                          <FormGroup className="position-relative">
                                            <Label className="requiredField"> Passport</Label>
                                            <Field
                                              name="Passport"
                                              id="Passport"
                                              type="text"
                                              className="form-control border"
                                            />
                                            {errors.Passport && (
                                              <div className="invalid-feedback d-block">
                                                {errors.Passport}
                                              </div>
                                            )}
                                          </FormGroup>
                                        </Col>
                                        <Col md="12 ">
                                          <FormGroup className="position-relative">
                                            <Label className="requiredField"> Issue Date</Label>
                                            <Field
                                              name="IssueDate"
                                              id="IssueDate"
                                              type="text"
                                              className="form-control border"
                                            />
                                            {errors.IssueDate && (
                                              <div className="invalid-feedback d-block">
                                                {errors.IssueDate}
                                              </div>
                                            )}
                                          </FormGroup>
                                        </Col>
                                        <Col md="12 ">
                                          <FormGroup className="position-relative">
                                            <Label className="requiredField"> Expiry Date</Label>
                                            <Field
                                              name="ExpiryDate"
                                              id="ExpiryDate"
                                              type="text"
                                              className="form-control border"
                                            />
                                            {errors.ExpiryDate && (
                                              <div className="invalid-feedback d-block">
                                                {errors.ExpiryDate}
                                              </div>
                                            )}
                                          </FormGroup>
                                        </Col>

                                        <Col md="12 ">
                                          <FormGroup className="position-relative">
                                            <Label className="requiredField"> Place of Issue</Label>
                                            <Field
                                              name="PlaceofIssue"
                                              id="PlaceofIssue"
                                              type="text"
                                              className="form-control border"
                                            />
                                            {errors.PlaceofIssue && (
                                              <div className="invalid-feedback d-block">
                                                {errors.PlaceofIssue}
                                              </div>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </Col>

                                      <Col lg="5">
                                        <FormGroup className="position-relative">
                                          <Label className="requiredField">Attach proof- Upload</Label>
                                          <CustomInput type="file" name={"InternationalEventProof"}
                                            className="form-control"
                                            onChange={(event) => {
                                              var value = event.target.files[0];
                                              if (value) {
                                                if (!(value.size / 1024 <= 500)) {
                                                  toastr.warning(
                                                    "",
                                                    "File too large"
                                                  );
                                                  value = "";
                                                }
                                                else
                                                  if (!["doc", "docx", "pdf", "png", "jpg", "jpeg"].includes(
                                                    value.name.toLowerCase().split(".")[
                                                    value.name.toLowerCase().split(".").length - 1
                                                    ]
                                                  )) {
                                                    toastr.warning(
                                                      "",
                                                      "File format not allowed"
                                                    );
                                                    value = "";
                                                  }
                                              }
                                              this.setState({ InternationalEventProof: value })
                                              setFieldValue("InternationalEventProof", value)
                                            }} />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  }

                                  {/* <DropDown
                            className="react-select "
                            classNamePrefix="react-select"
                            label="Previous events attended, If any "
                            name="PreviousEvents"
                            colSplit={6}
                            MobcolSplit={12}
                            options={this.state.events.filter(
                              (e) =>
                                e.startdate <= currentday
                            )}
                            placeholderText={"Previous Events"}
                            Checkbox={false}
                            Action={(e) => this.setState({
                              pastevent: e.value
                            })}
                          /> */}
                                  {this.state.events &&
                                    <DropDown
                                      classNamePrefix="react-select"
                                      className="react-select filters"
                                      label="Whether you have participated in past events of FaMe TN"
                                      name="PreviousEvents"
                                      colSplit={12}
                                      isSearchable
                                      isMulti
                                      MobcolSplit={12}
                                      options={this.state.events.filter(
                                        (e) =>
                                          e.startdate <= currentday
                                      )}
                                      placeholderText={""}
                                      Checkbox={false}
                                      Action={(entity) => {
                                        var PreviousEvents = [];
                                        if (entity)
                                          entity.map((e) => {
                                            return PreviousEvents.push(e.value);
                                          });
                                        this.setState({
                                          PreviousEvents: PreviousEvents
                                        })
                                        setFieldValue("PreviousEvents", PreviousEvents)
                                      }}
                                      errors={errors.PreviousEvents}
                                    />
                                  }
                                </Row>
                              </Col>
                              <div style={{ width: "100%" }}>
                                <Button className='btn singleEvent  float-right' color="primary" type="submit">Submit</Button>
                              </div>
                            </Row>
                          </Form>)}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>


            <Modal size="lg" isOpen={this.state.viewModal} toggle={this.toggleViewModal}>
              <ModalHeader toggle={this.toggleViewModal}>
                View Details
              </ModalHeader>
              <ModalBody>
                {RowDetails &&
                  <Fragment>


                    <div class="alert alert-primary fade show" role="alert">
                      <center>Check Your Detail And Register the event </center>
                    </div>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>First Name</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.FirstName
                          : "-"}
                      </Colxx>
                    </Row>

                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Last Name</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.LastName
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Gender</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.Gender
                          : "-"}
                      </Colxx>
                    </Row>

                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Company Name</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.UnitName
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Website</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.Website
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>GST Number</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.GSTNo
                          : "-"}
                      </Colxx>
                    </Row>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Company Classification</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.CompanyClassification
                          : "-"}
                      </Colxx>
                    </Row>


                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Email</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.EmailID
                          : "-"}
                      </Colxx>
                    </Row>

                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Unit</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.UnitName
                          : "-"}
                      </Colxx>
                    </Row>

                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Incorporation Year</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {RowDetails
                          ? RowDetails.IncorporationYear
                          : "-"}
                      </Colxx>
                    </Row>
                  </Fragment>
                }
              </ModalBody>
              <ModalFooter>

                <FormGroup className="float-sm-right ">
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.RegisterModal(RowDetails)}
                  >
                    Register
             </Button>
                </FormGroup>

                <FormGroup className="float-sm-right ">
                  <Button
                    color="danger"
                    className="ml-2"
                    onClick={() => this.toggleViewModal()}
                  >
                    Close
             </Button>
                </FormGroup>


              </ModalFooter>
            </Modal>


          </section >

          <FooterSection />
        </div >
      </div >
    );
  }
}

export default EventApplication;