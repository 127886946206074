import React from 'react';
function Loader() {
    return (
        // <ContentLoader viewBox="0 0 380 70">
        //     <circle cx="30" cy="30" r="30" />
        //     <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
        //     <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        // </ContentLoader>
        <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
        </div>
    );
}
export default Loader