import React, { Component } from "react"
import { Container } from "reactstrap"
// import { Link } from "react-router-dom" 
import { getDateTimeString } from "../../helpers/Utils";
import queryString from "query-string";
//Import Breadcrumb
import { CallService } from "../../helpers/servicecall";
import { MethodType } from "../../constants/defaultValues";
import { events } from "../../constants/config";
import HeaderBreadcrumb from "../HeaderSection/HeaderBreadcrumb";
import FooterSection from "../FooterSection/Footer";

class PaymentRedirect extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    var param = queryString.parse(this.props.location.search);
    // var PaymentResponse = {
    //   Flag: param["Flag"],
    //   OrderId: param["OrderId"],
    //   CustomerId: param["CustomerId"],
    //   TransactionAmount: param["TransactionAmount"],
    //   CurrencyCode: param["CurrencyCode"],
    //   SurePayTxnId: param["SurePayTxnId"],
    //   PaymentMode: param["PaymentMode"],
    //   ResponseDateTime: param["ResponseDateTime"],
    //   BankTransactionNo: param["BankTransactionNo"],
    //   AdditionalInfo1: param["AdditionalInfo1"],
    //   AdditionalInfo2: param["AdditionalInfo2"],
    //   AdditionalInfo3: param["AdditionalInfo3"],
    //   AdditionalInfo4: param["AdditionalInfo4"],
    //   AdditionalInfo5: param["AdditionalInfo5"],
    // }
    var PaymentTransactionId = param["PaymentTransactionId"];
    this.setState(
      {
        // PaymentResponse: PaymentResponse,
        PaymentTransactionId: PaymentTransactionId
      }, () => this.SubmitPayment()
    );
  }
  SubmitPayment = () => {
    CallService(
      events.PaymentResponse,
      MethodType.POST,
      false,
      { PaymentTransactionId: this.state.PaymentTransactionId },
      "",
      this.SubmitPaymentResponse
    );
  };

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        PaymentResponse: data.result,
      });
    }
  };
  render() {
    var { PaymentResponse } = this.state;
    if (!PaymentResponse) PaymentResponse = {};
    return (
      <div>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <div className="bg-white " style={{ marginBottom: "100px" }}>
          <HeaderBreadcrumb />
          <section className="m-5 ">
            <Container>
              <div class="pmt_bg">
                <div class="pmt_card">

                  <span class="card__success">
                    <i class="fas fa-check"></i>
                  </span>

                  <h1 class="card__msg">{PaymentResponse.Status ? PaymentResponse.Status : "Payment Complete"}</h1>
                  <h2 class="card__submsg">Thank you for your interest on {PaymentResponse.Payment && PaymentResponse.Payment.EventUser && PaymentResponse.Payment.EventUser.Event ? PaymentResponse.Payment.EventUser.Event.name : "Event"}</h2>

                  <div class="card__body">

                    {/* <img src="http://nathgreen.co.uk/assets/img/nath.jpg" class="card__avatar" /> */}
                    <div class="card__recipient-info">
                      <p class="card__recipient">{PaymentResponse.Payment ? PaymentResponse.Payment.EventUser ? PaymentResponse.Payment.EventUser.FirstName : "Name" : "Name"}</p>
                      {/* <p class="card__email"> {PaymentResponse.user ? this.state.PaymentResponse.user.mail : "Mail"}</p> */}
                    </div>

                    <h1 class="card__price"><span>Rs. {PaymentResponse.Payment ? PaymentResponse.Payment.Amount : ""}</span></h1>

                    {/* <p class="card__method">Payment method</p>
                    <div class="card__payment">
                      <img src="https://seeklogo.com/images/V/VISA-logo-F3440F512B-seeklogo.com.png" class="card__credit-card" />
                      <div class="card__card-details">
                        <p class="card__card-type">Credit / debit card</p>
                        <p class="card__card-number">Visa ending in **89</p>
                      </div>
                    </div> */}

                  </div>

                  <div class="card__tags">
                    <span class="card__tag">{PaymentResponse.Status ? PaymentResponse.Status : ""}</span>
                    <span class="card__tag">#{PaymentResponse.TransactionReceiptNumber ? PaymentResponse.TransactionReceiptNumber : ""} </span>
                    <span class="card__tag">{PaymentResponse.TransactionDateTime ? getDateTimeString(PaymentResponse.TransactionDateTime) : ""} </span>
                  </div>

                </div>
              </div>
            </Container>
          </section>

        </div>
        <FooterSection />
      </div >
    )
  }
}

export default PaymentRedirect
