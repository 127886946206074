import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import { CallService } from "../../helpers/servicecall";
import { MethodType } from "../../constants/defaultValues";
import { entries } from "../../constants/config";
import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";

class Album extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10000,
      },
    };
  }
  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "album";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      // var gallery = data.result.map(r => {
      //   return (
      //     {
      //       src: r.thumbnail,
      //       width: 4,
      //       height: 3
      //     }
      //   )
      // })
      this.setState({
        content: data.result,
        loading: false,
      });
    }
  };
  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            <div className="container">
              <div className="row">
                {/* <Gallery photos={this.state.content} />; */}
                {this.state.content &&
                  this.state.content.map((c) => (
                    !c.groupof &&
                    <div className="col-lg-4 col-md-6">
                      <Link style={{ width: "100%" }} to={c.friendlyurl}>
                        <div className="single-works pointer">
                          <div className="banner-overlay bottom-align">
                            <a
                              href={c.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p>{c.name}</p>
                            </a>
                          </div>
                          <img
                            src={c.thumbnail}
                            alt="source"
                          />
                          <p className="icon">
                            <Icon.Eye />
                          </p>

                          <div className="works-content">
                          </div>
                        </div>
                      </Link>
                    </div>

                  ))}
              </div>
            </div>
            <div className="shape8 rotateme">
              <img src={"/img/shape4.svg"} alt="source" />
            </div>
            <div className="shape2 rotateme">
              <img src={"/img/shape2.svg"} alt="source" />
            </div>
            <div className="shape7">
              <img src={"/img/shape3.svg"} alt="source" />
            </div>
            <div className="shape4">
              <img src={"/img/shape3.svg"} alt="source" />
            </div>
          </section>
          <FooterSection />{" "}
        </div >
      );
  }
}

export default Album;
