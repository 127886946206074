import React, { Component, Fragment } from "react";

import {
  FormGroup, Col, Row, Button, Modal, ModalBody, ModalFooter, ModalHeader, Label, Card, CardBody
} from "reactstrap";

// Modal Video
// import images
import { Link } from "react-router-dom";
import { events } from "../../constants/config";
import { CallService } from "../../helpers/servicecall";
import { MethodType } from "../../constants/defaultValues";
import PaymentForm from "../../components/Events/PaymentForm";
import html2pdf from 'html2pdf.js'
import moment from 'moment'
import { Colxx } from "../../components/common/CustomBootstrap";
import { Formik, Form } from "formik";
import { getDateTimeString } from "../../helpers/Utils";

class EventsColumnOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    // this.GetContent();
  }

  unixdateconversion = (data) => {
    var dates = new Date(data * 1000);
    var s = new Date(dates).toLocaleDateString("en-US")
    var dat = s.split("/");
    return dat;
  };


  redirectTo = (path) => {
    this.props.history.push(path);
  };

  selectEvents = (sector) => {
    var selectedShow = sector.friendlyurl;
    if (selectedShow)
      this.redirectTo(`${selectedShow}`);
  };
  PaymentInitiate = () => {
    var eventDetails = this.props.eventDetails;

    // this.setState({
    //   PaymentModal: true
    // })
    if (eventDetails)
      CallService(
        events.PaymentInitialize + eventDetails._id,
        MethodType.GET,
        false,
        "",
        "",
        this.PaymentInitiateResponse
      );
  }

  PaymentInitiateResponse = (data) => {
    if (data.result) {
      this.setState({
        InitiateResponse: data.result,
        PaymentModal: true
      })
    }
  }
  SubmitPayment = () => {
    var event = this.props.show;


    // this.setState({
    //   PaymentModal: false
    // })
    if (event)
      CallService(
        events.GetAll,
        MethodType.POST,
        false,
        { eventId: event._id },
        "",
        this.PaymentInitiateResponse
      );
  }

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        InitiateResponse: data.result,
        PaymentModal: true
      })
    }
  }


  toggleViewModal = (row) => {
    this.setState({
      PDFDetails: row,
      viewModal: !this.state.viewModal,
    });
  };

  viewPdfModal = () => {
    this.setState({
      PDFDetails: this.props.eventDetails
    })
    this.togglepdfModal()
  }
  togglepdfModal = () => {
    this.setState({
      pdfModal: !this.state.pdfModal,
    });
  };


  async savePDF() {
    window.scrollTo(0, 0);


    var element = document.getElementById('downloadForm');
    var opt = {
      margin: [10, 10, 10, 10],
      pagebreak: { mode: ['css'] },
      filename: moment(new Date()).format('DDMMYYYYHHmmSS') + '.pdf',
      image: { type: 'jpeg', quality: 0.95 },
      html2canvas: {
        dpi: 96,
        scale: 4,
        letterRendering: true,
        useCORS: true
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      compress: true,
    };

    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
  }
  render() {
    const { show, eventDetails } = this.props;
    var month = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return (

      <div className="Events-success-stories clear">
        <div className="item" tabindex="-1" style={{ width: "100%", display: "inline-block", marginRight: "50px" }}>

          <Link

            className="title text-dark"
          >
            <div className="item-inner">
              <div className="img" onClick={() =>
                this.selectEvents(show)
              }>
                <a href={`${show.friendlyurl}`}>
                  <img src={show.image ? show.image : '/img/events.jpg'} alt={show.title} /></a>
              </div>
              <Row className="video-text" onClick={() =>
                this.selectEvents(show)
              }>
                <Col lg="2">
                  <p className="title">
                    <h6 style={{ color: '#3DCF8E', fontWeight: '700', textAlign: 'center', marginLeft: "-5px" }}>
                      {month[
                        new Date(
                          show.startdate * 1000
                        ).getMonth()
                      ]}
                    </h6>
                    <h3 style={{ fontWeight: '700', fontSize: '19px', marginTop: '10px', textAlign: 'center', marginLeft: "-5px" }}>
                      {this.unixdateconversion(show.startdate)[1]}
                    </h3>
                    <h3 style={{ color: '#8E9890', fontWeight: '700', fontSize: '14px', marginTop: '7px', textAlign: 'center', marginLeft: "-5px" }}>
                      {this.unixdateconversion(show.startdate)[2]}
                    </h3>
                  </p>
                </Col>
                <Col lg="10">
                  <a className="title" href={show.friendlyurl}
                    style={{ marginLeft: "-5px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: show.name
                      }} />
                  </a>
                </Col>
              </Row>
              {eventDetails &&
                <React.Fragment>
                  {eventDetails.Status === "Approved" && (eventDetails.Payment && eventDetails.Payment.Status !== "Completed") &&
                    <Row>
                      <Col lg="2" />
                      <Col lg="8">
                        <Button
                          color="primary"
                          className="font-16 btn-block"
                          onClick={this.PaymentInitiate}
                        >  Make Payment
                </Button></Col>
                      <Col lg="2" />
                    </Row>
                  }
                  <Row className="mt-2">
                    <Col lg="2" />
                    <Col lg="8">
                      <Button
                        color="success"
                        className="font-16 btn-block"
                        onClick={() => this.toggleViewModal()}
                      >  View Details
                </Button></Col>
                    <Col lg="2" />
                  </Row>
                </React.Fragment>
              }
            </div>
          </Link>
        </div>

        {this.state.InitiateResponse &&

          <Modal
            size="lg"
            className="viewDocumentModal"
            isOpen={this.state.PaymentModal}
            toggle={() => this.setState({
              PaymentModal: false
            })} >
            <div class="modal-header">
              <h5 class="modal-title">Payment Initiated</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({
                PaymentModal: false
              })}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <ModalBody className="m-3" >
              <Row className="align-items-center">
                <Col lg={1} />
                <Col lg={10} className="mt-sm-0 pt-sm-0">
                  <div class="pmt_bg">
                    <div class="pmt_card">

                      <span class="card__success">
                        <i class="fas fa-check"></i>
                      </span>

                      <h1 class="card__msg">{"Payment Initiated"}</h1>
                      <h2 class="card__submsg">Thank you for your interest on {eventDetails ? eventDetails.name : "Event"}</h2>
                      <div class="card__body">
                        <h1 class="card__price"><span>Rs. {this.state.InitiateResponse && this.state.InitiateResponse.pmtData && this.state.InitiateResponse.pmtData.transactionAmount}</span></h1>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <PaymentForm SubmitPayment={this.SubmitPayment} InitiateResponse={this.state.InitiateResponse} />
            </ModalBody>
          </Modal>


        }



        <Modal size="lg" isOpen={this.state.viewModal} toggle={this.toggleViewModal}>
          <ModalHeader toggle={this.toggleViewModal}>
            View Details
              </ModalHeader>
          <ModalBody>
            {eventDetails &&
              <Fragment>



                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Name</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.FirstName
                      : "-"}
                  </Colxx>
                </Row>





                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Phone Number</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.MobileNumber
                      : "-"}
                  </Colxx>
                </Row>

                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Gender</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.Gender
                      : "-"}
                  </Colxx>
                </Row>

                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Email ID</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.EmailID
                      : "-"}
                  </Colxx>
                </Row>



                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Name of the unit</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.UnitName
                      : "-"}
                  </Colxx>
                </Row>



                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Address</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.Address
                      : "-"}
                  </Colxx>
                </Row>


                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Company Classification</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.CompanyClassification
                      : "-"}
                  </Colxx>
                </Row>



                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Social Status Of Entrepreneur</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">

                    {eventDetails
                      ? eventDetails.SocialStatus
                      : "-"}
                  </Colxx>
                </Row>


                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>IEC Registration Number</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.IECRegistrationNumber
                      : "-"}
                  </Colxx>
                </Row>




                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>Website</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.Website
                      : "-"}
                  </Colxx>
                </Row>





                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label> Districts </Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.District && eventDetails.District.name
                      : "-"}
                  </Colxx>
                </Row>
                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label> Past Event</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.PreviousEvents && eventDetails.PreviousEvents.map(function (event) {
                        return event.name;
                      }).join(", ")

                      : "-"}
                  </Colxx>
                </Row>






                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>GST Registeration No</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.GSTNo
                      : "-"}
                  </Colxx>
                </Row>


                <Row className="mb-10">
                  <Colxx xxs="4">
                    <Label>UDAYAM Registeration No</Label>
                  </Colxx>
                  {": "}
                  <Colxx xxs="6">
                    {eventDetails
                      ? eventDetails.UDAYAMNo
                      : "-"}
                  </Colxx>
                </Row>
                {eventDetails && eventDetails.Payment
                  &&
                  <div>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Payment Status</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {eventDetails.Payment.Status ?
                          eventDetails.Payment.Status : "-"}
                      </Colxx>
                    </Row>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Payment Receipt Number</Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {eventDetails.Payment.ReceiptNumber ?
                          eventDetails.Payment.ReceiptNumber : "-"}
                      </Colxx>
                    </Row>
                    <Row className="mb-10">
                      <Colxx xxs="4">
                        <Label>Payment Completed On </Label>
                      </Colxx>
                      {": "}
                      <Colxx xxs="6">
                        {eventDetails.Payment.CompletedTime ?
                          getDateTimeString(eventDetails.Payment.CompletedTime) : "-"}
                      </Colxx>
                    </Row>
                  </div>}
              </Fragment>
            }
          </ModalBody>
          <ModalFooter>




            <FormGroup className="float-sm-right ">
              <Button
                color="success"
                onClick={() => this.viewPdfModal()}
              >
                Form
                  </Button>
              <Button
                className="ml-2"
                onClick={() => this.toggleViewModal()}
              >
                Close
             </Button>
            </FormGroup>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.pdfModal}
          toggle={this.togglepdfModal}
          size="lg"
        >
          <ModalHeader toggle={this.togglepdfModal}>
            Form View
              </ModalHeader>
          <Formik
            initialValues={{ RentedItems: "" }}
            //   validationSchema={ReturnValidation}
            onSubmit={this.handleReturnSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ errors, values, setFieldValue }) => (
              <Form className="av-tooltip tooltip-label-bottom">
                <ModalBody >
                  <Fragment>
                    <Card>

                      <CardBody id="downloadForm">
                        <Row>

                          <Colxx xxs="12" style={{ textAlign: "center" }}><span><h3>The FaMe TN (Erstwhile MSME Trade and Investment Promotion Bureau)</h3></span></Colxx>

                        </Row>
                        <Row>
                          <Colxx xxs="12" style={{ textAlign: "center" }} >
                            <span style={{ fontSize: "15px" }}>(An Autonomous agency of the Government of Tamil Nadu)</span>
                          </Colxx>

                        </Row>
                        <Row>
                          <Colxx xxs="12" style={{ textAlign: "center" }} >
                            <span><b>Regd Office: First Floor, SIDCO Corporate Office Building, Thiru Vi Ka Industrial Estate, Guindy, Chennai-32</b></span>
                          </Colxx>

                        </Row>
                        <Row>
                          <Colxx xxs="12" style={{ textAlign: "center" }} >
                            <span><b>Phone:29530112, 29530113,29530114;  e-mail: mdmtipb@gmail.com; </b></span>
                            <a href="mailto:mtipboffice@gmail.com">mtipboffice@gmail.com</a>
                          </Colxx>

                        </Row>

                        <hr></hr>

                        <Row>
                          <Colxx xxs="12" style={{ textAlign: "center" }} >
                            <span><h5>Export Promotion and Marketing Support Scheme</h5></span>
                          </Colxx>

                        </Row>
                        <Row>
                          <Colxx xxs="12" style={{ textAlign: "center" }} >
                            <span><h6>Event Name: {this.state.PDFDetails && this.state.PDFDetails.Event && this.state.PDFDetails.Event.name}</h6></span>
                          </Colxx>
                        </Row>
                        <Row>
                          <table className="table table-responsive-sm table-bordered" style={{ marginTop: "30px", marginRight: "15px", marginLeft: "15px" }}>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>Name of the unit</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.FirstName + " " + this.state.PDFDetails.LastName}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Address</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.Address}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>District</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.District && this.state.PDFDetails.District.name}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Telephone(L.L and Mobile)</td>
                                <td >{this.state.PDFDetails && this.state.PDFDetails.TelephoneNumber}</td>
                                <td >{this.state.PDFDetails && this.state.PDFDetails.MobileNumber}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>E-mail</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.EmailID}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Website</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.Website}</td>
                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>Classification of company</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.CompanyClassification}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>UDAYAM No</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.UDAYAMNo}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Social status of Entrepreneur</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.SocialStatus}</td>
                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td>  </td>
                                <td> </td>
                                <td>
                                </td>
                                <td>
                                </td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>Major products / activities</td>
                                <td colspan="2">{ }</td>
                              </tr>
                              <tr>
                                <td>4</td>
                                <td>IEC registration No, if regd.</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.IECRegistrationNumber}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>HS Code (Exports)</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.HSCode}</td>
                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td> </td>
                                <td>  </td>
                                <td> </td>
                                <td> </td>
                              </tr>
                              <tr>
                                <td>5</td>
                                <td>Membership with trade bodies</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.MembershipWithTradeBodies.length > 0 && this.state.PDFDetails.MembershipWithTradeBodies[0].name}</td>
                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td></td>
                                <td> </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>6(a)</td>
                                <td>Turnover – Last 3 years</td>
                                <td>Domestic (Rs in lakh)</td>
                                <td>Overseas (Rs in lakh)</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>2017-18</td>
                                <td>{this.state.PDFDetails && this.state.PDFDetails.TurnOvers.length > 0 && this.state.PDFDetails.TurnOvers[0].DomesticAmountInLakh}</td>
                                <td>{this.state.PDFDetails && this.state.PDFDetails.TurnOvers.length > 0 && this.state.PDFDetails.TurnOvers[0].OverseasAmountInLakh}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>2018-19</td>
                                <td>{this.state.PDFDetails && this.state.PDFDetails.TurnOvers.length > 1 && this.state.PDFDetails.TurnOvers[1].DomesticAmountInLakh}</td>
                                <td>{this.state.PDFDetails && this.state.PDFDetails.TurnOvers.length > 1 && this.state.PDFDetails.TurnOvers[1].OverseasAmountInLakh}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>2019-20</td>
                                <td>{this.state.PDFDetails && this.state.PDFDetails.TurnOvers.length > 2 && this.state.PDFDetails.TurnOvers[2].DomesticAmountInLakh}</td>
                                <td>{this.state.PDFDetails && this.state.PDFDetails.TurnOvers.length > 2 && this.state.PDFDetails.TurnOvers[2].OverseasAmountInLakh}</td>
                              </tr>
                              <tr>
                                <td>(b)</td>
                                <td>No of employees</td>
                                <td>{this.state.PDFDetails && this.state.PDFDetails.NoOfCurrentEmployees}</td>
                                <td>Year of Incorporation: {this.state.PDFDetails && this.state.PDFDetails.IncorporationYear}</td>

                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td></td>
                                <td> </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>7</td>
                                <td>List of items (Product / Brochure) for display  </td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.ListOfProductForDisplay.map(o => o).join(",")}</td>
                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td></td>
                                <td> </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>8</td>
                                <td>Specific requirements, if any</td>
                                <td >B2B meeting
                                Participation in Seminars
                                If others, specify
</td>
                                <td></td>
                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td></td>
                                <td> </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>9</td>
                                <td>Major Industry group /
                                Product line in the Exhibition
</td>
                                <td>Industry category may be provided in drop down</td>

                                <td>{this.state.PDFDetails && this.state.PDFDetails.MajorIndustriesList.name}</td>
                              </tr>
                              <tr style={{ backgroundColor: "#57c157" }}>
                                <td></td>
                                <td> </td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>10</td>
                                <td>Passport details</td>
                                <td>Only for International Events</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>11</td>
                                <td>Whether you have participated in past
                                events of FaMe TN
</td>
                                <td colspan="2">{this.state.PDFDetails && this.state.PDFDetails.PreviousEvents.length > 0 && this.state.PDFDetails.PreviousEvents[0].name}</td>
                              </tr>
                            </tbody>
                          </table>
                        </Row>

                      </CardBody>
                    </Card>



                  </Fragment>
                </ModalBody>

                <ModalFooter>
                  <FormGroup className="float-sm-right ">

                    <Button

                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        this.savePDF();

                      }}
                    >
                      Download
                </Button>{" "}

                    <Button
                      color="danger"
                      className="ml-2"
                      onClick={() => this.togglepdfModal()}
                    >
                      Close
                        </Button>
                  </FormGroup>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>

    );
  }
}


export default EventsColumnOne;
