import React, { Component } from "react";

import FooterSection from "../../components/FooterSection/Footer";
//import FeatureSection from "../../components/Features/FeatureOne";
//import ServiceSection from "../../components/ServiceSection/ServiceOne";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import SectorOL from "./SectorOL"
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";

class SectorProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
      isOpenImage: false,
      contents: [],
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListRequestModelSector: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
    };
  }
  redirectTo = (path) => {
    this.props.history.push(path);
  };
  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "sector";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }
  GetContentResponse = (data) => {
    if (data.result) {
      this.setState(
        {
          sectors: data.result,
        },
        () => {
          var sectorName = this.props.location ? this.props.location.pathname ? this.props.location.pathname.replace("/sector-profile/", "") : "" : "";
          var sector = sectorName !== "/sector-profile" ? this.state.sectors.find(r => r.shortcode === sectorName) : this.state.sectors[0];
          this.selectSector(sector && sector._id);
        }
      );
    }
  };
  selectSector = (sector) => {
    var selectedSector = this.state.sectors.find((s) => s._id === sector);
    this.GetDistrictSectors(sector);
    this.GetSectorContent(sector);
    this.setState({
      selectedSector: selectedSector,
    });
  };
  GetDistrictSectors(sector) {
    const { ListRequestModelSector } = this.state;
    ListRequestModelSector.TableName = "districtsectors";
    ListRequestModelSector.sector = sector;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelSector,
      "",
      this.GetDistrictSectorsResponse
    );
  }

  GetDistrictSectorsResponse = (data) => {
    if (data.result) {
      var districts = [];

      data.result
        .map((d) => d.district)
        .map((r) => {
          if (districts.findIndex((d) => d._id === r._id) === -1)
            districts.push(r);
          return "";
        });
      // window.scrollTo({
      //   top: 1000,
      //   behavior: 'smooth',
      // })
      this.setState({
        isLoading: false,
        DistrictSectors: data.result,
        selectedDistricts: districts,
      });
    }
  };

  GetSectorContent(sector) {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      { TableName: "sectordetails", sector: sector },
      "",
      this.GetSectorContentResponse
    );
  }
  GetSectorContentResponse = (data) => {
    if (data.result) {
      this.setState({
        details: data.result,
        loading: false,

      });
    }
  };
  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            {/* <Section
              selectSector={this.selectSector}
            /> */}
            {this.state.selectedSector && (
              <SectorOL
                selectedDistricts={this.state.selectedDistricts}
                selectedSector={this.state.selectedSector}
                details={this.state.details}
              />
            )}
          </section>
          <FooterSection />
        </div>
      );
  }
}

export default SectorProfile;
