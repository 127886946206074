import React, { Component, Fragment } from "react";
import { CallService } from "../../helpers/servicecall";
import { mail } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";


class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.myForm = React.createRef();
  }

  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1,
    },
  };

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  submitHangler = (event) => {
    event.preventDefault();
    this.SendMail(this.state);

  };

  SendMail(state) {
    var data = { From: state.email, Subject: state.subject, Body: state.message }
    CallService(
      mail.Send,
      MethodType.POST,
      false,
      data,
      "",
      this.SendMailResponse
    );
  }

  SendMailResponse = (data) => {
    if (data.result) {
      this.myForm.current.reset();
      this.setState({
        name: "",
        email: "",
        subject: "",
        message: "",
        mailSent: true
      });
    }
    setTimeout(() => {
      this.setState({
        mailSent: false
      });
    }, 2000);
  };

  Submit() {
    const { name, subject, message } = this.state;
    const data = {
      From: name,
      Subject: subject,
      Body: message
    }

    CallService(
      mail.Register,
      MethodType.POST,
      false,
      data,
      "",
      this.GetEventResponse
    );

  }

  GetEventResponse = (data) => {
    if (data.result) {
      alert('Thank you for reaching out. We will Contact you soon ;)')
    };
  };
  // componentDidMount() {
  //   this.GetContent();
  // }

  // GetContent() {
  //   const { ListRequestModel } = this.state;
  //   ListRequestModel.TableName = "about";
  //   CallService(
  //     entries.GetAll,
  //     MethodType.POST,
  //     false,
  //     ListRequestModel,
  //     "",
  //     this.GetContentResponse
  //   );
  // }

  // GetContentResponse = (data) => {
  //   if (data.result) {
  //     this.setState({
  //       content: data.result[0],
  //       loading: false,
  //     });
  //   }
  // };

  render() {
    return (
      <Fragment >
        {/* Welcome Intro Start */}
        <div className="contact-box  contact-box-transition text-center border rounded p-4">
          <form
            ref={this.myForm}
            onSubmit={this.submitHangler}
            className="contact-form"
            noValidate="novalidate"
          >
            {this.state.mailSent ? "Mail sent successfully" : ""}
            <div className="row">
              <div className="col-12">
                <h4> Contact Us</h4>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.name}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Email"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.email}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.subject}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.message}
                  />
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn singleEvent font-weight-bold"
                  onClick={() => this.Submit()}>

                  Send Message
              </button>
              </div>
            </div>
          </form>
        </div>

      </Fragment>


    );
  }
}

export default ContactForm;
