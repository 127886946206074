import React from 'react';
import ScrollUpButton from "react-scroll-up-button";
import FeatherIcon from 'feather-icons-react';
// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'
import "./assets/scss/theme.scss";
import ReactGA from 'react-ga';
import { NotificationContainer } from './components/common/react-notifications';
const CustomDot = () => {
  return (
    <i><FeatherIcon icon="arrow-up" className="icons" /></i>
  );
};
function initializeReactGA() {
  ReactGA.initialize('G-267431558');
  ReactGA.pageview(window.location.pathname + window.location.search);
}
function App() {
  initializeReactGA();
  return (
    <div>
      <NotificationContainer />
      <MyRouts />
      <ScrollUpButton ContainerClassName="classForContainer" style={{ height: 36, width: 36, marginBottom: "2%" }} TransitionClassName="classForTransition"
        StopPosition={0}
        ShowAtPosition={150}
        EasingType='easeOutCubic'
        AnimationDuration={500}
        ToggledStyle={{}}
      >
        <CustomDot />
      </ScrollUpButton>
    </div>
  );
}

export default App;
