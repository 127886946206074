import React, { Fragment } from "react";
import FooterSection from "../components/FooterSection/Footer";
import { CallService } from "../helpers/servicecall";
import { entries } from "../constants/config";
import { MethodType } from "../constants/defaultValues";
import { Col, Container, Row } from "reactstrap";
import Loader from "../common/loader";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import HeaderBreadcrumb from "../components/HeaderSection/HeaderBreadcrumb";

class MsfecSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    this.GetContent();
    this.GetGroup();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "msfec";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
        loading: false,
      });
    }
  };



  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "msefcsteps";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
      });
  };



  render() {
    const { content } = this.state;
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            <Container>
              <Row className="align-items-center">
                <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <h4 className=" ml-lg-4">{content.title}</h4>
                  <div className="ml-lg-4 mt-3">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.about,
                        }}
                      ></div>
                    </p>
                  </div>
                  <div className="mt-3">
                    <h4 className=" ml-lg-4">Salient Features</h4>
                    {content.features && content.features.map(f => {
                      return (<Fragment>
                        <Row className="mt-2">
                          <Col lg={1} />
                          <Col lg={10} xxs={12} className="border border-radius" style={{ textAlign: "justify" }}>
                            <img src="/img/check.svg" alt="check" />
                            <span className="ml-3">{f.content}</span>
                          </Col>
                          <Col lg={1} />
                        </Row>
                      </Fragment>)
                    })}
                  </div>
                  <div className="mt-3">
                    <h4 className=" ml-lg-4">Implementation</h4>
                    <div className=" ml-lg-4 mt-3">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.implementation,
                          }}
                        ></div>
                      </p>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="ml-lg-4 mt-3">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.councildescription,
                          }}
                        ></div>
                      </p>
                    </div>
                    <Row>
                      {content.council && content.council.map(c => {
                        return (<div className="col-12 col-lg-3 mt-2">
                          <ul className=" contact-us border border-gray mb-4 contact-box  contact-box-transition p-4 " style={{ minHeight: "100px" }}>

                            <div>
                              {c.designation && <h4 className="mb-3 ml-3">{c.designation}</h4>}

                              <li className="font-weight-normal contact-us">
                                {c.name &&
                                  <div className="row">
                                    <div className="social-icon mr-3 col-1">
                                      <i className={"fas fa-user"} />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {c.name}
                                    </span></div>
                                }
                                {c.address &&
                                  <div className="row">
                                    <div className="social-icon mr-3 col-1">
                                      <i className={"fas fa-paper-plane"} />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {c.address}
                                    </span></div>
                                }
                                {c.number &&
                                  <div className="row">
                                    <div className="social-icon mr-3 col-1">
                                      <i className={"fas fa-phone-square-alt"} />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {c.number}
                                    </span></div>
                                }
                                {c.mobilenumber &&
                                  <div className="row">
                                    <div className="social-icon mr-3 col-1">
                                      <i className={"fas fa-mobile-alt"} />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {c.mobilenumber}
                                    </span></div>
                                }
                                {c.mailid &&
                                  <div className="row">
                                    <div className="social-icon mr-3 col-1">
                                      <i className={"fas fa-envelope"} />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {c.mailid}
                                    </span></div>
                                }
                                {c.website &&
                                  <div className="row">
                                    <div className="social-icon mr-3 col-1">
                                      <i className={"fas fa-globe"} />
                                    </div>
                                    <span className="media-body align-self-center col-10">
                                      {c.website}
                                    </span></div>
                                }
                              </li>
                            </div>
                          </ul>
                        </div>
                        )
                      })}
                    </Row>

                  </div>
                  <div className="mt-3">
                    <h4 className=" ml-lg-4">Who can Apply?</h4>
                    <div className=" ml-lg-4 mt-3">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.whocanapply,
                          }}
                        ></div>
                      </p>
                      <a href={content.applylink} target="_blank" rel="noopener noreferrer">
                        <button className="btn singleEvent  mt-2 mb-3"
                          style={{ width: '165px' }}>
                          Click Here to Apply
                                  </button>
                      </a>
                    </div>
                    <div className="mt-3">
                      <h4 className=" ml-lg-4">Steps to apply for MSEFC</h4>
                      <VerticalTimeline className="mb-4 mt-4">
                        {this.state.groups &&
                          this.state.groups.map((item, idx) => {
                            return (
                              <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', textAlign: "justify" }}
                                contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                // date={idx + 1}
                                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                icon={<img src={item.image} alt={idx} className={"timeline-icon"} />}
                              >
                                <h4 className="vertical-timeline-element-title">{item.title}</h4>
                                {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                                {item.content && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.content,
                                    }}
                                  />
                                )}
                              </VerticalTimelineElement>
                            )
                          })}
                      </VerticalTimeline>
                    </div>   <div className="mb-4 border key feature p-2" style={{ textAlign: 'left' }}>
                      <h4>Notes:</h4>
                      <p >  Each file size should not be more than 1MB. Maximum three work orders and three invoices can be uploaded.  </p>

                    </div>




                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <FooterSection />
        </div>
      );
  }
}

export default MsfecSection;

