export const checkIfImage = (url) => {
    if (url) {
        if (typeof url === "string") {
            var value = url.split(".");
            var length = value.length;
            if (
                value[length - 1].toLowerCase().includes("png") ||
                value[length - 1].toLowerCase().includes("jpg") ||
                value[length - 1].toLowerCase().includes("jpeg") ||
                value[length - 1].toLowerCase().includes("svg")
            )
                return true;
            else return false;
        } else return false;
    } else return false;
};
export const DateFormat = (date) => {
    if (date) {
        let regex = /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/;
        if (!date.match(regex)) {
            return ""
        }
        else {
            var dateParts = date.split("/");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            return (dateObject.getTime() / 1000)
        }
    }
}
export const AddressFormat = (Address) => {
    var parser = new DOMParser();
    return parser.parseFromString(
        (Address.AddressLine1
            ? Address
                .AddressLine1
            : "") +
        (Address
            .AddressLine2
            ? ", " + Address
                .AddressLine2
            : "") +
        (Address
            .AddressLine3
            ? ", " + Address
                .AddressLine3
            : "") +
        (Address
            .TalukItem
            ? ", " + Address
                .TalukItem.Name
            : Address
                .OtherTaluk
                ? ", " + Address
                    .OtherTaluk
                : "") +
        (Address
            .DistrictItem
            ? ", " + Address
                .DistrictItem.Name
            : Address
                .OtherDistrict
                ? ", " + Address
                    .OtherDistrict
                : "") +
        (Address
            .StateItem
            ? ", " + Address
                .StateItem.Name
            : Address
                .DistrictItem
                ? `, Tamil Nadu`
                : "") +
        (Address
            .Pincode ? ", " + Address.Pincode : ""),
        "text/html"
    ).body.textContent
}

export const getDateTimeString = (d, time) => {
    try {
        var dateObj = new Date(parseInt(d * 1000));
        var month = padValue(dateObj.getMonth() + 1);
        var day = padValue(dateObj.getDate());
        var year = padValue(dateObj.getFullYear());
        var hours = padValue(dateObj.getHours());
        var minutes = padValue(dateObj.getMinutes());
        var seconds = padValue(dateObj.getSeconds());
        var AMPM = "AM";

        var iHourCheck = parseInt(hours);

        if (iHourCheck > 12) {
            AMPM = "PM";
            hours = padValue(iHourCheck - 12);
        }
        else if (iHourCheck === 0) {
            hours = "00";
        }

        var newdate = "";
        if (time)
            newdate = day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds + " " + AMPM;
        else
            newdate = day + "/" + month + "/" + year;
        return newdate
    }
    catch {
        return d;
    }
}
function padValue(value) {
    return (value < 10) ? "0" + value : value;
}
export const getDayInEnglish = date => {
    var Date = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ];
    for (var i = 0; i < Date.length; i++) {
        if (date === i) {
            return Date[i];
        }
    }
};