import React, { Component } from "react";
// import { Button, Row } from "reactstrap";
import { Payment_URL } from "../../constants/config";
class PaymentForm extends Component {
  state = {
    loading: true,
    initData: [],
  };
  componentDidMount() {
    this.setState({
      loading: false,
    });
  }
  render() {
    // var currentday = new Date() / 1000;
    var { InitiateResponse } = this.props;
    if (!InitiateResponse) {
      InitiateResponse = {};
    }
    var pmtData = InitiateResponse.pmtData;
    if (!pmtData) {
      pmtData = {};
    }
    return (
      <div>
        <form id="frmdata" role="Form" action={InitiateResponse.pmtInitiateUrl ? InitiateResponse.pmtInitiateUrl : Payment_URL} method="post">
          <input
            type="hidden"
            id="checksum"
            name="checksum"
            value={InitiateResponse.checksumValue}
          />
          <input
            type="hidden"
            id="messageType"
            className="form-control valid"
            name="messageType"
            value={pmtData.messageType}
            maxLength="5"
          />

          <input
            type="hidden"
            id="merchantId"
            className="form-control valid"
            name="merchantId"
            value={pmtData.merchantID}
            maxLength="25"
          />
          <input
            type="hidden"
            id="serviceId"
            className="form-control valid"
            name="serviceId"
            value={pmtData.serviceID}
            maxLength="25"
          />
          <input
            type="hidden"
            id="orderId"
            className="form-control valid"
            name="orderId"
            value={pmtData.orderId}
            maxLength="50"
          />
          <input
            type="hidden"
            id="customerId"
            className="form-control valid"
            name="customerId"
            value={pmtData.customerId}
            maxLength="25"
          />
          <input
            type="hidden"
            id="transactionAmount"
            className="form-control valid"
            name="transactionAmount"
            value={pmtData.transactionAmount}
            maxLength="7"
          />

          <input
            type="hidden"
            id="currencyCode"
            className="form-control"
            name="currencyCode"
            value={pmtData.currencyCode}
          />

          <input
            type="hidden"
            id="requestDateTime"
            className="form-control hasDatepicker"
            name="requestDateTime"
            value={pmtData.requestDateTime}
          />

          <input
            type="hidden"
            id="successUrl"
            className="form-control "
            name="successUrl"
            value={pmtData.successUrl}
            maxLength="100"
          />
          <input
            type="hidden"
            id="failUrl"
            className="form-control "
            name="failUrl"
            value={pmtData.failUrl}
            maxLength="100"
          />

          <input
            type="hidden"
            id="additionalField1"
            className="form-control valid"
            name="additionalField1"
            value={pmtData.additionalField1}
            maxLength="500"
          />
          <input
            type="hidden"
            id="additionalField2"
            className="form-control valid"
            name="additionalField2"
            value={pmtData.additionalField2}
            maxLength="500"
          />
          <input
            type="hidden"
            id="additionalField3"
            className="form-control valid"
            name="additionalField3"
            value={pmtData.additionalField3}
            maxLength="3999"
          />
          <input
            type="hidden"
            id="additionalField4"
            className="form-control valid"
            name="additionalField4"
            value={pmtData.additionalField4}
            maxLength="255"
          />
          <input
            type="hidden"
            id="additionalField5"
            className="form-control valid"
            name="additionalField5"
            value={pmtData.additionalField5}
            maxLength="255"
          />
          <button
            type="submit"
            className="btn btn-primary btn-mg float-right"
            id="submitbtn"
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default PaymentForm;
