/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Fragment } from "react";
import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";
import FooterSection from "../../components/FooterSection/Footer";

// Modal Video
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import TextTruncate from "../../common/TextTruncate";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
import { Helmet } from "react-helmet";
import MetaTags from 'react-meta-tags';
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

class AboutUS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: "Landrick", link: "/index" },
        { id: 2, name: "Page", link: "#" },
        { id: 3, name: "About Us" },
      ],
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
      },
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListGroupContentRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetContent();
    this.GetGroup();
    this.GetGroupContent();
  }

  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "aboutgroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {

    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
        isVisblecontent: data.result[0],
      });
  };

  GetGroupContent() {
    const { ListGroupContentRequestModel } = this.state;
    ListGroupContentRequestModel.TableName = "aboutfunctions";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupContentRequestModel,
      "",
      this.GetGroupContentResponse
    );
  }

  GetGroupContentResponse = (data) => {
    if (data.result) {
      this.setState({
        functions: data.result,
      });
    }
  };

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "about";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
        loading: false,
      });
    }
  };

  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };

  render() {
    const { content } = this.state;
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return this.state.loading ? (
      <Loader />
    ) : (
        <div>
          <ReactSEOMetaTags
      website={{ siteMetadata :""}}
      breadcrumb={[
        { name: 'FaMe TN', url: 'https://fametn.com/' },
        { name: 'About', url: 'https://fametn/about' },
      ]}
      organization={{
        name: 'FaMe TN',
        legalName: 'FaMe TN',
        url: 'https://fametn.com/',
        
      }}
    />
          <MetaTags>
            <title>About</title>
            <meta name="description" content="FaMe TN (Erstwhile MSME Trade and Investment Promotion Bureau) | About" />
            <meta property="og:title" content="FaMe TN (Erstwhile MSME Trade and Investment Promotion Bureau) | About" />            
          </MetaTags>
          <Helmet>    
            <meta name="title" content="FaMe TN (Erstwhile MSME Trade and Investment Promotion Bureau) - About"/>
            <meta name="description" content="The FaMe TN (Erstwhile MSME Trade and Investment Promotion Bureau) Department was established in September 2019 with the intent to promote trade, investments and exports in the MSME sector in Tamil Nadu...."/>
            
<meta name="keywords" content="m-tipb, msme, msme chennai"/>
<meta name="robots" content="index, follow"/>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            <meta name="language" content="English"/>
           {/* <meta
      name="google-site-verification"
      content="8djcUCeZSWbP-AHxnOINKTDI_7_6MaSIzgsb9eKvURE"
           />*/}
<meta name="google-site-verification" content="goz-Wx3puDrMSexeYDQkJ3kS9vVWSqAOaaHZZDtFFHg" />

            
          </Helmet>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {content && (
          <div className="bg-white">
            <HeaderBreadcrumb />
            <section className="m-5 ">
              <Container>
                <Row>
                  <Col lg={5} xxs={12} >
                    <div className="section-title ml-lg-4">
                      <p>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.section,
                          }}
                        ></div>
                      </p>
                      <video width="100%" height="100%" controls src={this.state.content && this.state.content.video && this.state.content.video.file + "#t=0.7"}>
                        {/* <source  type="video/mp4" /> */}
                      </video>
                    </div>

                  </Col>
                  <Col lg={1} />
                  <Col lg={5} xxs={12} className="">  <div
                    dangerouslySetInnerHTML={{
                      __html: content.description,
                    }}
                  ></div>
                  </Col>
                </Row>
                {content.image &&
                  <img src={content.image}
                    className="rounded img-fluid mx-auto d-block mt-5"
                    alt=""
                  />
                }
                <Row>
                  <h3 className="mt-5 ml-2">
                    Broad Functions
                    </h3>
                </Row>
                <Row className="mb-5">
                  {this.state.groups &&
                    this.state.groups.map((group) => {
                      return (<div className="container mb-2"  >
                        <h4 class="row ml-4 mt-5 text-primary vertical-align" >
                          <img
                            src={(this.state.content && this.state.content.functions.filter(g => g.group === group._id).length > 0 && group.background) ? (this.state.content && this.state.content.functions.filter(g => g.group === group._id).length > 0 && group.background) : "/img/check.svg"}
                            className="avatar avatar-md-sm mr-1"
                            alt="group"
                            width={50}
                          />
                          {this.state.content && this.state.content.functions.filter(g => g.group === group._id).length > 0 && group.name}
                        </h4>
                        <div className="row m-1" >
                          {this.state.content &&
                            this.state.content.functions.filter(g => g.group === group._id) &&
                            this.state.content.functions.filter(g => g.group === group._id).map((obj, i) => {
                              return (
                                <Col key={obj._id} lg={(i % 2 === 0) ? 6 : 6} xxs={12} className="mt-3">
                                  <div className="border key-feature " style={{}}>
                                    <Row>

                                      {(!isMobile) &&
                                        <Col lg={1} xxs={1}>
                                          <img src="/img/check.svg" alt="check" /></Col>
                                      }
                                      <Col lg={10} xxs={10}>
                                        <h5 className="ml-2">
                                          <TextTruncate value={obj.title ? obj.title : obj.name} length={100} title={group.name} />
                                        </h5>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              );
                            })}
                        </div>
                      </div>)
                    })
                  }
                </Row>
              </Container>
            </section>

          </div>
        )
        }{" "}
        <FooterSection />
      </div >
    );
  }
}

export default AboutUS;
