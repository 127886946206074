import React, { Component } from 'react';
import {
    Col, Row,
} from "reactstrap";
import OwlCarousel from "react-owl-carousel3";

import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";
import { Link } from "react-router-dom";


const slideOptions = {
    loop: false,
    nav: false,
    autoplay: true,
    dots: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    items: 3,
    animateOut: "fadeOut",
};
class HomeEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            ListRequestModel: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000000,
            },
        };
    }
    componentDidMount() {
        this.GetContent();
    }
    GetContent() {
        const { ListRequestModel } = this.state;
        ListRequestModel.TableName = "event";
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModel,
            "",
            this.GetContentResponse
        );
    }

    GetContentResponse = (data) => {
        if (data.result) {
            this.setState({
                events: data.result,
            }
            );
        }
    };


    unixdateconversion = (data) => {
        var dates = new Date(data * 1000);
        var s = new Date(dates).toLocaleDateString("en-US")
        var dat = s.split("/");
        return dat;
    };


    redirectTo = (path) => {
        this.props.history.push(path);
    };

    selectEvents = (sector) => {
        var selectedShow = sector.friendlyurl;
        if (selectedShow)
            this.redirectTo(`${selectedShow}`);
    };
    render() {
        var currentday =
            new Date() /
            1000;

        var month = [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL",
            "AUG",
            "SEP",
            "OCT",
            "NOV",
            "DEC",
        ];

        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);



        return (
            <section className=" event-area mt-5 mb-5">
                <div className="container text-center">
                    <div className="section-title st-fs-28">
                        <h2 className={isMobile ? "mb-2" : ""}>Upcoming Events</h2>
                        {this.state.events.filter(
                            (e) =>
                                (e.startdate >= currentday || e.enddate >= currentday)
                        ).length === 0 && <p>
                                No Upcoming Events
            </p>}
                    </div>
                    <div className="row justify-content-center align-items-center" >
                        <div className="col-12 col-md-10 col-lg-11">

                            <OwlCarousel
                                // startPosition={2}
                                // className="section h-100vh bg-overlay d-flex align-items-center"
                                className="events owl-carousel"
                                {...slideOptions}
                            >
                                {/* Single event */}

                                {this.state.events.filter(
                                    (e) =>
                                        (e.startdate >= currentday || e.enddate >= currentday)
                                ).map((show, i) => (

                                    <Link
                                        onClick={() =>
                                            this.selectEvents(show)
                                        }
                                        className="title text-dark"
                                        key={i}
                                    >
                                        <div className="Events-success-stories clear">
                                            <div className="item" tabIndex="-1" style={{ width: "100%", display: "inline-block" }}>
                                                <div className="item-inner">
                                                    <div className="img">
                                                        <a href={`${show.friendlyurl}`}>
                                                            <img src={show.image ? show.image : ''} alt={show.title} /></a>
                                                        {/* <span class="playBtn"></span> */}
                                                        {/* <a href={`${item.friendlyurl}`}>
                                    <div class="img-logo">
                                        <h4>{item.title}</h4>
                                        <img src={item.image} alt={item.title} />
                                    </div>
                                </a> */}
                                                    </div>
                                                    <Row className="video-text" >
                                                        <Col lg="2">

                                                            <a className="month font-weight-bold" href="/">

                                                                <h6 style={{ color: '#3DCF8E', fontWeight: '700' }}>
                                                                    {month[
                                                                        new Date(
                                                                            show.startdate * 1000
                                                                        ).getMonth()
                                                                    ]}
                                                                </h6>
                                                                <h3 style={{ fontWeight: '700', fontSize: '19px', marginTop: '10px' }}>
                                                                    {this.unixdateconversion(show.startdate)[1]}
                                                                </h3>
                                                                <h3 style={{ color: '#8E9890', fontWeight: '700', fontSize: '14px', marginTop: '7px', textAlign: 'center', marginLeft: "-5px" }}>
                                                                    {this.unixdateconversion(show.startdate)[2]}
                                                                </h3>
                                                            </a>
                                                        </Col>

                                                        <Col lg="10">
                                                            <a className="title" href={show.friendlyurl}>
                                                                <div className={isMobile ? " ml-5" : ""}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: show.name
                                                                    }} />

                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                )
                                )}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HomeEvents;