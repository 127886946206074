import React from "react";
import Header from "../../components/HeaderSection/Header";
import FooterSection from "../../components/FooterSection/Footer";
import Breadcrumb from "../../components/Blogs/Breadcrumb";

class YellowPageForm extends React.Component {
  state = {
    contents: [],
    loading: true,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    formFields: {
      DistritName: "",
      CompanyName: "",
      Address: "",
      MobileNo: "",
      Website: "",
      GSTNo: "",
      Category: "",
      RegistrationNo: "",
      Sector: "",
      Service: "",
      Product: "",
      Capacity: "",
      ExportDetails: "",
      Year: "",
      Countries: "",
      Certification: "",

      name: "",
      email: "",
      phone: "",
      subject: "",
    },
  };

  render() {
    return (
      <div>
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        <div className="main epc">
          <Header imageData={"/img/logo-white.png"} />
          <Breadcrumb title="Form" />
          <section className="contact-area ptb-80">
            <div className="container">
              <div className="row h-100 justify-content-center align-items-center">
                <div className="col-lg-6 col-md-12">
                  <img src={"/img/about-one.png"} alt="source" />
                </div>

                <div className="col-lg-6 col-md-12">
                  <form id="contactForm" onSubmit={this.onSubmit}>
                    <div className="form-group">
                      <div class="select-location dropdown">
                        <select
                          class="form-control"
                          placeholder="District Name"
                        >
                          <option value="option1">Select District</option>
                          <option value="option1">Chennai</option>
                          <option value="option2">Kacheepuram</option>
                          <option value="option3">Tiruvallur</option>
                          value={this.state.formFields.DistritName}
                          onChange={this.subjectChangeHandler}
                        </select>
                      </div>

                      {/* <input
                            type="text"
                            name="Countries"
                            className="form-control"
                            placeholder="Countries"
                            value={this.state.formFields.Countries}
                            onChange={this.subjectChangeHandler}
                         />*/}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            required
                            data-error="Please enter your name"
                            placeholder="Name of The Company"
                            value={this.state.formFields.DistritName}
                            onChange={this.nameChangeHandler}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            required
                            data-error="Please enter your email"
                            placeholder="Email"
                            value={this.state.formFields.CompanyName}
                            onChange={this.emailChangeHandler}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            className="form-control"
                            id="message"
                            cols="30"
                            rows="5"
                            required
                            data-error="Write your message"
                            placeholder="Address"
                            value={this.state.formFields.Address}
                            onChange={this.textChangeHandler}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            placeholder="Phone"
                            value={this.state.formFields.phone}
                            onChange={this.phoneChangeHandler}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="website"
                            className="form-control"
                            placeholder="website"
                            value={this.state.formFields.website}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="GSTNo"
                            className="form-control"
                            placeholder="GST Number"
                            value={this.state.formFields.GSTNo}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <div class="select-location dropdown">
                            <select class="form-control" placeholder="Category">
                              <option value="option1">Category</option>
                              <option value="option1">Micro</option>
                              <option value="option2">Small</option>
                              <option value="option3">medium</option>
                              value={this.state.formFields.Category}
                              onChange={this.subjectChangeHandler}
                            </select>
                          </div>
                          {/*<input
                            type="text"
                            name="Category"
                            className="form-control"
                            placeholder="Category"
                            value={this.state.formFields.Category}
                            onChange={this.subjectChangeHandler}
                          />*/}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="Udyam Registration Number"
                            className="form-control"
                            placeholder="Udyam Registration No"
                            value={this.state.formFields.RegistrationNo}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          {/*<input
                            type="text"
                            name="Sector"
                            className="form-control"
                            placeholder="Sector"
                            value={this.state.formFields.Sector}
                            onChange={this.subjectChangeHandler}
                          />*/}
                        </div>
                      </div>

                      {/*<div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="Service"
                            className="form-control"
                            placeholder="Service"
                            value={this.state.formFields.Service}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                        </div>*/}
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <div className="form-group">
                            <div class="select-location dropdown">
                              <select
                                class="form-control"
                                placeholder="Manufacturing Sector "
                              >
                                <option value="option1">
                                  Manufacturing Sector{" "}
                                </option>
                                <option value="option1">Automobile</option>
                                <option value="option2">
                                  Leather and Allied
                                </option>
                                <option value="option3">
                                  Textile and Readymade garments
                                </option>
                                <option value="option4">
                                  Agro and Food Industry
                                </option>
                                <option value="option5">
                                  Engineering and Fabrication
                                </option>
                                <option value="option6">
                                  Electrical and Electronic
                                </option>
                                <option value="option7">
                                  Plastic and Rubber components
                                </option>
                                <option value="option8">
                                  Defence and Aero
                                </option>
                                <option value="option9">
                                  Chemical and Allied
                                </option>
                                <option value="option10">IT & ITES</option>
                                <option value="option11">
                                  Pharma including Bio- Tech
                                </option>
                                <option value="option12">Handicrafts</option>
                                <option value="option13">Others</option>
                                value={this.state.formFields.Sector}
                                onChange={this.subjectChangeHandler}
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="select-location dropdown">
                              <select
                                class="form-control"
                                placeholder="Manufacturing Sector "
                              >
                                <option value="option1">
                                  Services Sector{" "}
                                </option>
                                <option value="option1">
                                  Logistics and WareHousing
                                </option>
                                <option value="option2">
                                  Healthcare and medical
                                </option>
                                <option value="option3">
                                  Repair and maintenance of Machines
                                </option>
                                <option value="option4">
                                  Education,Training and Consultancy
                                </option>
                                <option value="option5">
                                  Banking ,Finance and Insurance
                                </option>
                                <option value="option6">
                                  Tourism and Hospitability
                                </option>
                                <option value="option7">IT & ITES</option>
                                <option value="option8">
                                  Other Value Added Services
                                </option>
                                value={this.state.formFields.Sector}
                                onChange={this.subjectChangeHandler}
                              </select>
                            </div>
                          </div>

                          <input
                            type="text"
                            name="Product"
                            className="form-control"
                            placeholder="Product"
                            value={this.state.formFields.Product}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          {/* <input
                            type="text"
                            name="Capacity"
                            className="form-control"
                            placeholder="Capacity"
                            value={this.state.formFields.Capacity}
                            onChange={this.subjectChangeHandler}
                         />*/}
                          <div className="form-group">
                            <div class="select-location dropdown">
                              <select
                                class="form-control"
                                placeholder="Countries Exported"
                              >
                                <option value="option1">
                                  Capacity/Annum(Tonne)
                                </option>
                                <option value="option1">
                                  Less Than 10 Tonne
                                </option>
                                <option value="option2">
                                  More Than 10 Tonne
                                </option>
                                <option value="option3">Above 100 Tonne</option>
                                value={this.state.formFields.Capacity}
                                onChange={this.subjectChangeHandler}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <div class="select-location dropdown">
                            <select
                              class="form-control"
                              placeholder="Countries Exported"
                            >
                              <option value="option1">
                                Export Details(if any)
                              </option>
                              <option value="option1">
                                Less Than 10 Tonne
                              </option>
                              <option value="option2">
                                More Than 10 Tonne
                              </option>
                              <option value="option3">Above 100 Tonne</option>
                              value={this.state.formFields.ExportDetails}
                              onChange={this.subjectChangeHandler}
                            </select>
                          </div>
                          {/*<input
                            type="text"
                            name="ExportDetails"
                            className="form-control"
                            placeholder="Export Details"
                            value={this.state.formFields.ExportDetails}
                            onChange={this.subjectChangeHandler}
                          />*/}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="Year"
                            name="website"
                            className="form-control"
                            placeholder="Year and Value"
                            value={this.state.formFields.Year}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="CountriesExported"
                            className="form-control"
                            placeholder="Countries Exported"
                            value={this.state.formFields.CountriesExported}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="Certification"
                            className="form-control"
                            placeholder="Certification"
                            value={this.state.formFields.Certification}
                            onChange={this.subjectChangeHandler}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button type="submit" className="btn btn-primary">
                          Create
                        </button>

                        <div
                          id="msgSubmit"
                          className="h3 text-center hidden"
                        ></div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    {/* {this.successMessage()} */}
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FooterSection />{" "}
      </div>
    );
  }
}

export default YellowPageForm;
