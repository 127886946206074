// import React, { Fragment } from "react";
import React, { Component } from "react";

import { Container, Row, Col, Card } from "reactstrap";

// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

class UdhyamFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100,
      },
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    this.GetContent();
    this.GetGroup();
  }



  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "udhayamsteps";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
      });
  };

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "file";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result,
        loading: false,
      });
    }
  };
  render() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return this.state.loading ? (
      <Loader />
    ) : (
        <div>
          <div id="scrollUp" title="Scroll To Top">
            <i className="fas fa-arrow-up" />
          </div>
          <div className="main epc">
            {/*<Breadcrumb title="Export Schemes" />*/}
            <div className="position-relative">
              <div className="shape overflow-hidden text-white">
                {/*<svg
                  viewBox="0 0 2880 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                    fill="currentColor"
                  ></path>
                </svg>*/}
              </div>
            </div>
            {/* <section className="section">*/}
            <Container>
              <Row className="align-items-center">
                <Col lg={12} md={12} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                  <div className="section-title ml-lg-4">
                    {this.state.content &&
                      this.state.content.map((obj) => {
                        return (
                          <Card className="section-title mt-4 pt-2 col-md-6 col-lg-12">
                            <div
                            // className="section-title mt-4 pt-2 col-md-6 col-lg-6 key-feature align-items-center p-3 rounded shadow bg-white"
                            // style={{
                            //   border: "3px solid #0393ae", padding: "7px", borderRadius: "10px",
                            // }}
                            >
                              <h3>{obj.name}</h3>

                              <p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: obj.content,
                                  }}
                                ></div>
                                <br />
                                {/* <a href={obj.file}
                                  target="_blank" rel="noopener noreferrer">
                                  <button className="btn btn-white btn-header-primary">
                                    Click Here
                    </button>
                                </a> */}
                                <iframe title="procedure" src={obj.file} height={500} width={isMobile ? 300 : 700} />
                              </p>
                            </div>
                          </Card>
                        );
                      })}
                    <VerticalTimeline className="mb-4">
                      {this.state.groups &&
                        this.state.groups.map((item, idx) => {
                          return (
                            <VerticalTimelineElement
                              className="vertical-timeline-element--work"
                              contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', textAlign: "justify" }}
                              contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                              // date={idx + 1}
                              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                              icon={<img src={item.image} alt={idx} className={"timeline-icon"} />}
                            >
                              <h4 className="vertical-timeline-element-title">{item.title}</h4>
                              {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                              {item.content && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.content,
                                  }}
                                />
                              )}
                            </VerticalTimelineElement>
                          )
                        })}
                    </VerticalTimeline>
                    <div className="mb-4 border key feature p-2" style={{ textAlign: 'left' }}>
                      <h4>Notes:</h4>
                      <p >Having PAN and GSTIN is mandatory for Udyam Registration from 01.04.2021. You are advised to apply for GSTIN immediately and update on this website by 31.03.2021, so as to continue with Udyam Registration from 01.04.2021</p>
                      <p>
                        The enterprises having exports and wanting to avail of the benefits accruing to the MSME sector must provide their PAN. Having PAN would be made mandatory even otherwise for all, with effect from 01.04.2021.
</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            {/* </section>*/}
          </div>
          {" "}
        </div>
      );
  }
}

export default UdhyamFile;
