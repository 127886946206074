import React, { Fragment } from "react";
import { RadioSVGMap } from "react-svg-map";
import {
    Row,
    Col,
    Container,
    UncontrolledPopover,
    PopoverHeader,
    Media,
    Modal,
    ModalBody,
    ModalHeader
} from "reactstrap";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import FeatherIcon from "feather-icons-react";
import TextTruncate from "../../common/TextTruncate";
export default class District extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            photoIndex: 0,
            isOpenImage: false,
            contents: [],
            loading: true,
            ListRequestModelTNMap: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModel: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelWebsite: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1,
            },
            ListRequestModelTransport: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelSector: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelInstitution: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelCompany: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelAdhoc: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelProducts: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 6,
            },
            ListRequestModelImportProducts: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 6,
            },
            ListRequestModelLargeIndustries: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 6,
            },
            ListRequestModelImages: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelIndustrialParks: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 6,
            },
            ListRequestModelCluster: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 6,
            },
            ListRequestModelMsmeSector: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 6,
            },
            ListRequestModelDistrictContacts: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
            },
            ListRequestModelExportFacilitators: {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 6,
            },
        };
    }
    handleLocationMouseOver = (event) => {
        const pointedLocation = event.target.attributes.name.value;
        const focusedId = event.target.attributes.id.value;
        this.setState({ focusedId: focusedId, pointedLocation: pointedLocation });
    };

    handleLocationMouseOut = () => {
        this.setState({ focusedId: null, pointedLocation: null });
    };

    handleLocationFocus = (event) => {
        const focusedLocation = event.target.attributes.name.value;
        const focusedId = event.target.attributes.id.value;
        this.setState({ focusedId: focusedId, focusedLocation: focusedLocation });
    };

    handleLocationBlur = () => {
        this.setState({ focusedId: null, focusedLocation: null });
    };

    componentDidMount() {
        this.GetContent();
    }
    GetContent() {
        const { ListRequestModelTNMap } = this.state;
        ListRequestModelTNMap.TableName = "tnmap";
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelTNMap,
            "",
            this.GetContentResponse
        );
    }
    GetContentResponse = (data) => {
        if (data.result) {
            var viewbox = {
                label: "Map of Tamil Nadu",
                viewBox: "0 0 1591.1 1975.3",
                locations: data.result,
            };
            var locationtoselect = {};
            if (this.props.toDistrict) {
                locationtoselect = data.result.find(
                    (r) => r.id === this.props.toDistrict
                );
            } else {
                locationtoselect = data.result[0];
            }
            this.setState(
                {
                    tn: viewbox,
                    districts: data.result.sort((a, b) => a.name.localeCompare(b.name)),
                    selectedLocation: locationtoselect.name
                        ? locationtoselect.name.toUpperCase()
                        : "",
                    selectedLocationObj: locationtoselect,
                    selectedLocationId: locationtoselect._id,
                    selectedDocId: locationtoselect.id
                },
                () => this.locationSelected()
            );
            setTimeout(() => {
                document.getElementById(locationtoselect.id) &&
                    document
                        .getElementById(locationtoselect.id)
                        .setAttribute("aria-checked", true);
            }, 1000);
        }
    };
    handleOnChange = (selectedNode) => {
        var district = this.state.districts.filter(
            (d) => d.name === selectedNode.attributes.name.value
        );
        this.setState(
            (prevState) => {
                return {
                    ...prevState,
                    selectedLocation: selectedNode.attributes.name.value.toUpperCase(),
                    selectedLocationObj: district[0],
                    selectedLocationId: district[0] ? district[0]._id : "",
                    selectedDocId: district[0] ? district[0].id : "",
                };
            },
            () => this.locationSelected()
        );
    };

    locationSelected = () => {
        var DistrictId = this.state.selectedLocationId
            ? this.state.selectedLocationId
            : "";
        if (DistrictId) {
            this.GetDistrictAbout(DistrictId);
            this.GetDistrictWebsites(DistrictId);
            this.GetDistrictTransports(DistrictId);
            this.GetDistrictSectors(DistrictId);
            this.GetDistrictInstitutions(DistrictId);
            this.GetDistrictAdhoc(DistrictId);
            this.GetDistrictCompanies(DistrictId);
            this.GetExportProducts(DistrictId);
            // this.GetImportProducts(DistrictId);
            this.GetLargeIndustries(DistrictId);
            this.GetMsmeSector(DistrictId);
            this.GetDistrictContacts(DistrictId);
            this.GetExportFacilitators(DistrictId);
            this.GetIndustrialParks(DistrictId);
            this.GetClusters(DistrictId);
            this.GetDistrictGallery(DistrictId);
        }
    };
    GetDistrictAbout(DistrictId) {
        const { ListRequestModel } = this.state;
        ListRequestModel.TableName = "districtmsmeprofile";
        ListRequestModel.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModel,
            "",
            this.GetDistrictAboutResponse
        );
    }

    GetDistrictAboutResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Abouts: data.result,
                ReadMore: false,
            });
    };
    GetDistrictWebsites(DistrictId) {
        const { ListRequestModelWebsite } = this.state;
        ListRequestModelWebsite.TableName = "districtwebsites";
        ListRequestModelWebsite.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelWebsite,
            "",
            this.GetDistrictWebsitesResponse
        );
    }

    GetDistrictWebsitesResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Websites: data.result,
            });
    };
    GetDistrictTransports(DistrictId) {
        const { ListRequestModelTransport } = this.state;
        ListRequestModelTransport.TableName = "districttransport";
        ListRequestModelTransport.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelTransport,
            "",
            this.GetDistrictTransportsResponse
        );
    }

    GetDistrictTransportsResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Transports: data.result,
            });
    };
    GetDistrictSectors(DistrictId) {
        const { ListRequestModelSector } = this.state;
        ListRequestModelSector.TableName = "districtsectors";
        ListRequestModelSector.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelSector,
            "",
            this.GetDistrictSectorsResponse
        );
    }

    GetDistrictSectorsResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Sectors: data.result,
            });
    };
    GetDistrictInstitutions(DistrictId) {
        const { ListRequestModelInstitution } = this.state;
        ListRequestModelInstitution.TableName = "districtinstitutions";
        ListRequestModelInstitution.district = DistrictId;
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelInstitution,
            "",
            this.GetDistrictInstitutionsResponse
        );
    }

    GetDistrictInstitutionsResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Institutions: data.result,
            });
    };

    GetDistrictAdhoc(DistrictId) {
        const { ListRequestModelAdhoc } = this.state;
        ListRequestModelAdhoc.TableName = "districtadhoc";
        ListRequestModelAdhoc.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelAdhoc,
            "",
            this.GetDistrictAdhocResponse
        );
    }

    GetDistrictAdhocResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Adhocs: data.result,
            });
    };

    GetDistrictCompanies(DistrictId) {
        const { ListRequestModelCompany } = this.state;
        ListRequestModelCompany.TableName = "districtcompanies";
        ListRequestModelCompany.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelCompany,
            "",
            this.GetDistrictCompaniesResponse
        );
    }

    GetDistrictCompaniesResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Companies: data.result,
            });
    };
    GetExportProducts(DistrictId) {
        const { ListRequestModelProducts } = this.state;
        ListRequestModelProducts.TableName = "exportproducts";
        ListRequestModelProducts.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelProducts,
            "",
            this.GetExportProductsResponse
        );
    }

    GetExportProductsResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                ExportProducts: data.result,
            });
    };

    GetImportProducts(DistrictId) {
        const { ListRequestModelImportProducts } = this.state;
        ListRequestModelImportProducts.TableName = "importproducts";
        ListRequestModelImportProducts.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelImportProducts,
            "",
            this.GetImportProductsResponse
        );
    }

    GetImportProductsResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                ImportProducts: data.result,
            });
    };
    GetLargeIndustries(DistrictId) {
        const { ListRequestModelLargeIndustries } = this.state;
        ListRequestModelLargeIndustries.TableName = "largeindustries";
        ListRequestModelLargeIndustries.district = DistrictId;
        // ListRequestModelLargeIndustries.pageLimit = 6;
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelLargeIndustries,
            "",
            this.GetLargeIndustriesResponse
        );
    }

    GetLargeIndustriesResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                LargeIndustries: data.result,
            });
    };
    GetMsmeSector(DistrictId) {
        const { ListRequestModelMsmeSector } = this.state;
        ListRequestModelMsmeSector.TableName = "msmesector";
        ListRequestModelMsmeSector.district = DistrictId;
        // ListRequestModelMsmeSector.pageLimit = 6;
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelMsmeSector,
            "",
            this.GetMsmeSectorResponse
        );
    }

    GetMsmeSectorResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                MsmeSector: data.result,
            });
    };
    GetDistrictContacts(DistrictId) {
        const { ListRequestModelDistrictContacts } = this.state;
        ListRequestModelDistrictContacts.TableName = "districtcontacts";
        ListRequestModelDistrictContacts.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelDistrictContacts,
            "",
            this.GetDistrictContactsResponse
        );
    }

    GetDistrictContactsResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                DistrictContacts: data.result,
            });
    };

    GetExportFacilitators(DistrictId) {
        const { ListRequestModelExportFacilitators } = this.state;
        ListRequestModelExportFacilitators.TableName = "exportfacilitators";
        ListRequestModelExportFacilitators.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelExportFacilitators,
            "",
            this.GetExportFacilitatorsResponse
        );
    }

    GetExportFacilitatorsResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                ExportFacilitators: data.result,
            });
    };

    GetDistrictGallery(DistrictId) {
        const { ListRequestModelImages } = this.state;
        ListRequestModelImages.TableName = "districtgallery";
        ListRequestModelImages.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelImages,
            "",
            this.GetDistrictGalleryResponse
        );
    }

    GetDistrictGalleryResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                DistrictGallery: data.result,
            });
    };
    GetIndustrialParks(DistrictId) {
        const { ListRequestModelIndustrialParks } = this.state;
        ListRequestModelIndustrialParks.TableName = "industrialestates";
        ListRequestModelIndustrialParks.district = DistrictId;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelIndustrialParks,
            "",
            this.GetIndustrialParksResponse
        );
    }

    GetIndustrialParksResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                IndustrialParks: data.result,
            });
    };
    GetClusters(DistrictId) {
        const { ListRequestModelCluster } = this.state;
        ListRequestModelCluster.TableName = "clusterdetails";
        ListRequestModelCluster.district = DistrictId;
        // ListRequestModelCluster.pageLimit = 6;

        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModelCluster,
            "",
            this.GetClustersResponse
        );
    }

    GetClustersResponse = (data) => {
        if (data.result)
            this.setState({
                isLoading: false,
                Clusters: data.result,
            });
    };
    ReadMore = () => {
        this.setState({
            ReadMore: !this.state.ReadMore,
        });
    };
    handleChange = (e) => {
        if (e && e.target.value) {
            var district = this.state.districts.find(d => d._id === e.target.value);
            if (district) {
                var previousID = this.state.selectedDocId;
                document.getElementById(previousID) &&
                    document
                        .getElementById(previousID)
                        .setAttribute("aria-checked", false);
                this.setState(
                    (prevState) => {
                        return {
                            ...prevState,
                            selectedLocation: district.name.toUpperCase(),
                            selectedLocationObj: district,
                            selectedLocationId: district._id,
                        };
                    },
                    () => this.locationSelected()
                );
                setTimeout(() => {
                    document.getElementById(district.id) &&
                        document
                            .getElementById(district.id)
                            .setAttribute("aria-checked", true);
                }, 1000);
            }
        }
    }
    toggleModal = () => {
        this.setState({
            detailsModal: !this.state.detailsModal
        })
    }
    render() {
        // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        const { photoIndex, isOpenImage } = this.state;
        return (
            <Container className="mt-60">

                {this.state.tn &&
                    this.state.tn.locations &&
                    this.state.tn.locations.map((l) => (
                        <UncontrolledPopover trigger="hover" placement="top" target={l.id}>
                            <PopoverHeader>{l.name}</PopoverHeader>
                        </UncontrolledPopover>
                    ))}
                {this.state.tn && (
                    <Fragment>
                        <div class="row">

                            <div class="col-lg-6 col-10 ml-lg-5 ml-4">
                                {this.state.selectedLocation && (
                                    <div className="main epc overflow-wb">
                                        {/* <h1 style={{ textAlign: "center" }}>
                                            {this.state.selectedLocation}
                                        </h1> */}

                                        {this.state.Abouts && (
                                            <div>
                                                <Row className="align-items-center mb-2">
                                                    {/* <Col xxs="6"> */}
                                                    <h3 className='mr-2'>About   </h3>
                                                    {/* </Col>
                                                    <Col xxs="6"> */}
                                                    <select style={{ width: "40%" }} className='select-css' onChange={this.handleChange}
                                                    >
                                                        {this.state.districts.map(o => {
                                                            return (
                                                                <option key={o._id} value={o._id} selected={this.state.selectedLocationId === o._id}>
                                                                    {o.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {/* </Col> */}
                                                </Row>
                                                <Row className="align-items-center mb-2">
                                                    {this.state.Abouts.length === 0 && "No details found"}
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                this.state.Abouts[0] &&
                                                                this.state.Abouts[0].section1,
                                                        }}
                                                    ></div>
                                                    {this.state.ReadMore ? (
                                                        <div>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        this.state.Abouts[0] &&
                                                                        this.state.Abouts[0].section2,
                                                                }}
                                                            ></div>
                                                            <span
                                                                className={"pointer text-link"}
                                                                onClick={() => this.ReadMore()}
                                                            >
                                                                Read less
                              </span>
                                                        </div>
                                                    ) : (
                                                            this.state.Abouts[0] &&
                                                            this.state.Abouts[0].section2 && (
                                                                <span
                                                                    className={"pointer text-link"}
                                                                    onClick={() => this.ReadMore()}
                                                                >
                                                                    Read more ...
                                                                </span>
                                                            )
                                                        )}
                                                </Row>
                                            </div>
                                        )}
                                        <br />
                                        {this.state.Abouts && (
                                            <div>
                                                {this.state.Websites && this.state.Websites[0] && (
                                                    <a href={this.state.Websites[0].link} rel="noopener noreferrer" target="_blank">
                                                        <Row className="website-area">
                                                            <div className="col-lg-2 col-2 mt-3">
                                                                <div >
                                                                    <img src="/img/website.svg" alt="website" />
                                                                </div>
                                                            </div>
                                                            <div className="pt-3 col-lg-9 col-9 text-white pointer">
                                                                {/* <span className="text-white"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this.state.Abouts[0].section5,
                                                                }}
                                                            /> */}
                                                                <strong>Website</strong>
                                                                <p>Click Here to View the District Site</p>
                                                            </div>
                                                        </Row>
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                        <br />
                                        {/* {this.state.Institutions &&
                                            <div>
                                                <Row className="align-items-center">
                                                    <h3 style={{ textAlign: "center" }}>
                                                        Institutions
                                                </h3>
                                                </Row>
                                                <Row className="align-items-center">
                                                    {this.state.Institutions.length === 0 && "No Institutions found"}
                                                    {this.state.Institutions.map(i =>
                                                        <Col lg={6} className="pt-2">
                                                            <Media className="key-feature align-items-center p-3 rounded  bg-white">
                                                                <div className="icon text-center rounded-circle h4 mr-3 mb-0">
                                                                    <img
                                                                        src={i.institutions && i.institutions.icon}
                                                                        className="avatar avatar-md-sm"
                                                                        alt={i.name}
                                                                    />
                                                                </div>
                                                                <Media body>
                                                                    <b>{i.institutions && i.institutions.name}</b>
                                                                    <span
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: i.content,
                                                                        }}
                                                                    />
                                                                </Media>
                                                            </Media>
                                                        </Col>)}
                                                </Row>
                                            </div>} */}
                                    </div>
                                )}
                            </div>
                            <div class="col-1" />
                            <div class="col-lg-4 col-10 ml-4">
                                <div className={"sp-map"}>
                                    <article className={"map-wh"}>
                                        <div>
                                            <RadioSVGMap
                                                map={this.state.tn}
                                                onLocationMouseOver={this.handleLocationMouseOver}
                                                onLocationMouseOut={this.handleLocationMouseOut}
                                                onLocationFocus={this.handleLocationFocus}
                                                onLocationBlur={this.handleLocationBlur}
                                                onChange={this.handleOnChange}
                                            />
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="ml-lg-5 ml-4">
                            <h3 className=" mb-3 mr-2">District Contact</h3>
                            <div className="align-items-center mb-2">
                                <Row>

                                    <div className="col-lg-6 col-12">
                                        {this.state.DistrictContacts && (
                                            <Fragment>
                                                <div className="align-items-center">

                                                    {this.state.DistrictContacts.map((val) => (
                                                        <div className="col-12">
                                                            <ul className=" contact-us border border-gray mb-4 contact-box  contact-box-transition p-4 " style={{ minHeight: "300px" }}>

                                                                <div>
                                                                    {val.designation && <h4 className="mb-3 ml-3">{val.designation}</h4>}

                                                                    <li className="font-weight-normal contact-us">
                                                                        {val.contactname &&
                                                                            <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-user"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.contactname}
                                                                                </span></div>
                                                                        }
                                                                        {val.address &&
                                                                            <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-paper-plane"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.address}
                                                                                </span></div>
                                                                        }
                                                                        {val.phonenumber &&
                                                                            <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-phone-square-alt"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.phonenumber}
                                                                                </span></div>
                                                                        }
                                                                        {val.mobilenumber &&
                                                                            <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-mobile-alt"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.mobilenumber}
                                                                                </span></div>
                                                                        }
                                                                        {val.email &&
                                                                            <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-envelope"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.email}
                                                                                </span></div>
                                                                        }
                                                                        {val.website &&
                                                                            <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-globe"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.website}
                                                                                </span></div>
                                                                        }
                                                                        {val.cugno &&
                                                                            <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-mobile-alt"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.cugno}
                                                                                </span></div>
                                                                        }
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </div>

                                                    ))}
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>








                                    <div className="col-lg-6 col-12">
                                        {this.state.DistrictContacts && (
                                            <Fragment>

                                                <div className="align-items-center row">
                                                    {this.state.ExportFacilitators &&
                                                        this.state.ExportFacilitators.length > 0 && (
                                                            <div className="col-12" >
                                                                <div className="contact-us border border-gray mb-4 contact-box  contact-box-transition p-4" style={{ minHeight: "315px" }}>

                                                                    <ul className=" row">
                                                                        {this.state.ExportFacilitators.map((val) => (
                                                                            <div>
                                                                                <h4 className="mb-3 ml-3"> {val.designation} Export Facilitators</h4>


                                                                                <li className="font-weight-normal contact-us col-12">
                                                                                    {val.name &&
                                                                                        <div className="row">
                                                                                            <div className="social-icon mr-3 col-1">
                                                                                                <i className={"fas fa-user"} />
                                                                                            </div>
                                                                                            <span className="media-body align-self-center col-10">
                                                                                                {val.name}
                                                                                            </span></div>}
                                                                                    {/* {val.designation && <div className="row">
                                                                                <div className="social-icon mr-3 col-1">
                                                                                    <i className={"fas fa-address-card"} />
                                                                                </div>
                                                                                <span className="media-body align-self-center col-10">
                                                                                    {val.designation}
                                                                                </span></div>} */}
                                                                                    {val.phone && <div className="row">
                                                                                        <div className="social-icon mr-3 col-1">
                                                                                            <i className={"fas fa-phone-square-alt"} />
                                                                                        </div>
                                                                                        <span className="media-body align-self-center col-10">
                                                                                            {val.phone}
                                                                                        </span></div>}
                                                                                    {val.mobilenumber &&
                                                                                        <div className="row">
                                                                                            <div className="social-icon mr-3 col-1">
                                                                                                <i className={"fas fa-mobile-alt"} />
                                                                                            </div>
                                                                                            <span className="media-body align-self-center col-10">
                                                                                                {val.mobilenumber}
                                                                                            </span></div>
                                                                                    }
                                                                                    {val.email &&
                                                                                        <div className="row">
                                                                                            <div className="social-icon mr-3 col-1">
                                                                                                <i className={"fas fa-envelope"} />
                                                                                            </div>
                                                                                            <span className="media-body align-self-center col-10">
                                                                                                {val.email}
                                                                                            </span></div>
                                                                                    }
                                                                                    {val.website &&
                                                                                        <div className="row">
                                                                                            <div className="social-icon mr-3 col-1">
                                                                                                <i className={"fas fa-globe"} />
                                                                                            </div>
                                                                                            <span className="media-body align-self-center col-10">
                                                                                                {val.website}
                                                                                            </span></div>
                                                                                    }
                                                                                </li>
                                                                            </div>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        )}
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>


























                                </Row>
                                <br />
                            </div>
                        </div>
                        {/* <div className="align-items-center ml-5">
                            Navigate the map below to see the exact location and images of the parks
                                <iframe style={{ width: "100%" }} src="https://sipcot.tn.gov.in/portal/pages/sipcotmap" title="sipcot" />
                        </div> */}
                        {this.state.IndustrialParks && this.state.IndustrialParks.length > 0 && (
                            <div className="key-feature  border  p-2 ml-5   row" style={{
                                background: "#a0d1f9"
                            }}>
                                <Col lg="9" xxs="12" className="mt-2 ">
                                    <div className="p-2">
                                        <h4 className={"text-white"}>
                                            Industrial Parks
                                        </h4>
                                    </div>

                                    {this.state.IndustrialParks.map((l) => (
                                        <div
                                            className="key-feature border p-3 bg-white d-inline-block m-2"
                                        >
                                            <div className="d-inline-block">
                                                <img
                                                    src={l.developer && l.developer.image ? l.developer.image : "/img/pin.svg"}
                                                    className="avatar avatar-md-sm"
                                                    alt={l.developer && l.developer.name}
                                                />
                                                {l.developer && l.developer.name},{" "}
                                                {l.location}
                                            </div>
                                        </div>
                                    ))}
                                </Col>
                                <Col lg="3" xxs="12" className="mt-2 ">
                                    {/* {this.state.selectedLocationObj && this.state.selectedLocationObj.path ?
                                        <svg fill="none" viewBox="0 0 1591.1 1975.3" xmlns="http://www.w3.org/2000/svg">
                                            <path id={this.state.selectedLocationObj.id} name={this.state.selectedLocationObj.name} d={"M 100,30" + this.state.selectedLocationObj.path.slice(10)} class="svg-map__location" />
                                        </svg>
                                        : */}
                                    <img
                                        src={"/img/indParks.jpg"}
                                        className="avatar avatar-md-sm p-2"
                                        alt={"Parks"}
                                        width={200}
                                    />
                                    {/* <svg fill="none" viewBox="0 0 1591.1 1975.3" xmlns="http://www.w3.org/2000/svg">
                                        <path id={this.state.selectedLocationObj.id} name={this.state.selectedLocationObj.name} d={this.state.selectedLocationObj.path} class="svg-map__location industrialpark" />
                                    </svg> */}

                                    {/* } */}
                                </Col>
                            </div>
                        )}
                        <br />
                        {this.state.Transports && (
                            <div className="ml-lg-5 ml-4  mb-4">

                                <h3>Transport</h3>
                                <div className="align-items-center row">
                                    {this.state.Transports.length === 0 &&
                                        "No transports found"}
                                    {/* <Col lg={4} xxs={12} >
                                        {this.state.Transports.map((t, i) => (
                                            i < 2 &&
                                            <div className="key-feature  border  p-3 rounded bg-white mt-4" style={{ maxHeight: isMobile ? "1000px" : "250px", overflow: "hidden", cursor: "pointer" }}
                                                onClick={() => {
                                                    this.setState({
                                                        modalContent: t.content,
                                                        modalTitle: t.transport && t.transport.name,
                                                        modalIcon: t.transport && t.transport.icon
                                                    }, () => this.toggleModal())
                                                }}>
                                                <div className="icon rounded-circle h4 mr-3  mb-2">
                                                    <img
                                                        src={t.transport && t.transport.icon}
                                                        className="avatar avatar-md-sm"
                                                        alt={t.name}
                                                        width={50}
                                                    />
                                                </div>
                                                <div className="ml-1">
                                                    <b>{t.transport && t.transport.name}</b>
                                                    <span
                                                        dangerouslySetInnerHTML={{
                                                            __html: t.content,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </Col> */}
                                    {this.state.Transports.map((t, i) => (
                                        // i > 1 &&
                                        <Col lg={6} xxs={12} className={"mt-4"} >
                                            <div className="key-feature  border  p-3 rounded bg-white" style={{ minHeight: "200px" }}>
                                                {/* <p className="icon rounded-circle h4 mr-3  mb-2"> */}
                                                <img
                                                    src={t.transport && t.transport.icon}
                                                    className="avatar avatar-md-sm"
                                                    alt={t.name}
                                                    width={50}
                                                />
                                                {/* </p> */}
                                                <span className="ml-1">
                                                    <b className="ml-2">{t.transport && t.transport.name}</b>
                                                    <TextTruncate isHtml={true} value={t.content} length={300} title={t.transport && t.transport.name} icon={t.transport && t.transport.icon} />
                                                </span>
                                            </div>
                                        </Col>
                                    ))}
                                </div>
                            </div>
                        )}
                        <br />

                        <div className={"ml-5"}>
                            {this.state.MsmeSector && (
                                <div>
                                    <h3>MSME Focus Sectors</h3>
                                    <Row className="align-items-center  mb-4">
                                        {this.state.MsmeSector.length === 0 &&
                                            "No sectors found"}
                                        {this.state.MsmeSector.map((s) => (
                                            <Col lg={6} xxs={12} className={"mt-4"}>
                                                <Media
                                                    className="key-feature border align-items-center p-3 rounded bg-white"
                                                    style={{ height: 90 }}
                                                >
                                                    <div className="icon text-center rounded-circle h4 mr-3 mb-0">
                                                        <img
                                                            src={s.product && s.product.image ? s.product.image : "/img/check.svg"}
                                                            className="avatar avatar-md-sm"
                                                            alt={s.name}
                                                        />
                                                    </div>
                                                    <Media body>
                                                        <b>{s.name}</b>

                                                        {/* <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: s.content,
                                                                }}
                                                            /> */}
                                                    </Media>
                                                </Media>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            )}
                            {/* {this.state.MsmeSector &&
                                this.state.MsmeSector.length > 0 && (
                                    <Col lg="6" xxs="12" className="pt-2">
                                        <div className="p-2">
                                            <h4>
                                                MSME Focus sectors
                        </h4>
                                        </div>
                                        <ul className="feature p-2">
                                            {this.state.MsmeSector.map((val) => (
                                                <li className="mb-2">
                                                    <img
                                                        src={val.products && val.products.icon ? val.products.icon : "/img/check.svg"}
                                                        className="avatar avatar-md-sm"
                                                        alt={
                                                            val.products ? val.products.name : val.name
                                                        }
                                                    />
                                                    <span className="ml-2">
                                                        {val.products ? val.products.name : val.name}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>

                                    </Col>
                                )} */}
                            {this.state.ExportProducts && (
                                <div>
                                    <h3>Export Focus Sectors</h3>
                                    <Row className="align-items-center  mb-4">
                                        {this.state.ExportProducts.length === 0 &&
                                            "No sectors found"}
                                        {this.state.ExportProducts.map((s) => (
                                            <Col lg={6} xxs={12} className={"mt-4"}>
                                                <Media
                                                    className="key-feature border align-items-center p-3 rounded bg-white"
                                                    style={{ height: 90 }}
                                                >
                                                    <div className="icon text-center rounded-circle h4 mr-3 mb-0">
                                                        <img
                                                            src={s.product && s.product.image ? s.product.image : "/img/check.svg"}
                                                            className="avatar avatar-md-sm"
                                                            alt={s.name}
                                                        />
                                                    </div>
                                                    <Media body>
                                                        <b>{s.name}</b>

                                                        {/* <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: s.content,
                                                                }}
                                                            /> */}
                                                    </Media>
                                                </Media>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            )}
                            {this.state.Clusters && (
                                <div>
                                    <h3>Clusters</h3>
                                    <Row className="align-items-center mb-4">
                                        {this.state.Clusters.length === 0 &&
                                            "No sectors found"}
                                        {this.state.Clusters.map((s) => (
                                            <Col lg={6} xxs={12} className={"mt-4"}>
                                                <Media
                                                    className="key-feature border align-items-center p-3 rounded bg-white"
                                                    style={{ height: 90 }}
                                                >
                                                    <div className="icon text-center rounded-circle h4 mr-3 mb-0">
                                                        <img
                                                            src={s.product && s.product.image ? s.product.image : "/img/check.svg"}
                                                            className="avatar avatar-md-sm"
                                                            alt={s.nameofthecluster}
                                                        />
                                                    </div>
                                                    <Media body>
                                                        <b>{s.nameofthecluster}</b>

                                                        {/* <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: s.content,
                                                                }}
                                                            /> */}
                                                    </Media>
                                                </Media>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            )}
                            <Row>
                                {/* <Col lg="6" xxs="12" > */}
                                {/*{this.state.ExportProducts &&
                                        this.state.ExportProducts.length > 0 && (
                                            <div className="key-feature border">
                                                <div className="">
                                                    <h4 >
                                                        <img
                                                            src={"/img/logistics.svg"}
                                                            className="avatar avatar-md-sm"
                                                            alt={"Export Focus Sectors"}
                                                            width={50}
                                                        />             Export Focus Sectors
                        </h4>
                                                </div>

                                                <ul className="feature  p-2">
                                                    {this.state.ExportProducts.map((val) => (
                                                        <li className="mb-2">
                                                            <img
                                                                src={val.product && val.product.icon ? val.product.icon : "/img/check.svg"}
                                                                className="avatar avatar-md-sm"
                                                                alt={
                                                                    val.product ? val.product.name : val.name
                                                                }
                                                            />
                                                            <span className="ml-2">
                                                                {val.name}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                                            )}*/}
                                {/* <br /> */}
                                {/* {this.state.ImportProducts &&
                                    this.state.ImportProducts.length > 0 && (
                                        <div>
                                            <div className="p-2 rounded-top">
                                                <h4>
                                                    Products identified for import
                        </h4>
                                            </div>

                                            <ul className="feature p-2">
                                                {this.state.ImportProducts.map((val) => (
                                                    <li className="mb-2">
                                                        <img
                                                            src={val.products && val.products.icon ? val.products.icon : "/img/check.svg"}
                                                            className="avatar avatar-md-sm"
                                                            alt={
                                                                val.products ? val.products.name : val.name
                                                            }
                                                        />
                                                        <span className="ml-2">
                                                            {val.products ? val.products.name : val.name}
                                                        </span>
                                                    </li>

                                                ))}
                                            </ul>
                                        </div>
                                    )} */}
                                {/* </Col> */}


                                {/*
                                {this.state.Clusters && this.state.Clusters.length > 0 && (
                                    <Col lg="6" xxs="12" className=" ">
                                        <div className="p-2 key-feature border">
                                            <h4 >
                                                <img
                                                    src={"/img/cluster.svg"}
                                                    className="avatar avatar-md-sm"
                                                    alt={"Clusters"}
                                                    width={50}
                                                />      Clusters
                      </h4>

                                            <ul className="feature p-2">
                                                {this.state.Clusters.map((l) => (
                                                    <li className="mb-2">
                                                        <img
                                                            src={"/img/check.svg"}
                                                            className="avatar avatar-md-sm"
                                                            alt={"check"
                                                            }
                                                        />
                                                        <span className="ml-2">
                                                            {l.nameofthecluster}
                                                        </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Col>
                                                        )}*/}
                                {this.state.LargeIndustries &&
                                    this.state.LargeIndustries.length > 0 && (
                                        <Col lg="12" xxs="12" className="">
                                            {/*<strong>
                      Large Industries functioning in the District
                    </strong>*/}
                                            {/* <Card className="bg-light  rounded border-0"> */}
                                            <h3>
                                                {/* <img
                                                        src={"/img/factory.svg"}
                                                        className="avatar avatar-md-sm"
                                                        alt={"Large Industries"}
                                                        width={50}
                                                    /> */}
                                                        Large Industries
                        </h3>
                                            <div className="row mt-4" style={{
                                                placeContent: "center",
                                                textAlign: "center"
                                            }}>
                                                {this.state.LargeIndustries.map((c) => (
                                                    <div className="col-lg-2 col-12">
                                                        <div className=" associate-img text-center">
                                                            {/* <a href={c.link} target="_blank" rel="noopener noreferrer"> */}
                                                            {c.image ? <img src={c.image} alt="source" style={{ height: "100px", objectFit: "contain" }}
                                                            />
                                                                :
                                                                <div style={{ height: "100px", textAlign: "-webkit-center" }}>
                                                                    <div className="circle vertical-align text-white text-uppercase">
                                                                        <h2> {c.name && c.name.split(" ") && c.name.split(" ").filter((n, i) => i < 2).map((n, i) => i < 3 && n[0]).join("")}</h2>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className="works-content mt-2">
                                                                <h3 className="">
                                                                    <p>{c.name}</p>
                                                                </h3>
                                                            </div>
                                                            {/* </a> */}
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>      {/* <CardBody> */}
                                            {/* <ul className="feature p-2">
                                                    {this.state.LargeIndustries.map((val) => (
                                                        <li className="mb-2">
                                                            <img
                                                                src={val.image ? val.image : "/img/check.svg"}
                                                                className="avatar avatar-md-sm"
                                                                alt={
                                                                    val.name
                                                                }
                                                                width={25}
                                                            />
                                                            <span className="ml-2">
                                                                {val.name}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul> */}

                                        </Col>
                                    )}

                                {/* key Features */}

                                {this.state.ExportFacilitators &&
                                    this.state.ExportFacilitators.length > 0 && (
                                        <Col lg="12" xxs="12" className="">
                                            {/*<strong>
                      Large Industries functioning in the District
                    </strong>*/}
                                            {/* <div className="p-2 key-feature border">
                                                <h4>
                                                    <img
                                                        src={"/img/logistics.svg"}
                                                        className="avatar avatar-md-sm"
                                                        alt={"Export Facilitators"}
                                                        width={50}
                                                    />    Export Facilitators
                        </h4>
                                                <ul className=" row">
                                                    {this.state.ExportFacilitators.map((val) => (
                                                        <li className="font-weight-normal contact-us key-feature border col-6">
                                                            {val.name && <div className="row"><div className="col-1" />
                                                                <div className="social-icon mr-3 col-1">
                                                                    <i className={"fas fa-id-card"} />
                                                                </div>
                                                                <span className="media-body align-self-center col-10">
                                                                    {val.name}
                                                                </span></div>}
                                                            {val.designation && <div className="row"><div className="col-1" />
                                                                <div className="social-icon mr-3 col-1">
                                                                    <i className={"fas fa-address-card"} />
                                                                </div>
                                                                <span className="media-body align-self-center col-10">
                                                                    {val.designation}
                                                                </span></div>}
                                                            {val.phone && <div className="row"><div className="col-1" />
                                                                <div className="social-icon mr-3 col-1">
                                                                    <i className={"fas fa-mobile"} />
                                                                </div>
                                                                <span className="media-body align-self-center col-10">
                                                                    {val.phone}
                                                                </span></div>}
                                                        </li>
                                                    ))}
                                                </ul>
                                                    </div>*/}
                                        </Col>
                                    )}
                            </Row>
                        </div>
                        <div className="Container-fluid mt-5 row">
                            {this.state.DistrictGallery &&
                                this.state.DistrictGallery.map((di, index) => (
                                    <div
                                        className="col-lg-4 col-md-4 pointer"
                                    // style={{ minHeight: 370 }}
                                    >
                                        <div
                                            className="project-details-image"
                                            onClick={() =>
                                                this.setState({ isOpenImage: true, photoIndex: index })
                                            }
                                        >
                                            <img
                                                style={{
                                                    maxHeight: "300px",
                                                    minHeight: "300px",
                                                    maxWidth: "392px",
                                                    minWidth: "392px",
                                                }}
                                                src={di.image}
                                                alt="work"
                                            />
                                            {di.remarks}
                                            <FeatherIcon icon="camera" className="fea icon-sm" />
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {isOpenImage && (
                            <Lightbox
                                mainSrc={
                                    this.state.DistrictGallery[photoIndex] &&
                                    this.state.DistrictGallery[photoIndex].image
                                }
                                nextSrc={
                                    this.state.DistrictGallery[
                                    (photoIndex + 1) % this.state.DistrictGallery.length
                                    ] &&
                                    this.state.DistrictGallery[
                                        (photoIndex + 1) % this.state.DistrictGallery.length
                                    ].image
                                }
                                prevSrc={
                                    this.state.DistrictGallery[
                                    (photoIndex + this.state.DistrictGallery.length - 1) %
                                    this.state.DistrictGallery.length
                                    ] &&
                                    this.state.DistrictGallery[
                                        (photoIndex + this.state.DistrictGallery.length - 1) %
                                        this.state.DistrictGallery.length
                                    ].image
                                }
                                onCloseRequest={() => this.setState({ isOpenImage: false })}
                                onMovePrevRequest={() =>
                                    this.setState({
                                        photoIndex:
                                            (photoIndex + this.state.DistrictGallery.length - 1) %
                                            this.state.DistrictGallery.length,
                                    })
                                }
                                onMoveNextRequest={() =>
                                    this.setState({
                                        photoIndex:
                                            (photoIndex + 1) % this.state.DistrictGallery.length,
                                    })
                                }
                            />
                        )}
                    </Fragment>
                )
                }
                <Modal
                    size="lg"
                    className="viewModal"
                    isOpen={this.state.detailsModal}
                    toggle={this.toggleModal}
                >
                    <ModalHeader>
                        <img
                            src={this.state.modalIcon}
                            className="avatar avatar-md-sm"
                            alt={this.state.modalTitle}
                            width={50}
                        />  {this.state.modalTitle}
                    </ModalHeader>
                    <ModalBody className="m-3">
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    this.state.modalContent
                            }}
                        ></div>
                    </ModalBody>
                </Modal>
            </Container >
        );
    }
}
