import React, { Component, Fragment } from "react";
import Header from "../../../src/components/HeaderSection/Header";
import FooterSection from "../../../src/components/FooterSection/Footer";
import { Link } from "react-router-dom";
import { CallService } from "../../helpers/servicecall";
import { yellowpages } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { Button, Row, Col } from "reactstrap";

class YellowPage extends Component {
  state = {
    data: {},
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    iconList: [],
    contents: [],
    isLoading: true,
  };
  componentDidMount() {
    this.GetContent();
  }

  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "sector";
    CallService(
      yellowpages.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        contents: data.result,
      });
  };
  render() {
    return (
      <div style={{ overflowX: "hidden" }} id="main-wrapper">
        <div className="yellowpage" style={{ backgroundColor: "#f8f9fa" }}>
          {/*====== Scroll To Top Area Start ======*/}
          <div id="scrollUp" title="Scroll To Top">
            <i className="fas fa-arrow-up" />
          </div>
          {/*====== Scroll To Top Area End ======*/}
          <div className="main yellowpage">
            <Header imageData={"/img/logo-white.png"} />
            <section className="section  breadcrumb-area bg-overlay d-flex align-items-center">
              <div className="container">
                <div class="header-search slider-home">
                  <div class="header-search-bar">
                    <form action="#">
                      <div class="search-toggle">
                        <div class="container">
                          <div class="distance-range">
                            <p>
                              <label for="amount-search">Distance:</label>
                              <input type="text" id="amount-search" />
                            </p>
                            <div id="slider-range-search"></div>
                          </div>
                          <div class="distance-range">
                            <p>
                              <label for="amount-search">Days published:</label>
                              <input type="text" id="amount-search-day" />
                            </p>
                            <div id="slider-range-search-day"></div>
                          </div>
                          <p>Location:</p>
                          <div class="select-country">
                            <select
                              class=""
                              data-placeholder="-Select Country-"
                            >
                              <option value="option">-Select Country-</option>
                              <option value="option1">option 1</option>
                              <option value="option2">option 2</option>
                              <option value="option3">option 3</option>
                            </select>
                          </div>

                          <div class="region">
                            <input type="text" placeholder="-Region-" />
                          </div>

                          <div class="address">
                            <input type="text" placeholder="-Address-" />
                          </div>

                          <div class="category-search">
                            <select
                              class=""
                              data-placeholder="-Select category-"
                            >
                              <option value="option">-Select category-</option>
                              <option value="option1">option 1</option>
                              <option value="option2">option 2</option>
                              <option value="option3">option 3</option>
                            </select>
                          </div>

                          <button class="search-btn" type="submit">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>

                      <div class="container">
                        <div class="search-value">
                          <div style={{ width: "87%" }} class="keywords">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Keywords"
                            />
                          </div>

                          <button class="search-btn" type="submit">
                            <i class="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="shape-bottom">
                <svg
                  viewBox="0 0 1920 310"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  className="svg replaced-svg"
                >
                  <title></title>
                  <desc></desc>
                  <defs />
                  <g
                    id="sApp-Landing-Page"
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="sApp-v1.0"
                      transform="translate(0.000000, -554.000000)"
                      fill="#f8f8f8"
                    >
                      <path
                        d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                        id="sApp-v1.0"
                      />
                    </g>
                  </g>
                </svg>
              </div>{" "}
            </section>
          </div>
        </div>

        <div id="page-content" class="home-slider-content">
          <div class="container">
            <div class="">
              <div class="row">
                <div class="col-md-9 col-md-push-3">
                  <div class="page-content">
                    <div class="change-view"></div>

                    <div class="product-details" className="pt-2">
                      <div class="tab-content">
                        <div class="tab-pane active" id="all-categories">
                          <Row>
                            <Col xs="9">
                              <h3>
                                All <span>Products</span>
                              </h3>
                            </Col>

                            {/* <Col xs="3">
                              <div class="filter-input">
                                {" "}
                                <input
                                  type="text"
                                  placeholder="Filter by Keywords"
                                />
                              </div>
                            </Col> */}
                            <Col xs="3">
                              <Link to="/form">
                                <Button
                                  style={{
                                    float: "right",
                                  }}
                                >
                                  Apply
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                          <br></br>

                          <div class="row clearfix">
                            {this.state.isLoading ? (
                              <div style={{ margin: "auto", marginTop: "5%" }}>
                                <h4 className="loading">Loading...</h4>
                              </div>
                            ) : (
                                <Fragment>
                                  {this.state.contents &&
                                    this.state.contents.length > 0 &&
                                    this.state.contents.map((c) => {
                                      return (
                                        <div class="col-2">
                                          <div class="category-item">
                                            <Link
                                              className="nav-link"
                                              to={`yellowpages-result/${c.name}`}
                                            >
                                              {/* <i class={c.icon}></i> */}
                                              <img src={c.icon} alt="logo" />
                                              <p style={{ fontSize: "14px" }}>
                                                {c.name}
                                              </p>
                                            </Link>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Fragment>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-md-pull-9 category-toggle">
                  <button>
                    <i class="fa fa-briefcase"></i>
                  </button>

                  <div class="page-sidebar">
                    <div class="custom-search">
                      <div class="location-details">
                        <form action="#">
                          {/* <div class="select-country">
                            <label>Country</label>

                            <select class="" data-placeholder="-Select-">
                              <option value="option1">-Select-</option>
                              <option value="option2">option 2</option>
                              <option value="option3">option 3</option>
                              <option value="option4">option 4</option>
                            </select>

                          </div>

                          <div class="select-state">
                            <label>State</label>

                            <select class="" data-placeholder="-Select-">
                              <option value="option1">-Select-</option>
                              <option value="option2">option 2</option>
                              <option value="option3">option 3</option>
                              <option value="option4">option 4</option>
                            </select>

                          </div> */}

                          {/* <div class="zip-code">
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Enter Pincode"
                            />
                          </div> */}
                        </form>
                      </div>
                    </div>

                    <div id="categories">
                      <div class="accordion">
                        <ul
                          class="nav nav-tabs d-block accordion-tab"
                          role="tablist"
                        >
                          <li class="active">
                            <div href="#" role="tab" data-toggle="tab">
                              <i class="fa fa-shopping-cart"></i>All Products
                            </div>

                            <div>
                              {this.state.contents.map((c) => {
                                return (
                                  <Link
                                    to={`yellowpages-result/${c.name}`}
                                    redirectTo={c}
                                  >
                                    {c.name}
                                  </Link>
                                );
                              })}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterSection />
      </div>
    );
  }
}

export default YellowPage;
