import React, { Fragment } from "react";
import { RadioSVGMap } from "react-svg-map";
import {
  Container,
  UncontrolledPopover,
  PopoverHeader,
  Col,
  Row,
  Media,
  Card,
  CardBody,
} from "reactstrap";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-image-lightbox/style.css";
import { Link } from "react-router-dom";
export default class StateSector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0,
      isOpenImage: false,
      contents: [],
      DistrictSectors: [],
      loading: true,
      ListRequestModelTNMap: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListRequestModelSectors: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListRequestModelSector: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
    };
  }
  componentDidMount() {
    this.GetContent();
    // this.GetSector();
  }
  GetContent() {
    const { ListRequestModelTNMap } = this.state;
    ListRequestModelTNMap.TableName = "tnmap";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelTNMap,
      "",
      this.GetContentResponse
    );
  }
  GetContentResponse = (data) => {
    if (data.result) {
      var viewbox = {
        label: "Map of Tamil Nadu",
        viewBox: "0 0 1591.1 1975.3",
        locations: data.result,
      };
      var locationtoselect = {};
      if (this.props.toDistrict) {
        locationtoselect = data.result.find(
          (r) => r.id === this.props.toDistrict
        );
      } else {
        locationtoselect = data.result[0];
      }
      this.setState(
        {
          tn: viewbox,
          districts: data.result,
          selectedLocation: locationtoselect.name
            ? locationtoselect.name.toUpperCase()
            : "",
          selectedLocationShortCode: locationtoselect.id,
          selectedLocationObj: locationtoselect,
          selectedLocationId: locationtoselect._id,
        },
        () => this.locationSelected()
      );
      setTimeout(() => {
        document.getElementById(locationtoselect.id) &&
          document
            .getElementById(locationtoselect.id)
            .setAttribute("aria-checked", true);
      }, 1000);
    }
  };
  handleOnChange = (selectedNode) => {
    var district = this.state.districts.filter(
      (d) => d.name === selectedNode.attributes.name.value
    );
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedLocation: selectedNode.attributes.name.value.toUpperCase(),
          selectedLocationShortCode: selectedNode.attributes.id.value,
          selectedLocationObj: district[0],
          selectedLocationId: district[0] ? district[0]._id : "",
        };
      },
      () => this.locationSelected()
    );
  };
  locationSelected = () => {
    var DistrictId = this.state.selectedLocationId
      ? this.state.selectedLocationId
      : "";
    if (DistrictId) {
      this.GetDistrictAbout(DistrictId);
      // this.GetDistrictSectors(DistrictId);
    }
  };
  GetDistrictAbout(DistrictId) {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "districtmsmeprofile";
    ListRequestModel.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetDistrictAboutResponse
    );
  }

  GetDistrictAboutResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        Abouts: data.result,
      });
  };
  GetDistrictSectors(DistrictId) {
    const { ListRequestModelSector } = this.state;
    ListRequestModelSector.TableName = "districtsectors";
    ListRequestModelSector.district = DistrictId;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelSector,
      "",
      this.GetDistrictSectorsResponse
    );
  }

  GetDistrictSectorsResponse = (data) => {
    if (data.result)
      this.setState({
        isLoading: false,
        DistrictSectors: data.result,
      });
  };
  handleLocationMouseOver = (event) => {
    const pointedLocation = event.target.attributes.name.value;
    const focusedId = event.target.attributes.id.value;
    this.setState({ focusedId: focusedId, pointedLocation: pointedLocation });
  };

  handleLocationMouseOut = () => {
    this.setState({ focusedId: null, pointedLocation: null });
  };
  handleLocationFocus = (event) => {
    const focusedLocation = event.target.attributes.name.value;
    const focusedId = event.target.attributes.id.value;
    this.setState({ focusedId: focusedId, focusedLocation: focusedLocation });
  };

  handleLocationBlur = () => {
    this.setState({ focusedId: null, focusedLocation: null });
  };
  GetSector() {
    const { ListRequestModelSectors } = this.state;
    ListRequestModelSectors.TableName = "sector";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelSectors,
      "",
      this.GetSectorResponse
    );
  }
  GetSectorResponse = (data) => {
    if (data.result) {
      this.setState({
        sectors: data.result,
      });
    }
  };
  render() {
    var menucontent = JSON.parse(sessionStorage.getItem("menucontent"));
    var menu = JSON.parse(sessionStorage.getItem("menu"));
    if (menu && menucontent)
      var currentContent = menucontent.find(m => (m.menu && m.menu._id) === (menu.find(m => m.friendlyurl === "/state-profile") && menu.find(m => m.friendlyurl === "/state-profile")._id));

    return (
      <Container style={{ textAlign: "-webkit-center", marginTop: "2%" }}>
        {this.state.tn &&
          this.state.tn.locations &&
          this.state.tn.locations.map((l) => (
            <UncontrolledPopover trigger="hover" placement="top" target={l.id} key={l.id}>
              <PopoverHeader>{l.name}</PopoverHeader>
            </UncontrolledPopover>
          ))}
        {this.state.tn && (
          <Fragment>
            <div className="section-title st-fs-28">
              <h2 className="heading title-dark mb-3">DISTRICT </h2>
              <p dangerouslySetInnerHTML={{
                __html: currentContent && currentContent.content,
              }}
              />
              <div className="row m-0">
                <div className="col-10 col-lg-7 mt-3 ">

                  <Row className="mt-4 pt-2 ml-0 mr-0">
                    {this.state.selectedLocation && (
                      <div className="epc overflow-wb">
                        <h2 style={{ marginTop: '36px' }}>
                          {this.state.selectedLocation}
                        </h2>
                        {this.state.Abouts && (
                          <div>
                            <Row className="align-items-center m-0">
                              {this.state.Abouts.length === 0 &&
                                "No details found"}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    this.state.Abouts[0] &&
                                    this.state.Abouts[0].section1,
                                  //&& this.state.Abouts[0].section1.split(' ').slice(0, 50).join(' '),
                                }}
                              ></div>
                            </Row>
                          </div>
                        )}
                        <br />
                        <a
                          className={"text-info"}
                          href={
                            "/state-profile/" +
                            this.state.selectedLocationShortCode
                          }
                        >
                          More about district
                      </a>
                      </div>
                    )}
                    {this.state.DistrictSectors.length === 0 &&
                      this.state.sectors &&
                      this.state.sectors.map((s, key) => (
                        <Fragment>
                          <Col key={key} xs={3} className="mt-4 pt-2">
                            {s.name && (
                              <Card className="explore-feature insheight border-0 rounded text-center bg-white">
                                <CardBody>
                                  <Link
                                    // onClick={() =>
                                    //   this.props.selectSector(s._id)
                                    // }
                                    className="title text-dark"
                                  >
                                    <div className="icon rounded-circle shadow-lg d-inline-block">
                                      <img
                                        src={s.icon}
                                        className="avatar avatar-md-sm"
                                        alt={s.name}
                                      />
                                    </div>
                                    <div className="content mt-3">
                                      <h6 className="mb-0">{s.name}</h6>
                                    </div>
                                  </Link>
                                </CardBody>
                              </Card>
                            )}
                          </Col>
                        </Fragment>
                      ))}
                    {this.state.DistrictSectors.map((s) => (
                      <Col lg={6} className="pt-2">
                        <Media className="key-feature align-items-center p-3 rounded shadow bg-white">
                          <div className="icon text-center rounded-circle h4 mr-3 mb-0">
                            <img
                              src={s.sector && s.sector.icon}
                              className="avatar avatar-md-sm"
                              alt={s.name}
                            />
                          </div>
                          <Media body>
                            <b>{s.sector && s.sector.name}</b>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: s.content,
                              }}
                            />
                          </Media>
                        </Media>
                      </Col>
                    ))}{" "}
                  </Row>

                </div>
                <div className="col-lg-1 col-2  mt-3 " />
                <div className="col-8 col-lg-4 " style={{ justifyContent: "center" }}>
                  <div className={"sp-map"}>
                    <article className={"map-wh"}>
                      <div>
                        <RadioSVGMap
                          map={this.state.tn}
                          onChange={this.handleOnChange}
                        />
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Container>



    );
  }
}
