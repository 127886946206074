import React, { Component, Fragment } from "react";
import { Col, Row } from "reactstrap";

class BlogDetails extends Component {
  state = {
    data: {},
    iconList: [],
    commentsData: [],
  };
  componentDidMount() {
    var currentSchemeContent = this.props.currentSchemeContent;
    this.setState({
      content: currentSchemeContent,
    });
  }
  render() {
    //  var { content } = this.props;
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      <div>
        {/* Single Blog Details */}
        <article className="single-blog-details">
          {/* Blog Thumb */}
          <span className="float-right pointer"
            style={{ color: "red" }}
            onClick={() => this.props.gotoApply()}
          >
            Close
           </span>
          <h4>{this.state.content && this.state.content.nameofscheme}</h4>

          <div className="bar"></div>
          <div className="blog-thumb">
            {/* <a href="/#"><img src={"/img/blog-2_apply.png"} alt="source" /></a> */}
          </div>
          {/* Blog Content */}
          <div className="blog-content sApp-blog">
            {/* Meta Info */}
            <div className="meta-info d-flex flex-wrap align-items-center py-2">
              {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{this.state.data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.comments}</a></li>
                            </ul> */}
              {/* Blog Share */}
              <div className="blog-share ml-auto d-none d-sm-block">
                {/* Social Icons */}
                {/* <div className="social-icons d-flex justify-content-center">
                                    {this.state.iconList.map((item, idx) => {
                                        return (
                                            <a key={`bdi_${idx}`} className={item.link} href="/#">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div> */}
              </div>
            </div>
            {/* Blog Details */}
            <div className="blog-details">
              {this.state.content && this.state.content.brief && (
                <blockquote className="blockquote px-4 py-3 my-3">
                  <p className="d-none d-sm-block">
                    {this.state.content && this.state.content.brief}
                  </p>
                </blockquote>
              )}
              {this.state.content && this.state.content.benefits && (
                <Fragment>
                  {" "}
                  <h5 className=" py-3">
                    <a href="/#">Benefits</a>
                  </h5>
                  <p
                    className="d-none d-sm-block"
                    dangerouslySetInnerHTML={{
                      __html: this.state.content && this.state.content.benefits,
                    }}
                  ></p>
                </Fragment>
              )}
              {this.state.content && this.state.content["eligibility/terms"] && (
                <Fragment>
                  {" "}
                  <h5 className="py-3">
                    <a href="/#">Eligibility Terms</a>
                  </h5>
                  {this.state.content && this.state.content.Eligibilities
                    ? this.state.content.Eligibilities.length > 0
                      ? this.state.content.Eligibilities.map((el) => {
                        return (
                          <Row className="py-2">

                            {!isMobile &&

                              <Col lg={1}
                              >
                                <img
                                  style={{ height: "33px" }}
                                  className="float-right"
                                  id={el._id}
                                  src={el.eligibility && el.eligibility.image}
                                  alt={el.eligibility && el.eligibility.name}
                                />
                              </Col>

                            }

                            {isMobile &&

                              <div
                              >
                                <img
                                  style={{ height: "33px" }}
                                  className="float-right"
                                  id={el._id}
                                  src={el.eligibility && el.eligibility.image}
                                  alt={el.eligibility && el.eligibility.name}
                                />
                              </div>

                            }







                            <Col lg={8}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: el.content,
                                }}
                              />
                            </Col>
                          </Row>
                        );
                      })
                      : this.state.content["eligibility/terms"] && <p
                        className="d-none d-sm-block"
                        dangerouslySetInnerHTML={{
                          __html: this.state.content["eligibility/terms"],
                        }}
                      ></p>
                    : this.state.content["eligibility/terms"] && <p
                      className="d-none d-sm-block"
                      dangerouslySetInnerHTML={{
                        __html: this.state.content["eligibility/terms"],
                      }}
                    ></p>}
                  {/* <p
                    className="d-none d-sm-block"
                    dangerouslySetInnerHTML={{
                      __html: content["eligibility/terms"],
                    }}
                  ></p> */}
                </Fragment>
              )}
              {this.state.content && this.state.content["howtoapply"] && (
                <Fragment>
                  <h5 className="py-3">
                    <a href="/#">How to apply</a>
                  </h5>
                  <p
                    className="d-none d-sm-block"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.content && this.state.content["howtoapply"],
                    }}
                  ></p>
                </Fragment>
              )}
              {this.state.content && this.state.content["officetocontact"] && (
                <Fragment>
                  <h5 className="py-3">
                    <a href="/#">Office to Contact</a>
                  </h5>
                  <p
                    className="d-none d-sm-block"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.state.content && this.state.content["officetocontact"],
                    }}
                  ></p>
                </Fragment>
              )}
              {this.state.content && this.state.content["link"] && (
                <div className="mt-4">
                  {" "}
                  <a href={this.state.content["link"]} target="_blank" rel="noopener noreferrer">
                    <button className="btn btn-white btn-header-primary"
                    >View respective site
                              </button></a>
                </div>
              )}
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default BlogDetails;
