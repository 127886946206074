import React, { Component } from "react";
import EpcDetails from "./EpcDetails";

class EpcBlogDetails extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <EpcDetails />
        </div>
      </div>

    );
  }
}

export default EpcBlogDetails;
