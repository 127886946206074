import React, { Component } from "react";
// Modal Video
// import images
import { CallService } from "../../helpers/servicecall";
import { entries, events } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Eventscomponent from "./Eventscomponent";
import Loader from "../../common/loader";




class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  componentDidMount() {
    this.GetContent();
  }
  componentWillReceiveProps() {
    this.GetContent();
  }
  GetContent() {
    var userid = JSON.parse(sessionStorage.getItem("userid"));
    if (userid) {
      this.GetUserEvents();
    }
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "event";
    ListRequestModel.sortCondition = { startdate: -1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      var Events = data.result.map((r) => {
        if (r.enddate) {
          var endDate = new Date(r.enddate * 1000);
          endDate.setDate(new Date(r.enddate * 1000).getDate() + 1);
        }
        return {
          id: r._id,
          title: r.name,
          friendlyurl: r.friendlyurl,
          start: r.startdate ? new Date(r.startdate * 1000) : new Date(),
          end: endDate
            ? endDate
            : r.startdate
              ? new Date(r.startdate * 1000)
              : new Date(),
          className: r.color + " text-white",
        };
      });
      var eventStatus = this.props.location.pathname;

      this.setState(
        {
          events: data.result,
          eventStatus,
          calendarEvents: Events.length > 0 ? Events : this.state.calendarEvents,
          loading: false,
        }
      );
    }
  };

  GetUserEvents() {
    CallService(
      events.GetByUser,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100,
      },
      "",
      this.GetUserEventsResponse
    );
  }

  GetUserEventsResponse = (data) => {
    if (data.result) {
      this.setState(
        {
          userevents: data.result,
        }
      );
    }
  };




  render() {
    const { events, eventStatus, calendarEvents, userevents } = this.state;

    return this.state.loading ? (
      <Loader />
    ) : (
        <div>
          <Eventscomponent data={events} userevents={userevents} status={eventStatus} calendarEvents={calendarEvents} history={this.props.history} />
        </div >

      );
  }
}


export default Events;
