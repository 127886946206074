/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-sub-hidden";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563",
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const themeColorStorageKey = "__theme_color";
export const isMultiColorActive = false;
export const defaultColor = "light.purple";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;
export const MethodType = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  PUT: "PUT",
  DELETE: "DELETE",
  FORMDATA: "FORMDATA",
};

export const StatusCodes = {
  Error: "500",
  InvalidData: "422",
  Success: "200",
  Unauthorized: "401",
  InvalidMediaType: "415",
  Forbidden: "403",
  NotFound: "404",
  Duplicate: "409",
};

export const masterListPageTableTheme = {
  rows: {
    height: "35px",
    cursor: "pointer",
  },
};

export const ApplicationStatus = {
  Draft: "Draft",
  Approved: "Approved",
  SeekingClarification: "Seeking Clarifications",
  Submitted: "Action Requested",
  Rejected: "Rejected",
};

export const Roles = {
  Admin: "admin",
  Officer: "Officer",
  DistrictAdmin: "DistrictAdmin",
  TnegaAdmin: "TnegaAdmin",
  Public: "Public",
};

export const RespondingDateThreshold = {
  info: 50,
  warning: 75,
};
