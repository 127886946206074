import React, { Component, Fragment } from "react";
import ContactForm from "./ContactForm";
import FooterSection from "../../../src/components/FooterSection/Footer";
import { Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import classnames from "classnames";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import { RadioSVGMap } from "react-svg-map";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";


class ContactPage extends Component {
  state = {
    data: {},
    iconList: [],
    activeTab: 1,
    loading: true,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 3,
    },
    ListRequestModelTNMap: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
  };
  componentDidMount() {
    this.toggle = this.toggle.bind(this);
    this.GetMapContent();
    this.GetAboutContent();
    this.GetContent();
    this.GetthirdContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "contactdetails";
    ListRequestModel.searchString = "";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result,
        loading: false,
      });
    }
  };
  GetthirdContent() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 100,
        TableName: "contactdetails",
      },
      "",
      this.GetthirdContentResponse
    );
  }

  GetthirdContentResponse = (data) => {
    if (data.result) {
      this.setState({
        thirdcontents: data.result,
        loading: false,
      });
    }
  };

  toggle(tab) {
    this.setState({ activeTab: tab }, () => {
      if (this.state.activeTab - 1 === 2)
        if (this.state.selectedDocId)
          setTimeout(() => {
            document.getElementById(this.state.selectedDocId) &&
              document
                .getElementById(this.state.selectedDocId)
                .setAttribute("aria-checked", true);
          }, 1000);
    });
  }

  handleLocationMouseOver = (event) => {
    const pointedLocation = event.target.attributes.name.value;
    const focusedId = event.target.attributes.id.value;
    this.setState({ focusedId: focusedId, pointedLocation: pointedLocation });
  };

  handleLocationMouseOut = () => {
    this.setState({ focusedId: null, pointedLocation: null });
  };

  handleLocationFocus = (event) => {
    const focusedLocation = event.target.attributes.name.value;
    const focusedId = event.target.attributes.id.value;
    this.setState({ focusedId: focusedId, focusedLocation: focusedLocation });
  };

  handleLocationBlur = () => {
    this.setState({ focusedId: null, focusedLocation: null });
  };
  handleOnChange = (selectedNode) => {
    var district = this.state.districts.filter(
      (d) => d.name === selectedNode.attributes.name.value
    );
    var previousID = this.state.selectedDocId;
    document.getElementById(previousID) &&
      document
        .getElementById(previousID)
        .setAttribute("aria-checked", false);
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          selectedLocationObj: district[0],
          selectedLocation: selectedNode.attributes.name.value.toUpperCase(),
          selectedLocationId: district[0] ? district[0]._id : "",
          selectedDocId: district[0] ? district[0].id : "",
        };
      }
    );
  };

  GetMapContent() {
    const { ListRequestModelTNMap } = this.state;
    ListRequestModelTNMap.TableName = "tnmap";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelTNMap,
      "",
      this.GetMapContentResponse
    );
  }
  GetMapContentResponse = (data) => {
    if (data.result) {
      var viewbox = {
        label: "Map of Tamil Nadu",
        viewBox: "0 0 1591.1 1975.3",
        locations: data.result,
      };
      var locationtoselect = {};
      if (this.props.toDistrict) {
        locationtoselect = data.result.find(
          (r) => r.id === this.props.toDistrict
        );
      } else {
        locationtoselect = data.result[0];
      }
      this.setState(
        {
          tn: viewbox,
          districts: data.result.sort((a, b) => a.name.localeCompare(b.name)),
          selectedLocation: locationtoselect.name
            ? locationtoselect.name.toUpperCase()
            : "",
          selectedLocationObj: locationtoselect,
          selectedLocationId: locationtoselect._id,
          selectedDocId: locationtoselect.id
        }
      );
    }
  };
  handleChange = (e) => {
    if (e && e.target.value) {
      var district = this.state.districts.find(d => d._id === e.target.value);
      if (district) {
        var previousID = this.state.selectedDocId;
        document.getElementById(previousID) &&
          document
            .getElementById(previousID)
            .setAttribute("aria-checked", false);
        this.setState(
          (prevState) => {
            return {
              ...prevState,
              selectedLocation: district.name.toUpperCase(),
              selectedLocationObj: district,
              selectedLocationId: district._id,
              selectedDocId: district.id
            };
          },
        );
        setTimeout(() => {
          document.getElementById(district.id) &&
            document
              .getElementById(district.id)
              .setAttribute("aria-checked", true);
        }, 1000);
      }
    }
  }
  GetAboutContent() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
        TableName: "about"
      },
      "",
      this.GetAboutContentResponse
    );
  }

  GetAboutContentResponse = (data) => {
    if (data.result) {
      this.setState({
        aboutcontent: data.result[0],
        loading: false,
      });
    }
  };

  render() {

    // const { thirdcontents } = this.state;


    // var NoOfContacts = thirdcontents ? Object.keys(thirdcontents).length : '0';

    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          {this.state.contents && (
            <section id="contact" className="m-5">
              <div className="container">
                <Row className="mt-4 pt-2 justify-content-center">
                  <Col md={12} className="text-center">
                    <Nav
                      pills
                      className="rounded nav-justified flex-column flex-sm-row"
                    >
                      {this.state.contents.map((c, index) => (
                        <NavItem>
                          <NavLink
                            style={{ minHeight: "50px" }}
                            className={classnames(
                              { active: this.state.activeTab === index + 1 },
                              "rounded"
                            )}
                            onClick={() => {
                              this.toggle(index + 1);
                            }}
                          >
                            <div
                              className="text-center pt-1 pb-1"
                              style={{ minWidth: "200px" }}
                            >
                              <h4 className="font-weight-normal mb-0"
                                style={{ color: "#3a80ea" }}
                              >
                                {c.name}
                              </h4>
                            </div>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </Col>
                </Row>

                <div className="row justify-content-between">
                  {this.state.contents[this.state.activeTab - 1] ? (
                    <div className="col-12 col-md-6  col-lg-6">
                      {/* Contact Us */}
                      {this.state.activeTab - 1 !== 2 &&
                        <div className="contact-us border border-gray mb-4 contact-box  contact-box-transition p-4" style={{ minHeight: "300px" }}>
                          <ul>
                            {this.state.contents[this.state.activeTab - 1].designation &&
                              <h4 className="mb-3 ml-3">{this.state.contents[this.state.activeTab - 1].designation}</h4>}
                            {this.state.contents[this.state.activeTab - 1]
                              .name && (
                                <li key={`ci_${0}`} className="py-2 row">
                                  <div className="social-icon mr-3 col-1">
                                    <i className={"fas fa-user"} />
                                  </div>

                                  <span className="media-body align-self-center col-10">
                                    {
                                      this.state.contents[this.state.activeTab - 1]
                                        .name
                                    }
                                  </span>
                                </li>
                              )}
                            {this.state.contents[this.state.activeTab - 1]
                              .address && (
                                <li key={`ci_${1}`} className="py-2 row">
                                  <div className="social-icon mr-3 col-1">
                                    <i className={"fas fa-paper-plane"} />
                                  </div>

                                  <span className="media-body align-self-center col-10">
                                    {
                                      this.state.contents[this.state.activeTab - 1]
                                        .address
                                    }
                                  </span>
                                </li>
                              )}

                            {this.state.contents[this.state.activeTab - 1]
                              .mobilenumber && (
                                <li key={`ci_${2}`} className="py-2 row">
                                  <div className="social-icon mr-3 col-1">
                                    <i class="fas fa-mobile-alt" />
                                  </div>
                                  <span className="media-body align-self-center col-10">
                                    {
                                      this.state.contents[this.state.activeTab - 1]
                                        .mobilenumber
                                    }
                                  </span>
                                </li>
                              )}
                            {this.state.contents[this.state.activeTab - 1]
                              .email && (
                                <li key={`ci_${3}`} className="py-2 row">
                                  <div className="social-icon mr-3 col-1">
                                    <i className={"fas fa-envelope"} />
                                  </div>
                                  <span className="media-body align-self-center col-10">
                                    {
                                      this.state.contents[this.state.activeTab - 1]
                                        .email
                                    }
                                  </span>
                                </li>
                              )}
                            {this.state.contents[this.state.activeTab - 1]
                              .website && (
                                <li key={`ci_${4}`} className="py-2 row">
                                  <div className="social-icon mr-3 col-1">
                                    <i class="fas fa-globe-americas"></i>
                                  </div>
                                  {/* <a className="media" href={this.state.contents[this.state.activeTab - 1].website} 
                                rel="noopener noreferrer"
                                target="_blank">*/}
                                  <a
                                    href={
                                      this.state.contents[this.state.activeTab - 1]
                                        .website
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="media-body align-self-center col-10">
                                      {
                                        this.state.contents[
                                          this.state.activeTab - 1
                                        ].website
                                      }
                                    </span>
                                  </a>
                                  {/* </a> */}
                                </li>
                              )}
                          </ul>
                        </div>
                      }

                      {this.state.activeTab - 1 === 2 &&
                        <div className="mb-5">
                          <Row>
                            <h3>Contacts:</h3>
                            {/* <h3>{NoOfContacts}</h3> */}
                          </Row>
                        </div>
                      }
                      {this.state.activeTab - 1 === 2 &&
                        this.state.thirdcontents &&
                        this.state.thirdcontents.filter(tc => (tc.district && tc.district._id) === this.state.selectedLocationId) &&
                        this.state.thirdcontents.filter(tc => (tc.district && tc.district._id) === this.state.selectedLocationId).map(
                          (tc) =>
                          (
                            <div className="contact-us border border-gray mb-4 contact-box  contact-box-transition p-4" style={{ minHeight: "300px" }}>
                              <div>
                                {tc.designation && <h4 className="mb-3">{tc.designation}</h4>}

                                <ul>
                                  {tc.name && (

                                    <li key={`ci_${1}`} className="py-2 row">
                                      <div className="social-icon mr-3 col-1">
                                        <i className={"fas fa-user"} />
                                      </div>

                                      <span className="media-body align-self-center col-10">
                                        {tc.name}
                                      </span>
                                    </li>
                                  )}
                                  {tc.address && (

                                    <li key={`ci_${1}`} className="py-2  row">
                                      <div className="social-icon mr-3 col-1">
                                        <i className={"fas fa-paper-plane"} />
                                      </div>

                                      <span className="media-body align-self-center col-10">
                                        {tc.address}
                                      </span>
                                    </li>
                                  )}
                                  {tc.mobilenumber && (
                                    <li key={`ci_${2}`} className="py-2  row">
                                      <div className="social-icon mr-3 col-1">
                                        <i className={"fas fa-phone-square-alt"} />
                                      </div>
                                      <span className="media-body align-self-center col-10">
                                        {tc.mobilenumber}
                                      </span>
                                    </li>
                                  )}
                                  {tc.email && (
                                    <li key={`ci_${3}`} className="py-2  row">
                                      <div className="social-icon mr-3 col-1">
                                        <i className={"fas fa-envelope"} />
                                      </div>
                                      <span className="media-body align-self-center col-10">
                                        {tc.email}
                                      </span>
                                    </li>
                                  )}
                                  {tc.phonenumber && (
                                    <li key={`ci_${2}`} className="py-2  row">
                                      <div className="social-icon mr-3 col-1">
                                        <i className={"fas fa-mobile-alt"} />
                                      </div>
                                      <span className="media-body align-self-center col-10">
                                        {tc.phonenumber}
                                      </span>
                                    </li>
                                  )}
                                  {tc.website && (
                                    <li key={`ci_${4}`} className="py-2 row">
                                      <div className="social-icon mr-3 col-1">
                                        <i class="fas fa-globe-americas"></i>
                                      </div>
                                      {/* <a className="media" href={this.state.contents[this.state.activeTab - 1].website} 
                                rel="noopener noreferrer"
                                target="_blank">*/}
                                      <a
                                        href={tc.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span className="media-body align-self-center col-10">
                                          {tc.website}
                                        </span>
                                      </a>
                                      {/* </a> */}
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  ) : (
                      ""
                    )}
                  <div className={this.state.activeTab - 1 === 2 ? "col-12 col-md-5 pt-4 pt-md-0" : "col-12 col-md-6 pt-4 pt-md-0"}>
                    {this.state.activeTab - 1 === 2 ?
                      this.state.tn &&
                      <Fragment>
                        <select className='select-css' onChange={this.handleChange}
                          value={this.state.selectedLocationId}>
                          {this.state.districts.map(o => {
                            return (
                              <option key={o._id} value={o._id}>
                                {o.name}
                              </option>
                            );
                          })}
                        </select>
                        <RadioSVGMap
                          map={this.state.tn}
                          onLocationMouseOver={this.handleLocationMouseOver}
                          onLocationMouseOut={this.handleLocationMouseOut}
                          onLocationFocus={this.handleLocationFocus}
                          onLocationBlur={this.handleLocationBlur}
                          onChange={this.handleOnChange}
                        />
                      </Fragment>
                      :
                      <ContactForm />
                    }
                  </div>

                </div>
                <div className="row m-4 ">
                  <div className="col-12" style={{ marginLeft: "0px", marginTop: "0px" }}>

                    <section className="eventmap-area"
                    >
                      <iframe
                        title="google-map"
                        src={this.state.aboutcontent && this.state.aboutcontent.location}
                        style={{ border: 0 }}
                      />
                    </section>
                  </div>
                </div>
                <div className="row m-4 " />

              </div>
            </section>
          )}
          <FooterSection />
        </div>
      );
  }
}

export default ContactPage;
