import React, { Component } from "react";

import BlogDetails from "./BlogDetails";

class BlogDetailsLeftSidebar extends Component {
  render() {
    return (
      <div className="blog">
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main">
          {/* <Header imageData={"/img/logo-white.png"} />
                    <Breadcrumb title="Blog Details - Left Sidebar" /> */}
          <section id="blog" className="section blog-area service-blog ptb_100">
            <div className="">
              <BlogDetails content={this.props.schemeContent} />
            </div>
          </section>
          {/* <FooterSection /> */}
        </div>
      </div>
    );
  }
}

export default BlogDetailsLeftSidebar;
