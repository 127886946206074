import React, { Component } from "react";

import Header from "../components/HeaderSection/Header";
import Breadcrumb from "../components/Blogs/Breadcrumb";
import FeatureSection from "../components/Features/FeatureOne";
import ServiceSection from "../components/ServiceSection/ServiceOne";
//import ContactSection from "../components/ContactSection/Contact";
import FooterSection from "../components/FooterSection/Footer";
import Section from "../components/ServiceSection/ServiceSeven";

class Services extends Component {
  state = {
    research: true,
  };
  handleChange = (event) => {
    console.log(event.target.value);
    if (event.target.value === "research")
      this.setState({
        research: true,
      });
    else
      this.setState({
        research: false,
      });
    // this.setState({ [event.target.name]: event.target.value });
  };
  handleTradePromotion = (event) => {
    console.log(event.target.value);
    if (event.target.value === "trade") {
      console.log(this.props);
      this.props.history.push("/servicepromotion");
    }
  };
  render() {
    return (
      <div>
        {/*====== Scroll To Top Area Start ======*/}
        <div id="scrollUp" title="Scroll To Top">
          <i className="fas fa-arrow-up" />
        </div>
        {/*====== Scroll To Top Area End ======*/}
        <div className="main services">
          <Header imageData={"/img/logo-white.png"} />
          <Breadcrumb title="Services" />
          <section id="service" className="contact-area ">
            <div className="container">
              <div className="row">
                <Section />
                <FeatureSection />
                <ServiceSection />
              </div>
            </div>
          </section>

          <FooterSection />
        </div>
      </div>
    );
  }
}

export default Services;
