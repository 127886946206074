import React, { Component, Fragment } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

class TextTruncate extends Component {
    state = {
        detailsModal: false
    }
    text_truncate = function (str, length, ending) {
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str)
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
    };
    toggleModal = () => {
        this.setState({
            detailsModal: !this.state.detailsModal
        })
    }
    render() {
        return (
            <Fragment>
                <div className={this.props.className + " pointer"} style={this.props.style} onClick={this.props.onClick ? this.props.onClick : () => {
                    this.setState({
                        modalContent: this.props.value,
                        modalTitle: this.props.title ? this.props.title : "Details",
                        modalIcon: this.props.icon ? this.props.icon : "/img/info.svg"
                    }, () => this.toggleModal())
                }}>
                    {this.props.isHtml ?
                        <span dangerouslySetInnerHTML={{
                            __html: this.text_truncate(this.props.value, this.props.length, this.props.ending)
                        }} />
                        :
                        this.text_truncate(this.props.value, this.props.length, this.props.ending)}
                </div>
                <Modal
                    size="lg"
                    className="viewModal"
                    isOpen={this.state.detailsModal}
                    toggle={this.toggleModal}
                >
                    <ModalHeader>
                        <div style={{ width: "100%" }}>
                            <img
                                src={this.state.modalIcon}
                                className="avatar avatar-md-sm"
                                alt={this.state.modalTitle}
                                width={30}
                            />  {this.state.modalTitle}

                        </div>
                    </ModalHeader>
                    <ModalBody className="m-3">
                        <span className="float-right pointer"
                            style={{ color: "red", marginTop: "-10%" }}
                            onClick={() => this.toggleModal()}
                        >
                            Close
           </span>
                        <div
                            dangerouslySetInnerHTML={{
                                __html:
                                    this.state.modalContent
                            }}
                        ></div>
                    </ModalBody>
                </Modal>
            </Fragment>
        )
    }
}
export default TextTruncate;
