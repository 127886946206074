export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const QR_KEY = process.env.REACT_APP_QR_KEY;
export const QR_PREFIX = process.env.REACT_APP_QR_URL_PREFIX;
export const passEndDate = process.env.REACT_APP_PASS_TILL;
export const TandC_URL = process.env.REACT_APP_TANDC_URL;
export const Payment_URL = process.env.REACT_APP_PAYMENT_URL;
export const GOOGLE_TRACKING_ID = process.env.GOOGLE_TRACKING_ID;

export const cms = {
  GetOne: BASE_URL + "cms/",
  GetAll: BASE_URL + "cms",
};
export const entries = {
  GetAll: BASE_URL + "table_entry",
  GetOne: BASE_URL + "table_entry/",
};
export const yellowpages = {
  GetAll: BASE_URL + "table_entry",
  GetOne: BASE_URL + "table_entry/",
};
export const mail = {
  Send: BASE_URL + "send/mail"
}
export const events = {
  Register: BASE_URL + "event_user",
  GetByUser: BASE_URL + "event_user",
  Signin: BASE_URL + "msmeuser",
  Login: BASE_URL + "msmeuser/signin",
  PaymentInitialize: BASE_URL + "payment/initialize/",
  PaymentResponse: BASE_URL + "payment/get_details",
};
export const authenticate = {
  Signin: BASE_URL + "msmeuser",
  Login: BASE_URL + "msmeuser/signin",
  OTPGet: BASE_URL + "otp/get",
  ResetPassword: BASE_URL + "msmeuser/resetpassword",
};
export const documentMaster = {
  GetAll: BASE_URL + "document",
  GetOne: BASE_URL + "document/",
  Create: BASE_URL + "document",
  Update: BASE_URL + "document/",
  GetPaginated: BASE_URL + "document",
  Delete: BASE_URL + "document/",
  DocumentMaping: BASE_URL + "documents_required",
  GetAllDocumentMaping: BASE_URL + "documents_required/unique",
  FileUpload: BASE_URL + "fileupload",
  UrltoBase64: BASE_URL + "urltobase64",
};