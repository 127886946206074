import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import EpcBlogDetails from "../Blogs/EpcBlogDetails";
import Loader from "../../common/loader";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";



class Epc extends React.Component {
  state = {
    contents: [],
    loading: true,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
  };
  componentDidMount() {
    //var schemename = this.props.location.pathname;
    //this.GetContent(schemename);
    this.setState({
      loading: false,
    });
  }
  GetContent(schemename) {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "schemegroup";
    ListRequestModel.searchString = schemename;

    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result.length > 0 ? data.result : [],
        loading: false,
      });
      if (data.result[0]) {
        this.GetSchemeContents(data.result[0]._id);
      }
    }
  };
  GetSchemeContents(schemeid) {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "scheme";
    ListRequestModel.searchString = "";
    ListRequestModel["schemegroup"] = schemeid;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetSchemeContentsResponse
    );
  }

  GetSchemeContentsResponse = (data) => {
    if (data.result)
      this.setState({
        schemecontents: data.result.length > 0 ? data.result : [],
        loading: false,
      });
  };
  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            <EpcBlogDetails />
          </section>
          <FooterSection />{" "}
        </div>
      );
  }
}

export default Epc;
