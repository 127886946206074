/* eslint-disable jsx-a11y/no-distracting-elements */
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";

const slideOptions = {
  items: 1,
  nav: false,
  dots: true,
  smartSpeed: 500,
  autoplay: true,
  autoplayHoverPause: true,
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  responsiveClass: true,
  responsive: {
    0: {
      dotsEach: 1,
      items: 1
    },
    600: {
      dotsEach: 1,
      items: 1
    },
    768: {
      dotsEach: 1,
      items: 1
    }
  }
};
class Banner extends React.Component {
  state = {
    data: {},
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModelEvent: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    events: [],
    contents: [],
    loading: true
  };
  componentDidMount() {
    this.GetContent();
    this.GetLatestUpdates();
    // this.changeSlide(0);
  }
  // startTimer() {
  //   if (!this.stopIntervalId) {
  //     this.stopIntervalId = setInterval(() => {
  //       if (this.state.currentItem < (this.state.contents.length - 1)) {
  //         this.changeSlide(this.state.currentItem + 1)
  //       }
  //       else {
  //         this.changeSlide(0)
  //       }
  //     }, 10000000);
  //   }
  // }
  // stopTimer() {
  //   clearInterval(this.stopIntervalId);
  //   this.stopIntervalId = undefined;
  // }
  componentWillReceiveProps() {
    this.componentDidMount();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "banners";
    ListRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result)
      this.setState({
        contents: data.result,
        loading: false
      });
  };
  GetLatestUpdates() {
    const { ListRequestModelEvent } = this.state;
    ListRequestModelEvent.TableName = "latestupdates";
    ListRequestModelEvent.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModelEvent,
      "",
      this.GetLatestUpdatesResponse
    );
  }

  GetLatestUpdatesResponse = (data) => {
    if (data.result)
      this.setState({
        latestupdates: data.result,
      });
  };

  render() {

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      this.state.loading ? (
        <Loader />
      ) : <section>
          {this.state.contents && (
            <div className="row m-0">
              <div className={isMobile ?"col-12 mt-4":"col-12"} >
                {/* <div className="works-slides"> */}
                <OwlCarousel
                  // className="section h-100vh bg-overlay d-flex align-items-center"
                  className="banner-slides owl-carousel owl-theme"
                  // onDragged={(e) => { this.changeSlide((e.item.index - 1)) }}
                  {...slideOptions}
                >
                  {this.state.contents.map((c) => (
                    <div className="item">
                      <a
                        href={c.link}
                        // target="_blank"
                        rel="noopener noreferrer"
                      >
                        <section
                          className={`slide-1`}
                          //alt="source"
                          style={{
                            background:
                              "rgba(0, 0, 0, 0) url(" +
                              c.image +
                              ") no-repeat scroll center center / cover",
                          }}
                        >
                          {/* <img src={c.image} alt="source" /> */}
                          {c.name && <div className="">
                            <div className="banner-overlay bottom-align text-center">

                              <p>{c.name}</p>

                            </div>
                          </div>}
                        </section>
                      </a>
                    </div>
                  ))}
                </OwlCarousel>
                <div style={{ width: "95%" }}>
                  <span className="font-weight-bold m-1" style={{ fontSize: "1rem", float: "left", marginTop: "-1%" }}>Latest Updates</span>
                  <marquee

                    Scrollamount={4}
                    className={"marq"}
                    loop="infinite"
                    id={"mymarquee"}
                    data-duplicated="true"
                    onMouseOver={() =>
                      document.getElementById('mymarquee').stop()
                    }
                    onMouseLeave={() =>
                      document.getElementById('mymarquee').start()
                    }
                  >

                    {this.state.latestupdates && this.state.latestupdates.map((l) => (
                      <React.Fragment>
                        <img src="/img/newicon.gif" alt="new" />
                        <a href={l.link} style={{ color: l.color }} target="_blank" rel="noopener noreferrer" key={l._id} className={"m-2"} >{l.name}</a>
                      </React.Fragment>
                    ))}
                  </marquee>
                  {/* <div className={"btn marq-btn"}>
                  <span style={{ paddingTop: "1%" }}>
                    Read more..
                  </span>
                </div> */}
                </div>
              </div>
              {/* <div className="col-3 banner-thumb-scroll" style={{marginLeft:"30px"}}>
              <div className="up-arrow text-center m-2 mt-3">
                <Icon.ChevronUp className="pointer" onClick={() => {
                  if (this.state.currentItem <= 0) {
                    this.changeSlide(0)
                  }
                  else {
                    this.changeSlide(this.state.currentItem - 1)
                  }
                }} />
              </div>
              {this.state.contents[this.state.currentItem === 0 ? this.state.contents.length - 1 : (this.state.currentItem - 1)] &&
                <div onClick={() => this.changeSlide(this.state.currentItem === 0 ? this.state.contents.length - 1 : (this.state.currentItem - 1))}
                  className={"m-2 mb-3 banner-thumb-item pointer"}
                  style={{
                    width:"230px",
                    height: "112px",
                    borderRadius: "8px",
                    position: "relative",
                    background:
                      "url(" +
                      this.state.contents[this.state.currentItem === 0 ? this.state.contents.length - 1 : (this.state.currentItem - 1)].image +
                      ") no-repeat center /cover",
                  }}
                >
                  <div className={"bottom-align"}>
                    <p>{this.state.contents[this.state.currentItem === 0 ? this.state.contents.length - 1 : (this.state.currentItem - 1)].name}</p>
                  </div>
                </div>}

              {this.state.contents[this.state.currentItem] &&
                <div onClick={() => this.changeSlide(this.state.currentItem)}
                  className={"m-2 banner-selected-thumb-item pointer"}
                  style={{
                    width:"230px",
                    height: "112px",
                    borderRadius: "8px",
                    position: "relative",
                    background:
                      "url(" +
                      this.state.contents[this.state.currentItem].image +
                      ") no-repeat center /cover",
                  }}
                >
                  <div className={"text-white bottom-align"}>
                    <p>{this.state.contents[this.state.currentItem].name}</p>
                  </div>
                </div>}
              {this.state.contents[(this.state.currentItem === (this.state.contents.length - 1) ? 0 : (this.state.currentItem + 1))] &&
                <div onClick={() => this.changeSlide(this.state.currentItem === (this.state.contents.length - 1) ? 0 : (this.state.currentItem + 1))}
                  className={"m-2 mt-3 banner-thumb-item pointer"}
                  style={{
                    width:"230px",
                    height: "112px",
                    borderRadius: "8px",
                    position: "relative",
                    background:
                      "url(" +
                      this.state.contents[(this.state.currentItem === (this.state.contents.length - 1) ? 0 : (this.state.currentItem + 1))].image +
                      ") no-repeat center /cover",
                  }}
                >
                  <div className={"bottom-align"}>
                    <p>{this.state.contents[(this.state.currentItem === (this.state.contents.length - 1) ? 0 : (this.state.currentItem + 1))].name}</p>
                  </div>
                </div>
              }
              <div className="down-arrow text-center mt-3">
                <Icon.ChevronDown className="pointer" onClick={() => {
                  if (this.state.currentItem < (this.state.contents.length - 1)) {
                    this.changeSlide(this.state.currentItem + 1)
                  }
                  else {
                    this.changeSlide(0)
                  }
                }} />
              </div>
            </div>
            <div className="col-1" /> */}
            </div>
          )
          }
        </section>
    );
  }
}

export default Banner;
