import React, { Component } from "react";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
class EpcBody extends Component {
  state = {
    data: {},
    iconList: [],
    commentsData: [],
    EPCContent: [],
    FourTable: [],
    loading: true,
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
  };
  componentDidMount() {
    this.GetContent();
  }
  componentWillReceiveProps() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "epcautonomousbody";
    // ListRequestModel.sortCondition = { sortorder: 1 };
    // ListRequestModel.searchString = window.location.hash.replace("#", "");
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      var contents = data.result;
      var ArrayValues = [];
      for (var i = 1; i <= data.result.length; i++) {
        var FourTable = i * 4;
        ArrayValues.push(FourTable);
      }
      this.setState({
        firstcontent: contents[0],
        content: contents.sort((a, b) => a.title.localeCompare(b.title)),
        FourTable: ArrayValues,
        loading: false,
      });
    }
  };
  getContent() {
    if (window.location.hash === "/epc") {
      this.setState({
        logo: "EPC.jpeg",
        Title: "Export Promotion Councils",
        Content:
          "Presently, there are fourteen Export Promotion Councils under the administrative control of the Department of Commerce. Names and addresses of these Councils are given in Annexure 1.2. These Councils are registered as non-profit organizations under the Companies Act/ Societies Registration Act. The Councils perform both advisory and executive functions. The role and functions of these Councils are guided by the Foreign Trade Policy, 2009-14. These Councils are also the registering authorities for exporters under the Foreign Trade Policy 2009-14.",
      });
    } else if (window.location.hash === "/epc/FIEO") {
      this.setState({
        logo: "FIEO.jpg",
        Title: "Federation of Indian Export Organisations (FIEO)",
        Content:
          "Federation of Indian Export Organisation (FIEO) is the India’s apex body of the Export Promotion Councils, Commodity Boards and Export Development Authorities in India.  established in 1965 and functioning under aegis of Department of Commerce, Ministry of Commerce and Industry, Government of India to  provide the crucial interface between international trading community of India, Central and State Governments, financial institutions, logistics sector and all engaged in the export trade facilitation.  FIEO is one of the registered authority for exporters for getting the benefits under Foreign Trade Policy besides the services rendered by FIEO for the export trading community.  For more information,  you may contact  the Southern Regional Office of FIEO in Tamilnadu through email:fieosr@fieo.org or visit their website: www.fieo.org.  ",
      });
    } else if (window.location.hash === "/epc/EEPC") {
      this.setState({
        logo: "EEPC.jpg",
        Title: "Engineering Export Promotion Council – EEPC INDIA",
        Content:
          "Engineering Export Promotion Council – EEPC INDIA (EEPC) is the premiier Trade and Investment Promotion Organisation in India, sponsored by the Ministry of Commerce & Industry, Government of India catering to the Indian Engineering Sector and is an interface between the engineering industry and the Government.  EEPC was set up in 1955 and has grown with a membership base of 13,000 out of whom 60% are MSMEs.  EEPC has been organising large number of promotional activities such as Buyers and Sellers Meet (BS) both in India and abroad, Trade Fairs/Exhibitions and India Pavillion/Information Booths in selected overseas exhibitions to demonstrate the capabilities of the Indian Engineering Industry and to provide overseas buyers with true value as propagated by brand India.  “Engineering the Future” as its Motto, EEPC serves as the reference point in the Indian Engineering Industry and the international business community in its efforts towards establishing India as a major Engineering Hub.  For further details, you may contact the Southern Regional Office in Tamil Nadu by e.mail: eepcrochen@eepcindia.net and visit their website:www:eepcindia.org. ",
      });
    } else if (window.location.hash === "/epc/PLEXCONCIL") {
      this.setState({
        logo: "PEPC.png",
        Title: "The Plastics Export Promotion Council (PLEXCONCIL)",
        Content:
          "The Plastics Export Promotion Council (PLEXCONCIL), sponsored by Ministry of Commerce & Industry, Government of India represents the exporting community in the Indian Plastic Industry to project India’s image abroad as a reliable supplier of high quality products in order to give boost to the Country’s exports.  PLEXCONCIL represents over 2500 exporters who manufacture/trade plastic products ranging from plastic raw materials to semi finished and finished products.  For more information, you may contact the Sothern Regional Office of PLEXCONCIL in Tamil Nadu by email: ruban.hobday@plexconcil.org and visit their website:                  www: plexconcil.org.",
      });
    } else if (window.location.hash === "/epc/AEPC") {
      this.setState({
        logo: "AEPC.jpg",

        Title: "Apparel Export Promotion Council (AEPC)",
        Content:
          "Apparel Export Promotion Council is official body established under the aegis of Ministry of Textiles, Government of India,  for the promotion, facilitation agreement, manufacturing of apparels and their exports.  AEPC provides assistance to exporters as well as importers/international buyers who choose India as their preferred sourcing destination.  For detailed information on the activities of AEPC,  you may contact their Regional Office in Tamil Nadu by email: chairman@aepcindia.com and visit their website: www. aepcindia.com  ",
      });
    } else if (window.location.hash === "/epc/EPCH") {
      this.setState({
        logo: "EPCH.jpg",

        Title: "Export Promotion Council for Handicrafts (EPCH)",
        Content:
          "Export Promotion Council for Handicrafts (EPCH) is the apex body of Handicrafts established for promotion of exports of Handicrafts from the country and projecting India’s image abroad as a reliable supplier of high quality handicrafts and services, meeting the international standards and specifications.  EPCH provides marketing support and information facilities to its members of exporters and importers.  For detailed information, you may contact the EPCH Southern Regional Office in Karnataka (Bangalore) by email: bangalore@epch.com and visit their website: www. epch.in.  ",
      });
    } else if (window.location.hash === "/epc/TEXPROCIL") {
      this.setState({
        logo: "CTEPC.jpeg",

        Title: " The Cotton Textiles Export Promotion Council (TEXPROCIL) ",
        Content:
          "The Cotton Textiles Export Promotion Council (TEXPROCIL) is the apex body under the Ministry of Textiles established for promoting exports of Cotton textile products from India such as raw cotton, blended yarns, oven & netted fabrics, home textiles.  TEXPROCIL has a membership of over 3000 Companies and provides information on India’s competitive advantage,  regular updates on international product trends, trade related issues, advances in technology and the latest developments in the industry and representing in international trade fairs/ Buyer Seller Meets.  For further detail, you may contact TEXPROCIL  by email: info@texprocil.org and visit their website:www: texprocil.org.",
      });
    }
  }

  render() {
    return this.state.loading ? (
      <Loader />
    ) : (
        <div>
          {/* Single Blog Details */}
          <article className="">
            {/* Blog Thumb */}
            {this.state.firstcontent && (
              <div className="text-center">
                {/* <img
                  style={{
                    height: "80px",
                    maxWidth: "130px",
                    marginTop: "10px",
                  }}
                  src={this.state.firstcontent.logo}
                  alt="shape"
                />*/}
                {/* <h4>{this.state.firstcontent.title}</h4>*/}
              </div>
            )}{" "}
            {/* Blog Content */}
            <br />


            <br />
            <div className="section-title mt-4 pt-2 col-md-6 col-lg-12">
              <h2>AUTONOMOUS BODIES</h2>
            </div>
            <br />
            <br />

            <div class="row">
              {this.state.content &&
                this.state.content.map((c, i) => {
                  return (
                    i !== 0 && (
                      <div className={"  epcDetails col-lg-3"}>
                        <a
                          href={c.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="image">
                            <center>
                              {c.logo && (
                                <img
                                  style={{
                                    height: "60px",
                                    maxWidth: "130px",
                                    marginTop: "10px",
                                  }}
                                  src={c.logo}
                                  alt="shape"
                                />
                              )}
                            </center>
                          </div>
                          <div style={{ textAlign: "center" }}>{c.title}</div>
                          {/* <div className="works-content customScrollBar onhoverScroll pointer">
                            <div className="d-none d-sm-block overflow-wb ">
                              {c.content && (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: c.content,
                                  }}
                                />
                              )}
                            </div>
                          </div> */}
                        </a>
                      </div>
                    )
                  );
                })}
              {/*<EpcContact />
              <EpcDocument />*/}
            </div>
          </article>
        </div>
      );
  }
}

export default EpcBody;
