import React, { Component } from "react";
import Loader from "../../common/loader";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";

import bg from "../../img/insurance/blog1.jpg";
import { Link } from "react-router-dom";
import { CallService } from "../../helpers/servicecall";
import { events, Payment_URL } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import PaymentForm from "./PaymentForm";

const initData = {
  heading: "India leather days, Offenbach, Germany (leather goods)",
  content:
    "Join a local group to meet people, try something new, or do more of what you love.",
  formContent:
    "EVENTS",
  formText: "do more of what you love.",
  btnText: "Sign Up",
  btnText_2: "Sign In",
};

class Signup extends Component {
  state = {
    loading: true,
    initData: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      loading: false,
    });
  }

  PaymentInitiate = () => {
    var { userevents, selectedEvent } = this.props;
    var eventDetails = userevents.find(ue => (ue.Event && ue.Event._id) === selectedEvent._id && ue.Status === "Approved");

    // this.setState({
    //   PaymentModal: true
    // })
    if (eventDetails)
      CallService(
        events.PaymentInitialize + eventDetails._id,
        MethodType.GET,
        false,
        "",
        "",
        this.PaymentInitiateResponse
      );
  }

  PaymentInitiateResponse = (data) => {

    if (data.result) {
      this.setState({
        InitiateResponse: data.result,
        PaymentModal: true
      })
    }
  }
  SubmitPayment = () => {
    var { userevents, selectedEvent } = this.props;
    var eventDetails = userevents.find(ue => (ue.Event && ue.Event._id) === selectedEvent._id);

    // this.setState({
    //   PaymentModal: false
    // })
    if (eventDetails)
      CallService(
        events.GetAll,
        MethodType.POST,
        false,
        { eventId: eventDetails._id },
        "",
        this.PaymentInitiateResponse
      );
  }

  SubmitPaymentResponse = (data) => {
    if (data.result) {
      this.setState({
        InitiateResponse: data.result,
        PaymentModal: true
      })
    }
  }


  render() {
    // var currentday = new Date() / 1000;
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    var { selectedEvent, userevents } = this.props;
    if (!userevents)
      userevents = [];
    var userevent = userevents.filter(ue => (ue.Event && ue.Event._id) === selectedEvent._id) && userevents.filter(ue => (ue.Event && ue.Event._id) === selectedEvent._id)[userevents.filter(ue => (ue.Event && ue.Event._id) === selectedEvent._id).length - 1];
    if (!userevent) {
      userevent = {};
    }
    return this.state.loading ? (
      <Loader />
    ) : (
      <section className="bg-half-130 bg-primary  w-100 bg-overlay "
        style={{
          background: `url(${JSON.parse(sessionStorage.getItem("menu")) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname) && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0] && JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg ? JSON.parse(sessionStorage.getItem("menu")).filter(m => m.friendlyurl === window.location.pathname)[0].bg : bg}) no-repeat center center/cover fixed`,
        }}>

        <div className="row m-0">
          <div className={"col-12 col-lg-6"} style={{ marginTop: isMobile ? "20px" : "80px", marginLeft: isMobile ? "1px" : "60px" }}>
            {/* <div className="works-slides"> */}
            <div className="welcome-intro">
              <h3 className="text-white font-weight-bold mt-0" style={{ width: 'auto' }}>
                {(selectedEvent.startdate > (new Date() / 1000) || selectedEvent.enddate > (new Date() / 1000)) ?
                  <Link className="text-white" to="/upcoming-events">
                    Upcoming Events
                   </Link>
                  :
                  <Link className="text-white" to="/past-events">
                    Past Events
                 </Link>
                }
                {" > "}
                {selectedEvent && selectedEvent.name}
              </h3>
              <p className="text-white mt-3" style={{ fontSize: '20px' }}>
                {/* {this.state.initData.content} */}
              </p>
            </div>
          </div>

          <div className="col-12 col-lg-3" style={{ marginLeft: isMobile ? "1px" : '100px' }}>
            {/* Single Review */}
            <div className="key-feature border  p-2 card"
              style={{ borderRadius: '7px' }}>
              {/* Card Top */}

              {/* <img src={'img/EventIcon.png'} width='29px' height='29px' alt="source" /> */}




              <div className="card-top p-4 " style={{ marginLeft: '0px', }}>
                {this.props.singleevent ?
                  <div dangerouslySetInnerHTML={{
                    __html: this.props.singleevent.participation,
                  }}
                  />
                  :
                  <Row className="vertical-align">
                    <img className=' mb-2 ' src={"/img/EventIcon.png"} alt="source" />

                    <h4 className=" mb-2 ml-3 font-weight-bold"
                    >Event Date : {selectedEvent && new Date(selectedEvent.startdate * 1000).toLocaleString("en-IN", { day: "2-digit", month: "short", year: "numeric" })}</h4>
                  </Row>
                }
                {(selectedEvent.startdate > (new Date() / 1000) || selectedEvent.enddate > (new Date() / 1000)) &&
                  (userevent.Status === "Approved" && (userevent.Payment && userevent.Payment.Status !== "Completed"))
                  ?
                  <Button
                    color="primary"
                    className="font-16 btn-block"
                    onClick={() => this.PaymentInitiate()}
                  >  Make Payment
                </Button>
                  :
                  ((userevent.Status === "Rejected") || (!userevent.Status)) &&
                  <a href={"/event_application/" + selectedEvent.friendlyurl.split("/")[selectedEvent.friendlyurl.split("/").length - 1]} rel="noopener noreferrer" >
                    <button className="btn btn-white btn-header-primary mt-2" style={{ width: "100%" }}>
                      Apply
                    </button>
                  </a>
                }
              </div>

            </div>
          </div>
        </div>
        <Modal
          size="lg"
          className="viewDocumentModal"
          isOpen={this.state.PaymentModal}
          toggle={() => this.setState({
            PaymentModal: false
          })} >
          <div class="modal-header">
            <h5 class="modal-title">Payment Initiated</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({
              PaymentModal: false
            })}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody className="m-3" >
            <Row className="align-items-center">
              <Col lg={1} />
              <Col lg={10} className="mt-sm-0 pt-sm-0">
                <div class="pmt_bg">
                  <div class="pmt_card">

                    <span class="card__success">
                      <i class="fas fa-check"></i>
                    </span>

                    <h1 class="card__msg">{"Payment Initiated"}</h1>
                    <h2 class="card__submsg">Thank you for your interest on {selectedEvent ? selectedEvent.name : "Event"}</h2>
                    <div class="card__body">
                      <h1 class="card__price"><span>Rs. {this.state.InitiateResponse && this.state.InitiateResponse.pmtData && this.state.InitiateResponse.pmtData.transactionAmount}</span></h1>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <PaymentForm SubmitPayment={this.SubmitPayment} InitiateResponse={this.state.InitiateResponse} />
          </ModalBody>
        </Modal>
      </section>

    );
  }
}

export default Signup;
