import React, { Component } from "react";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import TeamDetails from "./TeamDetails";

import { Modal, ModalBody, ModalFooter, Button, Col } from "reactstrap";
// const BASE_URL = "https://my-json-server.typicode.com/themeland/json-server/themeOneTeamSection";

class Team extends Component {
  state = {
    data: {},
    teamData: [],
    teamIcons: [],
    ListGroupRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1000,
    },
    contents: [],
  };
  componentDidMount() {
    this.setState({
      data: {
        heading: "Governing Body",
        headingText: "",
        headingTexttwo: "",
      },

      teamData: [
        {
          id: 1,
          image: "/img/Person.png",
          title: "Person 1",
          teamPost: "Ex-officio Chairman",
        },
        {
          id: 2,
          image: "/img/Person.png",
          title: "Person 2",
          teamPost: "Managing Director",
        },
        {
          id: 3,
          image: "/img/Person.png",
          title: "Person 3",
          teamPost: "Ex-officio Member",
        },
        {
          id: 4,
          image: "/img/Person.png",
          title: "Person 4",
          teamPost: "Ex-officio Member",
        },
        {
          id: 5,
          image: "/img/Person.png",
          title: "Person 5",
          teamPost: "Ex-officio Member",
        },
        {
          id: 6,
          image: "/img/Person.png",
          title: "Person 6",
          teamPost: "Ex-officio Member",
        },
        {
          id: 7,
          image: "/img/Person.png",
          title: "Person 7",
          teamPost: "Member",
        },
        {
          id: 8,
          image: "/img/Person.png",
          title: "Person 8",
          teamPost: "Member",
        },
        {
          id: 9,
          image: "/img/Person.png",
          title: "Person 9",
          teamPost: "Member",
        },
      ],
      teamIcons: [
        {
          id: 1,
          iconClass: "fab fa-facebook-f",
        },
        {
          id: 2,
          iconClass: "fab fa-twitter",
        },
        {
          id: 3,
          iconClass: "fab fa-google-plus-g",
        },
        {
          id: 4,
          iconClass: "fab fa-linkedin-in",
        },
      ],
    });
    this.GetGroup();
    this.GetContent();
  }
  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "teamgroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
      });
  };
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "governingbody";
    ListRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result.length > 0 ? data.result : [],
      });
    }
  };
  getDetails = (item) => {
    this.setState({
      person: item,
    });
  };
  render() {

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return (
      <div>
        <div className="team-title">


          <div className="shape1">
            <img src={"img/shape1.png"} alt="shape" />
          </div>
          <div className="shape2 rotateme">
            <img src={"img/shape2.svg"} alt="shape" />
          </div>
          <div className="shape3">
            <img src={"img/shape3.svg"} alt="shape" />
          </div>
          <div className="shape4">
            <img src={"img/shape4.svg"} alt="shape" />
          </div>
          <div className="shape5">
            <img src={"img/shape5.png"} alt="shape" />
          </div>
          <div className="shape6 rotateme">
            <img src={"img/shape4.svg"} alt="shape" />
          </div>
          <div className="shape7">
            <img src={"img/shape4.svg"} alt="shape" />
          </div>
          <div className="shape8 rotateme">
            <img src={"img/shape2.svg"} alt="shape" />
          </div>
        </div>
        <section className="m-5">
          {this.state.groups &&
            this.state.groups.map((group) => {
              return (<div className="container">
                <h3 class="row mt-3 ml-4" >
                  {this.state.contents.filter(g => g.group && g.group._id === group._id).length > 0 && group.name}
                </h3>
                <div className="row ml-4">
                  {this.state.contents &&
                    this.state.contents.filter(g => g.group && g.group._id === group._id) &&
                    this.state.contents.filter(g => g.group && g.group._id === group._id).map((item, idx) => {
                      return (
                        <Col lg={item.columndivision ? item.columndivision : 4} md={6} xs={12} key={idx} className="mt-4 pt-2 pointer"
                          onClick={() => this.getDetails(item)}
                        >
                          <div className="border border-gray p-2 team-box" >
                            <Col xxs="12">
                              <img
                                style={isMobile ? { width: "55% " } : item.columndivision ? { width: "40% " } : { width: "30% " }}
                                src={item.photo}
                                className="avatar avatar-medium rounded-circle"
                                alt={item.name}
                              />
                            </Col>
                            <Col xxs="12">
                              <div
                                className="content ml-3 mt-3 pointer"
                              >
                                <h5 className="mb-0">{item.name}</h5>
                                <small className="position text-muted">
                                  {item.designation}
                                </small>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.remarks
                                  }} />
                              </div>
                            </Col></div>
                        </Col>
                      );
                    })}
                </div>
              </div>)
            })
          }
          <Modal
            className="viewDocumentModal"
            isOpen={this.state.person}
            toggle={() => this.setState({ person: "" })}
          >
            <ModalBody>
              <TeamDetails Person={this.state.person} />
            </ModalBody>
            <ModalFooter>
              <Button
                className="default modal-btn   btn-sm mb-2 btn-addon"
                color="danger"
                onClick={() => this.setState({ person: "" })}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      </div>
    );
  }
}

export default Team;
