import React, { Component, Fragment } from "react";

import { Row, Container } from "reactstrap";
import FooterSection from "../../components/FooterSection/Footer";
// import Register from "../../components/Events/upcomingRegister";
import Calendar from "../../components/Events/Calendarmini";
import EventsColumnOne from "./EventsColumnOne";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";


const options = [
  { value: 'All', label: 'All - Months' },
  { value: 'JAN', label: 'January' },
  { value: 'FEB', label: 'February' },
  { value: 'MAR', label: 'March' },
  { value: 'APR', label: 'April' },
  { value: 'MAY', label: 'May' },
  { value: 'JUN', label: 'June' },
  { value: 'JUL', label: 'July' },
  { value: 'AUG', label: 'August' },
  { value: 'SEP', label: 'September' },
  { value: 'OCT', label: 'October' },
  { value: 'NOV', label: 'November' },
  { value: 'DEC', label: 'December' },
]




var month = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

var currentday =
  new Date() /
  1000;
// var date = new Date();
// , y = date.getFullYear(), m = date.getMonth();
// var lastDay = new Date(y, m + 1, 0);
// var monthLastDay = lastDay / 1000


class Eventscomponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isOpen: false,
      currentpage: 1,
      selectmonthVal: "All",
      selectyearVal: "All"
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  monthTypeInOptions = () => {
    return options.map(o => {
      return (
        <option key={o} value={o.value}  >
          {o.label}
        </option>
      );
    });
  };

  handleChange = (e) => {
    this.setState({
      selectmonthVal: e.target.value,
    });
  };
  handleChangeYear = (e) => {
    this.setState({
      selectyearVal: e.target.value,
    });
  };

  unixmonthconversion = (show) => {
    var monthOfShow = month[
      new Date(
        show * 1000
      ).getMonth()
    ]
    return monthOfShow;
  };



  render() {
    const { status } = this.props;
    var { userevents } = this.props;
    const { selectmonthVal, selectyearVal } = this.state;
    const options = this.monthTypeInOptions();
    if (!userevents)
      userevents = [];
    // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    var EventTitle = status === "/upcoming-events" ? 'Upcoming Events' : 'Past Events';
    const year = (new Date()).getFullYear();
    var years = [];
    if (status === "/upcoming-events") {
      years = Array.from(new Array(10), (val, index) => year + index);

    }
    else { years = Array.from(new Array(10), (val, index) => year - index); }
    return (
      <div className="bg-white">
        <HeaderBreadcrumb />
        <section className="m-5">
          <Container>
            <Row>

              <Fragment >
                <div className="container mt-0" style={{ marginBottom: "5%" }}>
                  {/* Welcome Intro Start */}
                  <div className="row m-0">
                    <div className="col-lg-8 col-12">
                      <div className="welcome-intro mt-4">
                        <Row>
                          <div className="col-lg-6 col-12 mt-2">
                            <h3>{EventTitle}</h3>
                          </div>

                          <div className="col-lg-6 col-12">
                            <Row>
                              <div className="col-lg-6 col-12">
                                <select className='select-css' onChange={this.handleChange}
                                  defaultValue={selectmonthVal}>
                                  {options}
                                </select>
                              </div>
                              <div className="col-lg-6 col-12">
                                <select className='select-css' onChange={this.handleChangeYear}>
                                  <option key={`yearAll`} value={`All`}>{`All - Years`}</option>
                                  {
                                    years.map((year, index) => {
                                      return <option key={`year${index}`} value={year}>{year}</option>
                                    })
                                  }
                                </select>
                              </div>
                            </Row>
                          </div>

                        </Row>
                        <Row>
                          {(status === "/upcoming-events") && this.props.data.filter(
                            (e, i) =>
                            (
                              (e.startdate >= currentday || e.enddate >= currentday) &&
                              (selectmonthVal === "All" ? true : selectmonthVal === this.unixmonthconversion(e.startdate)) &&
                              (selectyearVal === "All" ? true : selectyearVal === (new Date(
                                e.startdate * 1000
                              ).getFullYear().toString())))
                          ).map((show, key) =>
                          (
                            key < 2 &&
                            <EventsColumnOne eventDetails={userevents.filter(ue => (ue.Event && ue.Event._id) === show._id)[userevents.filter(ue => (ue.Event && ue.Event._id) === show._id).length - 1]} show={show} key={key} selectmonthVal={selectmonthVal} selectyearVal={selectyearVal} history={this.props.history} />
                          )
                          )}


                          {(status === "/past-events") && this.props.data.filter(
                            (e, i) =>
                            (e.enddate <= currentday &&
                              (selectmonthVal === "All" ? true : selectmonthVal === this.unixmonthconversion(e.startdate)) &&
                              (selectyearVal === "All" ? true : selectyearVal === (new Date(
                                e.startdate * 1000
                              ).getFullYear().toString())))
                          ).map((show, key) =>
                          (
                            key < 2 &&
                            <EventsColumnOne eventDetails={userevents.filter(ue => (ue.Event && ue.Event._id) === show._id)[userevents.filter(ue => (ue.Event && ue.Event._id) === show._id).length - 1]} show={show} key={key} selectmonthVal={selectmonthVal} selectyearVal={selectyearVal} history={this.props.history} />
                          )
                          )}
                        </Row>
                        <div ></div></div>


                    </div>

                    <div className="col-lg-4 col-12">

                      <Calendar calendarEvents={this.props.calendarEvents} history={this.props.history} />
                      {/* right side code */}
                      {/* {(status === "/upcoming-events") && this.props.data.filter(
                          (e) =>
                            e.startdate >= currentday && e.startdate <= monthLastDay &&
                            (selectmonthVal === this.unixmonthconversion(e.startdate))
                        ).map((show, key) => (
                          <EventsColumnTwo show={show} key={key} />
                        )
                        )}
                        {(status === "/past-events") && this.props.data.filter(
                          (e) =>
                            e.startdate <= currentday &&
                            (selectmonthVal === this.unixmonthconversion(e.startdate))
                        ).map((show, key) => (
                          <EventsColumnTwo show={show} key={key} />
                        )
                        )} */}
                    </div>

                    <div className="col-12">
                      <Row>
                        {(status === "/upcoming-events") && this.props.data.filter(
                          (e, i) =>
                          (
                            (e.startdate >= currentday || e.enddate >= currentday) &&
                            (selectmonthVal === "All" ? true : selectmonthVal === this.unixmonthconversion(e.startdate)) &&
                            (selectyearVal === "All" ? true : selectyearVal === (new Date(
                              e.startdate * 1000
                            ).getFullYear().toString())))
                        ).map((show, key) =>
                        (
                          key >= 2 &&
                          <EventsColumnOne eventDetails={userevents.filter(ue => (ue.Event && ue.Event._id) === show._id)[userevents.filter(ue => (ue.Event && ue.Event._id) === show._id).length - 1]} show={show} key={key} selectmonthVal={selectmonthVal} selectyearVal={selectyearVal} history={this.props.history} />
                        )
                        )}


                        {(status === "/past-events") && this.props.data.filter(
                          (e, i) =>
                          (e.enddate <= currentday &&
                            (selectmonthVal === "All" ? true : selectmonthVal === this.unixmonthconversion(e.startdate)) &&
                            (selectyearVal === "All" ? true : selectyearVal === (new Date(
                              e.startdate * 1000
                            ).getFullYear().toString())))
                        ).map((show, key) =>
                        (
                          key >= 2 &&
                          <EventsColumnOne eventDetails={userevents.filter(ue => (ue.Event && ue.Event._id) === show._id)[userevents.filter(ue => (ue.Event && ue.Event._id) === show._id).length - 1]} show={show} key={key} selectmonthVal={selectmonthVal} selectyearVal={selectyearVal} history={this.props.history} />
                        )
                        )}
                      </Row>
                    </div>
                  </div>
                </div>
              </Fragment>
            </Row>
          </Container>
        </section>
        <FooterSection />

      </div >

    );
  }
}


export default Eventscomponent;
