/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import FooterSection from "../../components/FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import TextTruncate from "../../common/TextTruncate";
import { Col, Row } from "reactstrap";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";
class testinglab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      isOpen: false,
      isVisblecontent: ""
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetGroup();
    this.GetContent();
  }
  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "testinglab";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
        isVisblecontent: data.result[0],
      });
  };
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "knowledgecentercontent";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result,
        loading: false,
      });
    }
  };
  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: e,
      });
    }
  };
  render() {
    const { isVisblecontent } = this.state;

    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            <div className="container">
              <div className="row mb-5">
                <div className="col-12 col-lg-3">
                  <div>
                    <aside className="sidebar">
                      <div className="single-widget">
                        {/* Category Widget */}
                        <div
                          className="accordions widget catagory-widget"
                          id="cat-accordion"
                        >
                          <div className="single-accordion blog-accordion">
                            <h5>
                              <a
                                role="button"
                                className="collapse show text-uppercase d-block p-2"
                                // data-toggle="collapse"
                                href="/testinglab"
                              >
                                Testing Labs
                                  </a>
                            </h5>
                            {/* Category Widget Content */}
                            <div
                              id="accordion1"
                              className="accordion-content widget-content collapse show"
                              data-parent="#cat-accordion"
                            >
                              {/* Category Widget Items */}

                              {this.state.groups && isVisblecontent && (
                                <ul className="widget-items sidebar">
                                  {this.state.groups.map((item, idx) => {
                                    return (
                                      <li key={`wdo_${idx}`} className={(item._id === isVisblecontent._id) ? "epc-li selected-accordion" : "epc-li"}>
                                        <a
                                          role="button"
                                          className="collapse show text-uppercase d-block p-2 pointer"
                                          // data-toggle="collapse"
                                          // href="/Export-Schemes"
                                          onClick={() =>
                                            this.toggleSchemesTitle(item)
                                          }
                                        >
                                          <span>{item.name}</span>
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  {this.state.groups && isVisblecontent &&
                    this.state.groups.map((item, idx) => {
                      return (
                        item && isVisblecontent._id === item._id && (
                          <div key={`bt_${idx}`} className="col-12">
                            {/* Single Blog */}
                            {/* <div className="key-feature border res-margin"> */}
                            {/* Blog Content */}
                            <div className="blog-content p-2">
                              <h3>{item.name}</h3>
                              <p className="mt-2">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.details,
                                  }}
                                ></div>
                              </p>
                            </div>
                            {item.certificates &&
                              item.certificates.length > 0 &&
                              <div className="ml-2 mt-2">
                                <h5> <img src={item.certificateicon ? item.certificateicon : "/img/check.svg"} alt="check" className="mr-2" /> Certificates</h5>
                                {item.certificates &&
                                  item.certificates.map(cert =>
                                    <Fragment>
                                      <Row className="mt-2">
                                        <Col lg={1} />
                                        <Col lg={10} xxs={12} className="border border-radius row vertical-align">

                                          {(!isMobile) &&
                                            <Col lg={1} >
                                              <img src={cert.icon ? cert.icon : "/img/check.svg"} alt="check" />
                                            </Col>
                                          }

                                          <Col lg={11} >
                                            <TextTruncate icon={cert.icon} title={"Certificate"} length={100} value={cert.content} isHtml={true} />
                                          </Col>
                                        </Col>
                                        <Col lg={1} />
                                      </Row>
                                    </Fragment>
                                  )}
                              </div>
                            }
                            {item.services &&
                              item.services.length > 0 &&
                              <div className="ml-2 mt-2">
                                <h5> <img src={item.serviceicon ? item.serviceicon : "/img/check.svg"} alt="check" className="mr-2" /> Services</h5>
                                {item.services &&
                                  item.services.map(ser =>
                                    <Fragment>
                                      <Row className="mt-2">
                                        <Col lg={1} />
                                        <Col lg={10} xxs={12} className="border border-radius row vertical-align">
                                          {(!isMobile) &&
                                            <Col lg={1} >
                                              <img src={ser.icon ? ser.icon : "/img/check.svg"} alt="check" />
                                            </Col>
                                          }
                                          <Col lg={11} >
                                            <TextTruncate icon={ser.icon} title={"Services"} length={100} value={ser.name} />
                                          </Col>
                                        </Col>
                                        <Col lg={1} />
                                      </Row>
                                    </Fragment>
                                  )}
                              </div>
                            }
                            {item.products &&
                              item.products.length > 0 &&
                              <div className="ml-2 mt-2 mb-2">
                                <h5><img src={item.producticon ? item.producticon : "/img/check.svg"} alt="check" className="mr-2" />Products</h5>
                                <Fragment>
                                  <Row className="ml-3 mt-1">
                                    {item.products &&
                                      item.products.map(pr =>
                                        <Col lg={3} xxs={12} >
                                          {/* {(!isMobile) &&
                                            <Col lg={1} > */}
                                          <div className="text-center">
                                            <img src={pr.icon ? pr.icon : "/img/check.svg"} alt="check" />
                                          </div>   {/* </Col>
                                          } */}
                                          {/* <Col lg={11} > */}
                                          <div className="mt-2  text-center">
                                            <p>{pr.name}</p>
                                            <TextTruncate icon={pr.icon} title={pr.name} length={100} value={pr.detail} isHtml={true} />
                                          </div>   {/* </Col> */}
                                        </Col>
                                      )}
                                  </Row>
                                </Fragment>
                              </div>
                            }
                            {item.benefit &&
                              item.benefit.length > 0 &&
                              <div className="ml-2 mt-2">
                                <h5><img src={item.benefiticon ? item.benefiticon : "/img/check.svg"} alt="check" className="mr-2" />Benefits</h5>
                                {item.benefit &&
                                  item.benefit.map(ben =>
                                    <Fragment>
                                      <Row className="mt-2">
                                        <Col lg={1} />
                                        <Col lg={10} xxs={12} className="border border-radius row vertical-align">
                                          {(!isMobile) &&
                                            <Col lg={1} >
                                              <img src={ben.icon ? ben.icon : "/img/check.svg"} alt="check" />
                                            </Col>
                                          }
                                          <Col lg={11} >
                                            <TextTruncate icon={ben.icon} title={"Benefit"} length={100} value={ben.content} />
                                          </Col>
                                        </Col>
                                        <Col lg={1} />
                                      </Row>
                                    </Fragment>
                                  )}
                              </div>
                            }
                            {item.training &&
                              <div className="blog-content p-2">
                                <p className="mt-2">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.training[0] && item.training[0].content,
                                    }}
                                  ></div>
                                </p>
                              </div>
                            }
                            {item.equipment &&
                              item.equipment.length > 0 &&
                              <div className="ml-2 mt-2 mb-2">
                                <h5><img src={item.equipmenticon ? item.equipmenticon : "/img/check.svg"} alt="check" className="mr-2" />Equipments</h5>
                                <Fragment>
                                  <Row className="ml-3" style={{ backgroundColor: "aliceblue" }}>
                                    {item.equipment &&
                                      item.equipment.map(eq =>
                                        <Col lg={4} xxs={12} className="border">
                                          {/* {(!isMobile) &&
                                            <Col lg={1} > */}
                                          {/* <div className="text-center">
                                            <img src={eq.icon ? eq.icon : "/img/check.svg"} alt="check" />
                                          </div>  */}
                                          {/* </Col>
                                          } */}
                                          {/* <Col lg={11} > */}
                                          <div className="mt-2">
                                            {eq.name}
                                            <TextTruncate icon={eq.icon} title={eq.name} length={100} value={eq.detail} isHtml={true} />
                                          </div>   {/* </Col> */}
                                        </Col>
                                      )}
                                  </Row>
                                </Fragment>
                              </div>
                            }
                            {item.contact &&
                              item.contact.length > 0 &&
                              <div className="ml-2 mt-4">
                                <h5><img src={item.contacticon ? item.contacticon : "/img/check.svg"} alt="check" className="mr-2" />Contact</h5>
                                <Row className="ml-3" >
                                  {item.contact &&
                                    item.contact.map((obj) => {
                                      return (
                                        <div className="col-lg-6 col-12 mt-2" >
                                          <div className="contact-us border border-gray mb-4 contact-box  contact-box-transition p-4" style={{ minHeight: "300px" }}>

                                            <h4 style={{ minHeight: "50px" }}>{obj.designation}</h4>
                                            <ul>

                                              {obj.name && <li key={`ci_${1}`} className=" row">
                                                <div className="social-icon mr-3 col-1">
                                                  <i class="fas fa-user" />
                                                </div>

                                                <span className="media-body align-self-center col-10">
                                                  {obj.name}
                                                </span>
                                              </li>}


                                              {obj.address && <li key={`ci_${1}`} className=" row">
                                                <div className="social-icon mr-3 col-1">
                                                  <i className={"fas fa-paper-plane"} />
                                                </div>

                                                <span className="media-body align-self-center col-10">
                                                  {obj.address}
                                                </span>
                                              </li>
                                              }
                                              {obj.phonenumber &&

                                                <li key={`ci_${3}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-phone-square-alt"></i>
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.phonenumber}
                                                  </span>
                                                </li>
                                              }
                                              {obj.mobilenumber &&
                                                <li key={`ci_${2}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-mobile-alt" />
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.mobilenumber}
                                                  </span>
                                                </li>
                                              }
                                              {obj.email &&
                                                <li key={`ci_${2}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-envelope" />
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.email}
                                                  </span>
                                                </li>
                                              }
                                              {obj.website &&
                                                <li key={`ci_${2}`} className=" row">
                                                  <div className="social-icon mr-3 col-1">
                                                    <i class="fas fa-globe-americas" />
                                                  </div>
                                                  <span className="media-body align-self-center col-10">
                                                    {obj.website}
                                                  </span>
                                                </li>
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </Row>
                              </div>
                            }
                          </div>
                          // </div>
                        )
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
          <FooterSection />
        </div>
      );
  }
}

export default testinglab;
