/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { Fragment } from "react";
import React, { Component } from "react";

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
// Modal Video
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
// import images
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import 'react-vertical-timeline-component/style.min.css';
import { Container } from "reactstrap";
import { Fragment } from "react";
import ModalVideo from "react-modal-video";
import Lightbox from "react-image-lightbox";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import TextTruncate from "../../common/TextTruncate";

class HowToExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListGroupRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListAlbumRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1000,
      },
      ListRequestImageModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10000,
      },
      ListRequestVideoModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10000,
      },
      isOpen: false,
      isVisblecontent: ""
    };

    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetGroup();
    this.GetContent();
    this.GetAlbumContent();
  }
  GetGroup() {
    const { ListGroupRequestModel } = this.state;
    ListGroupRequestModel.TableName = "exportresourcesgroup";
    ListGroupRequestModel.sortCondition = { sortorder: 1 };
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListGroupRequestModel,
      "",
      this.GetGroupResponse
    );
  }

  GetGroupResponse = (data) => {
    if (data.result)
      this.setState({
        groups: data.result.length > 0 ? data.result : [],
      });
  };
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "exportresourcescontent";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        contents: data.result,
        loading: false,
      }, () => this.state.groups && this.toggleSchemesTitle(this.state.groups[0]));
    }
  };
  GetAlbumContent() {
    const { ListAlbumRequestModel } = this.state;
    ListAlbumRequestModel.TableName = "album";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListAlbumRequestModel,
      "",
      this.GetAlbumContentResponse
    );
  }

  GetAlbumContentResponse = (data) => {
    if (data.result) {
      this.setState({
        albumContents: data.result,
      });
    }
  };

  GetImages(albumid) {
    const { ListRequestImageModel } = this.state;
    ListRequestImageModel.TableName = "images";
    ListRequestImageModel.album = albumid;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestImageModel,
      "",
      this.GetImagesResponse
    );
  }

  GetImagesResponse = (data) => {
    if (data.result) {
      this.setState({
        Images: data.result,
      });
    }
  };
  GetVideos(albumid) {
    const { ListRequestVideoModel } = this.state;
    ListRequestVideoModel.TableName = "videos";
    ListRequestVideoModel.album = albumid;
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestVideoModel,
      "",
      this.GetVideosResponse
    );
  }

  GetVideosResponse = (data) => {
    if (data.result) {
      this.setState({
        Videos: data.result,
      });
    }
  };
  toggleSchemesTitle = (e) => {
    if (e) {
      this.setState({
        isVisblecontent: this.state.contents ? this.state.contents.find(c => c.group && c.group._id === e._id) : e,
      });
    }
  };


  render() {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const { photoIndex, isOpenImage, isVisblecontent } = this.state;

    return (

      <Container>
        <section id="blog" className="">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-3">
                <div>
                  <aside className="sidebar">
                    <div className="single-widget">
                      {/* Category Widget */}
                      <div
                        className="accordions widget catagory-widget"
                        id="cat-accordion"
                      >
                        <div className="single-accordion blog-accordion">
                          <div
                            type="button"
                            className="collapse show text-uppercase d-block p-2 text-primary"
                          >
                            <h5>export resources </h5>
                          </div>
                          {/* Category Widget Content */}
                          <div
                            id="accordion1"
                            className="accordion-content widget-content collapse show"
                            data-parent="#cat-accordion"
                          >
                            {/* Category Widget Items */}
                            <ul className="widget-items sidebar">
                              {this.state.groups && this.state.groups.map((item, idx) => {
                                return (
                                  !item.parentgroup &&
                                  <li key={`wdo_${idx}`} className={(item._id === (isVisblecontent.group && isVisblecontent.group._id)) ? "epc-li selected-accordion" : "epc-li"} >
                                    <a
                                      className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                      onClick={() =>
                                        this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) && this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).length === 0 && this.toggleSchemesTitle(item)
                                      }
                                    >
                                      <span>{item.name}</span>
                                      {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id) &&

                                        <ul className="widget-items sidebar">
                                          {this.state.groups.filter(itemsub => (itemsub.parentgroup && itemsub.parentgroup._id) === item._id).map((itemsub, idx) => {
                                            return (
                                              <li key={`wdo_${idx}`} className={(itemsub._id === (isVisblecontent.group && isVisblecontent.group._id)) ? "epc-li selected-accordion" : "epc-li"}>
                                                <a
                                                  role="button"
                                                  className="collapse show text-uppercase d-block p-2 pointer dropbtn"
                                                  style={{ zIndex: 10 }}
                                                  onClick={() =>
                                                    this.toggleSchemesTitle(itemsub)
                                                  }
                                                >
                                                  <span>{itemsub.name}</span>
                                                </a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      }
                                    </a>

                                  </li>
                                );
                              })}
                            </ul>

                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
              {/* <div className="col-12 col-lg-9">
                      <h3 className="collapse show mt-4 text-primary">
                        {isVisblecontent && isVisblecontent.name}
                      </h3>
                      <Row>
                        <KeyFeaturesBox keyFeatures={contents} policyType={isVisblecontent} />
                      </Row>
                    </div> */}



              <div className="col-12 col-lg-9 ">
                {/* {this.state.contents &&
                        this.state.contents.map((item, idx) => {
                          return (
                            item.group && isVisblecontent._id === item.group._id && ( */}
                <div key={`bt_${isVisblecontent._id}`} className="col-12">
                  {/* Single Blog */}
                  {/* <div className="key-feature border res-margin"> */}
                  {/* Blog Content */}
                  <h3>{isVisblecontent.title}</h3>
                  {isVisblecontent.description ? <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: isVisblecontent.description,
                      }}
                    />  </p>
                    :
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui qer
                          </p>
                  }
                </div>
                {/* )
                          );
                        })} */}
                {/* 
                      {this.state.contents &&
                        this.state.contents.map((item, idx) => {
                          return (
                            item.group && isVisblecontent._id !== item.group._id && (
                              <div key={`bt_${idx}`} className="col-12">
                                <div className="blog-content p-4">
                                  <strong>{isVisblecontent.name}</strong>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.
                                  </p>
                                </div>

                              </div>
                            )
                          );
                        })} */}
                {((isVisblecontent.title && isVisblecontent.title.toLowerCase()) === 'procedure for export') &&


                  <VerticalTimeline>
                    {this.props.verticalimgData &&
                      this.props.verticalimgData.map((item, idx) => {
                        return (
                          <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', textAlign: "justify" }}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                            // date={idx + 1}
                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                            icon={<img src={item.image} alt={idx} className={"timeline-icon"} />}
                          >
                            <h4 className="vertical-timeline-element-title">{item.title}</h4>
                            {/* <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4> */}
                            {item.content && (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                            )}
                          </VerticalTimelineElement>
                        )
                      })}
                  </VerticalTimeline>
                }
                {((isVisblecontent.title && isVisblecontent.title.toLowerCase()) === 'tutorials for export') &&
                  <Fragment>

                    {this.state.albumContents &&
                      this.state.albumContents.filter(c => (c.groupof && c.groupof.toLowerCase()) === 'tutorials for export').map(c =>
                        this.state.albumContents.filter(c => (c.groupof && c.groupof.toLowerCase()) === 'tutorials for export').length < 3 ?
                          <div className={isMobile ? "col-lg-11 col-12 res-margin  mt-4" : "col-lg-11 col-12 res-margin ml-5 mt-4"}>

                            <div className="row key-feature border p-2">
                              <div className="col-3 res-margin ">
                                <img src={c.thumbnail} alt={c.name} />
                              </div>
                              <div className="col-9">
                                <h5 className="text-primary1 mb-2" >
                                  {c.name}
                                </h5>
                                <TextTruncate isHtml={true} value={c.description} length={200} title={c.name} />
                                <div
                                  className="mt-4  mb-2"
                                >
                                  <Link style={{ width: "100%" }} to={c.friendlyurl}>
                                    <img src={"/img/book.svg"} width={30} alt={c.name} />
                                    <span style={{ color: "red", paddingLeft: "10px" }}>View Album</span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="col-lg-4 col-12 res-margin mt-4">
                            <div className="key-feature border p-2">

                              <img src={c.thumbnail} alt={c.name} />
                              <h5 className="text-primary1 mb-2 mt-2" >
                                {c.name}
                              </h5>
                              <TextTruncate isHtml={true} value={c.description} length={100} title={c.name} />

                              <div
                                className="mt-4 mb-2"
                              >
                                <Link style={{ width: "100%" }} to={c.friendlyurl}>
                                  <img src={"/img/book.svg"} width={30} alt={c.name} />
                                  <span style={{ color: "red", paddingLeft: "10px" }}>View Album</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                      )}
                    {this.state.selectedAlbum &&
                      <div>
                        <span className="navbar-brand-logo-text pl-3">{this.state.selectedAlbum.name}</span>
                        <div
                          className="btn float-right pointer"
                          onClick={() => this.setState({ selectedAlbum: "", Images: [], Videos: [] })}
                        >
                          Close
                              </div>
                      </div>
                    }
                    <br />
                    {this.state.selectedAlbum &&
                      <div className="row m-0">

                        {this.state.Images &&
                          this.state.Images.map((img, i) => {
                            return (
                              <div className="col-lg-6 pointer">
                                <div
                                  className="project-details-image"
                                  onClick={() =>
                                    this.setState({ isOpenImage: true, photoIndex: i })
                                  }
                                >
                                  <img
                                    src={img.image}
                                    alt="work"
                                  />
                                  <div>{img.description}</div>
                                  <FeatherIcon icon="camera" className="fea icon-sm" />
                                </div>
                              </div>
                            );
                          })}
                        {this.state.Videos &&
                          this.state.Videos.map((vid, i) => {
                            return (
                              <div className="col-lg-6 pointer">
                                <div
                                  className="project-details-image"
                                  onClick={() =>
                                    this.setState({
                                      isOpen: true,
                                      videoId: vid.videoid,
                                    })
                                  }
                                >
                                  <img
                                    src={vid.thumbnail}
                                    alt="work"
                                  />
                                  <div>{vid.description}</div>
                                  <FeatherIcon icon="video" className="fea icon-sm" />
                                </div>
                              </div>
                            );
                          })}
                        <ModalVideo
                          channel="youtube"
                          isOpen={this.state.isOpen}
                          videoId={this.state.videoId}
                          onClose={() => this.setState({ isOpen: false })}
                        />

                        {isOpenImage && (
                          <Lightbox
                            mainSrc={this.state.Images[photoIndex].image}
                            nextSrc={
                              this.state.Images[
                                (photoIndex + 1) % this.state.Images.length
                              ].image
                            }
                            prevSrc={
                              this.state.Images[
                                (photoIndex + this.state.Images.length - 1) %
                                this.state.Images.length
                              ].image
                            }
                            onCloseRequest={() => this.setState({ isOpenImage: false })}
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex:
                                  (photoIndex + this.state.Images.length - 1) %
                                  this.state.Images.length,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: (photoIndex + 1) % this.state.Images.length,
                              })
                            }
                          />
                        )}
                      </div>
                    }
                  </Fragment>
                }
              </div>
            </div>
          </div>

        </section>
      </Container>

    );
  }
}

export default HowToExport;
