import React from "react";
import FooterSection from "../../components/FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import Loader from "../../common/loader";
import TextTruncate from "../../common/TextTruncate";
import HeaderBreadcrumb from "../../components/HeaderSection/HeaderBreadcrumb";

class FinancialAssistance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ListRequestModel: {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 10,
      },
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "exportfinancial";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result,
        loading: false,
      });
    }
  };
  render() {
    const { content } = this.state;
    return this.state.loading ? (
      <Loader />
    ) : (
        <div className="bg-white">
          <HeaderBreadcrumb />
          <section className="m-5">
            {content && (
              <div className="container">
                <div className="row">
                  {this.state.content.map((obj) => {
                    return (
                      <div className="col-12 col-md-6 col-lg-6 mb-lg-5 res-margin">
                        {/* Single Review */}
                        <div className="key-feature border  p-2 card" style={{ minHeight: "250px" }}>
                          {/* Card Top */}
                          <div className="card-top p-2" >
                            <h4 className="text-primary">{obj.title}</h4>
                            {/* Review Text */}
                            <div className="review-text">
                              <p>
                                <TextTruncate isHtml={true} value={obj.content} length={350} title={obj.title} />
                                <a href={obj.link} target="_blank" rel="noopener noreferrer" >
                                  <div className=" bottom-align" style={{ left: 0 }}>
                                    <button className=" btn btn-white btn-header-primary float-right">
                                      Click Here
                                  </button>
                                  </div>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}{" "}
          </section>
          <FooterSection />
        </div>
      );
  }
}


export default FinancialAssistance;
