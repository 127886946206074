import React, { Component, Fragment } from 'react';
import {
    Container,
    Row,
    Col,
    FormGroup,
    Button,
    Label,
    CardBody,
    Card,
    Table,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import Header from '../HeaderSection/Header';
import DropDown from "../../common/DropDown";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { CallService } from '../../helpers/servicecall';
import { authenticate, entries, events } from '../../constants/config';
import { MethodType } from '../../constants/defaultValues';
import { Colxx } from "../../components/common/CustomBootstrap";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const initData = {
    heading: "Create an account!",
    content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.",
    formHeading: "Sign Up",
    formContent: "Fill all fields so we can get some info about you. We'll never send you spam",
    formText: "Already have an account?",
    btnText: "Sign Up",
    btnText_2: "Sign In"
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);
const validationSchema = Yup.object().shape({
    FirstName: Yup.string().required("Please enter valid First Name!"),
    LastName: Yup.string().required("Please enter valid Last Name!"),
    Gender: Yup.string().required("Please select valid Gender!"),
    EmailID: Yup.string().required("Please enter EmailID!")
        .matches(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}/, "Please enter valid Email ID!"),
    MobileNumber: Yup.string()
        .required("Please enter mobile number")
        .matches(/^((\+*)((0[ -]+)*|(91 )*)(\d{12}|\d{10}))|\d{5}([- ]*)\d{6}$/, "Invalid Mobile Number"),
    Password: Yup.string()
        .required("Please enter valid password")
        .min(8, "Minimum 8 digits").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Please enter - At least one digit , At least one lowercase character ,  At least one uppercase character , At least one special character , At least 8 characters in length."
        ),
    ConfirmPassword: Yup.string()
        .required("Please enter valid password")
        .min(8, "Minimum 8 digits").matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
            "Please enter - At least one digit , At least one lowercase character ,  At least one uppercase character , At least one special character , At least 8 characters in length."
        ),
});
class Signup extends Component {
    state = {
        initData: [],
        MajorProducts: [""],
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            isShown: false
        })
        this.GetMajorindustrieslist();
        // this.GetMajorProducts();
        this.GetTnmapDistricts();
    }

    GetTnmapDistricts() {
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
                TableName: "tnmap"
            },
            "",
            this.GetTnmapDistrictsResponse
        );
    }


    GetTnmapDistrictsResponse = (data) => {
        this.setState(
            {
                districts: data.result.sort((a, b) => a.name.localeCompare(b.name)).map(function (a) {
                    return { value: a._id, label: a.name };
                }),
            });
    };


    GetMajorindustrieslist() {
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
                TableName: "majorindustrylist"
            },
            "",
            this.GetMajorindustrieslistResponse
        );
    }

    GetMajorindustrieslistResponse = (data) => {
        if (data.result) {
            var majorindustrieslist = data.result.sort((a, b) => a.name.localeCompare(b.name)).map(r => {
                return ({ label: r.name, value: r._id })
            });
            this.setState({
                majorindustrieslist: majorindustrieslist
            })
        }
    }

    GetMajorProducts() {
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            {
                searchString: "",
                sortCondition: { _id: 1 },
                pageNumber: 1,
                pageLimit: 1000,
                TableName: "majorproducts"
            },
            "",
            this.GetMajorProductsResponse
        );
    }

    GetMajorProductsResponse = (data) => {
        if (data.result) {
            var majorproducts = data.result.sort((a, b) => a.name.localeCompare(b.name)).map(r => {
                return ({ label: r.name, value: r._id })
            });
            this.setState({
                majorproducts: majorproducts
            })
        }
    }




    handleSubmit = (values, errors) => {
        console.log("blog data1=>" + JSON.stringify(values));


        if (values.Password && values.ConfirmPassword) {
            var error = 0;
            if (values.Password !== values.ConfirmPassword) {
                error += 1;
                errors.setFieldError("ConfirmPassword", "Password and confirm password is different");
            }
            if (this.state.MajorIndustryOther && !values.MajorIndustryOther) {
                error += 1;
                errors.setFieldError("MajorIndustryOther", "Please enter valid major industry details");

            }
            if (error === 0) {
                console.log("blog data2=>" + JSON.stringify(values));
                this.toggleViewModal(values)
            }
        }
    }



    toggleViewModal = (row) => {
        this.setState({
            RowDetails: row,
            viewModal: !this.state.viewModal,
        });
    };


    RegisterModal = (row) => {
        CallService(
            authenticate.Signin,
            MethodType.PUT,
            false,
            row,
            "",
            this.RegisterResponse
        );
        this.setState({
            viewModal: !this.state.viewModal,
        });
    };


    RegisterResponse = (data) => {
        if (data.result) {
            toastr.success("Registered successfully, Please login to continue")
            this.props.history.push("/login");
        }
    }



    render() {

        const { RowDetails } = this.state;
        return (
            <div className="main">
                <Header imageData={"/img/logo-white.png"} />
                <div className=" homepage-5 inner-pages signup">
                    <div className="main login " style={{ marginTop: "5%" }}>
                        <section id="home" className="section welcome-area h-100vh bg-overlay d-flex align-items-center">


                            <Container>
                                <Row className="justify-content-center"
                                    style={{ marginTop: '20%' }}
                                >
                                    <Col lg="10">
                                        <Card className="border-0 rounded shadow mb-3">
                                            <CardBody className="p-5">

                                                <div className="contact-top text-center">
                                                    <h4 className="contact-title">{this.state.initData.formHeading}</h4>
                                                    <h6 className="text-secondary fw-3 py-3">{this.state.initData.formContent} </h6>
                                                </div>


                                                <Formik
                                                    initialValues={{}}
                                                    onSubmit={this.handleSubmit}
                                                    validateOnBlur={false}
                                                    validateOnChange={false}
                                                    validationSchema={validationSchema}
                                                >
                                                    {({ setFieldValue, errors }) => (
                                                        <Form className="av-tooltip tooltip-label-bottom">
                                                            <Row className="mt-4">

                                                                <Col md="6">
                                                                    <FormGroup className="position-relative">
                                                                        <Label className="requiredField ">First Name</Label>
                                                                        <Field
                                                                            name="FirstName"
                                                                            id="first"
                                                                            type="text"
                                                                            className="form-control border"
                                                                        />
                                                                        {errors.FirstName && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.FirstName}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md="6">
                                                                    <FormGroup className="position-relative">
                                                                        <Label className="requiredField ">Last Name</Label>
                                                                        <Field
                                                                            name="LastName"
                                                                            id="last"
                                                                            type="text"
                                                                            className="form-control border"
                                                                        />
                                                                        {errors.LastName && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.LastName}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>


                                                                <Col lg={6}>
                                                                    <FormGroup className="position-relative ml-3">
                                                                        <Row>
                                                                            <Label className="requiredField ">Gender</Label>
                                                                            <input type="radio" id="G1" name="Gender" value="Male" className="mt-1 ml-2" onChange={() =>
                                                                                setFieldValue("Gender", "Male")
                                                                            } />
                                                                            <label for="G1" className="ml-2"  >Male</label>
                                                                            <input type="radio" id="G2" name="Gender" value="Female" className="mt-1 ml-2" onChange={() =>
                                                                                setFieldValue("Gender", "Female")
                                                                            } />

                                                                            <label for="G2" className="ml-2">Female</label>
                                                                            <input type="radio" id="G3" name="Gender" value="Transgender" className="mt-1 ml-2" onChange={() =>
                                                                                setFieldValue("Gender", "Transgender")
                                                                            } />
                                                                            <label for="G3" className="ml-2" >Transgender</label>
                                                                        </Row>
                                                                        {errors.Gender && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.Gender}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md="6">
                                                                    <FormGroup className="position-relative">
                                                                        <Label className="requiredField ">Company Name</Label>

                                                                        <Field
                                                                            name="CompanyName"
                                                                            id="CompanyName"
                                                                            type="text"
                                                                            className="form-control border"
                                                                        />
                                                                        {errors.CompanyName && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.CompanyName}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md="6">
                                                                    <FormGroup className="position-relative">
                                                                        <Label className="requiredField "> (User ID) Email </Label>

                                                                        <Field
                                                                            name="EmailID"
                                                                            id="EmailID"
                                                                            type="text"
                                                                            className="form-control border"
                                                                        />
                                                                        {errors.EmailID && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.EmailID}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>


                                                                <Col md="6">
                                                                    <FormGroup className="position-relative">
                                                                        <Label className="requiredField">  Phone</Label>

                                                                        <Field
                                                                            name="MobileNumber"
                                                                            id="MobileNumber"
                                                                            type="number"
                                                                            className="form-control border"
                                                                        />
                                                                        {errors.MobileNumber && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.MobileNumber}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md="6">
                                                                    <FormGroup className="position-relative">

                                                                        <Label className="requiredField ">Password </Label>

                                                                        <Field
                                                                            id="Password"
                                                                            name="Password"
                                                                            type="password"
                                                                            className="form-control border"
                                                                        />
                                                                        {errors.Password && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.Password}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col md="6">
                                                                    <FormGroup className="position-relative">

                                                                        <Label className="requiredField ">Confirm Password </Label>

                                                                        <Field
                                                                            id="ConfirmPassword"
                                                                            name="ConfirmPassword"
                                                                            type="password"
                                                                            className="form-control border"
                                                                        />
                                                                        {errors.ConfirmPassword && (
                                                                            <div className="invalid-feedback d-block">
                                                                                {errors.ConfirmPassword}
                                                                            </div>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>

                                                            </Row>
                                                            <center>
                                                                <Button className='btn singleEvent  mt-2 mb-3' color="primary" type="submit">Submit</Button>
                                                            </center>
                                                        </Form>
                                                    )}
                                                </Formik>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>



                            <Modal size="lg" isOpen={this.state.viewModal} toggle={this.toggleViewModal}>
                                <ModalHeader toggle={this.toggleViewModal}>
                                    View Details
              </ModalHeader>
                                <ModalBody>
                                    {RowDetails &&
                                        <Fragment>


                                            <div class="alert alert-success fade show" role="alert">
                                                <center>Check Your Detail And Signup </center>
                                            </div>
                                            <Row className="mb-10">
                                                <Colxx xxs="4">
                                                    <Label>First Name</Label>
                                                </Colxx>
                                                {": "}
                                                <Colxx xxs="6">
                                                    {RowDetails
                                                        ? RowDetails.FirstName
                                                        : "-"}
                                                </Colxx>
                                            </Row>

                                            <Row className="mb-10">
                                                <Colxx xxs="4">
                                                    <Label>Last Name</Label>
                                                </Colxx>
                                                {": "}
                                                <Colxx xxs="6">
                                                    {RowDetails
                                                        ? RowDetails.LastName
                                                        : "-"}
                                                </Colxx>
                                            </Row>
                                            <Row className="mb-10">
                                                <Colxx xxs="4">
                                                    <Label>Gender</Label>
                                                </Colxx>
                                                {": "}
                                                <Colxx xxs="6">
                                                    {RowDetails
                                                        ? RowDetails.Gender
                                                        : "-"}
                                                </Colxx>
                                            </Row>

                                            <Row className="mb-10">
                                                <Colxx xxs="4">
                                                    <Label>Company Name</Label>
                                                </Colxx>
                                                {": "}
                                                <Colxx xxs="6">
                                                    {RowDetails
                                                        ? RowDetails.CompanyName
                                                        : "-"}
                                                </Colxx>
                                            </Row>
                                            <Row className="mb-10">
                                                <Colxx xxs="4">
                                                    <Label>Email</Label>
                                                </Colxx>
                                                {": "}
                                                <Colxx xxs="6">
                                                    {RowDetails
                                                        ? RowDetails.EmailID
                                                        : "-"}
                                                </Colxx>
                                            </Row>

                                            <Row className="mb-10">
                                                <Colxx xxs="4">
                                                    <Label>Mobile Number</Label>
                                                </Colxx>
                                                {": "}
                                                <Colxx xxs="6">
                                                    {RowDetails
                                                        ? RowDetails.MobileNumber
                                                        : "-"}
                                                </Colxx>
                                            </Row>
                                        </Fragment>
                                    }
                                </ModalBody>
                                <ModalFooter>

                                    <FormGroup className="float-sm-right ">
                                        <Button
                                            color="danger"
                                            className="ml-2"
                                            onClick={() => this.RegisterModal(RowDetails)}
                                        >
                                            Register
             </Button>
                                    </FormGroup>

                                    <FormGroup className="float-sm-right ">
                                        <Button
                                            color="danger"
                                            className="ml-2"
                                            onClick={() => this.toggleViewModal()}
                                        >
                                            Close
             </Button>
                                    </FormGroup>


                                </ModalFooter>
                            </Modal>


                        </section>
                    </div>
                </div>
            </div>
        );
    }
}

export default Signup;