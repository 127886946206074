import React, { Component } from "react";
import Header from "../HeaderSection/Header";
import FooterSection from "../FooterSection/Footer";
import { CallService } from "../../helpers/servicecall";
import { MethodType } from "../../constants/defaultValues";
import { authenticate, events } from "../../constants/config";
import { Button } from "reactstrap";
import toastr from "toastr";


const initData = {
  heading:
    "Welcome!",
  content: "Your first leap towards an all-encompassing platform assisting in the development of MSMEs all over Tamil Nadu.",
  formHeading: "Sign In to your FaMe TN account",
  formContent:
    "",
  formText: "Don’t have an account? Sign up here.",
  btnText: "Sign In",
  btnText_2: "Sign Up",
};

class Login extends Component {
  state = {
    initData: [],
  };
  componentDidMount() {
    var Userdetails = JSON.parse(sessionStorage.getItem("usercontent"));
    if (Userdetails) {
      sessionStorage.clear();
      toastr.success("Logged out successfully");
    }
    this.setState({
      initData: initData,
      EmailID: "",
      Password: "",
    });
  }

  Submit() {
    const { EmailID, Password } = this.state;
    const data = {
      EmailID,
      Password,
    }

    CallService(
      authenticate.Login,
      MethodType.POST,
      false,
      data,
      "",
      this.GetRegisterResponse
    )
  }

  GetRegisterResponse = (data) => {
    if (data.result) {
      sessionStorage.setItem("user_token", data.result.token)
      sessionStorage.setItem("usercontent", JSON.stringify(data.result))
      sessionStorage.setItem("userid", JSON.stringify(data.result._id))
      toastr.success("Welcome, " + data.result.FirstName + " " + data.result.LastName);
      this.props.history.push('/');
    };
  };


  render() {
    return (
      <div className="main">
        <Header imageData={"/img/logo-white.png"} />
        <div className=" homepage-5 inner-pages signup">
          <div className="main login " style={{ marginTop: "5%" }}>
            <section
              id="home"
              className="section welcome-area h-100vh bg-overlay d-flex align-items-center"
            >
              <div className="container pt-5">
                <div className="row align-items-center justify-content-center">
                  {/* Welcome Intro Start */}
                  <div className="col-12 col-lg-7">
                    <div className="welcome-intro">
                      <h2 className="text-white">
                        {this.state.initData.heading}
                      </h2>
                      <p className="text-white my-4">
                        {this.state.initData.content}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-md-8 col-lg-5">
                    {/* Contact Box */}
                    <div className="contact-box  contact-box-transition bg-white text-center rounded p-4 p-sm-5 mt-5 mt-lg-0 shadow-lg">
                      {/* Contact Form */}
                      <form id="contact-form">
                        <div className="contact-top">
                          <h4 className="contact-title">
                            {this.state.initData.formHeading}
                          </h4>
                          <h5 className="text-secondary fw-3 py-3">
                            {this.state.initData.formContent}
                          </h5>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-envelope-open" />
                                  </span>
                                </div>
                                <input
                                  type="email"
                                  className="form-control"
                                  name="email"
                                  value={this.state.EmailID}
                                  placeholder="Email"
                                  required="required"
                                  onChange={(e) =>
                                    this.setState({ EmailID: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fas fa-unlock-alt" />
                                  </span>
                                </div>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="password"
                                  value={this.state.Password}
                                  placeholder="Password"
                                  required="required"
                                  onChange={(e) =>
                                    this.setState({ Password: e.target.value })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <span className="d-block float-right">
                              <a href="/Forgot">
                                Forgot Password?
                              </a>
                            </span>
                          </div>
                          <div className="col-12">
                            <Button
                              className="btn btn-bordered w-100 mt-3 mt-sm-4"
                              // type="submit"
                              onClick={() => this.Submit()}
                            >
                              {this.state.initData.btnText}
                            </Button>
                            <div className="contact-bottom">
                              <span className="d-inline-block mt-3">
                                By signing up, you accept our{" "}
                                <a href="/#">Terms</a> &amp;{" "}
                                <a href="/#">Privacy Policy</a>
                              </span>
                            </div>
                          </div>
                          <div className="col-12">
                            <span className="d-block pt-2 mt-4 border-top">
                              {this.state.initData.formText}{" "}
                              <a href="/signup">
                                {this.state.initData.btnText_2}
                              </a>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* Shape Bottom */}
              <div className="shape-bottom">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1000 100"
                  preserveAspectRatio="none"
                >
                  <path
                    className="shape-fill"
                    fill="#FFFFFF"
                    d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7  c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4  c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
                  />
                </svg>
              </div>
            </section>
          </div>
        </div>
        <FooterSection />{" "}
      </div>
    );
  }
}

export default Login;
