import React, { Component, Fragment } from "react";
// import { Col, Row } from "reactstrap";

class SingleBlogDetails extends Component {
  state = {
    data: {},
    iconList: [],
    commentsData: [],
  };
  componentDidMount() {
    var currentContent = this.props.currentContent;
    this.setState({
      content: currentContent,
    });
  }
  render() {
    //  var { content } = this.props;
    // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    return (
      <div>
        {/* Single Blog Details */}
        <article className="single-blog-details">
          {/* Blog Thumb */}
          <span className="float-right pointer"
            style={{ color: "red" }}
            onClick={() => this.props.close()}
          >
            Close
           </span>
          <h4>{this.state.content && this.state.content.name}</h4>

          <div className="bar"></div>
          <div className="blog-thumb">
            {/* <a href="/#"><img src={"/img/blog-2_apply.png"} alt="source" /></a> */}
          </div>
          {/* Blog Content */}
          <div className="blog-content sApp-blog">
            {/* Meta Info */}
            <div className="meta-info d-flex flex-wrap align-items-center py-2">
              {/* <ul>
                                <li className="d-inline-block p-2">By <a className="text-primary" href="/#">{this.state.data.author}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.date}</a></li>
                                <li className="d-inline-block p-2"><a href="/#">{this.state.data.comments}</a></li>
                            </ul> */}
              {/* Blog Share */}
              <div className="blog-share ml-auto d-none d-sm-block">
                {/* Social Icons */}
                {/* <div className="social-icons d-flex justify-content-center">
                                    {this.state.iconList.map((item, idx) => {
                                        return (
                                            <a key={`bdi_${idx}`} className={item.link} href="/#">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div> */}
              </div>
            </div>
            {/* Blog Details */}
            <div className="blog-details">
              {this.state.content && this.state.content.content && (
                <Fragment>
                  {" "}
                  {/* <h5 className=" py-3">
                    <a href="/#">Benefits</a>
                  </h5> */}
                  <p
                    className="d-none d-sm-block"
                    dangerouslySetInnerHTML={{
                      __html: this.state.content && this.state.content.content,
                    }}
                  ></p>
                </Fragment>
              )}
            </div>
          </div>
        </article>
      </div>
    );
  }
}

export default SingleBlogDetails;
