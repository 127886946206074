import React, { Component } from 'react';
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";

const initData = {
    iconClass: "far fa-lightbulb text-primary mr-1",
    preHeading: "Awesome",
    preHeadingspan: "Interface",
    heading: "Simple & Beautiful Interface",
    headingText: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}


class Queries extends Component {
    state = {
        initData: {},
        data: [],
        ListRequestModel: {
            searchString: "",
            sortCondition: { _id: 1 },
            pageNumber: 1,
            pageLimit: 1000000,
        },
        Queries: [],
    }
    componentDidMount() {
        this.setState({
            initData: initData,
        })
        this.GetQueries();
    }

    GetQueries() {
        const { ListRequestModel } = this.state;
        ListRequestModel.TableName = "queries";
        //  ListRequestModel.searchString = "covid";
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModel,
            "",
            this.GetQueriesResponse
        );
    }

    GetQueriesResponse = (data) => {
        if (data.result) {
            this.setState({
                Queries: data.result,
            });
        }
    };


    render() {

        return (
            <div className="container mt-5">
                <div className="row">


                    <div className="col-12 mt-3">
                        {/* <div className="welcome-intro mb-0 text-white">
                            <h1>Lorem ipsum dolor sit amet</h1>
                        </div>
                        <div className="welcome-intro mb-2" style={{ color: '#3DCF8E' }}>
                            <h1>consectetur adipisicing elit</h1>
                        </div>
                        <div className="mb-5 text-white" style={{ width: '39%' }}>
                            <p>To be the springboard of professional and economic success for MSMEs, and leapfrog them onto the international stage.
                </p>
                        </div> */}
                        {/* App Screenshot Slider Area */}
                        <div className="key-success-stories text-center clear">
                            {/* THis  APi call Changed Ui  */}
                            {this.state.Queries && this.state.Queries.map((item, idx) => {
                                return (
                                    <div key={idx} class="item" tabindex="-1" style={{ display: "inline-block" }}>
                                        <div class="item-inner">
                                            <a href={`${item.friendlyurl}`}>
                                                <div class="img">
                                                    <img src={item.image} alt={item.title} />
                                                    <div className="">
                                                        <div className="banner-overlay bottom-align" style={{ borderRadius: "10px" }}>
                                                            <div className="" style={{ textAlign: "center" }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.content
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}




export default Queries;
