import React, { Component, Fragment } from "react";
import { CallService } from "../../helpers/servicecall";
import { MethodType } from "../../constants/defaultValues";
import { cms, entries } from "../../constants/config";
import { Link } from "react-router-dom";
import MissionSection from "../../pages/MissionSection";
import VisionSection from "../../pages/VisionSection";

const initData = {
  heading: "Welcome To FaMe TN",
  content:
    "Hon’ble Chief Minister of Tamil Nadu, under Rule 110, made the announcement on 13.06.2018 that... “in order to Internationalize the MSMEs in Tamil Nadu and to promote foreign collaboration and investments in MSME Sector, an autonomous body namely “MSME – Trade and Investment Promotion Bureau (FaMe TN)”  is to be established, which will function as a facilitating  agency for Single Window Clearance System for MSME investments, facilitate speedy and timely clearances, promote National and International investments in MSME Sector in Tamil Nadu, forge ties with trade  bodies,  Industrial associations and trade & investment promotion organizations to promote trading opportunity for MSMEs in Tamil Nadu”.",
};

class HeroSection extends Component {
  state = {
    data: {},
    features: [],
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1,
    },
  };
  componentDidMount() {
    this.setState({
      data: initData,
    });
    this.GetCMS();
    this.GetContent();
  }
  GetCMS() {
    const { ListRequestModel } = this.state;
    ListRequestModel.searchString = "welcome";
    CallService(
      cms.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetCMSResponse
    );
  }

  GetCMSResponse = (data) => {
    if (data.result) {
      var contResult = data.result.map((tag) => {
        return { Content: tag.Content.replace(/&lt;/g, "<"), Title: tag.Title };
      });
      var features = this.state.features;
      features = features.concat(contResult);

      this.setState({
        features: features,
      });
    }
  };


  GetContent() {
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      {
        searchString: "",
        sortCondition: { _id: 1 },
        pageNumber: 1,
        pageLimit: 1,
        TableName: "about"
      },
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
        loading: false,
      });
    }
  };
  render() {
    return (
      <Fragment>
        <div className="container mt-0">
          {/* Welcome Intro Start */}
          <div className="row m-0">
            <div className="col-lg-6 col-12">
              <div className="welcome-intro mt-4">
                <h2>{this.state.data.heading}</h2>
                <p className="my-4"
                  dangerouslySetInnerHTML={{
                    __html: this.state.features[0]
                      ? this.state.features[0].Content
                      : "",
                  }}
                />
                <Link to="/about">
                  <span className="text-info">
                    Know More About Us
                  </span>
                </Link>
                {/* <Link to="/scheme">
                  <button className="btn btn-white btn-header-primary">
                    Explore Schemes
                  </button>
                  </Link>*/}

                {/* Store Buttons */}
                {/* <div className="button-group store-buttons d-flex">
                  <a href="/#">
                    <img src={this.state.data.playImg} alt="" />
                  </a>
                  <a href="/#">
                    <img src={this.state.data.appleImg} alt="" />
                  </a>
                </div> */}

                <span className="d-inline-block text-white fw-3 font-italic mt-3">
                  {this.state.data.headingSlug}
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-12" >
              <video width="100%" height="100%" controls src={this.state.content && this.state.content.video && "https://mtipb-master.s3.ap-south-1.amazonaws.com/files/Fametnintro.mp4" + "#t=0.7"}>
                {/* <source  type="video/mp4" /> */}
              </video>
            </div>
            <div className="col-lg-12 col-12  mt-3">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <VisionSection />
                </div>
                <div className="col-lg-8 col-12">
                  <MissionSection />
                </div>
              </div>
            </div>

          </div>

          {/*<div class="col-md-4">
              <div class="section-title1">
                <h4 class="title">Our Vision</h4>
                <h6 class="text-primary mb-4"></h6>
                <p class="para text-muted mb-0 cmscontent1">
                  To promote Global Competitiveness of MSMEs in Tamil Nadu
                  leveraging technology , infrastructure and resources to bring
                  the State as the MSMEs capital of Asia and to be a catalyst
                  for the promotion of investments and exports across sectors
                  and regions.
                </p>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div class="col-md-5">
              <div class="section-title1">
                <h4 class="title">Our Mission</h4>
                <h6 class="text-primary mb-4"></h6>
                <p class="para text-muted mb-0 cmscontent1">
                  To be a repository of all MSME related information and a
                  single point contact for all MSME policies and schemes for
                  guidance and handholding the entrepreneurs in setting up
                  industries and connecting them with the global value chain and
                  to promote value addition, superior manufacturing adopting
                  quality standards for global competiveness.
                </p>
              </div>
                </div>*/}

          {/* <div className="col-1"></div>
            <div className="col-3">
              <div className="welcome-thumb mx-auto">
                <img src={this.state.data.heroThumb} alt="" />
              </div>
            </div> */}

          {/* <Row>
            <Col lg={7}> */}
          {/* <Col lg={4} className="mt-4 pt-2 Scheme-Title-Card">
                <Card
                  style={{
                    minHeight: "115px",
                    background: "url('/img/scheme1.jpg')",
                  }}
                  className="scheme-tile rounded border  text-center"
                >
                  <Link to="/Scheme/GOI">
                    <CardBody>
                      <Media>
                        <Media body className="content">
                          <p
                            className="text-white location-time"
                            style={{
                              fontSize: "1.2em",
                              fontWeight: "bold",
                            }}
                          >
                            Goverment Of India Schemes
                          </p>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </Card>
              </Col>*/}
          {/*  <Col lg={4} className="mt-4 pt-2">
                <Card
                  style={{
                    minHeight: "115px",
                    background: "url('/img/scheme2.jpg')",
                  }}
                  className="scheme-tile rounded border  text-center"
                >
                  <Link to="/Scheme/GOTN">
                    <CardBody>
                      <Media>
                        <Media body className="content">
                          <p
                            className="text-white location-time"
                            style={{
                              fontSize: "1.2em",
                              fontWeight: "bold",
                            }}
                          >
                            Goverment Of Tamil Nadu Schemes
                          </p>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </Card>
              </Col>*/}
          {/*<Col lg={4} className="mt-4 pt-2">
                <Card
                  style={{
                    minHeight: "115px",
                    background: "url('/img/scheme3.jpg')",
                  }}
                  className="scheme-tile rounded border text-center"
                >
                  <Link to="/scheme">
                    <CardBody>
                      <Media>
                        <Media body className="content">
                          <p
                            className="text-white location-time"
                            style={{
                              fontSize: "1.2em",
                              fontWeight: "bold",
                            }}
                          >
                            Explore schemes
                          </p>
                        </Media>
                      </Media>
                    </CardBody>
                  </Link>
                </Card>
           </Col>*/}
          {/* </Col>
            <Col lg={5}></Col>
          </Row> */}
        </div>

        {/* <div className="shape-bottom">
          <svg
            viewBox="0 0 1920 310"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className="svg replaced-svg"
          >
            <title>sApp Shape</title>
            <desc></desc>
            <defs />
            <g
              id="sApp-Landing-Page"
              stroke="none"
              strokeWidth={1}
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="sApp-v1.0"
                transform="translate(0.000000, -554.000000)"
                fill="#FFFFFF"
              >
                <path
                  d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                  id="sApp-v1.0"
                />
              </g>
            </g>
          </svg>
        </div> */}
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </Fragment>
      // </section>
    );
  }
}

export default HeroSection;
