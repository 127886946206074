import React, { Component } from "react";
import { Link } from "react-router-dom";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";

class Cta extends Component {
  state = {
    data: {},
    features: [],
    ListRequestModel: {
      searchString: "",
      sortCondition: { _id: 1 },
      pageNumber: 1,
      pageLimit: 1,
    },
  };
  componentDidMount() {
    this.GetContent();
  }
  GetContent() {
    const { ListRequestModel } = this.state;
    ListRequestModel.TableName = "homesections";
    ListRequestModel.searchString = "covid";
    CallService(
      entries.GetAll,
      MethodType.POST,
      false,
      ListRequestModel,
      "",
      this.GetContentResponse
    );
  }

  GetContentResponse = (data) => {
    if (data.result) {
      this.setState({
        content: data.result[0],
      });
    }
  };
  render() {
    const { content } = this.state;
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


    var menucontent = JSON.parse(sessionStorage.getItem("menucontent"));
    var menu = JSON.parse(sessionStorage.getItem("menu"));
    if (menu && menucontent)
      var currentContent = menucontent.find(m => (m.menu && m.menu._id) === (menu.find(m => m.friendlyurl === "/scheme") && menu.find(m => m.friendlyurl === "/scheme")._id));
    return (
      <React.Fragment>

        {content && (
          <div className="container features-area ">
            {isMobile?
            <section className="repair-cta-area" 
            style={{ height: "640px"  }}>

              <div className="row align-items-center">
                <div className="col-lg-6 col-12">
                  <div className={"cta-repair-content mt-3 "} >
                    <h3 className={"ml-4"}>{content.title}</h3>

                      <p
                    className= "ml-3"
                      style={{ width: "369px" ,textAlign:"justify" }}
                      dangerouslySetInnerHTML={{
                        __html: currentContent && currentContent.content,
                      }}
                    />
                    
                    {/* <Link href={content.link}>
                    <button className="btn btn-primary">
                      {content.linklabel}
                    </button>
                  </Link>*/}
                    <Link to="/scheme">
                      <button className={"btn scheme ml-4"}>
                        Schemes
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="circle-box">
                <img src={"/img/SchemeMask Group.png"} width='1350px' height='329px' alt="source" />
              </div>

              <div className="cta-shape"
                style={{ marginRight: '20px' }}>
                <img src={content.image ? content.image : "/img/flower_pot.png"} alt="source" height='290px' />
              </div>
            </section>:
            <section className="repair-cta-area">

              <div className="row align-items-center">
                <div className="col-lg-6 col-12">
                  <div className={"cta-repair-content mt-3 ml-5"} >
                    <h3>{content.title}</h3>

                    
                    <p
                      style={{ width: "630px" ,textAlign:"justify" }}
                      dangerouslySetInnerHTML={{
                        __html: currentContent && currentContent.content,
                      }}
                    />

                    
                    
                    {/* <Link href={content.link}>
                    <button className="btn btn-primary">
                      {content.linklabel}
                    </button>
                  </Link>*/}
                    <Link to="/scheme">
                      <button className={"btn scheme"}>
                        Schemes
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="circle-box">
                <img src={"/img/SchemeMask Group.png"} width='1350px' height='329px' alt="source" />
              </div>

              <div className="cta-shape"
                style={{ marginRight: '20px' }}>
                <img src={content.image ? content.image : "/img/flower_pot.png"} alt="source" height='290px' />
              </div>
            </section>
            }
          </div>
        )}

      </React.Fragment>

    );
  }
}

export default Cta;
