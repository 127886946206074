import React, { Component } from 'react';
import {
} from "reactstrap";
import { entries } from "../../constants/config";
import { MethodType } from "../../constants/defaultValues";
import { CallService } from "../../helpers/servicecall";

class Feedback extends Component {
    state = {
        features: [],
        ListRequestModel: {
            searchString: "",
            sortCondition: { _id: 1 },
            pageNumber: 1,
            pageLimit: 10,
        },
    };
    componentDidMount() {
        this.GetContent();
    }
    GetContent() {
        const { ListRequestModel } = this.state;
        ListRequestModel.TableName = "testimonials";
        CallService(
            entries.GetAll,
            MethodType.POST,
            false,
            ListRequestModel,
            "",
            this.GetContentResponse
        );
    }

    GetContentResponse = (data) => {
        if (data.result) {
            this.setState({
                content: data.result,
            });
            setTimeout(() => {
                this.setState({
                    elipsvisi: true,
                })
            }, 5000)
        }
    };

    render() {
        const { content } = this.state;
        var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        return (
            <section className="testimonial-area mt-5 mb-5">
                <div className="container text-center">
                    <div className="section-title st-fs-28">
                        <h2 className="feedbacksub-title">Testimonials</h2>
                        {/* <h2>Our Clients Feedback</h2> */}
                        {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p> */}
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 ">
                            <div className="testimonials owl-carousel">
                                {/* <OwlCarousel
                                startPosition={1}
                                // className="section h-100vh bg-overlay d-flex align-items-center"
                                className="testimonials owl-carousel owl-theme"
                                {...slideOptions}
                            > */}
                                {/* Single Testimonial */}
                                {content && content.map((item, idx) => {
                                    return (
                                        <div key={`${idx}`}>
                                            <div key={`rt_${idx}`} className="single-testimonial p-3 p-md-5" style={{ verticalAlign: "center" }}
                                            >
                                                <div class={isMobile ? "" : "row"}>
                                                    <img src={item.image} className="mx-auto d-block ml-2" alt="" style={{ marginTop: '30px' }}
                                                    />
                                                    <div class={isMobile ? "testimonial-content" : " testimonial-content col p-4"} >
                                                        {/* Client Name */}
                                                        <h3 className=" mt-2 mb-2" style={{ textAlign: 'left', marginLeft: '40px', marginTop: '20px' }}>{item.name}</h3>
                                                        {/* Client Address */}
                                                        <h5 className=" fw-4 mb-2" style={{ textAlign: 'left', marginLeft: '40px' }}>{item.designation}</h5>
                                                        {/* Client Rating */}
                                                        {/* Client Description */}
                                                        <div className="client-description">
                                                            {/* Client Text */}
                                                            <div className="client-text" style={{ textAlign: 'left', marginLeft: '40px' }}>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.description,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {/* </OwlCarousel> */}
                            </div>
                            {this.state.elipsvisi &&
                                <img src="/img/elipsisbot.svg" alt="testi" style={{
                                    float: "right",
                                    marginTop: "-12%"
                                }} />
                            }
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Feedback;